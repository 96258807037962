import React from 'react';
import { connect } from 'react-redux';
import { push } from "connected-react-router";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert, Row, Col, Input, Button } from 'reactstrap';
import FullPageLoader from '../../components/loaders/FullPageLoader/FullPageLoader';
import ProductCard from './ProductCard';
import * as actions from '../actions';
import './CatalogPage.css';
import { addFavorites, removeFavorites, loadFavoriteLocal } from '../../favorites/actions';
import { sendAmplitudeData, amplitudeTypes } from '../../utils/amplitude';
import ProposeSketch from '../../autoBuild/ProposeSketch/ProposeSketch';
import FiltrView from '../../components/filtrView/filtView';
import ProductDetailsV3 from './ProductDetailsV3';
import { isMobile } from 'react-device-detect';
import VideoInfoView from '../../components/infoView/VideoInfoView';
import { MdClose } from 'react-icons/md';
import { getRegions } from '../../projects/newproject/actions';
import RegionSelect from '../../manufacturer/manufacturerPriceList/RegionSelect';
import InfoView from '../../components/infoView/InfoView';

class CatalogPageV3 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productType: -1,
            selectedItemId: -1,
            dimentionStr: '10',
            dimention: 1,
            selectedRegionId: -1,
            isSmallBottomMode: false
        };
    }

    componentDidMount() {
        this.props.getProductTypes();
        this.props.clearClosedProducts();

        sendAmplitudeData(amplitudeTypes.OPEN_CATALOG_PRODUCTS, null);
    }

    componentDidUpdate(prevProps) {
        if (this.props.catalogProducts !== prevProps.catalogProducts && this.props.catalogProducts.length > 0) {
            const id = this.props.match.params.productId ? parseInt(this.props.match.params.productId) : this.props.catalogProducts[0].id;
            this.onItemSelect(id);
            if (isMobile) {
                this.onTouchEndBottom();
            }
        }
        if (this.state.approximateFlag == true) {
            this.setState({ lastApproximate: this.state.approximateSize, approximateFlag: false });
        }
        if (this.props.productTypes != prevProps.productTypes) {
            this.props.productTypes.forEach((item) => {
                if (item.url && item.url == this.props.match.params.productType) {
                    if (this.state.productType != item.id) {
                        let url = '/kovanie-izdeliya-ceni' + (item.url ? '/' + item.url : "/notset");
                        if (this.props.match.params.regionKey) {
                            url += "/" + this.props.match.params.regionKey;
                        }
                        if (this.props.match.params.productId) {
                            url += "/" + this.props.match.params.productId;
                        }
                        this.onProductTypeChangeV2(item.id, url);
                    }
                }
            });
        }
        if (this.props.regions != prevProps.regions) {
            this.props.regions.forEach((item) => {
                if (item.key && item.key == this.props.match.params.regionKey) {
                    if (this.state.selectedRegionId != item.id) {
                        localStorage.setItem("selectedRegionId", item.id);
                        let url = '/kovanie-izdeliya-ceni' + (this.props.match.params.productType ? '/' + this.props.match.params.productType : "/notset");
                        url += "/" + item.key;
                        if (this.props.match.params.productId) {
                            url += "/" + this.props.match.params.productId;
                        }
                        this.onRegionSelectedV2(item.id, url);
                    }
                }
            });
        }
    }

    onProductTypeChange = (id) => {
        this.props.productTypes.forEach((item) => {
            if (id == item.id) {
                let url = '/kovanie-izdeliya-ceni' + (item.url ? '/' + item.url : "/notset");
                if (this.props.match.params.regionKey) {
                    url += "/" + this.props.match.params.regionKey;
                }
                if (this.props.match.params.productId) {
                    url += "/" + this.props.match.params.productId;
                }
                this.onProductTypeChangeV2(item.id, url);
            }
        });
    }

    onProductTypeChangeV2 = (id, url) => {
        if (id != this.state.productType) {
            /*if (window.location.pathname!=url) {
                window.location.pathname = url;
            }*/
            this.props.push(url);
            //else {
            if (id == -1) {
                this.setState({ approximateSize: 0 });
            }
            this.setState({
                productType: id,
                //dimention: null,
                //dimentionStr: (id == 6 || id == 9 || id == 10 || id == 11 || id == 13) ? '1' : '10',
                selectedItemId: -1
            });
            this.props.getClosedProducts({
                productType: id,
                includeCatalogProducts: true,
                manufacturerRegionId: this.state.selectedRegionId
            });
        }
    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onConstructorOpenClick(id) {
        this.props.constructorOpenClick(id);
    }

    onItemSelect = (itemId) => {
        this.setState({
            selectedItemId: itemId
        }, () => {
        });
    }

    onCloseDetails = () => {
        this.setState({
            selectedItemId: -1
        });
    }

    showCatalogClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            dimention: parseFloat(this.state.dimentionStr)
        });
        this.props.getClosedProducts({
            productType: this.state.productType,
            includeCatalogProducts: true,
            manufacturerRegionId: this.state.selectedRegionId
        });
    }

    onChangeDimentionClick = () => {
        this.setState({
            dimention: null,
            dimentionStr: this.state.dimention,
            selectedItemId: -1
        });
    }

    onRegionSelected = (regionId) => {
        if (regionId != -1) {
            this.props.regions.forEach((item) => {
                if (regionId == item.id) {
                    //localStorage.setItem("selectedRegionId", item.id);
                    let url = '/kovanie-izdeliya-ceni' + (this.props.match.params.productType ? '/' + this.props.match.params.productType : "/notset");
                    url += "/" + item.key;
                    if (this.props.match.params.productId) {
                        url += "/" + this.props.match.params.productId;
                    }
                    this.onRegionSelectedV2(item.id, url);
                }
            });
        }
        else {
            this.setState({ selectedRegionId: regionId });
            if (this.state.dimention && this.state.productType != -1) {
                this.props.getClosedProducts({
                    productType: this.state.productType,
                    includeCatalogProducts: true,
                    manufacturerRegionId: regionId
                });
            }
            let url = '/kovanie-izdeliya-ceni';
            if (this.props.match.params.productId) {
                url += (this.props.match.params.productType ? '/' + this.props.match.params.productType : "/notset") + "/all/" + this.props.match.params.productId;
            }
            this.props.push(url);
        }
    }

    onRegionSelectedV2 = (regionId, url) => {
        if (regionId != this.state.selectedRegionId) {
            this.props.push(url);
            this.setState({ selectedRegionId: regionId });
            if (this.state.dimention && this.state.productType != -1) {
                this.props.getClosedProducts({
                    productType: this.state.productType,
                    includeCatalogProducts: true,
                    manufacturerRegionId: regionId
                });
            }
        }
    }

    onTouchStartBottom = () => {
        //if (isMobile) {
        //    clearTimeout(this.startTimeoutSM);
        //    this.setState({
        //        isSmallBottomMode: false
        //    });
        //}
    }

    onTouchEndBottom = () => {
        //if (isMobile && this.props.catalogProducts.length != 0) {
        //    this.startTimeoutSM = setTimeout(() => {
        //        this.setState({
        //            isSmallBottomMode: true
        //        });
        //    }, 3000);
        //}
    }

    render() {
        const { t, loading, failed, catalogProducts, isAuthenticated, productTypes, isForMyProject } = this.props;
        const { selectedItemId, dimention, isSmallBottomMode } = this.state;

        let pTypes = productTypes;

        return (
            <div>
                {loading &&
                    <FullPageLoader />
                }
                {failed &&
                    <Alert color="danger">
                        {t('errorDataLoad')}
                    </Alert>
                }
                <FiltrView
                    show={!this.props.match.params.productType}
                    items={pTypes}
                    startId={this.state.productType}
                    onChangeFiltr={this.onProductTypeChange}
                    t={t}
                    showInHeader={isMobile}
                />

                {!isMobile ?
                    <div style={{ width: "100%", display: 'flex' }}>
                        <div id="catalogContainerv3" style={{ width: (selectedItemId != -1 && !isMobile) ? "60%" : "100%" }} className={!isMobile ? "catalog-pane" : "catalog-pane-mobile"}>
                            {this.renderCatalog()}
                        </div>
                        {selectedItemId != -1 && !isMobile &&
                            <div style={{ width: "40%" }} className="catalog-pane" id="pdRightContainer">
                                <ProductDetailsV3
                                    productId={selectedItemId}
                                    productItem={catalogProducts.find(p => p.id == selectedItemId)}
                                    userDimention={dimention}
                                    regionId={this.state.selectedRegionId}
                                    onRegionSelected={this.onRegionSelected}
                                />
                            </div>
                        }
                    </div>
                    :
                    <div style={{ height: "calc(100vh - 70px)" }}>
                        {selectedItemId != -1 &&
                            <div className={!isSmallBottomMode ? "cpTop-mobile" : "cpTop-mobile-small"}>
                                <ProductDetailsV3
                                    productId={selectedItemId}
                                    productItem={catalogProducts.find(p => p.id == selectedItemId)}
                                    userDimention={dimention}
                                    regionId={this.state.selectedRegionId}
                                    onRegionSelected={this.onRegionSelected}
                                />
                            </div>
                        }
                        <div id="catalogContainerv3" className="cpBottom-mobile" onTouchStart={this.onTouchStartBottom} onTouchEnd={this.onTouchEndBottom}>
                            {this.renderCatalog()}
                        </div>
                    </div>
                }

            </div>
        );
    }

    renderCatalog() {
        const { t, isAuthenticated, productTypes } = this.props;
        const { selectedItemId, dimentionStr, dimention, isSmallBottomMode } = this.state;
        const catalogProductsFiltered = this.props.catalogProducts;
        const pTypes = [{ id: -1, name: t('catalogPage.all') }, ...productTypes];
        let selectedType = pTypes.find((t) => t.id == this.state.productType);

        return (
            <>
                {(!isMobile || !dimention) &&
                    <>
                        <h3 className={!isMobile ? "align-center mt-5" : "align-center"}>
                            {t('catalogPage.catalogHeader')} {selectedType && ' - ' + selectedType.name}
                            {/*selectedType && dimention &&
                                <>
                                    <span>({dimention}{selectedType.generalUnits})</span>
                                    <Button color="success" size="sm" className="ml-1" onClick={this.onChangeDimentionClick}>{t('catalogPage.changeDimention')}</Button>
                                </>
                            */}
                        </h3>
                    </>
                }

                <RegionSelect
                    t={t}
                    getRegions={this.props.getRegions}
                    regions={this.props.regions}
                    onRegionSelected={this.onRegionSelected}
                    topLabel={t('catalogPage.actualPrices')}
                    style={{ display: !dimention || !isMobile ? "block" : "none" }}
                    className="align-center"
                    regionId={this.state.selectedRegionId}
                />

                {!dimention ?
                    <>
                        <div className="c-dimention-container mt-4" style={!isMobile ? {} : { flexWrap: 'wrap' }}>
                            <div>{t('catalogPage.enterDimention')}</div>
                            <Input className="ml-2 mr-1" type="number" step={0.1} style={{ maxWidth: '100px' }} name="dimentionStr" value={dimentionStr} onChange={this.onInputChange} />
                            <div>{selectedType && selectedType.generalUnits}</div>
                            <a className="btn btn-success ml-2" onClick={this.showCatalogClick}>{t('catalogPage.showButton')}</a>
                        </div>
                    </>
                    :
                    <div>
                        {!isMobile &&
                            <InfoView
                                title={t('catalogPage.infoTitlev23')}
                                line1={t('catalogPage.infoLine1v23')}
                                line2={t('catalogPage.infoLine2v23')}
                                showIcon={true}
                            />
                        }
                        <div className={!isMobile ? "catalog-item-container" : "catalog-item-container-mobile"}>
                            {catalogProductsFiltered.map((item, index) => (
                                <div id={`catalogItem-${item.id}`} key={!item.isNoContent ? item.id : "D" + item.id} onClick={() => this.onItemSelect(item.id)}>
                                    <ProductCard
                                        item={item}
                                        t={t}
                                        isAuthenticated={isAuthenticated}
                                        addFavorites={this.props.addFavorites}
                                        removeFavorites={this.props.removeFavorites}
                                        loadFavoriteLocal={this.props.loadFavoriteLocal}
                                        onConstructorOpenClick={this.props.constructorOpenClick}
                                        isSelected={item.id == this.state.selectedItemId}
                                        productType={productTypes.find(t => t.id == item.productTypeId)}
                                        dimention={dimention}
                                        isSmallMode={isSmallBottomMode}
                                    />
                                </div>
                            ))}
                            {!isSmallBottomMode &&
                                < ProposeSketch style={{ width: "240px", height: !isMobile ? "initial" : "240px" }} />
                            }
                        </div>
                        {!isMobile &&
                            <div className="mt-2">
                                <VideoInfoView videoKey="CatalogV3" />
                            </div>
                        }
                    </div>
                }

            </>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.home,
    isAuthenticated: state.login.isAuthenticated,
    productTypes: state.home.productTypes,
    regions: state.createProject.regions,
})

const mapDispatchToProps = {
    getClosedProducts: actions.getClosedProducts,
    clearClosedProducts: actions.clearClosedProducts,
    getProductTypes: actions.getProductTypesV2,
    addFavorites: addFavorites,
    removeFavorites: removeFavorites,
    loadFavoriteLocal: loadFavoriteLocal,
    constructorOpenClick: actions.constructorOpenClick,
    flush3dError: actions.flush3dError,
    push: push,
    getRegions: getRegions
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CatalogPageV3));