import { ProductTypes } from '../constants/productTypes';
import { PublicationTypes } from '../constants/publicationTypes';

export default class ProjectsExecuterHelper {
    static getProducatSizeText = (product) => {
        if (product.dimention == null) {
            return "";
        }

        switch (product.subTypeKey) {
            case ProductTypes.SubtypeBalcon_P:
                return product.dimention.depth + "x" + product.dimention.width;
            case ProductTypes.SubtypeBalcon_French:
                return product.dimention.depth + "x" + product.dimention.width;
        }
        if (product.dimention != null) {
            return product.dimention.depth + "x" + product.dimention.width + "x" + product.dimention.height;
        }
        return "";
    }
}