import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    loadingProducts: false,
    success: false,
    failed: false,
    myCompanySite: null,
    companySite: null,
    backgroundImages: null,
    products: [],
    productDetails: null,
    transactionData: [],
    siteCompanyError: '',
});

export const siteCompanyReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.GET_TRANSACTION_DATA: {
            return state.merge({
                loading: false,
                transactionData: action.payload
            });
        }

        case types.CREATE_SITE: {
            return state.merge({
                loading: false,
                myCompanySite: action.payload
            });
        }

        case types.SUCCESS: {
            return state.merge({
                loading: false
            });
        }

        case types.LOAD_DETAILS: {
            return state.merge({
                loading: false,
                productDetails: action.payload
            })
        }

        case types.SUCCESS_BGI_LOAD: {
            return state.merge({
                loading: false,
                backgroundImages: action.payload
            });
        }

        case types.SUCCESS_PRODUCT_LOAD: {
            return state.merge({
                loadingProducts: false,
                products: action.payload
            });
        }

        case types.SUCCESS_LOGO_LOAD: {
            return state.merge({
                loading: false,
                companySite: {...state.companySite, logo:action.payload}
            });
        }

        case types.CREATE_SITE_FAILURE:
        case types.UPDATE_SITE_FAILURE:
        case types.FAILURE:{
            return state.merge({
                loadingProducts: false,
                loading: false,
                failed: true,
            });
        }

        case types.LOAD_SITE_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
                siteCompanyError: action.payload.error
            });
        }

        case types.LOAD_SITE:
        case types.UPDATE_SITE:{
            return state.merge({
                loading: false,
                companySite: action.payload
            });
        }


        case types.GET_MY_COMPANY_SITE: {
            return state.merge({
                loading: false,
                myCompanySite: action.payload
            });
        }

        case types.REQUEST: {
            return state.merge({
                loading: true
            });
        }

        case types.REQUEST_PRODUCTS: {
            return state.merge({
                loadingProducts: true
            });
        }

        default: {
            return state;
        }
    }
}