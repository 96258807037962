export const GET_MYPORTFOLIO_REQUEST = 'myportfolio/GET_MYPORTFOLIO_REQUEST';
export const GET_MYPORTFOLIO_SUCCESS = 'myportfolio/GET_MYPORTFOLIO_SUCCESS';
export const GET_MYPORTFOLIO_FAILURE = 'myportfolio/GET_MYPORTFOLIO_FAILURE';

export const GET_MYPORTFOLIOITEM_REQUEST = 'myportfolio/GET_MYPORTFOLIOITEM_REQUEST';
export const GET_MYPORTFOLIOITEM_SUCCESS = 'myportfolio/GET_MYPORTFOLIOITEM_SUCCESS';
export const GET_MYPORTFOLIOITEM_FAILURE = 'myportfolio/GET_MYPORTFOLIOITEM_FAILURE';

export const UPLOAD_PORTFOLIOIMAGE_REQUEST = 'myportfolio/UPLOAD_PORTFOLIOIMAGE_REQUEST';
export const UPLOAD_PORTFOLIOIMAGE_SUCCESS = 'myportfolio/UPLOAD_PORTFOLIOIMAGE_SUCCESS';
export const UPLOAD_PORTFOLIOIMAGE_FAILURE = 'myportfolio/UPLOAD_PORTFOLIOIMAGE_FAILURE';

export const DELETE_PORTFOLIOIMAGE_REQUEST = 'myportfolio/DELETE_PORTFOLIOIMAGE_REQUEST';
export const DELETE_PORTFOLIOIMAGE_SUCCESS = 'myportfolio/DELETE_PORTFOLIOIMAGE_SUCCESS';
export const DELETE_PORTFOLIOIMAGE_FAILURE = 'myportfolio/DELETE_PORTFOLIOIMAGE_FAILURE';

export const UPDATE_PORTFOLIOITEM_REQUEST = "myportfolio/UPDATE_PORTFOLIOITEM_REQUEST";
export const UPDATE_PORTFOLIOITEM_SUCCESS = 'myportfolio/UPDATE_PORTFOLIOITEM_SUCCESS';
export const UPDATE_PORTFOLIOITEM_FAILURE = 'myportfolio/UPDATE_PORTFOLIOITEM_FAILURE';

export const UPLOAD_PORTFOLIOMODEL_REQUEST = "myportfolio/UPLOAD_PORTFOLIOMODEL_REQUEST";
export const UPLOAD_PORTFOLIOMODEL_SUCCESS = 'myportfolio/UPLOAD_PORTFOLIOMODEL_SUCCESS';
export const UPLOAD_PORTFOLIOMODEL_FAILURE = 'myportfolio/UPLOAD_PORTFOLIOMODEL_FAILURE';

export const DELETE_PORTFOLIO_REQUEST = "myportfolio/DELETE_PORTFOLIO_REQUEST";
export const DELETE_PORTFOLIO_SUCCESS = 'myportfolio/DELETE_PORTFOLIO_SUCCESS';
export const DELETE_PORTFOLIO_FAILURE = 'myportfolio/DELETE_PORTFOLIO_FAILURE';

export const EDIT_MYPORTFOLIO = "myportfolio/EDIT_PORTFOLIO";

