import MathHelper from "../utils/MathHelper";

export class FormulaHelper {
    //formula names
    static TYPE_CONSTANT = "TYPE_CONSTANT";
    static TYPE_CONSTANT_ANGLE = "TYPE_CONSTANT_ANGLE";
    static TYPE_LINEAR = "TYPE_LINEAR";
    static TYPE_LINEAR_OR_CONSTANT = "TYPE_LINEAR_OR_CONSTANT";
    static TYPE_CURVE = "TYPE_CURVE";
    static TYPE_CURVE_DIF_ANGLE = "TYPE_CURVE_DIF_ANGLE";
    static TYPE_TORSION = "TYPE_TORSION";

    //formula views
    static FORMULA_COPY = "2";
    static FORMULA_POSITION = "0";
    static FORMULA_ROTATION = "1";

    //constants
    static FILL_WIDTH = "FILL_WIDTH";
    static FILL_HEIGHT = "FILL_HEIGHT";
    static FILL_DEPTH = "FILL_DEPTH";
    static STEP = "STEP";
    static CONST_STEP_REMAIN = "CONST_STEP_REMAIN";
    static STAIR_HEIGHT = "STAIR_HEIGHT";
    static CURVE_PLUS = "+";
    static CURVE_MINUS = "-";

    static getObjectByType(type) {
        switch (type) {
            case this.TYPE_CONSTANT_ANGLE:
                return {
                    type: this.TYPE_CONSTANT_ANGLE,
                    v: 0
                };
            case this.TYPE_CONSTANT:
                return {
                    type: this.TYPE_CONSTANT,
                    v: 0
                };
            case this.TYPE_LINEAR:
                return {
                    type: this.TYPE_LINEAR,
                    v: this.FILL_WIDTH,
                    a: 0,
                    b: 0
                };
            case this.TYPE_CURVE:
                return {
                    type: this.TYPE_CURVE,
                    v: this.CURVE_Y_PLUS,
                    height: 0,
                    width: 1,
                    hCurve: 0,
                    step: 0,
                    perpendicular: false,
                };
            case this.TYPE_LINEAR_OR_CONSTANT:
                return {
                    type: this.TYPE_LINEAR_OR_CONSTANT,
                    v: this.FILL_WIDTH,
                    a: 0,
                    b: 0,
                    c: 0
                };
            case this.TYPE_CURVE_DIF_ANGLE:
                return {
                    type: this.TYPE_CURVE_DIF_ANGLE,
                    v: 0,
                    a: 0,
                    b: this.FILL_WIDTH,
                    height:0,
                };
            case this.TYPE_TORSION:
                return {
                    type: this.TYPE_TORSION,
                    c: 0,
                    d: 0
                }
        }
    }

    static getCurvePosition(object, userValues, count, stepX) {
        if (typeof (object) != "object") {
            return [];
        }
        if (object.type != this.TYPE_CURVE) {
            return [];
        }
        let step = stepX;
        if (step <= 0) {
            return 0;
        }
        const height = object.height / 1000;
        const width = count * stepX;
        const hCurve = object.hCurve != null || object.hCurve != 0 ? MathHelper.mmToMeters(object.hCurve) : 0;
        let dA = hCurve != null || hCurve != 0 ? -Math.atan(hCurve / width) : 0;
        const R = (height / 2) + ((width * width) / (8 * height));
        const aSegment = 2 * Math.asin(width / (R * 2));
        const ninetyD = 1.5708;
        const aStart = (ninetyD - aSegment / 2);
        const aEnd = (ninetyD + aSegment / 2);
        const xStart = R * Math.cos(aStart);
        let yStart = R * Math.sin(aStart);
        let yEnd = R * Math.sin(aEnd);
        const xEnd = R * Math.cos(aEnd);
        step = (xStart - xEnd) / count;
        let xTemp = xStart;
        let yDa = xStart * Math.sin(dA) + yStart * Math.cos(dA);
        let xDa = xStart * Math.cos(dA) - yStart * Math.sin(dA);
        let xEndDa = xEnd * Math.cos(dA) - yEnd * Math.sin(dA);
        let newHypotenuse = Math.sqrt(Math.pow(width, 2) + Math.pow(hCurve, 2));
        let xOffset = (newHypotenuse - width) / 2;
        let yOffset =  (newHypotenuse - width) * hCurve / newHypotenuse ;
        if (dA != 0) {
            yStart = yDa;
            step = (xDa - xEndDa) / count;
        }
        let y = [];
        for (let i = 0; i <= count; i++) {
            let a = Math.acos(xTemp / R);
            if (dA != 0) {
                a = Math.acos(xDa / R);
                xDa -= step;
            }
            let yTemp = R * Math.sin(a);
            let value = yTemp - yStart - hCurve/2;
            if (object.v == this.CURVE_MINUS) {
                value = -value;
            }
            y.push({ v: value + yOffset/2, a: ninetyD - a, xStepNew: step, offset: xOffset });
            xTemp -= step;
        }
        return y;
    }

    static calcValue(object, userValues, adjacentValues) {
        let value = object.v == undefined ? 0 : object.v;
        switch (object.type) {
            case this.TYPE_CONSTANT:
                return MathHelper.mmToMeters(parseInt(value));
            case this.TYPE_CONSTANT_ANGLE:
                return MathHelper.degreeToRad(parseFloat(value));
            case this.TYPE_LINEAR:
                return object.a * userValues[value] + MathHelper.mmToMeters(parseInt(object.b));
            case this.TYPE_CURVE:
                return 0;
            case this.TYPE_LINEAR_OR_CONSTANT:                
                if (userValues[value] == 0 || userValues[value] == null) {
                    return MathHelper.mmToMeters(parseInt(object.c));
                } else {
                    return object.a * userValues[value] + MathHelper.mmToMeters(parseInt(object.b));
                }
            case this.TYPE_CURVE_DIF_ANGLE:
                let hypotenuse = object.a * userValues[object.b];
                let cathetus = MathHelper.mmToMeters(parseFloat(object.height));
                let a = hypotenuse != 0 ? Math.atan(cathetus / hypotenuse) : 0;
                return (MathHelper.degreeToRad(parseInt(value)) + (a));
            case this.TYPE_TORSION:
                return MathHelper.degreeToRad(Math.floor((adjacentValues.end - adjacentValues.start) / MathHelper.mmToMeters(object.c)) *object.d);
        }
        return 0;
    }
}