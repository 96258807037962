import React from 'react';
import NavMenu from '../NavMenu';
import { Container, Row, Col } from "reactstrap";
import SideBar from '../sidebar/MyProfileSideBar';
import BannerColumn from '../../aboutProject/BannerColumn';
import FooterLayout from '../footer/FooterLayout';
import './MainLayout.css'
import { isMobile } from 'react-device-detect';
import { connect } from "react-redux";

class MyProfileSideBar extends React.Component {
    render() {
        return (
            <div className="ml-wrapper">
                <NavMenu />
                <Container className={!isMobile ? "container-full ml-container" : " ml-container"}>
                    {(!isMobile) ?
                        <Row>
                            <Col xs="2">
                                <SideBar />
                            </Col>
                            <Col>
                                {this.props.children}
                            </Col>
                        </Row>
                        :
                        <>
                            {this.props.children}
                        </>
                    }
                </Container>
                <FooterLayout />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({    
    isAuthenticated: state.login.isAuthenticated  
});

export default connect(mapStateToProps, null)(MyProfileSideBar);
