import axios from 'axios';
import * as actionTypes from './types';


export const setLanguage = (language) => (dispatch)=>{
      dispatch({type: actionTypes.SET_LANGUAGE_REQUEST});
      return new Promise(function(resolve, reject) {
        axios.post(`api/localization/setLanguage?culture=${language}`)
          .then((res) => {
            dispatch({type: actionTypes.SET_LANGUAGE_SUCCESS});

            resolve(res);
          })
          .catch((error)=>{
            dispatch({type: actionTypes.SET_LANGUAGE_FAILURE});

            reject(error);
          })
    })
}
