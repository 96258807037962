import axios from "axios";
import * as types from './types';
import { saveVerificationToken } from '../../utils/lsMethods';
import setAuthorizationToken from '../../utils/setAuthorizationToken';
import { sendAmplitudeData, amplitudeTypes } from '../../utils/amplitude';

import Helper from '../../utils/helper';
import { loginByJWT } from "../../login/actions";

const registerActions = {
    // For select info
    registerStarted: () => {
        return {
            type: types.REGISTER_SIMPLE_REQUEST
        }
    },
    registerSuccess: (data) => {
        return {
            type: types.REGISTER_SIMPLE_SUCCESS,
            payload: data
        }
    },
    registerFailed: (data) => {
        return {
            type: types.REGISTER_SIMPLE_FAILURE,
            payload:data
        }
    },
}

export const registerUser = (model, callback) => (dispatch) => {
    dispatch(registerActions.registerStarted());
    return axios.post(`api/account/registerUserSimple`, model)
        .then((response) => {
            
            if (response.data.token) {
                saveVerificationToken(response.data.token);
                setAuthorizationToken(response.data.token);
            } else {
                loginByJWT(response.data, dispatch);
            }

            dispatch(registerActions.registerSuccess(response.data));

            sendAmplitudeData(amplitudeTypes.REGISTER, { "type": model.isExecutor ? "Customer" : "Executor" });
            //sendAmplitudeData(amplitudeTypes.OPEN_WEBSITE, { 'registered': true });
            //const id = Helper.getDemoProductId();
            //if (id != null && id != 0) {
            //    axios.get('api/home/setDemoToProfile?productId=' + id);
            //}

            if (callback)
                callback(true, { isConfirmed: response.data.isConfirmed });

        }, err => { throw err; })
        .catch((err) => {
            dispatch(registerActions.registerFailed(err.response.data));
            if (callback)
                callback(false, err.response.data);
        })
}