import React, { Component } from "react";
import { Spinner } from "reactstrap";

export default class InlineLoader extends Component {
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <Spinner type="grow" color="success" />
            </div>
        );
    }
}