import React, { Component } from 'react';
import { Media, Row, Col, Button, Input, Dropdown, DropdownMenu, DropdownToggle, FormGroup, FormFeedback } from 'reactstrap';
import Helper from "../../utils/helper";
import { Md3DRotation } from "react-icons/md";
import { toastr } from "react-redux-toastr";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ImageScroller from "../../components/imageScroller/ImageScroller";
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

export default class ElementCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input_number_container: this.props.item.count ? this.props.item.count : 1,
            showPhoto: false,
            redirectToDetails: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item !== this.props.item && this.props.item.count) {
            this.setState({
                input_number_container: this.props.item.count
            });
        }
    }

    toggle = () => {
        this.setState({ showPhoto: !this.state.showPhoto });
    }

    onAddToBasket = (e) => {
        e.stopPropagation();
        this.props.addElementsToBasket({
            id: this.props.item.id,
            count: this.state.input_number_container
        });
        toastr.success('', this.props.t('goodsView.addedToBasket'));
    }

    onCountChange = (event) => {
        //event.stopPropagation();
        let val = event.target.value != '' && event.target.value != '0' ? event.target.value.replace(/\D/, '') : '1';
        this.setState({
            input_number_container: val
        });
        if (this.props.onCountChange) {
            this.props.onCountChange(val);
        }
    }

    showDetails = () => {
        //this.setState({ redirectToDetails: true });
    }

    render() {
        if (!this.props.selectMode) {
            return (
                <div className={"element-item element-item-clickable"}>
                    <Link to={`/kovanie-elementi-kovki-details/${this.props.item.id}`}>
                        {this.renderContent()}
                    </Link>
                    {this.renderBuyContainer()}
                </div>
            );
        } else {
            return (
                <div onClick={() => this.props.onItemSelect(this.props.item)} className={"element-item element-item-clickable"}>
                    {this.renderContent()}
                    {this.renderBuyContainer()}
                </div>
            );
        }
    }

    renderContent() {
        const { item, t, show3d, useWholesale } = this.props;
        let threedSrc = Helper.editorLinkElementPreview(item.id);
        //if (item.file3d != null) {
        //    threedSrc = `/viewer3d?src=${item.file3d}`;
        //}
        let imagesCount = 0;
        if (item.elementImages != null) {
            imagesCount = item.elementImages.length;
        }
        let is;
        let viewTwoPrice = false;
        if (window.location.href.indexOf("/kovanie-elementi-kovki") != -1) {
            viewTwoPrice = true;
        }
        let priceRetail = item.priceRetail;
        let priceWholesale = item.priceWholesale;
        let priceForSystem = item.priceForSystem;
        const isSale = item.isSale;
        if (isSale) {
            const min = Math.min(priceForSystem, priceWholesale, priceRetail);
            priceRetail = min;
            priceWholesale = min;
            priceForSystem = min;
        }
        let elementImgClass = this.props.smallImage ? "element-img-small" : "element-img";

        if (this.state.redirectToDetails) {
            return <Redirect to={`/kovanie-elementi-kovki-details/${item.id}`} />;
        }

        return (            
                <div>
                    <div style={{ height: "20px", textAlign:"center" }}>
                        <span style={{ float: "left" }}>{item.uniqueCode}</span>
                        {item.sellerName}
                        {(!this.props.smallImage && isSale) &&
                            <div className="sale-element-handler" >{t('catalogPage.sale')}</div>
                        }
                        {imagesCount > 0 &&
                            <>
                                <ImageScroller images={item.elementImages} srcLinkBuilder={(img) => img.image} altBuilder={(img) => { return "" }} ref={ref => is = ref} />
                                <div hidden className="photo-element-handler">{imagesCount + t('catalogPage.photo')}</div>
                            </>
                        }
                    </div>

                    <>
                        {show3d && threedSrc != null ?
                            <div className={elementImgClass + (!isMobile ? "" : "-mobile")}>
                                <iframe
                                    width="100%"
                                    height={this.props.smallImage ? "140":(!isMobile ? "280" : "200")}
                                    src={threedSrc}
                                    frameBorder="0"
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </div>
                            :
                            <div className={elementImgClass + (!isMobile ? "" : "-mobile")}>
                                {item.textureImage &&
                                    <div className="element-texture-img">
                                        <img src={item.textureImage} style={{ height: "100%" }} />
                                    </div>
                                }
                                <img src={item.icon} alt={item.alt} />
                                {(item.hasContent || item.file3d) &&
                                    <Md3DRotation className="icon-3d" onClick={(e) => { e.stopPropagation(); this.props.on3dClick(); }} />

                                }
                            </div>
                        }
                    </>
                    <div className="element-dimention">
                        <span className="element-name">{item.name}</span>
                    </div>
                    <div className="align-right">
                        <span>{item.weight} {t('myProfile.elements.weightDimen')}</span>
                    </div>
                    <div className="align-center">
                        {viewTwoPrice ?
                            <>
                                <span className="mr-1">{t('element_item.retail')}</span>
                                <span className="price">{priceRetail} {t('currencyUah')}</span>
                                <br />
                                <span className="mr-1">{t('element_item.wholesale')}</span>
                                <span className="price">{priceWholesale} {t('currencyUah')}</span>
                            </>
                            :
                            <>
                                <span className="mr-1">{(useWholesale && priceWholesale) ? t('element_item.wholesale') : t('element_item.retail')}</span>
                                <span className="price">{(useWholesale && priceWholesale) ? priceWholesale : priceRetail} {t('currencyUah')}</span>
                            </>
                        }
                    </div>
                    
                </div>
        );
    }

    renderBuyContainer() {
        return (
            <div className="buy-container">
                <div className="quantity-container" style={!this.props.selectMode ? {} : { textAlign: 'center' }}>
                    {!this.props.selectMode ?
                        <>
                            <Input value={this.state.input_number_container}
                                type="number"
                                style={{ width: "50px", padding: "5px 3px", display: "inline" }}
                                size="sm"
                                onClick={(e) => e.stopPropagation()}
                                onChange={this.onCountChange} />
                            <Button
                                className="buy-button"
                                outline={this.props.isInCatalog}
                                color="success"
                                size="sm"
                                onClick={this.onAddToBasket}>{this.props.t('element_item.basket')}</Button>
                        </>
                        :
                        <></>
                    }
                </div>
            </div>
        );
    }
}
