import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    loadingProducts: false,
    success: false,
    failed: false,
    productsMyProfile: [],
    executorSelections: [],
    isAutoAddToCatalog: false,
    isAutoAddToCatalogSellElements: false,
    embeddingKey: null,
    productsEmbedding: [],
    productsEmbeddingError: '',
    productDetails: null,
    productTypes: [],
    priceInfo: null
});

export const embeddingReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false,
                loadingProducts: false,
                failed: true
            });
        }

        case types.REQUEST_PRODUCTS: {
            return state.merge({
                loadingProducts: true
            });
        }

        case types.GET_PRODUCTS_FOR_PROFILE_SUCCESS: {
            return state.merge({
                loadingProducts: false,
                productsMyProfile: action.payload.products,
                executorSelections: action.payload.executorProducts,
                isAutoAddToCatalog: action.payload.isAutoAddToCatalog,
                isAutoAddToCatalogSellElements: action.payload.isAutoAddToCatalogSellElements 
            });
        }
        case types.SAVE_EXECUTOR_PRODUCT_SELECTION: {
            return state.merge({
                loading: false
            });
        }

        case types.GET_LOADED_IMAGE_SUCCESS: {
            let image = action.payload;
            return state.merge({
                loading: false,
            });
        }

        case types.DELETE_IMAGE_SUCCESS:
        case types.CHANGE_WRAPPER_SUCCESS: {
            return state.merge({
                loading: false,
            });
        }
            

        case types.GET_EXISTING_KEY: {
            return state.merge({
                loading: false,
                embeddingKey: action.payload
            });
        }
        case types.GET_PRICE_INFO: {
            return state.merge({
                loading: false,
                priceInfo: action.payload
            });
        }
        case types.GENERATE_KEY: {
            return state.merge({
                loading: false,
                embeddingKey: action.payload
            });
        }
        case types.GENERATE_KEY_FAILURE: {
            return state.merge({
                loading: false
            });
        }

        case types.GET_PRODUCT_TYPES: {
            return state.merge({
                loading: false,
                productTypes: action.payload,
            });
        }
        case types.GET_PRODUCTS_FOR_EMBEDDING: {
            return state.merge({
                loading: false,
                productsEmbedding: action.payload,
            });
        }
        case types.GET_PRODUCTS_FOR_EMBEDDING_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
                productsEmbeddingError: action.payload.error
            });
        }
        case types.GET_PRODUCT_DETAILS: {
            return state.merge({
                loading: false,
                productDetails: action.payload,
            });
        }

        default: {
            return state;
        }
    }
}