import React from 'react';

const NubmerOfTries = 3;

export default function loadlazy(importFunc) {
    return React.lazy(() => componentLoader(importFunc, NubmerOfTries));
}

export function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 500);
      });
  });
}