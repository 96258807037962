import React from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { MdKeyboardArrowDown } from "react-icons/md";
import uniqueId from 'lodash/uniqueId';

export default class LabelWithMoreText extends React.Component {
    render() {
        const { text, shortLength } = this.props;
        let textShort = text.length > shortLength ? text.substring(0, shortLength) + '...' : text;
        let id = 'lmt' + uniqueId();
        return (
            <>
                <span>{textShort}</span>
                <MdKeyboardArrowDown id={id} style={{ height: "1.6em", width: "1.6em", color: "#6c757d" }} />
                <UncontrolledPopover trigger="focus" placement="bottom" target={id}>
                    <PopoverBody>
                        {text}
                    </PopoverBody>
                </UncontrolledPopover>
            </>
        )
    }
}