export const LOGIN_POST_REQUEST  = "login/LOGIN_POST_REQUEST ";
export const LOGIN_POST_SUCCESS = "login/LOGIN_POST_SUCCESS";
export const LOGIN_POST_FAILED = "login/LOGIN_POST_FAILED";

export const LOGIN_SET_CURRENT_USER = "login/LOGIN_SET_CURRENT_USER";

export const LOGOUT_POST_REQUEST = "login/LOGOUT_POST_REQUEST";

export const LOGIN_SOCIAL_REQUEST = "login/LOGIN_SOCIAL_REQUEST";
export const LOGIN_SOCIAL_SUCCESS = "login/LOGIN_SOCIAL_SUCCESS";
export const LOGIN_SOCIAL_FAILED = "login/LOGIN_SOCIAL_FAILED";