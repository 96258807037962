export const REQUEST = "calculationFactors/REQUEST";
export const FAILURE = "calculationFactors/FAILURE";

export const ADD_CALCULATEFACTORS_REQUEST = "calculationFactors/ADD_CALCULATEFACTORS_REQUEST";
export const ADD_CALCULATEFACTORS_SUCCESS = "calculationFactors/ADD_CALCULATEFACTORS_SUCCESS";
export const ADD_CALCULATEFACTORS_FAILURE = "calculationFactors/ADD_CALCULATEFACTORS_FAILURE";

export const GET_CALCULATEFACTORS_REQUEST = "calculationFactors/GET_CALCULATEFACTORS_REQUEST";
export const GET_CALCULATEFACTORS_SUCCESS = "calculationFactors/GET_CALCULATEFACTORS_SUCCESS";
export const GET_CALCULATEFACTORS_FAILURE = "calculationFactors/GET_CALCULATEFACTORS_FAILURE";

export const ADD_PAINTINGSPRICE_REQUEST = "calculationFactors/ADD_PAINTINGSPRICE_REQUEST";
export const ADD_PAINTINGSPRICE_SUCCESS = "calculationFactors/ADD_PAINTINGSPRICE_SUCCESS";
export const ADD_PAINTINGSPRICE_FAILURE = "calculationFactors/ADD_PAINTINGSPRICE_FAILURE";

export const GET_PAINTINGPRICES_REQUEST = "calculationFactors/GET_PAINTINGPRICES_REQUEST";
export const GET_PAINTINGPRICES_SUCCESS = "calculationFactors/GET_PAINTINGPRICES_SUCCESS";
export const GET_PAINTINGPRICES_FAILURE = "calculationFactors/GET_PAINTINGPRICES_FAILURE";

export const UPDATE_PAINTINGPRICES_REQUEST = "calculationFactors/UPDATE_PAINTINGPRICES_REQUEST";
export const UPDATE_PAINTINGPRICES_SUCCESS = "calculationFactors/UPDATE_PAINTINGPRICES_SUCCESS";
export const UPDATE_PAINTINGPRICES_FAILURE = "calculationFactors/UPDATE_PAINTINGPRICES_FAILURE";

export const DELETE_PAINTINGPRICES_REQUEST = "calculationFactors/DELETE_PAINTINGPRICES_REQUEST";
export const DELETE_PAINTINGPRICES_SUCCESS = "calculationFactors/DELETE_PAINTINGPRICES_SUCCESS";
export const DELETE_PAINTINGPRICES_FAILURE = "calculationFactors/DELETE_PAINTINGPRICES_FAILURE";

export const SET_DEFAULT_PAINTINGPRICE_SUCCESS = "calculationFactors/SET_DEFAULT_PAINTINGPRICE_SUCCESS";
export const GET_INSTALL_PRICE_SUCCESS = "calculationFactors/GET_INSTALL_PRICE_SUCCESS";
export const UPDATE_INSTALL_PRICE_SUCCESS = "calculationFactors/UPDATE_INSTALL_PRICE_SUCCESS";

export const GET_REGION_FACTORS_SUCCESS = "calculationFactors/GET_REGION_FACTORS_SUCCESS";
export const SEND_REGION_FACTORS_SUCCESS = "calculationFactors/SEND_REGION_FACTORS_SUCCESS";
