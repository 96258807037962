export const GET_MYPROJECTS_REQUEST = 'myproject/GET_MYPROJECTS_REQUEST';
export const GET_MYPROJECTS_SUCCESS = 'myproject/GET_MYPROJECTS_SUCCESS';
export const GET_MYPROJECTSV2_SUCCESS = 'myproject/GET_MYPROJECTSV2_SUCCESS';
export const GET_MYPROJECTS_FAILURE = 'myproject/GET_MYPROJECTS_FAILURE';

export const GET_PROJECTS_WITHOUT_MEASURER_REQUEST = 'myproject/GET_PROJECTS_WITHOUT_MEASURER_REQUEST';
export const GET_PROJECTS_WITHOUT_MEASURER_SUCCESS = 'myproject/GET_PROJECTS_WITHOUT_MEASURER_SUCCESS';
export const GET_PROJECTS_WITHOUT_MEASURER_FAILURE = 'myproject/GET_PROJECTS_WITHOUT_MEASURER_FAILURE';

export const CALL_MEASURER_REQUEST = 'myproject/CALL_MEASURER_REQUEST';
export const CALL_MEASURER_SUCCESS = 'myproject/CALL_MEASURER_SUCCESS';
export const CALL_MEASURER_FAILURE = 'myproject/CALL_MEASURER_FAILURE';

export const DELETE_PROJECT_REQUEST = 'myproject/DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'myproject/DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'myproject/DELETE_PROJECT_FAILURE';

export const CREATE_PRODUCT_REQUEST = 'myproject/CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'myproject/CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'myproject/CREATE_PRODUCT_FAILURE';

export const REQUEST = 'myproject/REQUEST';
export const FAILURE = 'myproject/FAILURE';

export const CALCULATE_PRICE_SUCCESS = 'myproject/CALCULATE_PRICE_SUCCESS';
export const DELETE_PRODUCT_SUCCESS = 'myproject/DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_GROUP_SUCCESS = 'myproject/DELETE_PRODUCT_GROUP_SUCCESS';
export const GET_SPECIFICATION_SUCCESS = 'myproject/GET_SPECIFICATION_SUCCESS';
export const UPDATE_PROJECT_NAME_SUCCESS = 'myproject/UPDATE_PROJECT_NAME_SUCCESS';

export const CLOSE_PROJECT_SUCCESS = 'myproject/CLOSE_PROJECT_SUCCESS';
export const GET_MY_PRICE_FACTOR = 'myproject/GET_MY_PRICE_FACTOR';