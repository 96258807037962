export const GET_MYINVITATIONS_REQUEST = 'projectsExecuter/GET_MYINVITATIONS_REQUEST';
export const GET_MYINVITATIONS_SUCCESS = 'projectsExecuter/GET_MYINVITATIONS_SUCCESS';
export const GET_MYINVITATIONS_FAILURE = 'projectsExecuter/GET_MYINVITATIONS_FAILURE';

export const GET_PROJECTDETAILS_REQUEST = 'projectsExecuter/GET_PROJECTDETAILS_REQUEST';
export const GET_PROJECTDETAILS_SUCCESS = 'projectsExecuter/GET_PROJECTDETAILS_SUCCESS';
export const GET_PROJECTDETAILS_FAILURE = 'projectsExecuter/GET_PROJECTDETAILS_FAILURE';

export const SEND_PROPOSITION_REQUEST = 'projectsExecuter/SEND_PROPOSITION_REQUEST';
export const SEND_PROPOSITION_SUCCESS = 'projectsExecuter/SEND_PROPOSITION_SUCCESS';
export const SEND_PROPOSITION_FAILURE = 'projectsExecuter/SEND_PROPOSITION_FAILURE';

export const FIND_PROJECTS_REQUEST = 'projectsExecuter/FIND_PROJECTS_REQUEST';
export const FIND_PROJECTS_SUCCESS = 'projectsExecuter/FIND_PROJECTS_SUCCESS';
export const FIND_PROJECTS_FAILURE = 'projectsExecuter/FIND_PROJECTS_FAILURE';

export const GET_PRODUCT_TYPES_REQUEST = 'projectsExecuter/GET_PRODUCT_TYPES_REQUEST';
export const GET_PRODUCT_TYPES_SUCCESS = 'projectsExecuter/GET_PRODUCT_TYPES_SUCCESS';
export const GET_PRODUCT_TYPES_FAILURE = 'projectsExecuter/GET_PRODUCT_TYPES_FAILURE';

export const GET_MYPROPOSITIONS_REQUEST = 'projectsExecuter/GET_MYPROPOSITIONS_REQUEST';
export const GET_MYPROPOSITIONS_SUCCESS = 'projectsExecuter/GET_MYPROPOSITIONS_SUCCESS';
export const GET_MYPROPOSITIONS_FAILURE = 'projectsExecuter/GET_MYPROPOSITIONS_FAILURE';

export const GET_CUSTOMER_DETAILS_REQUEST = 'projectsExecuter/GET_CUSTOMER_DETAILS_REQUEST';
export const GET_CUSTOMER_DETAILS_SUCCESS = 'projectsExecuter/GET_CUSTOMER_DETAILS_SUCCESS';
export const GET_CUSTOMER_DETAILS_FAILURE = 'projectsExecuter/GET_CUSTOMER_DETAILS_FAILURE';

export const GET_MYCONTRACTS_REQUEST = 'projectsExecuter/GET_MYCONTRACTS_REQUEST';
export const GET_MYCONTRACTS_SUCCESS = 'projectsExecuter/GET_MYCONTRACTS_SUCCESS';
export const GET_MYCONTRACTS_FAILURE = 'projectsExecuter/GET_MYCONTRACTS_FAILURE';

export const GET_CONTRACT_DETAILS_REQUEST = 'projectsExecuter/GET_CONTRACT_DETAILS_REQUEST';
export const GET_CONTRACT_DETAILS_SUCCESS = 'projectsExecuter/GET_CONTRACT_DETAILS_SUCCESS';
export const GET_CONTRACT_DETAILS_FAILURE = 'projectsExecuter/GET_CONTRACT_DETAILS_FAILURE';

export const SEND_CONTRACT_TO_REVIEW_REQUEST = 'projectsExecuter/SEND_CONTRACT_TO_REVIEW_REQUEST';
export const SEND_CONTRACT_TO_REVIEW_SUCCESS = 'projectsExecuter/SEND_CONTRACT_TO_REVIEW_SUCCESS';
export const SEND_CONTRACT_TO_REVIEW_FAILURE = 'projectsExecuter/SEND_CONTRACT_TO_REVIEW_FAILURE';