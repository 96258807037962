import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    countries: [],
    regions: [],
    cities: [],
    productTypes: [],
    createSuccess: false,
    createProjectId: null,
    data: {
        images: []
    },
    projectData: null
});

export const createProjectReducer = (state = initialState, action) => {
    switch (action.type) {
        //#region get regions
        case types.GET_REGIONS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_REGIONS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                regions: action.payload.regions,
                countries: action.payload.countries
            });
        }
        case types.GET_REGIONS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_CITIES
        case types.GET_CITIES_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_CITIES_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                cities: action.payload.cities
            });
        }
        case types.GET_CITIES_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region upload image
        case types.UPLOAD_IMAGE_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.UPLOAD_IMAGE_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                data: {
                    ...state.data,
                    images: [
                        ...state.data.images,
                        action.payload
                    ]
                }
            });
        }
        case types.UPLOAD_IMAGE_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        case types.DELETE_IMAGE: {
            var id = action.payload;
            const images = state.data.images.filter(o => o.id != id);
            return state.merge({
                data: {
                    ...state.data,
                    images: images,
                }
            });
        }

        //#region upload image
        case types.GET_PRODUCT_TYPES_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_PRODUCT_TYPES_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                productTypes: action.payload
            });
        }
        case types.GET_PRODUCT_TYPES_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region CREATE_PROJEC
        case types.CREATE_PROJECT_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.CREATE_PROJECT_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                createSuccess: true,
                createProjectId: action.payload
            });
        }
        case types.CREATE_PROJECT_FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        //#endregion

        //#region GET_PROJECT_DETAILS
        case types.GET_PROJECT_DETAILS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_PROJECT_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                projectData: action.payload
            });
        }
        case types.GET_PROJECT_DETAILS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        //#endregion

        case types.RESET: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}