import axios from "axios";
import * as types from './types';
import { data } from "jquery";

export const getSketches = () => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/AutoBuild/GetAllSketches`)
        .then((response) => {
            dispatch({
                type: types.GET_SKETCHES_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getSketchDetails = (sketchId) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/AutoBuild/GetSketchDetails?sketchId=${sketchId}`)
        .then((response) => {
            dispatch({
                type: types.GET_SKETCH_DETAILS_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getTemplates = () => (dispatch) => {
    //dispatch({ type: types.REQUEST });
    return axios.get(`api/AutoBuild/GetAllTemplates`)
        .then((response) => {
            dispatch({
                type: types.GET_TEMPLATES_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const proposeSketchImage = (data, callback) => (dispatch) => {
    return axios.post(`api/AutoBuild/ProposeSketchImage`, data)
        .then((response) => {
            callback(true);
        }, err => { throw err; })
        .catch((err) => {
            callback(false);
        })
}