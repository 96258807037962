import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    failed: false,
    images: []
});

export const sitePortfolioReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }

        case types.GET_PORFOLIO_IMAGES_SUCCESS: {
            return state.merge({
                loading: false,
                images: action.payload
            });
        }

        case types.UPLOAD_IMAGE_SUCCESS: {
            return state.merge({
                loading: false
            });
        }
        case types.DELETE_IMAGE_SUCCESS: {
            return state.merge({
                loading: false
            });
        }

        default: {
            return state;
        }
    }
}