import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert, Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { MdFavoriteBorder, MdFavorite, MdLocationOn, MdShare, MdChevronLeft, MdChevronRight } from "react-icons/md";
import * as actions from '../actions';
import FullPageLoader from '../../components/loaders/FullPageLoader/FullPageLoader';
import './ProductDetails.css';
import InfoView from '../../components/infoView/InfoView';
import Helper from '../../utils/helper';
import { Redirect } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import FavotireHelper from '../../utils/favoriteHelper';
import { addFavorites, removeFavorites, loadFavoriteLocal } from '../../favorites/actions';
import { isMobile } from 'react-device-detect';
import SharePopUp from '../catalog/SharePopUp';
import { sendAmplitudeData, amplitudeTypes } from '../../utils/amplitude';
import TemplateList from '../../autoBuild/createProduct/TemplateList';
import { getTemplates, getSketchDetails } from '../../autoBuild/actions';
import SectionHelper from '../../autoBuild/createProduct/sections/SectionHelper';
import { generateHiddenProduct, moveProductToNewProject } from '../../autoBuildEdit/actions';
import ElementCard from '../../elements/elementslist/ElementItem';
import '../../elements/elementslist/ElementPage.css';
import { addElementsToBasket } from '../../basket/actions';
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai';
import ManufacturerPriceList from '../../manufacturer/manufacturerPriceList/ManufacturerPriceList';
import HistoryStorage from '../../utils/HistoryStorage';
import { registerUser } from '../../register/registerSimple/actions';
import { verificateAccount, sendSms } from '../../verify/actions';
import { RegisterPopup } from '../../manufacturer/manufacturerPriceList/RegisterPopup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import * as debounce from 'lodash/debounce';
import InlineLoader from '../../components/loaders/InlineLoader/InlineLoader';

class ProductDetailsV3 extends Component {
    constructor(props) {
        super(props);

        this.sections = [];

        this.state = {
            selectedImageIndex: 0,
            isRedirectToRegister: false,
            showAfterDemoPopup: false,
            showPopUp: false,
            sections: [],
            loadingPreview: false,
            currentEditorProductId: null,
            productIdForPrice: null,
            show3dElementIndex: -1,
            elementsForProduct: [],
            elementsAndMetals: null,
            showAuthPopup: false,
            isFullscreenMode: false,
            selectedVariantId: -1,
            isShowRegisterFromOpenEditor: false,
            isRedirectToConstructor: false,
            isRedirectToBasket: false,
            userPhone: '',
            selectedTopScreenMobile: 0,
            showTopHeader: false,
            sketch: null,
            selectedPaintVariantId: 1,
            selecredPaintForProductId: -1,
        };

        this.isMobile = false;

        this.pdItemsData = [
            { id: "pdItem1", title: this.props.t('productInfo.manufaturersTitle') },
            { id: "pdItem2", title: this.props.t('productInfo.previewTitle') },
            { id: "pdItem3", title: this.props.t('productInfo.templateTitle') }
        ];
        this.autoScrollIntervalLoopCount = 0;
    }

    componentDidMount() {
        this.props.loadPrints();
        if (this.props.productId) {
            if (!this.props.productItem || !this.props.productItem.isNoContent) {
                this.props.getCatalogProductDetails(this.props.productId);
                this.props.getListElementsAndMetalsForProduct(this.props.productId);
                //this.props.getElementsForProduct(this.props.productId);
            }
            this.props.getTemplates();
            this.props.getTexturedMetalsList();
            this.showTimeSpinner();
        }
        window.addEventListener("message", this.onMessageFromFrame);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.onMessageFromFrame);
    }

    componentDidUpdate(prevProps) {
        if (this.props.productId !== prevProps.productId) {
            if (!this.props.productItem || !this.props.productItem.isNoContent) {
                this.props.getCatalogProductDetails(this.props.productId);
                this.props.getListElementsAndMetalsForProduct(this.props.productId);
                //this.props.getElementsForProduct(this.props.productId);
                this.needInitialScroll = true;
                this.setState({
                    sections: [],
                    productIdForPrice: null,
                    //selectedPaintVariantId: -1,
                    //selecredPaintForProductId: -1,
                }, () => {
                    this.showTimeSpinner();
                    setTimeout(() => {
                        if (isMobile) {
                            this.moveToTopScreenMobile(1);
                        }
                    }, 2000);
                });
            }
        }
        if (this.props.paints != prevProps.paints) {
            console.log(this.props.paints);
            let paints = this.props.paints.filter(p => p.paintType == 1);
            this.setState({
                selecredPaintForProductId: (paints != null && paints.length > 0) ? paints[0].id : -1,
            });
            this.onAddPaintToProduct((paints != null && paints.length > 0) ? paints[0].id : -1);
        }
        if (this.props.product !== prevProps.product && this.props.product != null) {
            //sendAmplitudeData(amplitudeTypes.OPEN_CATALOG_PRODUCTS_BY_ID, { "productId": this.props.match.params.id, "sketchId": this.props.product.sketchId });
            if (this.props.product.sketchId) {
                this.props.getSketchDetails(this.props.product.sketchId);
            }
        }
        if (this.props.elementsAndMetals !== prevProps.elementsAndMetals) {
            this.setState({
                elementsAndMetals: this.props.elementsAndMetals
            });
        }
        if (this.props.elementsForProduct !== prevProps.elementsForProduct) {
            this.setState({
                elementsForProduct: this.props.elementsForProduct
            });
        }
        if (this.props.autoBuildTemplates !== prevProps.autoBuildTemplates) {
            this.needInitialScroll = true;
        }

        if (this.props.sketchDetails != prevProps.sketchDetails) {
            let variants = [
                ...(this.props.sketchDetails.sketch.variants ? this.props.sketchDetails.sketch.variants : [])
            ];
            if (this.props.sketchDetails.useTextureVariant) {
                variants.push(SectionHelper.addNewVariant(this.props.sketchDetails.sketch, this.props.texturedMetalList, this.props.t("editSketch.texturedVariant")));
            }
            this.setState({
                sketch: {
                    ...this.props.sketchDetails.sketch,
                    variants: variants
                }
            });
        }
    }

    showTimeSpinner() {
        this.setState({ loadingPreview: true });
        setTimeout(() => {
            this.setState({ loadingPreview: false });
            this.startAutoScrollMobile();
        }, 3000);
    }

    onImageSelected = (index) => {
        this.setState({
            selectedImageIndex: index
        });
    }

    onBuyClick = () => {
        if (this.props.isAuthenticated) {
            let productId = this.props.product.id;
            this.props.sendOrderToSeller({ productIds: [productId] }, (isOk, data) => {
                if (isOk) {
                    //this.props.addToBasket(data);
                    toastr.success('', this.props.t('homeProject.buyElementSuccess'));
                } else {
                    toastr.error('', this.props.t('homeProject.buyElementFail'));
                }

            });
        } else {
            this.setState({ isRedirectToRegister: true });
        }
    }

    onConstructorOpenClick = (id) => {
        if (this.props.isAuthenticated == true) {
            this.props.constructorOpenClick(id);
        }
        else {
            this.setState({
                showAfterDemoPopup: true
            });
        }
    }


    showpopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showPopUp: true });
    }
    hidepopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showPopUp: false });
    }

    flush3DError = () => {
        this.props.flush3dError();
    }

    openCreateProject = () => {
        HistoryStorage.isRedirectToConstructor = true;
        this.setState({
            isRedirectToConstructor: true
        });
    }

    //#region preview
    onAutobuildTemplateApply = (sections) => {
        sendAmplitudeData(amplitudeTypes.APPLY_TEMPLATE_ON_SKETCH_DETAILS, null);

        this.setState({
            sections: sections
        });

        if (this.isFrameReady) {
            this.renderSections(sections);
        }

        const pdContainer = document.getElementById("pdContainer");
        const pdItem = document.getElementById("pdItem2");
        this.isProgrammScroll = true;
        pdContainer.scrollTo({
            top: 0,
            left: pdItem.offsetLeft,
            behavior: 'smooth'
        });
    }

    renderSections = (sections) => {
        if (sections.length != 0) {
            this.sections = sections;
            let template = SectionHelper.buildTemplate(sections, this.state.sketch, this.state.selectedVariantId);
            let frame = window.frames[window.frames.length - 1];
            frame.postMessage({
                message: "render",
                data: template
            }, '*');
        }
        this.setState({ loadingPreview: true });

        this.isRenderWorking = true;
        setTimeout(() => {
            if (this.isRenderWorking) {
                this.onMessageFromFrame({ data: { message: "renderready" } });
            }
        }, 20000);
    }

    onMessageFromFrame = (e) => {
        if (e.data.message == 'ready') {
            this.isFrameReady = true;
            this.renderSections(this.state.sections);
        }
        if (e.data.message == 'renderready') {
            this.isRenderWorking = false;
            setTimeout(() => {
                const data = {
                    template: JSON.stringify(this.sections),
                    sketchId: this.props.product.sketchId,
                    productTypeId: this.props.product.productTypeId
                };
                this.props.generateHiddenProduct(data, (isOk, productId) => {
                    if (isOk) {
                        window.localStorage.setItem("elementsHiddenProductId", productId);
                        this.setState({ currentEditorProductId: productId });
                        let frame = window.frames[window.frames.length - 1];
                        frame.postMessage({
                            message: "save",
                            productId: productId
                        }, '*');
                    }
                });
                this.changePreviewColor();
            }, 1000);
            //this.setState({ loadingPreview: false, currentEditorProductId: 1057 });
            //this.props.getElementsForProduct(1057);
        }
        if (e.data.message == 'savedone') {
            setTimeout(() => {
                this.setState({ loadingPreview: false, productIdForPrice: this.state.currentEditorProductId });
                this.props.getElementsForProduct(this.state.currentEditorProductId);
                this.props.getListElementsAndMetalsForProduct(this.state.currentEditorProductId);
            }, 1000);
        }
    }

    saveProductState = () => {
        let frame = window.frames[window.frames.length - 1];
        frame.postMessage({
            message: "save",
            productId: this.props.createdProductId
        }, '*');
    }

    onVariantSelect = (id) => {
        this.setState({
            selectedVariantId: id
        }, () => {
            this.renderSections(this.state.sections);
        });
    }

    changePreviewColor = () => {
        let selectedPaint = this.props.paints.find(p => p.id == this.state.selecredPaintForProductId);        
        if (selectedPaint && selectedPaint.textureColor) {
            let frame = window.frames[window.frames.length - 1];
            if (frame) {
                frame.postMessage({
                    message: "changeColor",
                    data: selectedPaint.textureColor
                }, '*');
            }
        }        
    }
    //#endregion

    on3dClick = (index) => {
        this.setState({ show3dElementIndex: index });
    }

    onElementCountChange = (index, count) => {
        let elementsForProduct = [...this.state.elementsForProduct];
        elementsForProduct[index] = { ...elementsForProduct[index], count: count };
        this.setState({
            elementsForProduct: elementsForProduct
        });
    }

    onBuyElementsFromEditorProduct = () => {
        this.state.elementsForProduct.forEach((item) => {
            this.props.addElementsToBasket({
                id: item.id,
                count: item.count
            });
        });
        toastr.success('', this.props.t('goodsView.addedToBasket'));
        this.setState({
            isRedirectToBasket: true
        });
    }

    hideAuthPopup = () => {
        this.setState({ showAuthPopup: false });
    }

    toggleFullScreen = () => {
        this.setState({ isFullscreenMode: !this.state.isFullscreenMode });
    }

    onOpenEditorClick = () => {
        if (this.props.isAuthenticated) {
            this.openEditor();
        } else {
            this.setState({
                isShowRegisterFromOpenEditor: true
            });
        }
    }

    openEditor() {
        this.props.moveProductToNewProject(this.state.currentEditorProductId, () => {
            window.open(Helper.editorEditLink(this.state.currentEditorProductId), "_blank");
        });
    }

    onRegisterDone = () => {
        this.setState({
            isShowRegisterFromOpenEditor: false
        });
        this.openEditor();
    }

    onCloseRegisterPopupClick = () => {
        this.setState({
            isShowRegisterFromOpenEditor: false
        });
    }

    onContainerTouch = (e) => {
        if (isMobile) {
            window.clearInterval(this.autoScrollInterval);
            window.clearInterval(this.timeoutAutoScrollInterval);
            //if (e.type == "touchend") {
            //    this.timeoutAutoScrollInterval = setTimeout(() => {
            //        this.startAutoScrollMobile();
            //    }, 5000);
            //}
        }
    }

    onContainerScroll = (e) => {
        if (isMobile) {
            window.clearTimeout(this.isScrolling);
            this.isScrolling = setTimeout(() => {
                if (this.isProgrammScroll) {
                    this.isProgrammScroll = false;
                    return;
                }
                const pdContainer = document.getElementById("pdContainer");
                const currentLeft = pdContainer.scrollLeft;
                const currentRight = pdContainer.scrollLeft + pdContainer.clientWidth;

                let scrollToItem = null;
                let scrollLeft = 0;
                let scrollToItemIndex = -1;
                for (let i = 1; i < this.pdItemsData.length; i++) {
                    const pdItem = document.getElementById(this.pdItemsData[i].id);
                    if (currentLeft < pdItem.offsetLeft && pdItem.offsetLeft < currentRight) {
                        if ((pdItem.offsetLeft - currentLeft) < (currentRight - pdItem.offsetLeft)) {
                            scrollToItem = this.pdItemsData[i];
                            scrollLeft = pdItem.offsetLeft;
                            scrollToItemIndex = i;
                        } else {
                            scrollToItem = this.pdItemsData[i - 1];
                            scrollLeft = pdItem.offsetLeft - pdContainer.clientWidth;
                            scrollToItemIndex = i - 1;
                        }
                        break;
                    }
                }

                if (scrollToItem) {
                    this.isProgrammScroll = true;
                    pdContainer.scrollTo({
                        top: 0,
                        left: scrollLeft,
                        behavior: 'smooth'
                    });
                    this.onSelectTopScreenMobile(scrollToItemIndex);
                } else {
                    for (let i = 0; i < this.pdItemsData.length; i++) {
                        const pdItem = document.getElementById(this.pdItemsData[i].id);
                        if (pdItem.offsetLeft <= currentLeft && currentLeft <= pdItem.offsetLeft + pdItem.clientWidth) {
                            this.onSelectTopScreenMobile(i);
                            break;
                        }
                    }
                }
            }, 600);
        }
    }

    startAutoScrollMobile() {
        if (!isMobile || this.autoScrollIntervalLoopCount >= 1)
            return;
        window.clearInterval(this.autoScrollInterval);
        this.autoScrollInterval = setInterval(() => {
            let selectedTopScreenMobile = this.state.selectedTopScreenMobile + 1;
            if (selectedTopScreenMobile >= this.pdItemsData.length) {
                selectedTopScreenMobile = 0;
                this.autoScrollIntervalLoopCount++;
                if (this.autoScrollIntervalLoopCount >= 1) {
                    window.clearInterval(this.autoScrollInterval);
                }
            }
            const pdContainer = document.getElementById("pdContainer");
            const pdItem = document.getElementById(this.pdItemsData[selectedTopScreenMobile].id);
            this.isProgrammScroll = true;
            pdContainer.scrollTo({
                top: 0,
                left: pdItem.offsetLeft,
                behavior: 'smooth'
            });
            this.onSelectTopScreenMobile(selectedTopScreenMobile);
        }, 3000);
    }

    onSelectTopScreenMobile(index) {
        this.setState({
            selectedTopScreenMobile: index,
            showTopHeader: true
        });
        setTimeout(() => {
            this.setState({
                showTopHeader: false
            });
        }, 1000);
    }

    moveToTopScreenMobile(index) {
        const pdContainer = document.getElementById("pdContainer");
        const pdItem = document.getElementById(this.pdItemsData[index].id);
        this.isProgrammScroll = true;
        pdContainer.scrollTo({
            top: 0,
            left: pdItem.offsetLeft,
            behavior: 'smooth'
        });
        this.onSelectTopScreenMobile(index);
    }

    onPaintVariantSelect = (id) => {
        if (this.state.selectedPaintVariantId != id) {
            this.setState({
                selectedPaintVariantId: id,
                selecredPaintForProductId: -1,
            });
        }
    }

    onAddPaintToProduct = (id) => {
        //let data = { productId: this.props.productId, paintId: id };
        this.setState({ selecredPaintForProductId: id }, () => {
            this.changePreviewColor();
        });
        //this.props.addPaintToProduct(data);        
    }

    render() {
        const { t, loading, loadingData, failed, product, isAuthenticated, isExecutor, productItem, userDimention } = this.props;
        const { loadingPreview, elementsForProduct, isFullscreenMode, elementsAndMetals, selectedVariantId, isRedirectToConstructor, isRedirectToBasket,
            selectedTopScreenMobile, showTopHeader } = this.state;
        const totalPrice = (product != null && product.price) ? product.price.productionPrice + product.price.paintingPrice + product.price.installationPrice : 0;
        const editorSrc = product != null ? Helper.editorPreviewLink(product.id) : null;
        const priceCoeff = (product != null && userDimention && this.state.sections.length == 0) ? (userDimention / product.generalDimentionNumb) : 1.0;

        if (isRedirectToConstructor) {
            return (<Redirect to={`/createProduct/${this.props.product.sketchId}`} />);
        }
        if (isRedirectToBasket) {
            return (<Redirect to={`/basket`} />);
        }

        const variants = this.state.sketch ? this.state.sketch.variants : null;

        if (this.state.isRedirectToRegister) {
            return <Redirect to='/register' />;
        }

        if (productItem && productItem.isNoContent) {
            return this.renderNoContent();
        }

        const paintVariants = [
            //{ id: -1, name: this.props.t('editSketch.paintDefault') },
            { id: 1, name: this.props.t('editSketch.paintRough') },
            { id: 2, name: this.props.t('editSketch.paintHammer') }
        ];

        return (
            <div className="pdTopContainer">
                {loading &&
                    <FullPageLoader />
                }
                {failed &&
                    <Alert color="danger">
                        {t('errorDataLoad')}
                    </Alert>
                }
                {product != null &&
                    <div className={!isMobile ? "pdContainer" : "pdContainer-mobile"} id="pdContainer" onScroll={this.onContainerScroll}
                        onTouchStart={this.onContainerTouch} onTouchMove={this.onContainerTouch} onTouchEnd={this.onContainerTouch}>
                        <div style={{ width: !isMobile ? '100%' : 'initial' }} id="pdItem1">
                            <ManufacturerPriceList                                
                                priceCoeff={priceCoeff}
                                regionId={this.props.regionId}
                                onRegionSelected={this.props.onRegionSelected}
                                outerPaintId={this.state.selecredPaintForProductId}
                            />
                        </div>

                        <div className={!isMobile ? "align-center mt-2" : "align-center ml-2 mr-2"}
                            style={!isMobile ? { width: '100%' } : { display: 'flex' }} id="pdItem2">
                            <div className={!isFullscreenMode ? (!isMobile ? "abcpPreviewContainer" : "abcpPreviewContainer-mobile") : "abcpPreviewContainerFull"}>
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={this.state.sections.length != 0 ? Helper.editorLinkAutoBuild(true) : editorSrc}
                                    frameBorder="0"
                                />
                                {!isFullscreenMode ?
                                    <AiOutlineFullscreen className="abcpFullScreenIcon" onClick={this.toggleFullScreen} />
                                    :
                                    <AiOutlineFullscreenExit className="abcpFullScreenIcon" onClick={this.toggleFullScreen} />
                                }
                                <div className="pdPreviewTitle align-left">
                                    <div><b>{t('productInfo.modelSample')}</b></div>
                                    {!isMobile && elementsAndMetals != null &&
                                        <div className={!isMobile ? "mt-2" : "pdMetalList-mobile"} >
                                            {elementsAndMetals["Metals"].map(item => (item + ", "))}
                                        </div>
                                    }
                                    {!isMobile && this.renderVariants()}
                                </div>
                                <div className="pdPreviewVariants-mobile align-left">
                                    {isMobile && this.renderVariants()}
                                </div>
                                {loadingPreview &&
                                    <div className="pdPreviewLoader">
                                        <InlineLoader />
                                    </div>
                                }
                                {isMobile && !isFullscreenMode &&
                                    <div className="frameScrollDiv"></div>
                                }
                            </div>
                            {isMobile && elementsAndMetals != null &&
                                <div className={!isMobile ? "mt-2" : "pdMetalList-mobile"} >
                                    {elementsAndMetals["Metals"].map(item => (item + ", "))}
                                </div>
                            }
                        </div>

                        <div id="pdItem3">
                            {this.state.sections.length != 0 &&
                                <>
                                    <div className="mt-2 mb-2" style={{ display: 'flex', alignItems: 'center', flexWrap: "nowrap", overflowX: "auto" }}>
                                        <div className="mr-2">{this.props.t('editSketch.paintVariants')}</div>
                                        {paintVariants.map((item, index) => (
                                            <div key={item.id}
                                                className={"pdVariantItem " + (this.state.selectedPaintVariantId == item.id ? "pdVariantItemSelected" : "")}
                                                onClick={() => this.onPaintVariantSelect(item.id)}
                                                style={{ display: 'flex' }}
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                    </div>
                                    {this.props.paints &&
                                        <div style={{ justifyContent: 'left', flexWrap: 'nowrap', overflowX: 'auto' }} className="element-item-container mt-2">
                                            {this.props.paints.filter(p => p.paintType == this.state.selectedPaintVariantId).map((item) => (
                                                <div key={item.id} onClick={() => this.onAddPaintToProduct(item.id)}>
                                                    <img src={item.textureImage} className={this.state.selecredPaintForProductId == item.id ? "paint-item-selected" : "paint-item"} />
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </>
                            }

                            {this.props.autoBuildTemplates && product.sketchId != null &&
                                <TemplateList
                                    t={t}
                                    currentLang={this.props.i18n.language}
                                    templates={this.props.autoBuildTemplates}
                                    selectedProductType={product.productTypeId}
                                    onApply={this.onAutobuildTemplateApply}
                                    extraButtonClick={this.openCreateProject}
                                    extraButtonText={t('productInfo.autobuildWithSketch')}
                                    innerSectionWidth={this.props.sketchDetails ? this.props.sketchDetails.sketch.innerSectionWidth : null}
                                    isSigleRow={isMobile}
                                    autoShowFirstTime={false}
                                />
                            }
                        </div>
                    </div>
                }
                {isMobile &&
                    <div className="pdPointContainer">
                        {this.pdItemsData.map((item, index) => (
                            <div className={(index == selectedTopScreenMobile) ? "pdPointSelected" : "pdPoint"}></div>
                        ))}
                    </div>
                }
                {showTopHeader &&
                    <div className="pdTopHeader">{this.pdItemsData[selectedTopScreenMobile].title}</div>
                }

                {this.state.isShowRegisterFromOpenEditor &&
                    <RegisterPopup
                        t={t}
                        title={t('manufacturer.validateTitle3')}
                        isAuthenticated={this.props.isAuthenticated}
                        registerUser={this.props.registerUser}
                        sendSmsVerification={this.props.sendSmsVerification}
                        verificateAccount={this.props.verificateAccount}
                        onSendContactRequest={this.onRegisterDone}
                        onCloseClick={this.onCloseRegisterPopupClick}
                        registerFrom={"Open editor"}
                    />
                }
            </div>
        );
    }

    renderVariants() {
        const variants = this.state.sketch ? this.state.sketch.variants : null;
        /*const paintVariants = [
            { id: -1, name: this.props.t('editSketch.paintDefault') },
            { id: 1, name: this.props.t('editSketch.paintRough') },
            { id: 2, name: this.props.t('editSketch.paintHammer') }
        ];*/
        return (
            <>
                {this.state.sections.length != 0 && variants && variants.length != 0 &&
                    <div className="mt-2 mb-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="mr-2">{this.props.t('editSketch.variants')}</div>
                        <div
                            className={"pdVariantItem " + (this.state.selectedVariantId == -1 ? "pdVariantItemSelected" : "")}
                            onClick={() => this.onVariantSelect(-1)}
                        >
                            {this.props.t('editSketch.mainVariant')}
                        </div>
                        {variants.map((item, index) => (
                            <div key={item.id}
                                className={"pdVariantItem " + (this.state.selectedVariantId == item.id ? "pdVariantItemSelected" : "")}
                                onClick={() => this.onVariantSelect(item.id)}
                                style={{ display: 'flex' }}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                }
                {/*this.state.sections.length != 0 &&
                    <div className="mt-2 mb-2" style={{ display: 'flex', alignItems: 'center', flexWrap: "nowrap", overflowX: "auto" }}>
                        <div className="mr-2">{this.props.t('editSketch.paintVariants')}</div>
                        {paintVariants.map((item, index) => (
                            <div key={item.id}
                                className={"pdVariantItem " + (this.state.selectedPaintVariantId == item.id ? "pdVariantItemSelected" : "")}
                                onClick={() => this.onPaintVariantSelect(item.id)}
                                style={{ display: 'flex' }}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                */}
            </>
        );
    }

    onUserPhoneChange = (phone) => {
        if (phone == "3800") {
            phone = "380";
        }
        this.setState({
            userPhone: phone
        });
    }

    onSendProductCatalogSketchRequest = () => {
        if (this.state.userPhone != '') {
            const data = {
                userPhone: this.state.userPhone,
                catalogProductId: this.props.productItem.id
            };
            this.props.sendProductCatalogSketchRequest(data, (isOk) => {
                if (isOk) {
                    toastr.success('', this.props.t('productInfo.requestSent'));
                }
            });
        }
    }

    renderNoContent() {
        const { t, productItem } = this.props;
        return (
            <div className={!isMobile ? "align-center" : "pdNoContent-mobile"} style={{ backgroundColor: 'white' }}>
                <div className="m-5 align-center" style={!isMobile ? {} : { minWidth: '80%' }}>
                    <img src={productItem.firstSpecificationImage} style={{ maxHeight: '400px', maxWidth: '90%' }} />
                    <div className="mb-1">{t('productInfo.emptyProductTitle')}</div>
                    <PhoneInput
                        inputClass="phone-input"
                        inputStyle={{ width: '100%' }}
                        country={'ua'}
                        preferredCountries={['ua', 'us', 'ca']}
                        name="userPhone"
                        value={this.state.userPhone}
                        onChange={this.onUserPhoneChange}
                    />
                    <Button className="mt-1 mb-3" color="success" onClick={this.onSendProductCatalogSketchRequest}>{t('productInfo.send')}</Button>
                </div>
                <ManufacturerPriceList />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.home.loading,
    loadingData: state.home.loadingData,
    failed: state.home.failed,
    product: state.home.catalogProductDetails,
    isAuthenticated: state.login.isAuthenticated,
    isExecutor: state.login.user.isExecutor,
    threeDTimeError: state.home.threeDTimeError,
    autoBuildTemplates: state.autoBuild.autoBuildTemplates,
    sketchDetails: state.autoBuild.sketchDetails,
    elementsForProduct: state.home.elementsForProduct,
    elementsAndMetals: state.home.elementsAndMetals,
    texturedMetalList: state.home.texturedMetalList,
    paints: state.home.paints,
})

const mapDispatchToProps = {
    getCatalogProductDetails: actions.getCatalogProductDetails,
    sendOrderToSeller: actions.sendOrderToSeller,
    addFavorites: addFavorites,
    removeFavorites: removeFavorites,
    loadFavoriteLocal: loadFavoriteLocal,
    constructorOpenClick: actions.constructorOpenClick,
    flush3dError: actions.flush3dError,
    getTemplates: getTemplates,
    getSketchDetails: getSketchDetails,
    generateHiddenProduct: generateHiddenProduct,
    moveProductToNewProject: moveProductToNewProject,
    getElementsForProduct: actions.getElementsForProduct,
    addElementsToBasket: addElementsToBasket,
    getListElementsAndMetalsForProduct: actions.getListElementsAndMetalsForProduct,
    getTexturedMetalsList: actions.getTexturedMetalsList,
    registerUser: registerUser,
    sendSmsVerification: sendSms,
    verificateAccount: verificateAccount,
    sendProductCatalogSketchRequest: actions.sendProductCatalogSketchRequest,
    loadPrints: actions.loadPrints,
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductDetailsV3));