import React, { Component } from "react";
import { Button, Input } from "reactstrap";
import CheckBox from "../../components/fields/checkbox/CheckBox";
import './TemplateList.css';
import { toastr } from "react-redux-toastr";
import HistoryStorage from "../../utils/HistoryStorage";
import { isMobile } from "react-device-detect";

export default class TemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: -1,
            isOpen: false,
            params: []
        };
    }

    static LastSelectedTemlates = {};

    static wasDetailsApplied = false;
    static isFromProductDetails = () => {
        if (HistoryStorage.historyArray.length >= 2
            && HistoryStorage.historyArray[HistoryStorage.historyArray.length - 2].indexOf("/home/productDetails") == 0) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.templates !== prevProps.templates || this.props.selectedProductType !== prevProps.selectedProductType) {
            const arr = this.props.templates.filter(t => t.productTypeId == this.props.selectedProductType);
            let selectedItem = arr.length != 0 ? arr[arr.length - 1] : null;
            if (TemplateList.LastSelectedTemlates[this.props.selectedProductType]) {
                selectedItem = arr.find(t => t.id == TemplateList.LastSelectedTemlates[this.props.selectedProductType]);
            }
            const isAutoShow = !this.props.autoShowFirstTime || !TemplateList.wasDetailsApplied;
            if (selectedItem != null && !TemplateList.isFromProductDetails() && isAutoShow) {
                this.onTemplateSelect(selectedItem);
            }
        }

        if (this.state.selectedId != prevState.selectedId || this.state.isOpen != prevState.isOpen) {
            if (isMobile && !this.props.isSigleRow) {
                const el = document.getElementById("abTemplateParamDiv");
                if (el) {
                    const clientRect = el.getBoundingClientRect();
                    let left = (window.innerWidth - clientRect.width) / 2;
                    left -= clientRect.x;
                    el.style.left = left + "px";
                }
            }
        }
    }

    componentDidMount() {
        if (this.props.templates && this.props.selectedProductType) {
            const arr = this.props.templates.filter(t => t.productTypeId == this.props.selectedProductType);
            let selectedItem = arr.length != 0 ? arr[arr.length - 1] : null;
            if (TemplateList.LastSelectedTemlates[this.props.selectedProductType]) {
                selectedItem = arr.find(t => t.id == TemplateList.LastSelectedTemlates[this.props.selectedProductType]);
            }
            const isAutoShow = !this.props.autoShowFirstTime || !TemplateList.wasDetailsApplied;
            if (selectedItem != null && !TemplateList.isFromProductDetails() && isAutoShow) {
                this.onTemplateSelect(selectedItem);
            }
        }
    }

    onTemplateSelect(item) {
        if (item.id != this.state.selectedId) {            
            this.setState({
                selectedId: item.id,
                isOpen: true,
                params: this.getParams(item)
            });
            TemplateList.LastSelectedTemlates[this.props.selectedProductType] = item.id;
        } else {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }

    getParams(template) {
        const d = JSON.parse(template.template);
        const sessionParam = JSON.parse(sessionStorage.getItem("template-" + template.id));
        if (sessionParam != null && sessionParam.params.length > 0) {
            return sessionParam.params;
        }
        return d.userVars;
    }

    onParamValueChange = (value, index) => {
        if (parseInt(value) > 20000) {
            toastr.warning('', this.props.t('editSketch.lengError'));
            return;
        }

        let params = [...this.state.params];
        params[index] = {
            ...params[index],
            val: value
        };
        if (params[index].useForPillarACount) {
            const pillarAIndex = params.findIndex(p => p.name == params[index].useForPillarACount);
            if (pillarAIndex >= 0) {
                let innerSectionWidth = this.props.innerSectionWidth ? parseInt(this.props.innerSectionWidth) : 2500;
                let pillarACount = Math.floor(parseInt(value) / innerSectionWidth);
                params[pillarAIndex].val = pillarACount;
            }
        }
        this.setState({
            params: params
        });
    }

    onApplyClick = () => {
        TemplateList.wasDetailsApplied = true;
        const templateItem = this.props.templates.find(t => t.id == this.state.selectedId);
        const templateObj = JSON.parse(templateItem.template);
        let sections = templateObj.sections;
        const params = this.state.params;
        sessionStorage.setItem("template-" + this.state.selectedId, JSON.stringify({ params }));
        for (let i = 0; i < sections.length; i++) {
            let section = sections[i];
            for (let prop in section.userVars) {
                let userVarsObj = section.userVars[prop];
                let userValueItem = params.find(p => p.name == userVarsObj.v);
                if (userValueItem != null) {
                    if (userValueItem.type == "TYPE_NUMBER") {
                        section[prop] = parseFloat(userVarsObj.a) * userValueItem.val + parseFloat(userVarsObj.b);
                    } else {
                        section[prop] = userValueItem.val;
                    }
                }
            }
        }

        this.setState({
            isOpen: false
        });

        this.props.onApply(sections);
    }

    render() {
        const { selectedId, isOpen } = this.state;
        const { t, templates, selectedProductType, isSigleRow } = this.props;
        const filteredTemplates = templates.filter(t => t.productTypeId == selectedProductType);

        return (
            <div>
                {filteredTemplates.length > 0 &&
                    <div className="mt-2"><b className="mr-2">{t('autoBuildProduct.selectTemplateH')}</b>{t('autoBuildProduct.selectTemplate')}</div>
                }
                <div className="mt-1" style={{ display: 'flex', flexWrap: !isSigleRow ? 'wrap' : 'nowrap' }}>
                    {filteredTemplates.map(item => (
                        <div
                            key={item.id}
                            className={"abcpTemplateItem" + (item.id == selectedId ? " abcpTemplateItemSelected" : "")}
                            style={{ position: 'relative' }}
                        >
                            <div className="abcpTemplateImage" onClick={() => this.onTemplateSelect(item)}>
                                <img src={item.image} />
                            </div>
                            {isOpen && item.id == selectedId && this.renderParams(item)}
                        </div>
                    ))}
                    {this.props.extraButtonClick &&
                        <Button style={{ width: '150px', height: '150px', margin: '5px' }} color="success" outline
                            onClick={this.props.extraButtonClick}>{this.props.extraButtonText}</Button>
                    }
                </div>
            </div>
        );
    }

    renderParams(template) {
        const params = this.state.params.filter(p => p.isShownUser);
        let lang = this.props.currentLang;
        if (lang == 'uk') lang = 'ua';

        return (
            <div id="abTemplateParamDiv" className="abTemplateParamDiv">
                <div>
                    <table className="m-2">
                        <tbody>
                            {this.state.params.map((userVar, vIndex) => {
                                return userVar.isShownUser ?
                                    <>
                                        <tr key={userVar.key}>
                                            <td className="align-right pr-2">
                                                {userVar["label_" + lang]}
                                            </td>
                                            <td>
                                                {userVar.type == "TYPE_NUMBER" ?
                                                    <Input name="" value={userVar.val}
                                                        onChange={(e) => this.onParamValueChange(parseFloat(e.target.value), vIndex)}
                                                        style={{ width: isMobile ? "100px" : "200px" }} type="number" />
                                                    :
                                                    <CheckBox
                                                        value={userVar.val}
                                                        name=''
                                                        onChange={(e) => this.onParamValueChange(e.target.checked, vIndex)}
                                                        label=''
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    </>
                                    : <></>
                            })}
                        </tbody>
                    </table>
                    <div className="align-center">
                        <Button color='success' onClick={this.onApplyClick}>
                            {this.props.t('autoBuildProduct.apply')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}