export const GET_ALL_INSTRUCTIONS_REQUEST = 'instruction/GET_ALL_INSTRUCTIONS_REQUEST';
export const GET_ALL_INSTRUCTIONS_SUCCESS = 'instruction/GET_ALL_INSTRUCTIONS_SUCCESS';
export const GET_ALL_INSTRUCTIONS_FAILURE = 'instruction/GET_ALL_INSTRUCTIONS_FAILURE';

export const GET_ALL_BLOG_REQUEST = 'instruction/GET_ALL_BLOG_REQUEST';
export const GET_ALL_BLOG_SUCCESS = 'instruction/GET_ALL_BLOG_SUCCESS';
export const GET_ALL_BLOG_FAILURE = 'instruction/GET_ALL_BLOG_FAILURE';

export const GET_SINGLE_INSTRUCTION_REQUEST = 'instruction/GET_SINGLE_INSTRUCTION_REQUEST';
export const GET_SINGLE_INSTRUCTION_SUCCESS = 'instruction/GET_SINGLE_INSTRUCTION_SUCCESS';
export const GET_SINGLE_INSTRUCTION_FAILURE = 'instruction/GET_SINGLE_INSTRUCTION_FAILURE';

export const GET_LAST_BLOG_REQUEST = 'instruction/GET_LAST_BLOG_REQUEST';
export const GET_LAST_BLOG_SUCCESS = 'instruction/GET_LAST_BLOG_SUCCESS';
export const GET_LAST_BLOG_FAILURE = 'instruction/GET_LAST_BLOG_FAILURE';

export const GET_INSTUCTION_TYPE_REQUEST = 'instruction/GET_INSTUCTION_TYPE_REQUEST';
export const GET_INSTUCTION_TYPE_SUCCESS = 'instruction/GET_INSTUCTION_TYPE_SUCCESS';
export const GET_INSTUCTION_TYPE_FAILURE = 'instruction/GET_INSTUCTION_TYPE_FAILURE';
