import { FormulaHelper } from "./FormulaHelper";
import * as uniqueId from "lodash/uniqueId";
import MathHelper from "../utils/MathHelper";

export class EditSketchConstants {
    static CURRENT_VERSION = 6.0;
    static PROCESS_TYPE_SINGLE = "single";
    static PROCESS_TYPE_COPY = "copy";
    static OBJECT_TYPE_METALL = "metalrolling";
    static OBJECT_TYPE_ELEMENT = "element";
    static OBJECT_TYPE_TEMPLATE = "template";
    static OBJECT_TYPE_GROUP = "group";

    static AUTOFIT_TYPE_INSIDE = "inside";
    static AUTOFIT_TYPE_OUTSIDE = "outside";
    
    static PILLAR1 = "pillar1";
    static PILLAR2 = "pillar2";
    static PILLARA = "pillarA";

    static COPY_X = "x";
    static COPY_Y = "y";
    static COPY_Z = "z";

    static VISIBLE_STANDART = 1;
    static VISIBLE_COPY = 2;
    static VISIBLE_ALFA = 3;
        
    static ROTATE_SECTION_OUTER_ANGLE = 1;
    static ROTATE_SECTION_PILLAR = 2;

    static END_TYPE_CUT = "type_cut";
    static END_TYPE_ABC = "type_abc";

    static getNewRow = () => {
        return {
            processType: EditSketchConstants.PROCESS_TYPE_SINGLE,
            id: 0,
            type: EditSketchConstants.OBJECT_TYPE_METALL,
            position: {
                x: FormulaHelper.getObjectByType(FormulaHelper.TYPE_CONSTANT),
                y: FormulaHelper.getObjectByType(FormulaHelper.TYPE_CONSTANT),
                z: FormulaHelper.getObjectByType(FormulaHelper.TYPE_CONSTANT),
            },
            rotation: {
                x: FormulaHelper.getObjectByType(FormulaHelper.TYPE_CONSTANT_ANGLE),
                y: FormulaHelper.getObjectByType(FormulaHelper.TYPE_CONSTANT_ANGLE),
                z: FormulaHelper.getObjectByType(FormulaHelper.TYPE_CONSTANT_ANGLE)
            },
            rotation2: null,
            length: FormulaHelper.getObjectByType(FormulaHelper.TYPE_CONSTANT),
            tag: null,
            globalStep: false,
            copyToInnerSection: false,
            useSegment: false,
            useSpline: false,
            noCalculate: false,
            useRotation2: false,
            donotMirror: false,
            visibleInterval: null,
            visibleIntervalY: null,
            visibleIntervalA: null,
            torsions:null,
            key: uniqueId('or-')
        };
    }

    static convertToLatestVerion = (objects, version) => {
        if (version < EditSketchConstants.CURRENT_VERSION) {
            let arr = [];
            for (let i = 0; i < objects.length; i++) {
                let objectOld = objects[i];
                let object = { ...objectOld };
                object.position = { ...object.position };
                object.copy = { ...object.copy };
                object.rotation = { ...object.rotation };
                arr.push(object);
                if (version < 3) {
                    if (typeof (object.length) == "object") {
                        object.length = {
                            type: FormulaHelper.TYPE_LINEAR,
                            v: objectOld.length.l,
                            a: objectOld.length.k,
                            b: objectOld.length.offset
                        };
                    } else {
                        object.length = {
                            type: FormulaHelper.TYPE_CONSTANT,
                            v: objectOld.length
                        };
                    }
                    if (typeof (object.position.x) == "object") {
                        object.position.x = {
                            type: FormulaHelper.TYPE_LINEAR,
                            v: objectOld.position.x.l,
                            a: objectOld.position.x.k,
                            b: objectOld.position.x.offset
                        };
                    } else {
                        object.position.x = {
                            type: FormulaHelper.TYPE_CONSTANT,
                            v: objectOld.position.x
                        };
                    }
                    if (typeof (object.position.y) == "object") {
                        object.position.y = {
                            type: FormulaHelper.TYPE_LINEAR,
                            v: objectOld.position.y.l,
                            a: objectOld.position.y.k,
                            b: objectOld.position.y.offset
                        };
                    } else {
                        object.position.y = {
                            type: FormulaHelper.TYPE_CONSTANT,
                            v: objectOld.position.y
                        };
                    }
                    object.position.z = {
                        type: FormulaHelper.TYPE_CONSTANT,
                        v: objectOld.position.z
                    };
                    if (object.copy != null && JSON.stringify(object.copy) != '{}') {
                        object.copy.left = {
                            type: FormulaHelper.TYPE_CONSTANT,
                            v: objectOld.copy.left
                        };
                        object.copy.right = {
                            type: FormulaHelper.TYPE_CONSTANT,
                            v: objectOld.copy.right
                        };
                    }
                }
                if (version < 4) {
                    if (object.copy != null && typeof (object.copy.stepX) != 'object') {
                        object.copy.stepX = {
                            type: FormulaHelper.TYPE_CONSTANT,
                            v: object.copy.stepX
                        };
                        object.copy.stepY = {
                            type: FormulaHelper.TYPE_CONSTANT,
                            v: object.copy.stepY
                        };
                        object.copy.stepZ = {
                            type: FormulaHelper.TYPE_CONSTANT,
                            v: object.copy.stepZ
                        };
                    }
                }
                if (version < 5) {
                    if (typeof (object.rotation.x) != 'object') {
                        object.rotation.x = {
                            type: FormulaHelper.TYPE_CONSTANT_ANGLE,
                            v: object.rotation.x
                        };
                        object.rotation.y = {
                            type: FormulaHelper.TYPE_CONSTANT_ANGLE,
                            v: object.rotation.y
                        };
                        object.rotation.z = {
                            type: FormulaHelper.TYPE_CONSTANT_ANGLE,
                            v: object.rotation.z
                        };
                    }
                }
                if (version < 5.1) {
                    if (object.visibleInterval != null) {
                        object.visibleInterval = [object.visibleInterval];
                    }
                    if (object.visibleIntervalY != null) {
                        object.visibleIntervalY = [object.visibleIntervalY];
                    }
                }
                if (version < 6) {
                    if (object.torsion != null) {
                        object.torsions = [{
                            angle: {
                                type: FormulaHelper.TYPE_CONSTANT_ANGLE,
                                v: object.torsion.angle
                            },
                            end: {
                                type: FormulaHelper.TYPE_CONSTANT,
                                v: object.torsion.end
                            },
                            start: {
                                type: FormulaHelper.TYPE_CONSTANT,
                                v: object.torsion.start
                            }
                        }];
                        object.torsion = null;
                    }
                }
            }
            return arr;
        }
        else {
            return objects;
        }
    }

    static loadStateDataToGroup = (editorData, itemOld) => {
        console.log(editorData);
        console.log(itemOld);

        let objectsIn = editorData[0].children;
        if (objectsIn.length == 1 && objectsIn[0].type == EditSketchConstants.OBJECT_TYPE_GROUP) {
            objectsIn = objectsIn[0].children;
        }
        const objects = objectsIn.filter(o => o.type).map(o => this.stateItemToInner(o));

        let item = {
            ...itemOld,
            objects: objects
        };

        return item;
    }

    static loadStateDataToItems = (editorData) => {
        let items = editorData.filter(o => o.type).map(o => {
            let item = this.stateItemToInner(o);
            if (o.children) {
                item.type = this.OBJECT_TYPE_GROUP;
                item.isEditorGroup = true;
                item.objects = o.children.filter(o => o.type).map(o => this.stateItemToInner(o));
                item.sizeOriginal = {
                    x: 100,
                    y: 100
                };
                item.sizeTarget = {
                    x: {
                        type: FormulaHelper.TYPE_CONSTANT,
                        v: 100
                    },
                    y: {
                        type: FormulaHelper.TYPE_CONSTANT,
                        v: 100
                    }
                };                
            }
            return item;
        });
        return items;
    }

    static stateItemToInner = (o) => {
        let objOut = this.getNewRow();
        objOut.id = o.id;
        objOut.type = o.type;
        objOut.length.v = MathHelper.metersToMm(o.length);
        objOut.skipCalculation = o.skipCalculation;
        objOut.position.x.v = Math.abs(o.position.x) >= 0.001 ? MathHelper.metersToMm(o.position.x) : 0;
        objOut.position.y.v = Math.abs(o.position.y) >= 0.001 ? MathHelper.metersToMm(o.position.y) : 0;
        objOut.position.z.v = Math.abs(o.position.z) >= 0.001 ? MathHelper.metersToMm(o.position.z) : 0;
        objOut.rotation.x.v = Math.abs(o.rotation.x) >= 0.0001 ? MathHelper.radToDegree(o.rotation.x) : 0;
        objOut.rotation.y.v = Math.abs(o.rotation.y) >= 0.0001 ? MathHelper.radToDegree(o.rotation.y) : 0;
        objOut.rotation.z.v = Math.abs(o.rotation.z) >= 0.0001 ? MathHelper.radToDegree(o.rotation.z) : 0;
        if (o.points != null && o.points.length > 2) {
            objOut.useSpline = true;
            objOut.length.v = MathHelper.metersToMm(Math.abs(o.points[0].y)) * 2;
            objOut.points = o.points
                //.filter((p, pi) => pi != 0 && pi != (o.points.length - 1))
                .map(p => ({
                    x: Math.abs(p.x) >= 0.001 ? MathHelper.metersToMm(p.x) : 0,
                    y: Math.abs(p.y) >= 0.001 ? MathHelper.metersToMm(p.y) : 0,
                    z: Math.abs(p.z) >= 0.001 ? MathHelper.metersToMm(p.z) : 0,
                    key: uniqueId('pid-')
                }));
            objOut.useStartPoints = true;
        }
        if (o.ends != null) {
            objOut.ends = {};

            if (o.ends.first != null) {
                if (o.ends.first.cutLength != null) {
                    objOut.ends.first = {
                        type: this.END_TYPE_CUT,
                        cutLength: MathHelper.metersToMm(o.ends.first.cutLength),
                        axis: o.ends.first.axis
                    };
                } else if (o.ends.first.a != null) {
                    objOut.ends.first = {
                        type: this.END_TYPE_ABC,
                        a: MathHelper.metersToMm(o.ends.first.a),
                        b: MathHelper.metersToMm(o.ends.first.b),
                        c: MathHelper.metersToMm(o.ends.first.c)
                    };
                }
            }

            if (o.ends.last != null) {
                if (o.ends.last.cutLength != null) {
                    objOut.ends.last = {
                        type: this.END_TYPE_CUT,
                        cutLength: MathHelper.metersToMm(o.ends.last.cutLength),
                        axis: o.ends.last.axis
                    };
                } else if (o.ends.last.a != null) {
                    objOut.ends.last = {
                        type: this.END_TYPE_ABC,
                        a: MathHelper.metersToMm(o.ends.last.a),
                        b: MathHelper.metersToMm(o.ends.last.b),
                        c: MathHelper.metersToMm(o.ends.last.c)
                    };
                }
            }

        }
        return objOut;
    }
}