export const GET_CLOSEDPROJECTS_REQUEST = 'homeproject/GET_CLOSEDPROJECTS_REQUEST';
export const GET_CLOSEDPROJECTS_SUCCESS = 'homeproject/GET_CLOSEDPROJECTS_SUCCESS';
export const GET_CLOSEDPROJECTS_FAILURE = 'homeproject/GET_CLOSEDPROJECTS_FAILURE';
export const CLEAR_CLOSEDPROJECTS = 'homeproject/CLEAR_CLOSEDPROJECTS';

export const GET_PROJECTDETAILS_REQUEST = 'homeproject/GET_PROJECTDETAILS_REQUEST';
export const GET_PROJECTDETAILS_SUCCESS = 'homeproject/GET_PROJECTDETAILS_SUCCESS';
export const GET_PROJECTDETAILS_FAILURE = 'homeproject/GET_PROJECTDETAILS_FAILURE';

export const SEND_ORDERTOSELLER_REQUEST = 'homeproject/SEND_ORDERTOSELLER_REQUEST';
export const SEND_ORDERTOSELLER_SUCCESS = 'homeproject/SEND_ORDERTOSELLER_SUCCESS';
export const SEND_ORDERTOSELLER_FAILURE = 'homeproject/SEND_ORDERTOSELLER_FAILURE';

export const GET_HOMEDATA_REQUEST = 'home/GET_HOMEDATA_REQUEST';
export const GET_HOMEDATA_SUCCESS = 'home/GET_HOMEDATA_SUCCESS';
export const GET_HOMEDATA_FAILURE = 'home/GET_HOMEDATA_FAILURE';

export const GET_CLOSEDPRODUCTS_REQUEST = 'home/GET_CLOSEDPRODUCTS_REQUEST';
export const GET_CLOSEDPRODUCTS_SUCCESS = 'home/GET_CLOSEDPRODUCTS_SUCCESS';
export const GET_CLOSEDPRODUCTS_FAILURE = 'home/GET_CLOSEDPRODUCTS_FAILURE';

export const GET_PRODUCTTDETAILS_REQUEST = 'homeproject/GET_PRODUCTTDETAILS_REQUEST';
export const GET_PRODUCTTDETAILS_SUCCESS = 'homeproject/GET_PRODUCTTDETAILS_SUCCESS';
export const GET_PRODUCTTDETAILS_FAILURE = 'homeproject/GET_PRODUCTTDETAILS_FAILURE';

export const THREED_TIME_OUT = "home/3D_TIME_OUT";
export const THREED_TIME_OUT_FLUSH = "home/THREED_TIME_OUT_FLUSH";

export const REQUEST = 'home/REQUEST';
export const FAILURE = 'home/FAILURE';
export const SUCCESS = 'home/SUCCESS';

export const GET_ELEMENTS_FOR_PRODUCT_REQUEST = 'home/GET_ELEMENTS_FOR_PRODUCT_REQUEST';
export const GET_ELEMENTS_FOR_PRODUCT_SUCCESS = 'home/GET_ELEMENTS_FOR_PRODUCT_SUCCESS';

export const GET_LIST_ELEMENTS_AND_METALS_FOR_PRODUCT_SUCCESS = 'home/GET_LIST_ELEMENTS_AND_METALS_FOR_PRODUCT_SUCCESS';

export const GET_PRODUCT_TYPES_SUCCESS = 'home/GET_PRODUCT_TYPES_SUCCESS';

export const GET_TEXTURED_METALS_LIST_SUCCESS = 'home/GET_TEXTURED_METALS_LIST_SUCCESS';
export const REQUEST_METALS_LIST = 'home/REQUEST_METALS_LIST';
export const FAILURE_METALS_LIST = 'home/FAILURE_METALS_LIST';

export const GET_PRINTS_SUCCESS = 'home/GET_PRINTS_SUCCESS';
export const SET_PAINT_TO_PRODUCT_REQUEST = 'home/SET_PAINT_TO_PRODUCT_REQUEST';
export const SET_PAINT_TO_PRODUCT_SUCCESS = 'home/SET_PAINT_TO_PRODUCT_SUCCESS';




