import React, { Suspense } from 'react';
import { Route } from 'react-router';
import './App.css';

import LiveSupport from './feedback/liveSupport/LiveSupport';
import CookiesBanner from './cookies/CookiesBanner';

// Loader
import { FullPageLoaderNoText } from './components/loaders/FullPageLoader/FullPageLoader';


// Layouts
import MainLayout from './components/layouts/MainLayout';
import MyProfileLayout from './components/layouts/MyProfileLayout';
import MainLayoutHome from './components/layouts/MainLayoutHome';
import MyCompanyLayout from './siteCompany/components/menu/CompanyLayout';

// Routes
import GuestRoute from './components/routes/GuestRoute';
import UserRoute from './components/routes/UserRoute';
import UserRouteExecutor from './components/routes/UserRouteExecutor'
import UserRoutePortfolio from './components/routes/UserRoutePortfolio';
import UnConfirmAccountRoute from './components/routes/UnConfirmAccountRoute';
import UserRouteSeller from './components/routes/UserRouteSeller';

// import { loadReCaptcha } from 'react-recaptcha-google'

import './i18n';
import HomeCatalogPageV23 from './home/catalogv23/CatalogPageV3';
import TitleHelper from './utils/TitleHelper';
import AppTitleHelper from './AppTitleHelper';
import MainLayoutFullWidth from './components/layouts/MainLayoutFullWidth';
import HistoryStorage from './utils/HistoryStorage';
import { ErrorBoundary } from './errorBoundary';
import loadlazy from './utils/componentLoader';
import { isMobile } from 'react-device-detect';

// Pages

const AboutProject = loadlazy(() => import('./aboutProject/AboutProject'));
const HomePage = loadlazy(() => import('./home/homePage/HomePage'));
const HomeCatalogPage = loadlazy(() => import('./home/catalog/CatalogPage'));
const HomeCatalogPageV2 = loadlazy(() => import('./home/catalogv2/CatalogPageV2'));
const HomeCatalogPageV3 = loadlazy(() => import('./home/catalogv3/CatalogPageV3'));
const HomeCatalogPageV4 = loadlazy(() => import('./home/catalogv4/CatalogPageV4'));

const CatalogProductPage = loadlazy(() => import('./home/catalogProductDetails/ProductDetails'));
const HomeProjectsPage = loadlazy(() => import('./home/homeProjects/HomeProjectsPage'));
const HomeProjectDetailsPage = loadlazy(() => import('./home/projectDetails/HomeProjectDetailsPage'));
const GoodsListPage = loadlazy(() => import('./goods/goodslist/GoodsListPage'));
const ManufacturersPage = loadlazy(() => import('./manufacturer/ManufacturersPage'));
const GoodsDetailsPage = loadlazy(() => import('./goods/goodsdetails/GoodsDetailsPage'));
const ElementsListPage = loadlazy(() => import('./elements/elementslist/ElementsListPage'));
const ElementDetailsPage = loadlazy(() => import('./elements/elementDetails/ElementDetailsPage'));

const ForBusinessPage = loadlazy(() => import('./aboutProject/ForBusinessPage'));
const HowToOrderPage = loadlazy(() => import('./static/howToOrder/HowToOrder'));

const SolutionFooter = loadlazy(() => import('./solutions/static/SolutionFooter'));
/*
const PrivacyPage = loadlazy(() => import('./static/privacy/PrivacyPage'));
const TermsOfUsePage = loadlazy(() => import('./static/terms/TermsOfUse'));
const TariffsPage = loadlazy(() => import('./static/tariffs/Tariffs'));
*/
const RegisterPage = loadlazy(() => import('./register/registerSimple/RegisterPage'));
const CustomerPage = loadlazy(() => import('./register/registerCustomer/scenes/index'));
const RegisterExecutorStepOne = loadlazy(() => import('./register/registerExecutor/scenes/registerExecutorStepOne/index'));
const LoginPage = loadlazy(() => import('./login/scenes'));
const RecoverPasswordPage = loadlazy(() => import('./login/scenes/RecoverPasswordPage'));
const MyInfoPage = loadlazy(() => import('./myprofile/myinfo/scenes'));
const MySpecialtyPage = loadlazy(() => import('./myprofile/myspecialty/scenes/index'));
const ListMyPortfolioPage = loadlazy(() => import('./myprofile/myportfolio/scenes/index'));
const PortfolioItemPage = loadlazy(() => import('./myprofile/myportfolio/scenes/scenes/index'));
const EditSiteProtforlioPage = loadlazy(() => import('./myprofile/sitePortfolio/EditSiteProtforlioPage'));
const VerificateAccountPage = loadlazy(() => import('./verify/scenes/index'));
const MyElementsPage = loadlazy(() => import('./myprofile/myelements/scenes/index'));
const ElementMatchPage = loadlazy(() => import('./myprofile/elementmatch/scenes/ElementMatchPage'));
const MyElementPage = loadlazy(() => import('./myprofile/myelements/scenes/MyElementPage/index'));
const BulkUploadInfoPage = loadlazy(() => import('./myprofile/bulkuploadinfo/BulkUploadInfoPage'));
const MyEditorSessions = loadlazy(() => import('./myprofile/editorSessions/MyEditorSessions'));
const MyGoods = loadlazy(() => import('./myprofile/goods/myGoods/MyGoods'));
const AddGoods = loadlazy(() => import('./myprofile/goods/addGoods/AddGoods'));
const AddPaints = loadlazy(() => import('./myprofile/goods/addGoods/AddPaint'));
const AddProfsheet = loadlazy(() => import('./myprofile/goods/addGoods/AddProfsheet'));
const AddMetal = loadlazy(() => import('./myprofile/goods/addGoods/AddMetal'));
const AddGateAutomation = loadlazy(() => import('./myprofile/goods/addGoods/AddGateAutomation'));
const MyFeedbacksPage = loadlazy(() => import('./feedback/MyFeedbacksPage'));
const FeedbackChatPage = loadlazy(() => import('./feedback/FeedbackChatPage'));

const CreateProjectPage = loadlazy(() => import('./projects/newproject/scenes/CreateProjectPage'));
const MyProjectsPage = loadlazy(() => import('./projects/myprojects/MyProjectsPage'));
const MyProjectsPageV2 = loadlazy(() => import('./projects/myprojects/MyProjectsPageV2'));
const MyProjectDetailsPage = loadlazy(() => import('./projects/projectdetails/MyProjectDetailsPage'));
const ExecuterPage = loadlazy(() => import('./projects/executerdetails/ExecuterPage'));
const ExecuterMyInvitationsPage = loadlazy(() => import('./projectsExecuter/myinvitations/MyInvitationsPage'));
const ExecuterProjectDetailsPage = loadlazy(() => import('./projectsExecuter/projectdetails/ProjectDetailsPage'));
const ExecuterProjectFindProjectsPage = loadlazy(() => import('./projectsExecuter/findprojects/FindProjectsPage'));
const ExecuterProjectMyPropositionsPage = loadlazy(() => import('./projectsExecuter/mypropositions/MyPropositionsPage'));
const ECustomerPage = loadlazy(() => import('./projectsExecuter/customerdetails/CustomerPage'));
const ExecuterMyContractsPage = loadlazy(() => import('./projectsExecuter/mycontracts/MyContractsPage'));
const ExecuterContractDetailsPage = loadlazy(() => import('./projectsExecuter/contractdetails/ContractDetailsPage'));
const CalculateFactorsPage = loadlazy(() => import('./myprofile/calculationfactors/scenes/CalculationFactorsPage'));

const AllInstructionsPage = loadlazy(() => import('./instruction/global/AllInstructionsPage'));
const InstructionPage = loadlazy(() => import('./instruction/details/InstructionPage'));

const ChatPage = loadlazy(() => import('./chat/ChatPage'));
const FavoriteProductsPage = loadlazy(() => import('./favorites/favoriteProducts/FavoriteProductsPage'));
const BasketPage = loadlazy(() => import('./basket/basketPage/BasketPage'));


const MySketchesPage = loadlazy(() => import('./autoBuildEdit/sketches/MySketches'));
const MySketchesElementsPage = loadlazy(() => import('./autoBuildEdit/sketches/MySketchesElements'));
const EditSketchPage = loadlazy(() => import('./autoBuildEdit/editSketch/EditSketchPage'));
const MyAutoBuildTemplatesPage = loadlazy(() => import('./autoBuildEdit/templates/MyTemplates'));
const EditAutoBuildTemplatePage = loadlazy(() => import('./autoBuildEdit/editTemplate/EditTemplatePage'));

const AutoBuildCreateProduct = loadlazy(() => import('./autoBuild/createProduct/CreateProductPage'));

const EmptyPage = loadlazy(() => import('./EmptyPage'));

const MainSolutionPage = loadlazy(() => import('./solutions/Solution'));
const SolutionPage = loadlazy(() => import('./solutions/SolutionById'));

const BalancePage = loadlazy(() => import('./myprofile/balance/Balance'));

const ExecutorSettingsPage = loadlazy(() => import('./embedding/executorSettings/ExecutorSettingsPage'));
const EmbedingCatalogPage = loadlazy(() => import('./embedding/userPage/EmbedingCatalogPage'));
const EmbeddingDetailsPage = loadlazy(() => import('./embedding/userPage/EmbeddingDetailsPage'));

const SiteCompanyServicePage = loadlazy(() => import('./siteCompany/siteCompanyService/SiteCompanyServicePage'));

const CompanyPage = loadlazy(() => import('./siteCompany/company/CompanyPage'));
//const CompanyPageAbout = loadlazy(() => import('./siteCompany/company/About'));
const CompanyPage3dCatalog = loadlazy(() => import('./siteCompany/company/3dCatalog'));
const CompanyPageContacts = loadlazy(() => import('./siteCompany/company/Contacts'));
const CompanyPagePortfolio = loadlazy(() => import('./siteCompany/company/Portfolio'));
const CompanyPageProductsDetails = loadlazy(() => import('./siteCompany/components/catalog/CompanyProductDetailsPage'));

const CalculatorPage = loadlazy(() => import('./calculator/components/CalculatorPage'));
const MyOrdersPage = loadlazy(() => import('./calculator/myorders/MyOrdersPage'));
const MyProjectsV3 = loadlazy(() => import('./projects/myprojectsv3/MyProjectsV3'));
const MyProductDetails = loadlazy(() => import('./projects/myprojectsv3/MyProductDetails'));
const NewProductPage = loadlazy(() => import('./projects/myprojectsv3/NewProductPage'));

export class App extends React.Component {
    componentDidMount() {
        // loadReCaptcha();
        //this.titleHelper = new TitleHelper();
        this.unlisten = this.props.history.listen((location, action) => {
            //console.log(this.props);
            //this.titleHelper.onRouteChange(location, action);
            window.scrollTo(0, 0);
            if (action == "PUSH") {
                HistoryStorage.push(location.pathname);
            }
        });
    }
    componentWillUnmount() {
        if (this.unlisten)
            this.unlisten();
    }

    componentDidUpdate(prevProps) {
        console.log(this.props);
    }

    render() {
        let showLiveSup = true;
        showLiveSup &= window.location.pathname.indexOf("/embedding/") == -1;
        showLiveSup &= window.location.pathname.indexOf("/company/") == -1;
        showLiveSup &= window.location.pathname.indexOf("/companyedit/") == -1;
        const showCookieBanner = window.location.pathname.indexOf("/embedding/") == -1;
        return (
            <Suspense fallback={<FullPageLoaderNoText />}>
                <ErrorBoundary>
                    <GuestRoute exact layout={MainLayoutHome} path='/' component={HomePage} />

                    <GuestRoute exact layout={MainLayoutFullWidth} hideFooter={true} path='/home/projects' component={HomeCatalogPageV23} />
                    <GuestRoute exact layout={MainLayoutFullWidth} hideFooter={true} path='/kovanie-izdeliya-ceni/:productType?/:regionKey?/:productId?' component={HomeCatalogPageV23} />
                    <GuestRoute exact layout={MainLayoutFullWidth} path='/kovanie-izdeliya-ceni-v2/:productType?' component={HomeCatalogPageV2} />
                    <GuestRoute exact layout={MainLayoutFullWidth} path='/kovanie-izdeliya-ceni-v3/:productType?' component={HomeCatalogPageV3} />
                    <GuestRoute exact layout={MainLayoutFullWidth} hideFooter={true} path='/kovanie-izdeliya-ceni-v4/:productType?/:productId?' component={HomeCatalogPageV4} />
                    <GuestRoute exact layout={MainLayoutFullWidth} path='/home/productDetails/:id' component={CatalogProductPage} />
                    <GuestRoute exact layout={MainLayout} path='/home/projectDetails/:id/:productIndex?' component={HomeProjectDetailsPage} />
                    <GuestRoute exact layout={MainLayout} path='/goods' component={GoodsListPage} />
                    <GuestRoute exact layout={MainLayout} path='/goods/details/:id' component={GoodsDetailsPage} />
                    <GuestRoute exact layout={MainLayoutFullWidth} path='/elements' component={ElementsListPage} />
                    <GuestRoute exact layout={MainLayoutFullWidth} path='/kovanie-elementi-kovki/:elementType' component={ElementsListPage} />
                    <GuestRoute exact layout={MainLayoutFullWidth} path='/kovanie-elementi-kovki' component={ElementsListPage} />
                    <GuestRoute exact layout={MainLayoutFullWidth} path='/kovanie-elementi-kovki-details/:id' component={ElementDetailsPage} />
                    <GuestRoute exact layout={MainLayout} path='/manufacturers' component={ManufacturersPage} />

                    <GuestRoute exact layout={MainLayout} path='/aboutProject' component={AboutProject} />
                    <GuestRoute exact layout={MainLayout} path='/solution' component={MainSolutionPage} />
                    <GuestRoute exact layout={MainLayout} path='/solution/:id' component={SolutionPage} />
                    <GuestRoute exact layout={MainLayout} path='/privacy' component={SolutionFooter} />
                    <GuestRoute exact layout={MainLayout} path='/termsofuse' component={SolutionFooter} />
                    <GuestRoute exact layout={MainLayout} path='/tariffs' component={SolutionFooter} />
                    <GuestRoute exact layout={MainLayout} path='/delivery' component={SolutionFooter} />
                    <GuestRoute exact layout={MainLayout} path='/contacts' component={SolutionFooter} />
                    <GuestRoute exact layout={MainLayout} path='/executor-projects' component={SolutionFooter} />
                    {/*<GuestRoute exact layout={MainLayout} path='/privacy' component={PrivacyPage} />
                <GuestRoute exact layout={MainLayout} path='/termsofuse' component={TermsOfUsePage} />
                <GuestRoute exact layout={MainLayout} path='/tariffs' component={TariffsPage} />*/}

                    <GuestRoute exact layout={MainLayout} path='/login/:p?' component={LoginPage} />
                    <GuestRoute exact layout={MainLayout} path='/login/recoverPassword' component={RecoverPasswordPage} />

                    <GuestRoute exact layout={MainLayout} path='/register' component={RegisterPage} />
                    <GuestRoute layout={MainLayout} path='/register/customer' component={CustomerPage} />
                    <GuestRoute layout={MainLayout} path='/register/executer' component={RegisterExecutorStepOne} />
                    <UnConfirmAccountRoute layout={MainLayout} path='/confirmaccount' component={VerificateAccountPage} />

                    <UserRoute exact layout={MyProfileLayout} path='/myprofile' component={MyInfoPage} />
                    <UserRouteExecutor layout={MyProfileLayout} path='/myprofile/specialty' component={MySpecialtyPage} />
                    <UserRoutePortfolio exact layout={MyProfileLayout} path='/myprofile/portfolio' component={ListMyPortfolioPage} />
                    <UserRoutePortfolio layout={MyProfileLayout} path='/myprofile/portfolio/edit/:id' component={PortfolioItemPage} />
                    <UserRoutePortfolio layout={MyProfileLayout} path='/myprofile/portfolio/add' component={PortfolioItemPage} />
                    <UserRoutePortfolio layout={MyProfileLayout} path='/myProfile/calculationfactors' component={CalculateFactorsPage} />
                    <UserRoute exact layout={MyProfileLayout} path='/myprofile/siteportfolio' component={EditSiteProtforlioPage} />

                    <UserRouteSeller exact layout={MyProfileLayout} path='/myprofile/myelements' component={MyElementsPage} />
                    <UserRouteSeller exact layout={MyProfileLayout} path='/myprofile/myelement/edit/:id' component={MyElementPage} />
                    <UserRouteSeller exact layout={MyProfileLayout} path='/myprofile/myelement/add' component={MyElementPage} />
                    <UserRoute exact layout={MyProfileLayout} path='/myprofile/myelements/bulkuploadinfo' component={BulkUploadInfoPage} />
                    <UserRoute exact layout={MainLayoutFullWidth} path='/myprofile/elementmatch' component={ElementMatchPage} />

                    <UserRoute exact layout={MyProfileLayout} path='/myprofile/feedbacks' component={MyFeedbacksPage} />
                    <UserRoute exact layout={MyProfileLayout} path='/myprofile/feedback/:id' component={FeedbackChatPage} />

                    <UserRoute exact layout={MyProfileLayout} path='/myprofile/myeditorsessions' component={MyEditorSessions} />
                    <UserRoute exact layout={MainLayout} path='/myprofile/mygoods' component={MyGoods} />
                    <UserRoute exact layout={MainLayout} path='/myprofile/addgoods/:id?' component={AddGoods} />
                    <UserRoute exact layout={MainLayout} path='/myprofile/addpaints/:id?' component={AddPaints} />
                    <UserRoute exact layout={MainLayout} path='/myprofile/addprofsheet/:id?' component={AddProfsheet} />
                    <UserRoute exact layout={MainLayout} path='/myprofile/addmetalrolling/:id?' component={AddMetal} />
                    <UserRoute exact layout={MainLayout} path='/myprofile/addGateAutomation/:id?' component={AddGateAutomation} />

                    <UserRoute exact layout={MainLayout} path='/project/create/:id?' component={CreateProjectPage} />
                    <UserRoute exact layout={MainLayout} path='/project/myprojects' component={MyProjectsPage} />
                    <UserRoute exact layout={MainLayout} path='/project/details/:id' component={MyProjectDetailsPage} />
                    <GuestRoute exact layout={MainLayout} path='/project/executer/:id' component={ExecuterPage} />
                    <UserRoute exact layout={MainLayoutFullWidth} path='/projectn/myprojects/:sketchId?' component={MyProjectsPageV2} />
                    <UserRoute exact layout={MainLayoutFullWidth} path='/myorders' component={MyOrdersPage} />
                    <UserRoute exact layout={MainLayoutFullWidth} path='/calculator/:id?' component={CalculatorPage} />
                    <UserRoute exact layout={MainLayoutFullWidth} path='/myprojectsv3' component={MyProjectsV3} />
                    <GuestRoute exact layout={MainLayoutFullWidth} path='/myproduct/:id' component={MyProductDetails} />
                    <GuestRoute exact layout={MainLayoutFullWidth} path='/product/:id/:isCustomer?&:isBuyer?' component={MyProductDetails} />
                    <UserRoute exact layout={MainLayoutFullWidth} path='/createProduct/:sketchId?' component={NewProductPage} />

                    <UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/myinvitations' component={ExecuterMyInvitationsPage} />
                    <UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/projectdetails/:publicationId' component={ExecuterProjectDetailsPage} />
                    <UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/search' component={ExecuterProjectFindProjectsPage} />
                    <UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/mypropositions' component={ExecuterProjectMyPropositionsPage} />
                    <UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/customerdetails/:id' component={ECustomerPage} />
                    <UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/mycontracts' component={ExecuterMyContractsPage} />
                    <UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/contractdetails/:id' component={ExecuterContractDetailsPage} />

                    <UserRoute exact layout={MainLayout} path='/chat/:id?' component={ChatPage} />

                    <GuestRoute exact layout={MainLayout} path='/favorite/products' component={FavoriteProductsPage} />


                    <GuestRoute exact layout={MainLayout} path='/instructions/:IsInstruct/' component={AllInstructionsPage} />
                    <GuestRoute layout={MainLayout} path='/instruction/:id' component={InstructionPage} />
                    <GuestRoute exact layout={MainLayout} path='/basket' component={BasketPage} />

                    <UserRouteExecutor exact layout={MainLayout} path='/autoBuildEdit/sketches' component={MySketchesPage} />
                    <UserRouteExecutor exact layout={MainLayout} path='/autoBuildEdit/sketchesWithElements' component={MySketchesElementsPage} />
                    <UserRouteExecutor exact layout={MainLayoutFullWidth} path='/autoBuildEdit/sketch/:id?' component={EditSketchPage} />
                    <UserRouteExecutor exact layout={MainLayout} path='/autoBuildEdit/templates' component={MyAutoBuildTemplatesPage} />
                    <UserRouteExecutor exact layout={MainLayoutFullWidth} path='/autoBuildEdit/template/:id?' component={EditAutoBuildTemplatePage} />

                    <UserRouteExecutor exact layout={MyProfileLayout} path='/myprofile/mycatalog' component={ExecutorSettingsPage} />
                    <UserRouteExecutor exact layout={MyProfileLayout} path='/myprofile/mycompanysite' component={SiteCompanyServicePage} />

                    <GuestRoute exact layout={MyCompanyLayout} path='/company/:company_name' component={CompanyPage} />
                    {/*<GuestRoute exact layout={MyCompanyLayout} path='/company/:company_name/about' component={CompanyPageAbout} />*/}
                    <GuestRoute exact layout={MyCompanyLayout} path='/company/:company_name/contacts' component={CompanyPageContacts} />
                    <GuestRoute exact layout={MyCompanyLayout} path='/company/:company_name/portfolio' component={CompanyPagePortfolio} />
                    <GuestRoute exact layout={MyCompanyLayout} path='/company/:company_name/catalog/:isProducts' component={CompanyPage3dCatalog} />
                    <GuestRoute exact layout={MyCompanyLayout} path='/company/:company_name/catalog/:isProducts/details/:id' component={CompanyPageProductsDetails} />
                    <GuestRoute exact layout={MyCompanyLayout} path='/companyedit/:company_name' component={CompanyPage} />
                    {/*<GuestRoute exact layout={MyCompanyLayout} path='/companyedit/:company_name/about' component={CompanyPageAbout} />*/}
                    <GuestRoute exact layout={MyCompanyLayout} path='/companyedit/:company_name/contacts' component={CompanyPageContacts} />
                    <GuestRoute exact layout={MyCompanyLayout} path='/companyedit/:company_name/portfolio' component={CompanyPagePortfolio} />
                    <GuestRoute exact layout={MyCompanyLayout} path='/companyedit/:company_name/catalog/:isProducts' component={CompanyPage3dCatalog} />

                    <UserRoute exact layout={MainLayout} path='/autoBuild/createProduct/:id?' component={AutoBuildCreateProduct} />

                    <UserRoute exact layout={MyProfileLayout} path='/myprofile/balance' component={BalancePage} />

                    <Route path='/embedding/key=:key&lang=:lang' component={EmbedingCatalogPage} />
                    <Route path='/embedding/details/id=:id&key=:key&lang=:lang' component={EmbeddingDetailsPage} />

                    <Route exact path='/embedding/:key/:lang/:productType?' component={EmbedingCatalogPage} />
                    <Route exact path='/embedding/details/:id/:key/:lang/:productType?' component={EmbeddingDetailsPage} />

                    {showCookieBanner &&
                        <CookiesBanner />
                    }
                   
                    {showLiveSup ?
                        <LiveSupport /> : <div />
                    }
                    <AppTitleHelper history={this.props.history} />

                    {/* 
          

                */}
                </ErrorBoundary>
            </Suspense>
        )
    }
}

export default App;
