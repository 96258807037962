import axios from "axios";
import * as types from './types';

export const addGoodsToBasket = (goodsItem) => (dispatch) => {
    let basket = getBasket();
    const index = basket.goods.findIndex((i) => i.id == goodsItem.id);
    if (index == -1) {
        basket.goods.push(goodsItem);
    }
    else {
        let new_item = { id: goodsItem.id, count: parseInt(basket.goods[index].count) + parseInt(goodsItem.count) }
        basket.goods[index] = new_item;
    }
    setBasket(basket);  
    dispatch({ type: types.REFRESH_BASKET, payload: basket });
}

export const changeBasketFromState = (basket) => (dispatch) => {
    setBasket(basket);
    dispatch({ type: types.REFRESH_BASKET_WITHOUT_PAGE_UPDATE, payload: basket });
}

export const addElementsToBasket = (elementsItem) => (dispatch) => {
    let basket = getBasket();
    if (!Array.isArray(elementsItem)) {
        const index = basket.elements.findIndex((i) => i.id == elementsItem.id);
        if (index == -1) {
            basket.elements.push(elementsItem);
        }
        else {
            let new_item = { id: elementsItem.id, count: parseInt(basket.elements[index].count) + parseInt(elementsItem.count) }
            basket.elements[index] = new_item;
        }
    }
    else {
        elementsItem.forEach((item) => {
            const index = basket.elements.findIndex((i) => i.id == item.id);
            if (index == -1) {
                basket.elements.push(item);
            }
            else {
                let new_item = { id: item.id, count: parseInt(basket.elements[index].count) + parseInt(item.count) }
                basket.elements[index] = new_item;
            }
        })
    }
    setBasket(basket);
    dispatch({ type: types.REFRESH_BASKET, payload: basket });
}

export const getBasket = () => {
    let basket = window.localStorage.getItem("basket");
    if (basket != null) {
        return JSON.parse(basket);
    }
    return {
        goods: [],
        elements: []
    };
}

const setBasket = (basket) => {
    window.localStorage.setItem("basket", JSON.stringify(basket));
}

export const clearBasket = (isGood) => (dispatch) => {
    let basket;
    let basket_storage = window.localStorage.getItem("basket");
    if (basket_storage != null) {
        basket = JSON.parse(basket_storage);
    }
    else {
        basket = {
            goods: [],
            elements: []
        };
    }
    if (isGood) {
        basket.goods = [];
    }
    else {
        basket.elements = [];
    }
    setBasket(basket);
    dispatch({ type: types.REFRESH_BASKET, payload: basket });
}

export const getGoodsByIds = (data) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/goods/getGoodsByIds`, data)
        .then((response) => {
            dispatch({
                type: types.GET_GOODS_BY_IDS_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const makeOrder = (data) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/goods/makeOrder`, data)
        .then((response) => {
            dispatch({
                type: types.MAKE_ORDER_SUCCESS
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getElementsByIds = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/elements/getElementsByIds`, data)
        .then((response) => {
            dispatch({
                type: types.GET_ELEMENTS_BY_IDS_SUCCESS,
                payload: response.data
            });
            if (callback) callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback) callback(false);
        })
}

export const makeElementsOrder = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/elements/makeOrder`, data)
        .then((response) => {
            dispatch({
                type: types.MAKE_ELEMENTS_ORDER_SUCCESS
            });
            if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback) callback(false);
        })
}