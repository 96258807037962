import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Router } from 'react-router-dom'

import App from './App';

import * as loginActions from './login/actions';
// import { setVerToken } from './verify/actions'
import store, { history } from './store/configureStore';
import { getAuthToken, getRefreshToken, getVerificationToken } from './utils/lsMethods';
import * as rax from 'retry-axios';

import ReduxToastr from 'react-redux-toastr'


//Styles
import 'bootstrap/dist/css/bootstrap.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import "./styles/main.scss";
import setAuthorizationToken from './utils/setAuthorizationToken';
import { initAmplitude, sendAmplitudeData, setAmplitudeUserId, amplitudeTypes } from './utils/amplitude';
import jwt from 'jsonwebtoken';
import axios from 'axios';

const rootElement = document.getElementById('root');
if (window.location.hostname != "localhost") {
    axios.defaults.timeout = 120000;
}

const interceptorId = rax.attach();
if (getAuthToken()) {
  let data={ authToken:getAuthToken(), refreshToken:getRefreshToken() }
  loginActions.loginByJWT(data, store.dispatch)
}
else if(getVerificationToken()){
  let token=getVerificationToken();
  setAuthorizationToken(token);
}


initAmplitude();
const authToken = getAuthToken();
if (authToken != null) {
    const tokenData = jwt.decode(authToken);
    setAmplitudeUserId(tokenData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']);
    sendAmplitudeData(amplitudeTypes.OPEN_WEBSITE, { 'registered': true });
} else {
    sendAmplitudeData(amplitudeTypes.OPEN_WEBSITE, { 'registered': false });
}

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ReduxToastr
                className='alert-font'
                timeOut={5000}
            />
            <Router history={history}>
                <App history={history} />
            </Router>
        </ConnectedRouter>
    </Provider>,
  rootElement);
