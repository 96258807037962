import axios from "axios";
import * as types from './types';
import Helper from '../utils/helper';
import { sendAmplitudeData, amplitudeTypes } from "../utils/amplitude";

const actionList = {

    //#region getClosedProjects
    getClosedProjectsStarted: () => {
        return {
            type: types.GET_CLOSEDPROJECTS_REQUEST
        }
    },
    getClosedProjectsSuccess: (data) => {
        return {
            type: types.GET_CLOSEDPROJECTS_SUCCESS,
            payload: data
        }
    },
    getClosedProjectsFailed: () => {
        return {
            type: types.GET_CLOSEDPROJECTS_FAILURE,
        }
    },
    //#endregion

    //#region getProjectDetails
    getProjectDetailsStarted: () => {
        return {
            type: types.GET_PROJECTDETAILS_REQUEST
        }
    },
    getProjectDetailsSuccess: (data) => {
        return {
            type: types.GET_PROJECTDETAILS_SUCCESS,
            payload: data
        }
    },
    getProjectDetailsFailed: () => {
        return {
            type: types.GET_PROJECTDETAILS_FAILURE,
        }
    },
    //#endregion

    //#region sendOrderToSeller
    sendOrderToSellerStarted: () => {
        return {
            type: types.SEND_ORDERTOSELLER_REQUEST
        }
    },
    sendOrderToSellerSuccess: () => {
        return {
            type: types.SEND_ORDERTOSELLER_SUCCESS
        }
    },
    sendOrderToSellerFailed: () => {
        return {
            type: types.SEND_ORDERTOSELLER_FAILURE
        }
    },
    //#endregion

    //#region getHomeData
    getHomeDataStarted: () => {
        return {
            type: types.GET_HOMEDATA_REQUEST
        }
    },
    getHomeDataSuccess: (data) => {
        return {
            type: types.GET_HOMEDATA_SUCCESS,
            payload: data
        }
    },
    getHomeDataFailed: () => {
        return {
            type: types.GET_HOMEDATA_FAILURE,
        }
    },
    //#endregion

    //#region getClosedProducts
    getClosedProductsStarted: () => {
        return {
            type: types.GET_CLOSEDPROJECTS_REQUEST
        }
    },
    getClosedProductsSuccess: (data) => {
        return {
            type: types.GET_CLOSEDPRODUCTS_SUCCESS,
            payload: data
        }
    },
    getClosedProductsFailed: () => {
        return {
            type: types.GET_CLOSEDPRODUCTS_FAILURE,
        }
    },
    //#endregion

    //#region getCatalogProductDetails
    getCatalogProductDetailsStarted: () => {
        return {
            type: types.GET_PRODUCTTDETAILS_REQUEST
        }
    },
    getCatalogProductDetailsSuccess: (data) => {
        return {
            type: types.GET_PRODUCTTDETAILS_SUCCESS,
            payload: data
        }
    },
    getCatalogProductDetailsFailed: () => {
        return {
            type: types.GET_PRODUCTTDETAILS_FAILURE,
        }
    },
    //#endregion
    ThreeDTimeOut: () => {
        return {
            type: types.THREED_TIME_OUT,
        }
    },
    ThreeDTimeOutFlush: () => {
        return {
            type: types.THREED_TIME_OUT_FLUSH,
        }
    },
}

export const addPaintToProduct = (data) => (dispatch) => {
    dispatch({ type: types.SET_PAINT_TO_PRODUCT_REQUEST });
    return axios.get(`api/home/setprintforproduct?productId=` + data.productId + `&paintId=` + data.paintId)
        .then((response) => {
            dispatch({
                type: types.SET_PAINT_TO_PRODUCT_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const loadPrints = (id) => (dispatch) => {
    //dispatch({ type: types.REQUEST });
    return axios.get(`api/home/getprints?Id=`+id)
        .then((response) => {
            dispatch({
                type: types.GET_PRINTS_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getTexturedMetalsList = () => (dispatch) => {
    //dispatch({ type: types.REQUEST_METALS_LIST });
    return axios.get(`api/home/gettexturedmetalslist`)
        .then((response) => {
            dispatch({
                type: types.GET_TEXTURED_METALS_LIST_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE_METALS_LIST });
        })
}

export const getClosedProjects = () => (dispatch) => {
    dispatch(actionList.getClosedProjectsStarted());
    return axios.get(`api/project/searchclosedprojects`)
        .then((response) => {
            dispatch(actionList.getClosedProjectsSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getClosedProjectsFailed());
        })
}

export const getProjectDetails = (id) => (dispatch) => {
    dispatch(actionList.getProjectDetailsStarted());
    return axios.get(`api/project/getclosedprojectdetails/${id}`)
        .then((response) => {
            dispatch(actionList.getProjectDetailsSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getProjectDetailsFailed());
        })
}

export const sendOrderToSeller = (productIds, callback) => (dispatch) => {
    dispatch(actionList.sendOrderToSellerStarted());
    return axios.post(`api/project/sendProductOrderToSeller`, productIds)
        .then((response) => {
            dispatch(actionList.sendOrderToSellerSuccess());
            if (callback) callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.sendOrderToSellerFailed());
            if (callback) callback(false);
        })
}

export const sendOrderToSeller2 = (productIds, callback) => (dispatch) => {
    dispatch(actionList.sendOrderToSellerStarted());
    return axios.post(`api/project/sendProductOrderToSeller2`, productIds)
        .then((response) => {
            dispatch(actionList.sendOrderToSellerSuccess());
            if (callback) callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.sendOrderToSellerFailed());
            if (callback) callback(false);
        })
}

export const getHomeData = () => (dispatch) => {
    dispatch(actionList.getHomeDataStarted());
    return axios.get(`api/home/getHomeData`)
        .then((response) => {
            dispatch(actionList.getHomeDataSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getHomeDataFailed());
        })
}

export const getClosedProducts = (data) => (dispatch) => {
    dispatch(actionList.getClosedProductsStarted());
    return axios.post(`api/home/getClosedProducts`, data)
        .then((response) => {
            dispatch(actionList.getClosedProductsSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getClosedProductsFailed());
        })
}

export const clearClosedProducts = () => (dispatch) => {
    dispatch({ type: types.CLEAR_CLOSEDPROJECTS });
}

export const getCatalogProductDetails = (productId) => (dispatch) => {
    dispatch(actionList.getCatalogProductDetailsStarted());
    return axios.get(`api/home/getCatalogProductDetails?productId=${productId}`)
        .then((response) => {
            dispatch(actionList.getCatalogProductDetailsSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getCatalogProductDetailsFailed());
        })
}

export const makeDemoProduct = (callback) => (dispatch) => {
    return axios.get(`api/home/makeDemoProduct`)
        .then((response) => {
            if (checkLastDate()) {
                callback(true, response.data);
            }
            else {
                throw { error: -1 };
            }
        }, err => { throw err; })
        .catch((err) => {
            callback(false, null);
        })
}

const checkLastDate = () => {
    let date = Number(localStorage.getItem("lastSessionDate"));
    const date_now = Date.now();
    if (date_now > date + 60000) {
        localStorage.setItem("lastSessionDate", date_now);
        return true;
    }
    else {
        return false;
    }
}

export const flush3dError = () => (dispatch) => {
    dispatch(actionList.ThreeDTimeOutFlush());
}

export const constructorOpenClick = (id) => (dispatch) => {
    sendAmplitudeData(amplitudeTypes.OPEN_DEMO_FROM_PRODUCT, { "productId": id });
    return axios.get(`api/home/productToDemo?productId=` + id)
        .then((response) => {
            if (checkLastDate()) {
                window.open(Helper.editorEditLink(response.data.id), '_blank');
                window.location.pathname = "projectn/myprojects";
            }
            else {
                throw { error: -1 };
            }
        }, err => { throw err; })
        .catch((err) => {
            if (err["error"] == -1)
                dispatch(actionList.ThreeDTimeOut());
            console.log(err);
        })
}

export const getInfoVideo = (key, callback) => (dispatch) => {
    return axios.get(`api/home/GetInfoVideo?key=${key}`)
        .then((response) => {
            callback(response.data.code);
        }, err => { throw err; })
        .catch((err) => {
        })
}

export const getElementsForProduct = (productId, selectedGoodsIds) => (dispatch) => {
    dispatch({ type: types.GET_ELEMENTS_FOR_PRODUCT_REQUEST });
    return axios.post(`api/elements/GetElementsForProduct`, { productId: productId, selectedGoodsIds: selectedGoodsIds })
        .then((response) => {
            dispatch({
                type: types.GET_ELEMENTS_FOR_PRODUCT_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getListElementsAndMetalsForProduct = (productId) => (dispatch) => {
    //dispatch({ type: types.REQUEST });
    return axios.get(`api/elements/GetListElementsAndMetalsForProduct?productId=` + productId)
        .then((response) => {
            dispatch({
                type: types.GET_LIST_ELEMENTS_AND_METALS_FOR_PRODUCT_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getProductTypesV2 = (showAll) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/project/getproducttypesv2?showAll=${showAll == true}`)
        .then((response) => {
            dispatch({
                type: types.GET_PRODUCT_TYPES_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const sendProductCatalogSketchRequest = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/home/SendProductCatalogSketchRequest`, data)
        .then((response) => {
            dispatch({
                type: types.SUCCESS,
                payload: response.data
            });
            if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback) callback(false);
        })
}

export const sendProductCatalogSketchRequestv4 = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/home/SendProductCatalogSketchRequestv4`, data)
        .then((response) => {
            dispatch({
                type: types.SUCCESS,
                payload: response.data
            });
            if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback) callback(false);
        })
}