import Immutable from 'seamless-immutable';
import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    products: [],
    categories: [],
    metalCategories: [],
    currencies: [],
    addGoodSuccess: false,
    productInfo: null,
    myGoods: [],
    metalsForGoods:[],
    goodDetails: null
});

export const goodsReducer = (state = initialState, action) => {
    switch(action.type){
        case(types.GET_REQUEST): {
            return state.merge({
                loading: true,
                addGoodSuccess: false
            });
        }
        case (types.GET_FAILURE): {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        case(types.GET_CATEGORIES_SUCCESS): {
            return state.merge({
                loading: false,
                categories: action.payload.categories,
                metalCategories: action.payload.metalCategories
            });
        }
        case(types.GET_CURRENCIES_SUCCESS): {
            return state.merge({
                loading: false,
                currencies: action.payload
            });
        }
        case(types.GET_PRODUCTS_SUCCESS): {
            return state.merge({
                loading: false,
                products: action.payload
            });
        }
        case(types.GET_PRODUCTINFO_SUCCESS): {
            return state.merge({
                loading: false,
                productInfo: action.payload
            });
        }
        case (types.ADD_GOOD_SUCCESS): {
            return state.merge({
                loading: false,
                addGoodSuccess: true
            });
        }
        case (types.GET_MY_GOODS_SUCCESS): {
            return state.merge({
                loading: false,
                myGoods: action.payload
            });
        }
        case (types.GET_DETAILS_REQUEST): {
            return state.merge({
                loading: true,
                goodDetails: null
            });
        }
        case (types.GET_DETAILS_SUCCESS): {
            return state.merge({
                loading: false,
                goodDetails: action.payload
            });
        }
        case (types.GET_METALS_SUCCESS): {
            return state.merge({
                loading: false,
                metalsForGoods: action.payload
            });
        }
        case types.COPY_SUCCESS: {
            return state.merge({
                loading: false
            });
        }
        default: {
            return state;
        }
    }
};