import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    edit: {
        loading: false,
        success: false,
        failed: false,
        errors:{}
    },
});

export const registerCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REGISTER_CUSTOMER_REQUEST: { 
            return initialState.merge({
                edit:{
                    ...initialState.edit,
                    loading:true
                }
            });
        }
        case types.REGISTER_CUSTOMER_SUCCESS: {
            return state.merge({
                edit:{
                    ...state.edit,
                    loading:false,
                    success:true
                }
            });
        }
        case types.REGISTER_CUSTOMER_FAILURE: {
            return state.merge({
                edit:{
                    ...initialState.edit,
                    loading:false,
                    failed:true,
                    errors:action.payload
                }
            });
        }

        default: {
            return state;
        }
    }
}