import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";
import * as UserRoles from '../../constants/specialty';

const GuestRoute = ({ iAuthentificated, roles, layout: Layout, component: Component, left, right, hideFooter, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                <Layout left={left} right={right} hideFooter={hideFooter} >
                    <Component {...props} />
                </Layout>
            }
        />
    )
};
GuestRoute.propTypes = {
    component: PropTypes.any.isRequired,
    iAuthentificated: PropTypes.bool.isRequired
};
function mapStateToProps(state) {
    return {
        iAuthentificated: state.login.isAuthenticated,
        roles: state.login.user.roles
    };
}

export default connect(mapStateToProps)(GuestRoute);
