import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    failed: false,
    manufacturers: [],
    priceManufacturers: [],
    isActiveExecutor: false
});

export const manufacturerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        case types.GET_MANUFACTORERS_SUCCESS: {            
            return state.merge({
                loading: false,
                manufacturers: action.payload
            });
        }
        case types.GET_PRICE_MANUFACTORERS_SUCCESS: {
            return state.merge({
                loading: false,
                priceManufacturers: action.payload.executors,
                isActiveExecutor: action.payload.isActiveExecutor
            });
        }
        
        case types.SEND_CONTACT_REQUEST_SUCCESS:
        case types.BECOME_MANUFACTURER_REQUEST_SUCCESS: {
            return state.merge({
                loading: false
            });
        }
        default: {
            return state;
        }
    }
}