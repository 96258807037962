import React from 'react';
import objectToGetParams from 'react-share/es/utils/objectToGetParams';
import createShareButton from 'react-share/es/hocs/createShareButton';
import { sendAmplitudeData, amplitudeTypes } from '../../utils/amplitude';
import {
    //EmailShareButton, 
    FacebookShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon,
} from "react-share";

function emailLink(url, _a) {
    var subject = _a.subject, body = _a.body, separator = _a.separator;
    return 'mailto:' + objectToGetParams({ subject: subject, body: body ? body + separator + url : url });
}

var EmailBlankTargetShareButton = createShareButton('email', emailLink, function (props) {
    return ({
        subject: props.subject,
        body: props.body,
        separator: props.separator || ' ',
    });
}, {
    openShareDialogOnClick: false,
        onClick: function (_, link) {
            sendAmplitudeData(amplitudeTypes.SHARE_CLICK, 'email');
            // you should put here the code that opens the link in a new window. 
            // something like this :
            window.open(link, "_blank");
    },
});

export default class SharePopUp extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { link, itemName, className, t } = this.props;
        return (
            <div className={className}>
                <EmailBlankTargetShareButton
                    url={link}
                    subject={itemName}
                    //openShareDialogOnClick={newWindows}
                    body={itemName}
                    separator=" " >
                    <EmailIcon size="1.5em" />
                </EmailBlankTargetShareButton>
                <LinkedinShareButton
                    url={link}
                    title={itemName}
                    summary={itemName}
                    source={link}
                    separator=" "
                    onClick={() => sendAmplitudeData(amplitudeTypes.SHARE_CLICK, 'linkedin')}>
                    <LinkedinIcon size="1.5em" />
                </LinkedinShareButton>
                <FacebookShareButton
                    url={link}
                    quote={itemName}
                    hashtag="#product"
                    onClick={() => sendAmplitudeData(amplitudeTypes.SHARE_CLICK, 'facebook')}>
                    <FacebookIcon size="1.5em" />
                </FacebookShareButton>
                <TelegramShareButton
                    url={link}
                    title ={itemName}
                    onClick={() => sendAmplitudeData(amplitudeTypes.SHARE_CLICK, 'telegram')}>
                    <TelegramIcon size="1.5em" />
                    
                </TelegramShareButton>
                <TwitterShareButton
                    url={link}
                    title={itemName}
                    hashtag="#product"
                    onClick={() => sendAmplitudeData(amplitudeTypes.SHARE_CLICK, 'twitter')}>
                    <TwitterIcon size="1.5em" />
                </TwitterShareButton>
                <ViberShareButton
                    url={link}
                    title={itemName}
                    separator=" "
                    onClick={() => sendAmplitudeData(amplitudeTypes.SHARE_CLICK, 'viber')}>
                    <ViberIcon size="1.5em" />
                </ViberShareButton>
            </div>
            );
    }
}