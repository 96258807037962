export const GET_ALLSELLERS_REQUEST = 'goods/GET_ALLSELLERS_REQUEST';
export const GET_ALLSELLERS_SUCCESS = 'goods/GET_ALLSELLERS_SUCCESS';
export const GET_ALLSELLERS_FAILURE = 'goods/GET_ALLSELLERS_FAILURE';

export const FIND_GOODS_REQUEST = 'goods/FIND_GOODS_REQUEST';
export const FIND_GOODS_SUCCESS = 'goods/FIND_GOODS_SUCCESS';
export const FIND_GOODS_FAILURE = 'goods/FIND_GOODS_FAILURE';
export const FIND_GOODS_RESET = 'goods/FIND_GOODS_RESET';

export const GET_GOODS_DETAILS_REQUEST = 'goods/GET_GOODS_DETAILS_REQUEST';
export const GET_GOODS_DETAILS_SUCCESS = 'goods/GET_GOODS_DETAILS_SUCCESS';
export const GET_GOODS_DETAILS_FAILURE = 'goods/GET_GOODS_DETAILS_FAILURE';