export const GET_REQUEST = 'balance/GET_REQUEST';
export const GET_FAILURE = 'balance/GET_FAILURE';

export const SET_CURRENCY_SUCCESS = 'balance/SET_CURRENCY_SUCCESS';
export const SET_BILL_SUCCESS = 'balance/SET_BILL_SUCCESS';
export const GET_TRANSACTION_SUCCESS = 'balance/GET_TRANSACTION_SUCCESS';

export const GET_PPEUSER_SUCCESS = 'balance/GET_PPEUSER_SUCCESS';
export const SET_PPEUSER_SUCCESS = 'balance/SET_PPEUSER_SUCCESS';

export const GET_ACT_SUCCESS = 'balance/GET_ACT_SUCCESS';
