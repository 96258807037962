import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    favoritesData: null,
    favoriteProducts: [],
    isForMyProject: false
});

export const favoriteReducer = (state = initialState, action) => {
    switch (action.type) {
        //#region GET_FAVORITES_PRODUCTS
        case types.GET_FAVORITES_PRODUCTS_REQUEST: {
            return state.merge({
                loading: true,
                isForMyProject: false
            });
        }
        case types.GET_FAVORITES_PRODUCTS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                favoriteProducts: action.payload.products,
                isForMyProject: action.payload.isForMyProject
            });
        }
        case types.GET_FAVORITES_PRODUCTS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_FAVORITES        
        case types.GET_FAVORITES_SUCCESS: {
            return state.merge({                
                favoritesData: action.payload
            });
        }        
        //#endregion

        case types.LOAD_FAVORITES_LOCAL: {
            return state.merge({
                favoritesData: action.payload
            });
        }        

        default: {
            return state;
        }
    }
}