import axios from "axios";
import * as types from './types';

const actionList = {

    //#region get my projects
    getProjectsStarted: () => {
        return {
            type: types.GET_MYPROJECTS_REQUEST
        }
    },
    getProjectsSuccess: (data) => {
        return {
            type: types.GET_MYPROJECTS_SUCCESS,
            payload: data
        }
    },
    getProjectsFailed: () => {
        return {
            type: types.GET_MYPROJECTS_FAILURE,
        }
    },
    //#endregion

    //#region getProjectsWithoutMeasurer
    getProjectsWithoutMeasurerStarted: () => {
        return {
            type: types.GET_PROJECTS_WITHOUT_MEASURER_REQUEST
        }
    },
    getProjectsWithoutMeasurerSuccess: (data) => {
        return {
            type: types.GET_PROJECTS_WITHOUT_MEASURER_SUCCESS,
            payload: data
        }
    },
    getProjectsWithoutMeasurerFailed: () => {
        return {
            type: types.GET_PROJECTS_WITHOUT_MEASURER_FAILURE,
        }
    },
    //#endregion

    //#region callMeasurer
    callMeasurerStarted: () => {
        return {
            type: types.CALL_MEASURER_REQUEST
        }
    },
    callMeasurerSuccess: () => {
        return {
            type: types.CALL_MEASURER_SUCCESS
        }
    },
    callMeasurerFailed: () => {
        return {
            type: types.GET_MYPROJECTS_FAILURE
        }
    },
    //#endregion

    //#region deleteProject
    deleteProjectStarted: () => {
        return {
            type: types.DELETE_PROJECT_REQUEST
        }
    },
    deleteProjectSuccess: () => {
        return {
            type: types.DELETE_PROJECT_SUCCESS
        }
    },
    deleteProjectFailed: () => {
        return {
            type: types.DELETE_PROJECT_FAILURE
        }
    },
    //#endregion
}

export const getMyProjects = () => (dispatch) => {
    dispatch(actionList.getProjectsStarted());
    return axios.get(`api/project/getmyprojects`)
        .then((response) => {
            dispatch(actionList.getProjectsSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getProjectsFailed());
        })
}
export const getMyProjectsV2 = () => (dispatch) => {
    dispatch(actionList.getProjectsStarted());
    return axios.get(`api/project/getmyprojectsV2`)
        .then((response) => {
            dispatch({
                type: types.GET_MYPROJECTSV2_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getProjectsFailed());
        })
}

export const getProjectsWithoutMeasurer = () => (dispatch) => {
    dispatch(actionList.getProjectsWithoutMeasurerStarted());
    return axios.get(`api/project/getProjectsWihoutMeasurerCalled`)
        .then((response) => {
            dispatch(actionList.getProjectsWithoutMeasurerSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getProjectsWithoutMeasurerFailed());
        })
}

export const callMeasurer = (projectId, callback) => (dispatch) => {
    dispatch(actionList.callMeasurerStarted());
    return axios.get(`api/project/callMeasurerForProject?projectId=${projectId}`)
        .then((response) => {
            dispatch(actionList.callMeasurerSuccess());
            if (callback != null)
                callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.callMeasurerFailed());
            if (callback != null)
                callback(false);
        })
}

export const deleteProject = (projectId, isDelete, callback) => (dispatch) => {
    dispatch(actionList.deleteProjectStarted());
    return axios.get(`api/project/deleteProject?projectId=${projectId}&isDelete=${isDelete}`)
        .then((response) => {
            dispatch(actionList.deleteProjectSuccess());
            if (callback != null)
                callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.deleteProjectFailed());
            if (callback != null)
                callback(false);
        })
}

export const createProduct = (data, callback) => (dispatch) => {
    dispatch({ type: types.CREATE_PRODUCT_REQUEST });
    return axios.post(`api/project/createProduct`, data)
        .then((response) => {
            dispatch({
                type: types.CREATE_PRODUCT_SUCCESS,
                payload: response.data
            });
            if (callback != null)
                callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.CREATE_PRODUCT_FAILURE });
            if (callback != null)
                callback(false);
        })
}

export const calculatePrices = (ids, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/project/calculatePrices`, ids)
        .then((response) => {
            dispatch({
                type: types.CALCULATE_PRICE_SUCCESS,
                payload: response.data
            });
            if (callback != null)
                callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback != null)
                callback(false);
        })
}

export const deleteProduct = (id, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/project/deleteProduct?productId=${id}`)
        .then((response) => {
            dispatch({
                type: types.DELETE_PRODUCT_SUCCESS,
                payload: response.data
            });
            if (callback != null)
                callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback != null)
                callback(false);
        })
}

export const deleteProductGroup = (id, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/project/deleteProductGroup?productGroupId=${id}`)
        .then((response) => {
            dispatch({
                type: types.DELETE_PRODUCT_GROUP_SUCCESS,
                payload: response.data
            });
            if (callback != null)
                callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback != null)
                callback(false);
        })
}

export const getSpecification = (id, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/editor/getspecification?productId=${id}&specType=full`, {
        responseType: 'blob'
    })
        .then((response) => {
            dispatch({
                type: types.GET_SPECIFICATION_SUCCESS,
                payload: response.data
            });
            if (callback != null)
                callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback != null)
                callback(false);
        })
}

export const requestSpecification = (id, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/project/UserRequestSpecification?productId=${id}`)
        .then((response) => {
            if (callback != null)
                callback(true);
        }, err => { throw err; })
        .catch((err) => {
            if (callback != null)
                callback(false);
        })
}

export const updateProjectName = (data) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/project/UpdateProjectName`, data)
        .then((response) => {
            dispatch({
                type: types.UPDATE_PROJECT_NAME_SUCCESS,
                payload: data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}



export const projectClose = (id) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/project/setprojectclosedv2/` + id)
        .then((response) => {
            dispatch({
                type: types.CLOSE_PROJECT_SUCCESS,
                payload: id
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getMyPriceFactors = () => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/project/GetMyPriceFactors`)
        .then((response) => {
            dispatch({
                type: types.GET_MY_PRICE_FACTOR,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}