import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    loadingFilter: false,
    failed: false,
    typeloaded: false,
    typeloadedV2: false,
    elementsCatalog: [],
    elementFilter: [],
    elementFilterV2: [],
    noMoreElements: false,
    link: null,
    linkImage: null,
    elementDetails: null,
    elementExamples: [],
    homeInstructions: [],
    elementMatches: []
});

export const elementsReducer = (state = initialState, action) => {
    switch (action.type) {
        //#region GET_ELEMENTS
        case types.GET_ELEMENTS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_ELEMENTS_SUCCESS: {
            let elements = [...state.elementsCatalog, ...action.payload]
            return state.merge({
                loading: false,
                elementsCatalog: elements,
                noMoreElements: action.payload.length == 0
            });
        }
        case types.GET_ELEMENTS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        //#endregion
        case types.SET_ELEMENTS_ZERO: {
            return state.merge({
                noMoreElements: false,
                elementsCatalog: []
            });
        }
        //#region GET_FILTER
        case types.GET_FILTER_REQUEST: {
            return state.merge({
                //loading: true,
                typeloaded: false
            });
        }
        case types.GET_FILTER_SUCCESS: {
            return state.merge({
                //loading: false,
                elementFilter: action.payload,
                typeloaded: true
            });
        }
        case types.GET_FILTER_FAILURE: {
            return state.merge({
                //loading: false,
                failed: true,
                typeloaded: false
            });
        }
        //#endregion
        //#region GET_FILTER_V2
        case types.GET_FILTER_V2_REQUEST: {
            return state.merge({
                loadingFilter: true,
                elementFilterV2: [],
                typeloadedV2: false
            });
        }
        case types.GET_FILTER_V2_SUCCESS: {
            return state.merge({
                loadingFilter: false,
                elementFilter: action.payload,
                typeloadedV2: true,
                typeloaded: true
            });
        }
        case types.GET_FILTER_V2_FAILURE: {
            return state.merge({
                loadingFilter: false,
                //failed: true,
                elementFilterV2: [],
                typeloadedV2: false
            });
        }
       //#endregion
        case types.GET_LINK_SUCCESS: {
            return state.merge({
                link: action.payload.elementsLink,
                linkImage: action.payload.elementsLinkImage
            });
        }

        case types.GET_ELEMENT_DETAILS_REQUEST: {
            return state.merge({
                loading: true,
                elementDetails: null
            });
        }
        case types.GET_ELEMENT_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                elementDetails: action.payload
            });
        }
        case types.GET_ELEMENT_DETAILS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }

        case types.GET_ELEMENT_EXAMPLES_REQUEST: {
            return state.merge({
                elementExamples: []
            });
        }
        case types.GET_ELEMENT_EXAMPLES_SUCCESS: {
            return state.merge({                
                elementExamples: action.payload
            });
        }
        case types.GET_ELEMENT_EXAMPLES_FAILURE: {
            return state.merge({                
                failed: true
            });
        }

        case types.GET_HOME_INSTRUCTIONS_SUCCESS: {
            return state.merge({
                homeInstructions: action.payload
            });
        }
        case types.GET_HOME_INSTRUCTIONS_FAILURE: {
            return state.merge({
                failed: true
            });
        }

        case types.GET_ELEMENT_MATCHES_REQUEST: {
            return state.merge({
                elementMatches: []
            });
        }
        case types.GET_ELEMENT_MATCHES_SUCCESS: {
            return state.merge({
                elementMatches: action.payload
            });
        }
        case types.GET_ELEMENT_MATCHES_FAILURE: {
            return state.merge({
                failed: true
            });
        }

        default: {
            return state;
        }
    }
}