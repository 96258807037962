import Immutable from 'seamless-immutable';
import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    transactions: [],
    userInfo: [],
    file: ''
});

export const balanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case (types.GET_REQUEST): {
            return state.merge({
                loading: true
            });
        }
        case (types.GET_FAILURE): {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        case (types.SET_BILL_SUCCESS): {
            return state.merge({
                loading: false
            });
        }
        case (types.SET_CURRENCY_SUCCESS): {
            return state.merge({
                loading: false
            });
        }
        case (types.GET_TRANSACTION_SUCCESS): {
            return state.merge({
                loading: false,
                transactions: action.payload
            });
        }
        case (types.GET_ACT_SUCCESS): {
            return state.merge({
                loading: false,
                file: action.payload
            });
        }
        case (types.GET_PPEUSER_SUCCESS): {
            return state.merge({
                loading: false,
                userInfo: action.payload
            });
        }
        case (types.SET_PPEUSER_SUCCESS): {
            return state.merge({
                loading: false
            });
        }
        default: {
            return state;
        }
    }
};