import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CookieBanner from 'react-cookie-banner';
import Cookies from "js-cookie";
import { isMobile } from "react-device-detect";

class CookiesBanner extends Component {

    render() {
        const { t } = this.props;
        let styleMessage = isMobile ?
            { fontWeight: 400, display: "block", marginLeft : "10%", fontSize: "75%", width: "60%", textAlign: "left", lineHeight: "25px" }
            :
            { fontWeight: 400, fontSize: "125%", display: "inline-block", width: "80%", textAlign: "left" };
        let styleButton = isMobile ?
            { backgroundColor: "#00d17b", color: "white" }
            :
            { backgroundColor: "#00d17b", color: "white", transform: "scale(1.5)" };
        return (
            <div id="getHere">
                <CookieBanner
                    styles={{
                        banner: { position: "fixed", bottom: "0px", height: "20%", backgroundColor: 'rgba(72, 72, 72, 0.8)' },
                        message: styleMessage ,
                        button: styleButton
                    }}
                    message={t("cookies.message")}
                    buttonMessage={t("cookies.button")}
                    link={<a href='/privacy'>{t("cookies.link")}</a>}
                    onAccept={() => { Cookies.set('user-has-accepted-cookies', true); }}
                    dismissOnScroll={false}
                    cookie="user-has-accepted-cookies" />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CookiesBanner));