import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    get:{
        regions:[]
    },
    edit: {
        loading: false,
        success: false,
        failed: false,
        errors:{},
    },
});

export const registerExecutorReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REGISTER_EXECUTOR_REQUEST: { 
            return initialState.merge({
                edit:{
                    ...initialState.edit,
                    loading:true
                }
            });
        }
        case types.REGISTER_EXECUTOR_SUCCESS: {
            return state.merge({
                edit:{
                    ...state.edit,
                    loading:false,
                    success:true
                }
            });
        }
        case types.REGISTER_EXECUTOR_FAILURE: {
            return state.merge({
                edit:{
                    ...initialState.edit,
                    loading:false,
                    failed:true,
                    errors:action.payload
                }
            });
        }


        case types.GETREGIONS_EXECUTOR_REQUEST: { 
            return initialState.merge({
                edit:{
                    ...initialState.edit,
                    loading:true
                }
            });
        }
        case types.GETREGIONS_EXECUTOR_SUCCESS: {
            return state.merge({
                edit:{
                    ...state.edit,
                    loading:false,
                },
                get:{
                    regions:action.payload.regions
                }
            });
        }
        case types.GETREGIONS_EXECUTOR_FAILURE: {
            return state.merge({
                edit:{
                    ...initialState.edit,
                    loading:false,
                    failed:true,
                    errors:action.payload
                }
            });
        }

        default: {
            return state;
        }
    }
}