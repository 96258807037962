import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    failed: false,
    sellers: [],
    goods: [],
    noMoreGoods: false,
    goodsDetails: null
});

export const goodsReducer = (state = initialState, action) => {
    switch (action.type) {
        //#region GET_ALLSELLERS
        case types.GET_ALLSELLERS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_ALLSELLERS_SUCCESS: {
            return state.merge({
                loading: false,
                sellers: action.payload
            });
        }
        case types.GET_ALLSELLERS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        //#endregion

        //#region FIND_GOODS
        case types.FIND_GOODS_RESET: {
            return state.merge({
                goods: []
            });
        }
        case types.FIND_GOODS_REQUEST: {            
            return state.merge({
                loading: true,
            });
        }
        case types.FIND_GOODS_SUCCESS: {
            let goods = [...state.goods, ...action.payload]
            return state.merge({
                loading: false,
                goods: goods,
                noMoreGoods: action.payload.length == 0
            });
        }
        case types.FIND_GOODS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        //#endregion

        //#region GET_GOODS_DETAILS
        case types.GET_GOODS_DETAILS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_GOODS_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                goodsDetails: action.payload
            });
        }
        case types.GET_GOODS_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        //#endregion

        default: {
            return state;
        }
    }
}