import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    isSuccess: false,
    isFailed: false,
    factors: null,
    paintingPrices: null,
    installPriceFactor: null,
    regions: [],
    regionFactors: []
});

export const calculationFactorsReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case types.GET_CALCULATEFACTORS_REQUEST: {
            return state.merge({
                loading: true
            })
        }

        case types.GET_CALCULATEFACTORS_SUCCESS: {
            return state.merge({
                isSuccess: true,
                loading: false,
                factors: action.payload
            })
        }

        case types.GET_CALCULATEFACTORS_FAILURE: {
            return state.merge({
                isFailed: true,
                loading: false
            })
        }

        case types.ADD_CALCULATEFACTORS_REQUEST: {
            return state.merge({
                loading: true
            })
        }

        case types.ADD_CALCULATEFACTORS_SUCCESS: {
                return state.merge({
                    isSuccess: true,
                    loading: false
                })

            
        }

        case types.ADD_CALCULATEFACTORS_FAILURE: {
            return state.merge({
                isFailed: true,
                loading: false
            })
        }

        case types.ADD_PAINTINGSPRICE_REQUEST: {
            return state.merge({
                loading: true
            })
        }

        case types.ADD_PAINTINGSPRICE_SUCCESS: {
            return state.merge({
                isSuccess: true,
                loading: false,
                paintingPrices: [...state.paintingPrices,
                    action.payload]
            })
        }

        case types.ADD_PAINTINGSPRICE_FAILURE: {
            return state.merge({
                isFailed: true,
                loading: false
            })
        }

        case types.GET_PAINTINGPRICES_REQUEST: {
            return state.merge({
                loading: true
            })
        }

        case types.GET_PAINTINGPRICES_SUCCESS: {
            return state.merge({
                isSuccess: true,
                loading: false,
                paintingPrices: action.payload
            })
        }

        case types.GET_PAINTINGPRICES_FAILURE: {
            return state.merge({
                isFailed: true,
                loading: false
            })
        }

        case types.UPDATE_PAINTINGPRICES_REQUEST: {
            return state.merge({
                loading: true
            })
        }

        case types.UPDATE_PAINTINGPRICES_SUCCESS: {
            let data = action.payload;
            let currValues = [...state.paintingPrices];
            let index = currValues.findIndex(x => x.id == data.id);
            currValues[index] = data
            return state.merge({
                isSuccess: true,
                loading: false,
                paintingPrices: currValues
            })
        }

        case types.UPDATE_PAINTINGPRICES_FAILURE: {
            return state.merge({
                isFailed: true,
                loading: false
            })
        }

        case types.DELETE_PAINTINGPRICES_REQUEST: {
            return state.merge({
                loading: true
            })
        }

        case types.DELETE_PAINTINGPRICES_SUCCESS: {
            return state.merge({
                isSuccess: true,
                loading: false,
                paintingPrices: action.payload
            })
        }

        case types.DELETE_PAINTINGPRICES_FAILURE: {
            return state.merge({
                isFailed: true,
                loading: false
            })
        }

        case types.SET_DEFAULT_PAINTINGPRICE_SUCCESS: {
            return state.merge({
                isSuccess: true,
                loading: false
            })
        }

        case types.REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.FAILURE: {
            return state.merge({
                isFailed: true,
                loading: false
            });
        }
        case types.GET_INSTALL_PRICE_SUCCESS: {
            return state.merge({
                loading: false,
                installPriceFactor: action.payload
            });
        }
        case types.UPDATE_INSTALL_PRICE_SUCCESS: {
            return state.merge({
                loading: false
            });
        }

        case types.GET_REGION_FACTORS_SUCCESS: {
            return state.merge({
                loading: false,
                regionFactors: action.payload.factors,
                regions: action.payload.regions
            });
        }
        case types.SEND_REGION_FACTORS_SUCCESS: {
            return state.merge({
                loading: false
            });
        }

        //#endregion
        default: {
            return state;
        }
    }
}