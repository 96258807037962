import Immutable from 'seamless-immutable';
import * as actionTypes from './types';

const initialState = Immutable({
    loading:false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE_REQUEST:
        return state.merge({
          loading: true
        });
      case actionTypes.SET_LANGUAGE_SUCCESS:
        return state.merge({
          loading: false
        });
      case actionTypes.SET_LANGUAGE_FAILURE:
        return state.merge({
          loading: false
        });
    default:
      return state;
  }
};
