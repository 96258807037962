import axios from "axios";
import * as types from './types';
import FavotireHelper from "../utils/favoriteHelper";

export const addFavorites = (data) => (dispatch) => {
    dispatch({ type: types.ADD_FAVORITES_REQUEST });
    return axios.post(`api/profile/addFavorites`, data)
        .then((response) => {
            dispatch({
                type: types.ADD_FAVORITES_SUCCESS,
                payload: response.data
            });
            getFavorites()(dispatch);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.ADD_FAVORITES_FAILURE });
        })
}

export const removeFavorites = (data) => (dispatch) => {
    dispatch({ type: types.REMOVE_FAVORITES_REQUEST });
    return axios.post(`api/profile/removeFavorites`, data)
        .then((response) => {
            dispatch({
                type: types.REMOVE_FAVORITES_SUCCESS,
                payload: response.data
            });
            getFavorites()(dispatch);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.REMOVE_FAVORITES_FAILURE });
        })
}

export const getFavoriteProducts = () => (dispatch) => {
    dispatch({ type: types.GET_FAVORITES_PRODUCTS_REQUEST });
    return axios.get(`api/home/getFavoriteProducts`)
        .then((response) => {
            dispatch({
                type: types.GET_FAVORITES_PRODUCTS_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.GET_FAVORITES_PRODUCTS_FAILURE });
        })
}

export const getFavorites = () => (dispatch) => {
    dispatch({ type: types.GET_FAVORITES_REQUEST });
    return axios.get(`api/profile/getFavorites`)
        .then((response) => {
            dispatch({
                type: types.GET_FAVORITES_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.GET_FAVORITES_FAILURE });
        })
}

export const loadFavoriteLocal = () => (dispatch) => {
    let data = FavotireHelper.loadFavoriteData();
    dispatch({ type: types.LOAD_FAVORITES_LOCAL, payload: data });
}