import * as types from './types';
import VerifyAccountService from './verifyAccountService';
import * as loginActions from '../login/actions';


const verifyAccountActions = {

    //#region Register executor
    sendSmsStarted: () => {
        return {
            type: types.SEND_SMS_REQUEST
        }
    },
    sendSmsSuccess: (data) => {
        return {
            type: types.SEND_SMS_SUCCESS,
            payload: data
        }
    },
    sendSmsFailed: (errors) => {
        return {
            type: types.SEND_SMS_FAILURE,
            payload: errors
        }
    },
    //#endregion


    //#region Get Regions
    verificateAccountStarted: () => {
        return {
            type: types.VERIFY_ACCOUNT_REQUEST
        }
    },
    verificateAccountSuccess: (data) => {
        return {
            type: types.VERIFY_ACCOUNT_SUCCESS,
            payload: data
        }
    },
    verificateAccountFailed: (errors) => {
        return {
            type: types.VERIFY_ACCOUNT_FAILURE,
            payload: errors
        }
    },

    //#endregion

    setTimerStarted:(seconds)=>{
        return {
            type: types.SET_TIMER_REQUEST,
            payload:seconds
        }
    }
}


export const sendSms = (callback) => (dispatch) => {
    dispatch(verifyAccountActions.sendSmsStarted());
    return VerifyAccountService.sendSms()
        .then((response) => {
            dispatch(verifyAccountActions.sendSmsSuccess(response.data));

            if (callback) 
                callback(true, null);
        }, err => { throw err; })
        .catch((err) => {
            dispatch(verifyAccountActions.sendSmsFailed(err.response.data));

            if (callback)
                callback(false, err.response.data);
        })
}

export const verificateAccount = (confirmationCode, callback) => (dispatch) => {
    dispatch(verifyAccountActions.verificateAccountStarted());
    return VerifyAccountService.verificateAccount(confirmationCode)
        .then((response) => {
            
            loginActions.loginByJWT(response.data, dispatch)
            dispatch(verifyAccountActions.verificateAccountSuccess(response.data));

            if (callback)
                callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch(verifyAccountActions.verificateAccountFailed(err.response.data));

            if (callback)
                callback(false);
        })
}
export const setTimer=(seconds)=>(dispatch)=>{
    dispatch(verifyAccountActions.setTimerStarted(seconds));
}

export const sendExecuterAnswer = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return VerifyAccountService.sendExecuterAnswer(data)
        .then((response) => {
            dispatch({ type: types.SUCCESS });
            if (callback)
                callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback)
                callback(false);
        });
}