import axios from "axios";
import * as types from './types';

export const getTransactionData = () => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/SiteCompany/GetTransactionData`)
        .then((response) => {
            dispatch({
                type: types.GET_TRANSACTION_DATA,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const sendToModeration = (callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/SiteCompany/SendMyCompanySiteToModeration`)
        .then((response) => {
            dispatch({ type: types.SUCCESS });
            if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback) callback(false, err.response.data);
        })
}

export const getMyCompanySite = () => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/SiteCompany/GetMyCompanySite`)
        .then((response) => {
            dispatch({
                type: types.GET_MY_COMPANY_SITE,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getProductsForCompany = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST_PRODUCTS });
    return axios.post(`api/SiteCompany/GetProductsForCS`, data)
        .then((response) => {
            dispatch({
                type: types.SUCCESS_PRODUCT_LOAD,
                payload: response.data
            }); if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({
                type: types.FAILURE,
            });
            if (callback) callback(false, err.response.data);
        })
}

export const createSite = (data,callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/SiteCompany/CreateSite?name=`+data)
        .then((response) => {
            dispatch({
                type: types.CREATE_SITE,
                payload: response.data
            });
            if (callback) callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({
                type: types.CREATE_SITE_FAILURE,
            });
            if (callback) callback(false, err.response.data);
        })
}

export const getProductDetails = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/SiteCompany/GetProductsDetails`, data)
        .then((response) => {
            dispatch({
                type: types.LOAD_DETAILS,
                payload: response.data
            });
            if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({
                type: types.FAILURE,
            });
            if (callback) callback(false, err.response.data);
        })
}

export const loadSite = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/SiteCompany/LoadCompanySite?companyName=` + data)
        .then((response) => {
            dispatch({
                type: types.LOAD_SITE,
                payload: response.data
            });
            if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({
                type: types.LOAD_SITE_FAILURE,
                payload: err.response.data,
            });
            if (callback) callback(false, err.response.data);
        })
}

export const updateCompanySite = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/SiteCompany/UpdateCompanySite`, data)
        .then((response) => {
            dispatch({
                type: types.UPDATE_SITE,
                payload: response.data
            });
            if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({
                type: types.UPDATE_SITE_FAILURE,
            });
            if (callback) callback(false, err.response.data);
        })
}

export const getBGI = () => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/SiteCompany/GetBackgroundImages`)
        .then((response) => {
            dispatch({
                type: types.SUCCESS_BGI_LOAD,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({
                type: types.FAILURE,
            });
        })
}

export const loadLogo = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/SiteCompany/LoadLogo`, data)
        .then((response) => {
            dispatch({
                type: types.SUCCESS_LOGO_LOAD,
                payload: response.data
            });
            if (callback) callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({
                type: types.FAILURE,
            });
            if (callback) callback(false, err);
        })
}

export const loadImage = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/SiteCompany/ImageLoad`, data)
        .then((response) => {
            dispatch({ type: types.SUCCESS });
            if (callback) callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({
                type: types.FAILURE,
            });
            if (callback) callback(false, err);
        })
}

export const deleteImage = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/SiteCompany/DeleteImage`, data)
        .then((response) => {
            dispatch({ type: types.SUCCESS });
            if (callback) callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({
                type: types.FAILURE,
            });
            if (callback) callback(false, err.response.data);
        })
}