import amplitude from 'amplitude-js/amplitude';
import * as lodash from "lodash-uuid";

export const amplitudeTypes = {
    OPEN_WEBSITE: 'open-website',
    REGISTER: 'register',
    REGISTER_START: 'register_start',
    PROJECT_CREATE: 'project_create',
    PROJECT_CREATE_START: 'project_create_start',
    PROJECT_CLOSE: 'project_close',
    PUBLICATION: 'publication',
    CONTRACT: 'contract',
    OPEN_DEMO_EDITOR: 'open_demo_editor',

    CLICK_ABOUT_US: 'click_about_us',
    CLICK_FOR_BUSINESS: 'click_for_business',
    CLICK_HOW_TO_ORDER: 'click_how_to_order',
    CLICK_MORE_PRODUCTS: 'click_more_products',
    CLICK_MORE_GOODS: 'click_more_goods',
    CLICK_MORE_ELEMENTS: 'click_more_elements',
    CLICK_PRIVACY: 'click_privacy',
    CLICK_TERMS_OF_USE: 'click_terms_of_use',
    CLICK_TARIFFS: 'click_tariffs',
    CLICK_PROJECT_CREATE_HOMEPAGE: 'click_project_create_homepage',
    CLICK_PROJECT_CREATE_DEMO: 'click_project_create_demo',
    CLICK_PROJECT_CANCEL_DEMO: 'click_project_cancel_demo',
    CLICK_OPEN_EDITOR: 'click_open_editor',
    CLICK_GOOGLE_PLAY: 'click_google_play',
    OPEN_CATALOG_PRODUCTS: 'open_catalog_producs',
    OPEN_CATALOG_GOODS: 'open_catalog_goods',
    OPEN_DEMO_FROM_PRODUCT: 'open_demo_from_product',
    OPEN_CATALOG_PRODUCTS_BY_ID: 'open_catalog_products_by_id',
    OPEN_SOLUTION_MAIN: 'open_solution_main',

    CREATE_NEW_PRODUCT: 'create_new_product',
    CREATE_NEW_OPTION: 'create_new_option',
    CREATE_NEW_PRODUCT_OBJECT: 'create_new_product_object',
    CALCULATE_SERVICE_USAGE: 'calculate_service_usage',
    DOWNLOAD_DESIGN: 'download_design',
    CONSTRUCTOR_OPEN: 'constructor_open',
    SHARE_CLICK: 'share_click',
    CATALOG_APPLY_CLICK: 'catalog_apply_click',
    MY_PROFILE_OPEN: 'my_profile_open',
    CALCULATE_PRICE_KOEF_USAGE: 'calculate_price_koef_usage',
    CALCULATE_PRICE_KOEF_SAVE: 'calculate_price_koef_save',
    SAVE_NEW_PRODUCT: 'save_new_product',
    APPLY_TEMPLATE_ON_SKETCH_DETAILS: 'APPLY_TEMPLATE_ON_SKETCH_DETAILS',
    CONTACT_MANUFACTURER: 'CONTACT_MANUFACTURER',
    BECOME_MANUFACTURER: 'BECOME_MANUFACTURER',

    OPEN_INSTRUCTION_PAGE: 'open_instruction_page',
    OPEN_SOLUTION_BY_ID: 'open_solution_main_by_id',

    CREATE_COMPANY_SITE: 'create_company_site',
    CREATE_EMBEDDING_SITE: 'create_embedding_site',

    GET_PIDKOVA_PHONE: 'get_pidkova_phone',
    GET_MANUFACTURER_PHONE: 'get_manufacturer_phone',
    OPEN_CALCULATOR: 'open_calculator',
};

export const initAmplitude = () => {
    amplitude.getInstance().init('593c38efe18d5b8d399fc977cc3437cf');
    let deviceId = localStorage.getItem("amplitudeDeviceId");
    if (!deviceId) {
        deviceId = lodash.uuid();
        localStorage.setItem("amplitudeDeviceId", deviceId);
    }
    amplitude.getInstance().setDeviceId(deviceId);
};

export const setAmplitudeUserId = userId => {    
    if (window.location.host.indexOf('simplicad') >= 0) {
        amplitude.getInstance().setUserId(userId);
        //amplitude.getInstance().regenerateDeviceId();
    }
};

export const setAmplitudeUser = (userName) => {
    if (window.location.host.indexOf('simplicad') >= 0) {
        var identify = new amplitude.Identify().set('userName', userName);
        amplitude.getInstance().identify(identify);
    }
};

export const sendAmplitudeData = (eventType, eventProperties) => {
    if (window.location.host.indexOf('simplicad') >= 0) {
        amplitude.getInstance().logEvent(eventType, eventProperties);
    }
};