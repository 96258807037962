import axios from "axios";

export default class LoginService {
    static login(model) {
        return axios.post(`api/account/login`,model)
    };

    static logout(model) {
        return axios.post(`api/account/logout`, model)
    };
}