export const GET_REGIONS_REQUEST = 'createproject/GET_REGIONS_REQUEST';
export const GET_REGIONS_SUCCESS = 'createproject/GET_REGIONS_SUCCESS';
export const GET_REGIONS_FAILURE = 'createproject/GET_REGIONS_FAILURE';

export const GET_CITIES_REQUEST = 'createproject/GET_CITIES_REQUEST';
export const GET_CITIES_SUCCESS = 'createproject/GET_CITIES_SUCCESS';
export const GET_CITIES_FAILURE = 'createproject/GET_CITIES_FAILURE';

export const UPLOAD_IMAGE_REQUEST = 'createproject/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'createproject/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'createproject/UPLOAD_IMAGE_FAILURE';

export const DELETE_IMAGE = 'createproject/DELETE_IMAGE';

export const GET_PRODUCT_TYPES_REQUEST = 'createproject/GET_PRODUCT_TYPES_REQUEST';
export const GET_PRODUCT_TYPES_SUCCESS = 'createproject/GET_PRODUCT_TYPES_SUCCESS';
export const GET_PRODUCT_TYPES_FAILURE = 'createproject/GET_PRODUCT_TYPES_FAILURE';

export const CREATE_PROJECT_REQUEST = 'createproject/CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'createproject/CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'createproject/CREATE_PROJECT_FAILURE';

export const RESET = 'createproject/RESET';

export const GET_PROJECT_DETAILS_REQUEST = 'createproject/GET_PROJECT_DETAILS_REQUEST';
export const GET_PROJECT_DETAILS_SUCCESS = 'createproject/GET_PROJECT_DETAILS_SUCCESS';
export const GET_PROJECT_DETAILS_FAILURE = 'createproject/GET_PROJECT_DETAILS_FAILURE';
