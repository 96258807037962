export const VERIFY_ACCOUNT_REQUEST='verifyaccount/VERIFY_ACCOUNT_REQUEST';
export const VERIFY_ACCOUNT_SUCCESS='verifyaccount/VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_FAILURE='verifyaccount/VERIFY_ACCOUNT_FAILURE';

export const SEND_SMS_REQUEST='verifyaccount/SEND_SMS_REQUEST';
export const SEND_SMS_SUCCESS='verifyaccount/SEND_SMS_SUCCESS';
export const SEND_SMS_FAILURE='verifyaccount/SEND_SMS_FAILURE';

export const SET_TIMER_REQUEST = 'verifyaccount/SET_TIMER_REQUEST';

export const REQUEST = 'verifyaccount/REQUEST';
export const SUCCESS = 'verifyaccount/SUCCESS';
export const FAILURE = 'verifyaccount/FAILURE';