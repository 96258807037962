import axios from "axios";
import * as types from './types';

const actionList = {

    //#region getMyFeedbacks
    getMyFeedbacksStarted: () => {
        return {
            type: types.GET_MYFEEDBACKS_REQUEST
        }
    },
    getMyFeedbacksSuccess: (data) => {
        return {
            type: types.GET_MYFEEDBACKS_SUCCESS,
            payload: data
        }
    },
    getMyFeedbacksFailed: () => {
        return {
            type: types.GET_MYFEEDBACKS_FAILURE,
        }
    },
    //#endregion

    //#region getMessages
    getMessagesStarted: (newOnly) => {
        return {
            type: types.GET_MESSAGES_REQUEST,
            newOnly: newOnly
        }
    },
    getMessagesSuccess: (data) => {
        return {
            type: types.GET_MESSAGES_SUCCESS,
            payload: data
        }
    },
    getMessagesFailed: () => {
        return {
            type: types.GET_MESSAGES_FAILURE,
        }
    },
    //#endregion

    //#region sendMessage
    sendMessageStarted: () => {
        return {
            type: types.SEND_MESSAGE_REQUEST
        }
    },
    sendMessageSuccess: (data) => {
        return {
            type: types.SEND_MESSAGE_SUCCESS,
            payload: data
        }
    },
    sendMessageFailed: () => {
        return {
            type: types.SEND_MESSAGE_FAILURE,
        }
    },
    //#endregion
}

export const getMyFeedbacks = () => (dispatch) => {
    dispatch(actionList.getMyFeedbacksStarted());
    return axios.get(`api/feedback/getmyfeebacks`)
        .then((response) => {
            dispatch(actionList.getMyFeedbacksSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getMyFeedbacksFailed());
        })
}

export const getMessages = (feedbackId, newOnly) => (dispatch) => {
    dispatch(actionList.getMessagesStarted(newOnly == true));
    return axios.get(`api/feedback/getmessages?feedbackId=${feedbackId}&newOnly=${newOnly == true}`)
        .then((response) => {
            dispatch(actionList.getMessagesSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getMessagesFailed());
        })
}

export const sendMessage = (data) => (dispatch) => {
    dispatch(actionList.sendMessageStarted());
    return axios.post(`api/feedback/sendmessage`, data)
        .then((response) => {
            dispatch(actionList.sendMessageSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.sendMessageFailed());
        })
}

export const sendLiveSupportMessage = (data) => (dispatch) => {
    dispatch({ type: types.SEND_LIVE_SUPPORT_MESSAGE_REQUEST, payload: data });
    return axios.post(`api/feedback/sendLiveMessage`, data)
        .then((response) => {            
            dispatch({ type: types.SEND_LIVE_SUPPORT_MESSAGE_SUCCESS, payload: response.data });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.SEND_LIVE_SUPPORT_MESSAGE_FAILURE });
        })
}

export const getLiveSupportMessages = (newOnly, sessionId) => (dispatch) => {
    dispatch({ type: types.GET_LIVE_SUPPORT_MESSAGES_REQUEST, payload: newOnly });
    return axios.get(`api/feedback/getLiveMessages?newOnly=${newOnly == true}&sessionId=${sessionId}`)
        .then((response) => {
            dispatch({ type: types.GET_LIVE_SUPPORT_MESSAGES_SUCCESS, payload: response.data });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.GET_LIVE_SUPPORT_MESSAGES_FAILURE });
        })
}

export const getIsAdminOnline = () => (dispatch) => {
    return axios.get(`api/feedback/getIsAdminOnline`)
        .then((response) => {
            dispatch({ type: types.GET_IS_ADMIN_ONLINE, payload: response.data });
        }, err => { throw err; })
        .catch((err) => {
            
        })
}

export const attachAdminLiveSupportMessage = (message) => (dispatch) => {
    dispatch({ type: types.ATTACH_ADMIN_LIVE_SUPPORT_MESSAGE, payload: message });
}

export const setLiveMessagesUser = (sessionId) => (dispatch) => {
    return axios.post(`api/feedback/setLiveMessagesUser?sessionId=${sessionId}`)
        .then((response) => {
            
        }, err => { throw err; })
        .catch((err) => {
            
        })
}