import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    errors: {}
});

export const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REGISTER_SIMPLE_REQUEST: {
            return initialState.merge({
                loading: true
            });
        }
        case types.REGISTER_SIMPLE_SUCCESS: {
            return state.merge({
                loading: false,
                success: true
            });
        }
        case types.REGISTER_SIMPLE_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
                errors: action.payload
            });
        }


        case types.SET_DEMO_REQUEST: {
            return state.merge({
                loading: true
            });
        }

        case types.SET_DEMO_SUCCESS: {
            return state.merge({
                loading: false,
                success: true
            });
        }
        case types.SET_DEMO_FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }

        default: {
            return state;
        }
    }
}