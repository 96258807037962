import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    invitations: [],
    project: null,
    publications: [],
    productTypes: [],
    propositions: [],
    customer: null,
    contracts: [],
    selectedContract: null,
    sendToReviewError: null
});

export const projectsExecuterReducer = (state = initialState, action) => {
    switch (action.type) {
        //#region GET_MYINVITATIONS
        case types.GET_MYINVITATIONS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_MYINVITATIONS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                failed: false,
                invitations: action.payload
            });
        }
        case types.GET_MYINVITATIONS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_PROJECTDETAILS
        case types.GET_PROJECTDETAILS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_PROJECTDETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                failed: false,
                project: action.payload
            });
        }
        case types.GET_PROJECTDETAILS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region SEND_PROPOSITION
        case types.SEND_PROPOSITION_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.SEND_PROPOSITION_SUCCESS: {            
            return state.merge({
                loading: false,
                success: true,
                failed: false,
                project: {
                    ...state.project,
                    hasProposition: true,
                    proposition: action.payload
                }
            });
        }
        case types.SEND_PROPOSITION_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region FIND_PROJECTS
        case types.FIND_PROJECTS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.FIND_PROJECTS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                failed: false,
                publications: action.payload
            });
        }
        case types.FIND_PROJECTS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_PRODUCT_TYPES
        case types.GET_PRODUCT_TYPES_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_PRODUCT_TYPES_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                productTypes: action.payload
            });
        }
        case types.GET_PRODUCT_TYPES_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_MYPROPOSITIONS
        case types.GET_MYPROPOSITIONS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_MYPROPOSITIONS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                propositions: action.payload
            });
        }
        case types.GET_MYPROPOSITIONS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_CUSTOMER_DETAILS
        case types.GET_CUSTOMER_DETAILS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_CUSTOMER_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                customer: action.payload
            });
        }
        case types.GET_CUSTOMER_DETAILS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_MYCONTRACTS
        case types.GET_MYCONTRACTS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_MYCONTRACTS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                contracts: action.payload
            });
        }
        case types.GET_MYINVITATIONS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_CONTRACT_DETAILS
        case types.GET_CONTRACT_DETAILS_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.GET_CONTRACT_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                selectedContract: action.payload
            });
        }
        case types.GET_CONTRACT_DETAILS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region SEND_CONTRACT_TO_REVIEW
        case types.SEND_CONTRACT_TO_REVIEW_REQUEST: {
            return state.merge({
                loading: true,
                sendToReviewError: null
            });
        }
        case types.SEND_CONTRACT_TO_REVIEW_SUCCESS: {
            return state.merge({
                loading: false,
                success: true
            });
        }
        case types.SEND_CONTRACT_TO_REVIEW_FAILURE: {
            return state.merge({
                loading: false,
                sendToReviewError: action.payload
            });
        }
        //#endregion

        default: {
            return state;
        }
    }
}