import React from 'react';
import { Modal, ModalBody, ModalFooter, Button, Input, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { callMeasurer, getProjectsWithoutMeasurer } from '../myprojects/actions';
import { toastr } from 'react-redux-toastr';

class CallMeasurerPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProjectId: props.projectsWithoutMeasurer[0].id
        };
    }

    onSendClick = () => {
        this.props.callMeasurer(this.state.selectedProjectId, (isOk) => {
            if (isOk) {
                toastr.success('', this.props.t('projects.myprojects.infoText1'));
                this.props.getProjectsWithoutMeasurer();
                this.props.onHideClick();
            }
        });
    }

    onProjectSelect = (id) => {
        this.setState({
            selectedProjectId: id
        });
    }

    render() {
        const { t, onHideClick, projectsWithoutMeasurer, loading } = this.props;
        const { selectedProjectId } = this.state;

        return (
            <Modal isOpen={true} toggle={onHideClick}>
                <ModalBody className="pl-5 pr-5">
                    <div className="align-center">{t('projects.callMeasurerPopup.title')}</div>
                    {projectsWithoutMeasurer.map((item) => (
                        <div key={item.id} style={{ cursor: "pointer" }} onClick={() => this.onProjectSelect(item.id)}>
                            <Input type="radio" checked={item.id == selectedProjectId} /> {item.name}
                        </div>
                    ))}
                    {loading &&
                        <div className="align-center">
                            <Spinner type="grow" color="success" />
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onSendClick}>{t('projects.callMeasurerPopup.send')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.myProjects.loading,
    projectsWithoutMeasurer: state.myProjects.projectsWithoutMeasurer    
});

const mapDispatchToProps = {
    callMeasurer: callMeasurer,
    getProjectsWithoutMeasurer: getProjectsWithoutMeasurer
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CallMeasurerPopup))