import Immutable from 'seamless-immutable';

import * as types from './types.js';
import * as specialtyTypes from '../../constants/specialty';

const initialState = Immutable({
    get: {
        data:{
            specialty: [
                {
                    text: "registerExecuterStepOne.services.sellElem",
                    name: specialtyTypes.sellElements,
                    value: false
                },
                {
                    text: "registerExecuterStepOne.services.prodElem",
                    name: specialtyTypes.productionElements,
                    value: false
                },
                {
                    text: "registerExecuterStepOne.services.settingElem",
                    name: specialtyTypes.setupElements,
                    value: false
                },
                {
                    text: "registerExecuterStepOne.services.design",
                    name: specialtyTypes.designElements,
                    value: false
                }
            ],
            regOfDest: [],
            regOfServiced:[]
        },
        regions:[],
        loading: false,
        success: false,
        failed: false,
        errors:{}
    },
    edit: {
        loading: false,
        success: false,
        failed: false,
        errors:{}
    },
});


export const mySpecialtyReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_MYSPECIALTY_REQUEST: { 
            return initialState.merge({
                get:{
                    ...initialState.get,
                    loading:true
                }
            });
        }
        case types.GET_MYSPECIALTY_SUCCESS: {
            var tempTypes = Object.assign([], action.payload.specialty);
            for (let i = 0; i < tempTypes.length; i++) {
                tempTypes[i].text = state.get.data.specialty[i].text;
            }

            return state.merge({
                get:{
                    ...state.get,
                    loading:false,
                    success:true,
                    data:{
                        ...action.payload,
                        specialty: tempTypes
                    }
                }
            });
        }
        case types.GET_MYSPECIALTY_FAILURE: {
            return state.merge({
                get:{
                    ...initialState.get,
                    loading:false,
                    failed:true
                }
            });
        }




        case types.SAVE_MYSPECIALTY_REQUEST: { 
            return initialState.merge({
                ...state,
                edit:{
                    ...initialState.edit,
                    loading:true
                }
            });
        }
        case types.SAVE_MYSPECIALTY_SUCCESS: {
            return state.merge({
                ...state,
                edit:{
                    ...state.edit,
                    loading:false,
                    success:true,
                    data:action.payload
                }
            });
        }
        case types.SAVE_MYSPECIALTY_FAILURE: {
            return state.merge({
                ...state,
                edit:{
                    ...state.edit,
                    loading:false,
                    failed:true,
                    errors:action.errors
                }
            });
        }

        case types.EDIT_MYSPECIALTY:{
            return state.merge({
                ...state,
                get:{
                    ...state.get,
                    data:{
                        ...state.get.data,
                        [action.payload.name]:action.payload.value
                    }

                }
            });
        }
        default: {
            return state;
        }
    }
}