import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    failed: false,
    sketches: [],
    editorStateData: [],
    templates: [],
    metals: [],
    saveSketchSuccess: false,
    sketchData: null,
    autobuildTemplates: [],
    autobuildTemplateDetails: null,
    sketchSaveId: -1,
    elementsForSketches: null,
});

export const autoBuildEditReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST: {
            return state.merge({
                loading: true,
                failed: false,
                sketchData: null,
                saveSketchSuccess: false
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        case types.SUCCESS: {
            return state.merge({
                loading: false
            });
        }

        case types.GET_ELEMENTS_FOR_SKETCHES_SUCCESS: {
            return state.merge({
                loading: false,
                elementsForSketches: action.payload
            });
        }

        case types.GET_MYSKETCHES_SUCCESS: {
            return state.merge({
                loading: false,
                sketches: action.payload
            });
        }

        case types.GET_PRODUCT_EDITOR_STATE_SUCCESS: {
            return state.merge({
                loading: false,
                editorStateData: action.payload
            });
        }

        case types.SAVE_SKETCH_SUCCESS: {
            return state.merge({
                loading: false,
                saveSketchSuccess: true,
                sketchSaveId:  action.payload 
            });
        }

        case types.COPY_SKETCH_SUCCESS: {
            return state.merge({
                loading: false
            });
        }

        case types.GET_SKETCH_DATA_SUCCESS: {
            return state.merge({
                loading: false,
                sketchData: action.payload
            });
        }

        case types.DELETE_SKETCH_SUCCESS: {
            return state.merge({
                loading: false
            });
        }
        case types.REQUEST_TEMPLATE: {
            return state.merge({
                loading: true,
                failed: false,
            });
        }
        case types.GET_TEMPLATE_SUCCESS: {
            return state.merge({
                loading: false,
                templates: action.payload
            });
        }

        case types.GET_SKETCH_METALS_SUCCESS: {
            return state.merge({
                loading: false,
                metals: action.payload
            });
        }

        case types.SAVE_END: {
            return state.merge({
                saveSketchSuccess: false
            });
        }

        case types.GET_MYTEMPLATES_SUCCESS: {
            return state.merge({
                loading: false,
                autobuildTemplates: action.payload
            });
        }

        case types.SAVE_AUTOBUILD_TEMPLATE_SUCCESS: {
            return state.merge({
                loading: false
            });
        }

        case types.GET_AUTOBUILD_TEMPLATE_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                autobuildTemplateDetails: action.payload
            });
        }
        case types.DELETE_AUTOBUILD_TEMPLATE_SUCCESS: {
            return state.merge({
                loading: false
            });
        }
        case types.GENERATE_HIDDEN_PRODUCT: {
            return state.merge({
                loading: false
            });
        }
        case types.MOVE_PRODUCT_TO_NEW_PROJECT: {
            return state.merge({
                loading: false
            });
        }

        case types.TOGGLE_TEXTURE_VARIANT: {
            return state.merge({
                loading: false
            });
        }

        default: {
            return state;
        }
    }
}