export const GET_REQUEST = 'addGoods/GET_REQUEST';
export const GET_FAILURE = 'addGoods/GET_FAILURE';
export const GET_CATEGORIES_SUCCESS = 'addGoods/GET_CATEGORIES_SUCCESS';
export const GET_CURRENCIES_SUCCESS = 'addGoods/GET_CURRENCIES_SUCCESS';
export const GET_PRODUCTS_SUCCESS = 'addGoods/GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTINFO_SUCCESS = 'addGoods/GET_PRODUCTINFO_SUCCESS';
export const ADD_GOOD_SUCCESS = 'addGoods/ADD_GOOD_SUCCESS';
export const GET_MY_GOODS_SUCCESS = 'addGoods/GET_MY_GOODS_SUCCESS';
export const SET_GOODS_ACTIVE = 'addGoods/SET_GOODS_ACTIVE';
export const COPY_SUCCESS = 'addGoods/COPY_SUCCESS';

export const GET_DETAILS_REQUEST = 'addGoods/GET_DETAILS_REQUEST';
export const GET_DETAILS_SUCCESS = 'addGoods/GET_DETAILS_SUCCESS';

export const GET_METALS_SUCCESS = 'addGoods/GET_METALS_SUCCESS';
