export const REFRESH_BASKET = 'basket/REFRESH_BASKET';
export const REFRESH_BASKET_WITHOUT_PAGE_UPDATE = 'basket/REFRESH_BASKET_WITHOUT_PAGE_UPDATE';

export const REQUEST = 'basket/REQUEST';
export const FAILURE = 'basket/FAILURE';

export const GET_GOODS_BY_IDS_SUCCESS = 'basket/GET_GOODS_BY_IDS_SUCCESS';
export const MAKE_ORDER_SUCCESS = 'basket/MAKE_ORDER_SUCCESS';


export const GET_ELEMENTS_BY_IDS_SUCCESS = 'basket/GET_ELEMENTS_BY_IDS_SUCCESS';
export const MAKE_ELEMENTS_ORDER_SUCCESS = 'basket/MAKE_ELEMENTS_ORDER_SUCCESS';