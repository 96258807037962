import Immutable from 'seamless-immutable';

import * as types from './types.js';
import * as uniqueId from 'lodash/uniqueId';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    messages: [],
    feedbacks: [],
    liveSupportMessages: [],
    liveSupportFailed: false,
    liveSupportIsAdminOnline: false
});

export const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_IS_ADMIN_ONLINE: {
            return state.merge({
                liveSupportIsAdminOnline: action.payload.isAdminOnline
            });
        }
        //#region GET_MESSAGES
        case types.GET_MESSAGES_REQUEST: {
            if (!action.newOnly) {
                return state.merge({
                    messages: []
                });
            } else {
                return state;
            }
        }
        case types.GET_MESSAGES_SUCCESS: {
            let messages = [...state.messages, ...action.payload];
            let t = 0;
            return state.merge({
                loading: false,
                success: true,
                messages: messages
            });
        }
        case types.GET_MESSAGES_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_MYFEEDBACKS
        case types.GET_MYFEEDBACKS_REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.GET_MYFEEDBACKS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                feedbacks: action.payload
            });
        }
        case types.GET_MYFEEDBACKS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region SEND_MESSAGE
        case types.SEND_MESSAGE_REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.SEND_MESSAGE_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                messages: [...state.messages, action.payload]
            });
        }
        case types.SEND_MESSAGE_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        case types.SEND_LIVE_SUPPORT_MESSAGE_REQUEST: {
            let messages = [...state.liveSupportMessages,
                {
                    id: "local-" + uniqueId(),
                    userSessionId: action.payload.sessionId,
                    text: action.payload.text,
                    time: new Date(),
                    isUserSender: true,
                    isLocal: true
                }];
            return state.merge({
                liveSupportMessages: messages
            });
        }   

        case types.ATTACH_ADMIN_LIVE_SUPPORT_MESSAGE: {
            let messages = [...state.liveSupportMessages, action.payload];
            return state.merge({
                liveSupportMessages: messages
            });
        }

        //#region GET_LIVE_SUPPORT_MESSAGES
        case types.GET_LIVE_SUPPORT_MESSAGES_REQUEST: {
            let messages = state.liveSupportMessages;
            if (!action.payload) {
                messages = [];
            }
            return state.merge({
                liveSupportFailed: false,
                liveSupportMessages: messages
            });
        }
        case types.GET_LIVE_SUPPORT_MESSAGES_SUCCESS: {
            if (action.payload.messages.count == 0) {
                return state.merge({
                    liveSupportIsAdminOnline: action.payload.isAdminOnline
                });
            }
            let messages = state.liveSupportMessages.filter(m => !m.isLocal && !m.isTempAdmin);
            let tempAdminMessages = state.liveSupportMessages.filter(m => m.isTempAdmin);
            messages = [...messages, ...action.payload.messages, ...tempAdminMessages];
            return state.merge({
                liveSupportMessages: messages,
                liveSupportIsAdminOnline: action.payload.isAdminOnline
            });
        }
        case types.GET_LIVE_SUPPORT_MESSAGES_FAILURE: {
            return state.merge({
                liveSupportFailed: true,
            });
        }
        //#endregion

        default: {
            return state;
        }
    }
}