import React, { Component } from "react";
import { MdErrorOutline } from "react-icons/md";
import axios from "axios";
import StackTrace from 'stacktrace-js';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {        
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        if (window.location.host.indexOf('localhost') == -1) {
            axios.post('api/ErrorHandle/SaveError', {
                appVersion: 'web',
                platfrom: window.location.host,
                date: new Date().toISOString(),
                errorText: error.toString()
            });

            StackTrace.fromError(error)
                .then(err => {
                    let errText = error.message + '\n';
                    errText += err.map(e => `${e.functionName}(${e.fileName}:${e.lineNumber}:${e.columnNumber})\n`).join('');
                    //console.log(errText);

                    axios.post('api/ErrorHandle/SaveError', {
                        appVersion: 'web',
                        platfrom: window.location.host,
                        date: new Date().toISOString(),
                        errorText: errText
                    }).finally(() => {
                        window.location.reload();
                    });
                });
        }
    }

    render() {
        if (this.state.hasError) {            
            return (
                <div style={{ textAlign: 'center', marginTop: "50px" }}>
                    <MdErrorOutline color="red" size="5em" />
                    <h2>Обновите страницу</h2>
                </div>
            );
        }

        return this.props.children;
    }
}