import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Alert, Spinner } from 'reactstrap';
import axios from "axios";
import { toastr } from 'react-redux-toastr'

export default class FeedbackPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            failed: false,
            loading: true,
            themes: [],
            themeId: undefined,
            text: '',
            email: ''
        };
    }

    componentDidMount() {
        axios.get(`api/feedback/getfeedbackthemes`)
            .then((response) => {
                this.setState({
                    themes: response.data,
                    themeId: response.data[0].id,
                    loading: false
                });
            }, err => { throw err; })
            .catch((err) => {
                this.setState({
                    failed: true,
                    loading: false
                });
            })
    }

    onSendClick = () => {        
        this.setState({
            loading: true
        });
        let data = {
            themeId: this.state.themeId,
            email: this.state.email,
            text: this.state.text
        };
        axios.post(`api/feedback/sendfeedback`, data)
            .then((response) => {
                this.setState({                    
                    loading: false
                });
                toastr.success('', this.props.t('feedback.sendSucess'));
                this.props.onHideFeedbackClick();
                if (this.props.onFeedbackSent != null)
                    this.props.onFeedbackSent();
            }, err => { throw err; })
            .catch((err) => {
                this.setState({
                    failed: true,
                    loading: false
                });
            })
    }

    onFieldChange = (e) => {
        let name = e.target.name;
        this.setState({
            [name]: e.target.value
        });
    }

    validateFields = () => {
        if (this.props.isAuthenticated) {
            return this.state.text.trim() != '';
        } else {
            let emailRegex = /\S+@\S+\.\S+/;
            return emailRegex.test(this.state.email) && (this.state.text.trim() != '');
        }
    }

    render() {
        const { t, onHideFeedbackClick, isAuthenticated } = this.props;
        const { failed, loading, themes, themeId, text, email } = this.state;
        return (
            <Modal isOpen={true} toggle={onHideFeedbackClick}>
                <ModalBody>
                    {failed &&
                        <Alert color="danger">
                            {t('errorDataLoad')}
                        </Alert>
                    }
                    {loading &&
                        <div className="align-center">
                            <Spinner color="secondary" />
                        </div>
                    }
                    <div>{t('feedback.theme')}</div>
                    <Input type="select" className="mb-2" value={themeId} onChange={this.onFieldChange} name="themeId">
                        {
                            themes.map((item) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))
                        }
                    </Input>
                    {!isAuthenticated &&
                        <Input type="email" className="mb-2" value={email} onChange={this.onFieldChange} name="email" placeholder={t('feedback.email')} />
                    }
                    <Input type="textarea" style={{ height: 200 }} value={text} onChange={this.onFieldChange} name="text" />
                </ModalBody>
                <ModalFooter>
                    <Button disabled={!this.validateFields()} color="secondary" onClick={this.onSendClick}>{t('feedback.send')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}