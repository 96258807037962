import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    failed: false,
    metals: [],
    paints: [],
    orders: [],
    orderDetails: null,
    executorInfo: null
});

export const calculatorReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false
            });
        }
        case types.SUCCESS: {
            return state.merge({
                loading: false
            });
        }

        case types.GET_METALS_SUCCESS: {
            return state.merge({
                loading: false,
                metals: action.payload
            });
        }
        case types.GET_PAINTS_SUCCESS: {
            return state.merge({
                loading: false,
                paints: action.payload
            });
        }
        case types.SAVE_ORDER_SUCCESS: {
            return state.merge({
                loading: false
            });
        }
        case types.GET_ORDERS_SUCCESS: {
            return state.merge({
                loading: false,
                orders: action.payload
            });
        }
        case types.GET_ORDER_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                orderDetails: action.payload
            });
        }
        case types.MAKE_CONTRACT_SUCCESS: {
            return state.merge({
                loading: false
            });
        }
        case types.GET_EXECUTOR_INFO_SUCCESS: {
            return state.merge({
                loading: false,
                executorInfo: action.payload.executorInfo
            });
        }
        case types.GET_ELEMENTS_FOR_ORDERS_SUCCESS: {
            return state.merge({
                loading: false
            });
        }

        default: {
            return state;
        }
    }
}