import React, { Component } from 'react';
import { NavItem, Nav, DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import './MyProfileSideBar.css';
import { MdFavorite, MdPlayCircleOutline } from 'react-icons/md';
import { getFavorites, loadFavoriteLocal } from '../../favorites/actions';
import { isMobile } from 'react-device-detect';
import { getLatestBlog } from '../../instruction/actions';
import { makeExecutor } from '../../myprofile/myspecialty/actions';
import { toastr } from 'react-redux-toastr';
import FullPageLoader from '../loaders/FullPageLoader/FullPageLoader';

export class MyProfileSideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupVideoId: null,
            isLoading: false
        };
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {

    }

    getFavoriteCount = () => {
        if (this.props.favoritesData != null) {
            return this.props.favoritesData.productIds.length;
        }
        return '';
    }

    getFavoriteProductCount = () => {
        if (this.props.favoritesData != null) {
            return this.props.favoritesData.productIds.length;
        }
        return '';
    }


    makeExecutor = () => {
        this.setState({ isLoading: true });
        this.props.makeExecutor((isOk) => {
            if (isOk) {
                toastr.success('', this.props.t('myProfile.services.youBecomeExecutor'));
            }
            this.setState({ isLoading: false });
        });
    }

    render() {
        const { t, isAuthenticated, roles } = this.props;
        const isSketchEditor = roles.includes("SketchBuilder");

        return (
            <div className={!isMobile ? "" : "sb-cont-mobile"}>
                {this.state.isLoading &&
                    <FullPageLoader />
                }
                {isAuthenticated &&
                    <>
                        <div className="sb-title">{t('sidebar.tMyProfile')}</div>
                        <Link className="sb-link" to="/myprofile">{t('sidebar.generalInfo')}</Link>
                        {this.props.isExecutor ?
                            <Link className="sb-link" to="/myprofile/specialty">{t('sidebar.services')}</Link>
                            :
                            <Link className="sb-link" onClick={this.makeExecutor}>{t('sidebar.makeExecutor')}</Link>
                        }                        
                        {this.props.isSeller && isSketchEditor &&
                            <>
                                <Link className="sb-link" to="/myprofile/myelements">{t('sidebar.elements')}</Link>
                                <Link className="sb-link" to="/myprofile/elementmatch">{t('sidebar.elementMatch')}</Link>
                            </>
                        }
                        <Link className="sb-link" to="/myprofile/feedbacks">{t('sidebar.support')}</Link>
                        {/*<Link className="sb-link" to="/myprofile/myeditorsessions">{t('sidebar.sessionEditors')}</Link>*/}
                        {this.props.isManufactor &&
                            <Link className="sb-link" to="/myprofile/calculationfactors">{t('sidebar.calculationFactors')}</Link>
                        }

                        {this.props.isExecutor &&
                            <Link className="sb-link" to="/myprofile/mycatalog">{t('sidebar.mycatalog')}</Link>
                        }

                        <Link className="sb-link" to="/myprofile/mycompanysite">{t('sidebar.mycompanysite')}</Link>
                        <Link className="sb-link" to="/myprofile/siteportfolio">{t('sidebar.portfolio')}</Link>

                        {/*<Link className="sb-link" to="/myprofile/balance">{t('sidebar.balance')}</Link>*/}
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.language.loading,
    isAuthenticated: state.login.isAuthenticated,
    isConfirmed: state.login.isConfirmed,
    isExecutor: state.login.user.isExecutor,
    isShowPortfolio: state.login.user.roles.includes("Manufacturer") ||
        state.login.user.roles.includes("Installer") ||
        state.login.user.roles.includes("Designer"),
    isSeller: state.login.user.roles.includes("Seller"),
    isManufactor: state.login.user.roles.includes("Manufacturer"),
    roles: state.login.user.roles,
    favoritesData: state.favorite.favoritesData,
    LastBlogs: state.instructions.LastBlogs
});

const mapDispatchToProps = {
    getFavorites: getFavorites,
    loadFavoriteLocal: loadFavoriteLocal,
    getLatestBlog: getLatestBlog,
    makeExecutor: makeExecutor
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MyProfileSideBar))
