import axios from "axios";

export default class VerifyAccountService {
    static sendSms() {
        return axios.post(`api/account/sendsms`)
    };
    static verificateAccount(confirmationCode){
        return axios.post(`api/account/verificationphone`,{code:confirmationCode})
    }
    static sendExecuterAnswer(data) {
        return axios.post(`api/account/SendExecuterAnswer`, data)
    }
}