import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import './FooterLayout.css';
import FeedbackPopup from '../../feedback/FeedbackPopup';
import Helper from '../../utils/helper';
import { sendAmplitudeData, amplitudeTypes } from '../../utils/amplitude';


class FooterLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFeedbackOpen: false
        };
    }

    onOpenGooglePlay = () => {
        sendAmplitudeData(amplitudeTypes.CLICK_GOOGLE_PLAY, null);
    }

    onARSupportClick = () => {
        window.open("https://developers.google.com/ar/discover/supported-devices", '_blank');
    }

    onShowFeedbackClick = () => {
        this.setState({
            isFeedbackOpen: true
        });
    }

    onHideFeedbackClick = () => {
        this.setState({
            isFeedbackOpen: false
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="mt-4 footer">
                <Container className="container-full pt-4 pb-3">
                    <Row>
                        <Col xs="auto">
                            <div>
                                <img style={{ marginTop: "-15px" }} className="logo-img" src="/img/logo.png" />
                                <span className="f-brandname">SimpliCAD</span>
                            </div>
                        </Col>
                        <Col>
                            <Row className="mt-3">
                                <Col xs="auto">
                                    <Link className="f-menu-item" to="/aboutProject">
                                        <div></div>
                                        <label>{t('footer.aboutUs')}</label>
                                    </Link>
                                    <Link className="f-menu-item" to="/solution">
                                        <div></div>
                                        <label>{t('footer.forBusiness')}</label>
                                    </Link>
                                    <span className="f-menu-item" onClick={this.onShowFeedbackClick}>
                                        <div></div>
                                        <label>{t('footer.support')}</label>
                                    </span>
                                </Col>
                                <Col xs="auto">
                                    <Link className="f-menu-item" to="/privacy">
                                        <div></div>
                                        <label>{t('footer.privacy')}</label>
                                    </Link>
                                    <Link className="f-menu-item" to="/termsofuse">
                                        <div></div>
                                        <label>{t('footer.publicContract')}</label>
                                    </Link>
                                    <Link className="f-menu-item" to="/tariffs">
                                        <div></div>
                                        <label>{t('footer.tariffs')}</label>
                                    </Link>
                                </Col>
                                <Col xs="auto">
                                    <Link className="f-menu-item" to="/delivery">
                                        <div></div>
                                        <label>{t('footer.delivery')}</label>
                                    </Link>
                                    <Link className="f-menu-item" to="/contacts">
                                        <div></div>
                                        <label>{t('footer.contacts')}</label>
                                    </Link>
                                </Col>
                            </Row>
                            <div className="f-item-text">{t('footer.contactPhone')}</div>
                        </Col>
                        <Col xs="auto">
                            <Row className="f-googlePlayRow  p-3">
                                <Col className="p-0">
                                    <span style={{ fontSize: "14px", color:"white" }}>{t('homeProject.arMobile')}</span>
                                </Col>
                                <Col className="p-0" xs="auto">
                                    <a href={Helper.AndroidMobileApp} target="_blank" onClick={this.onOpenGooglePlay}>
                                        <img style={{ marginTop: "15px" }} src="/img/google-play.png" />
                                    </a>
                                </Col>
                                <div className="f-arSupport" onClick={this.onARSupportClick}>{t('homeProject.arSupport')}</div>
                            </Row>
                        </Col>
                    </Row>
                    <div className="footer-divider mt-2 mb-2"></div>
                    <div className="align-center" style={{ color: "#6b708d", fontSize: "12px" }}>&#169; 2019-2021 SimpliCAD</div>
                </Container>

                {this.state.isFeedbackOpen &&
                    <FeedbackPopup
                        t={t}
                        onHideFeedbackClick={this.onHideFeedbackClick}
                        isAuthenticated={this.props.isAuthenticated}
                    />
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({   
    isAuthenticated: state.login.isAuthenticated    
});

export default withTranslation()(connect(mapStateToProps, null)(FooterLayout))