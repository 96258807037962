export default class MathHelper {
    static metersToMm(m) {
        return Math.round(m * 1000);
    }

    static mmToMeters(mm) {
        return mm / 1000.0;
    }

    static radToDegree(rad) {
        return Math.round(rad * (180 / Math.PI));
    }

    static degreeToRad(degree) {
        return degree * (Math.PI / 180.0);
    }
}