import axios from "axios";
import * as types from './types';
import { amplitudeTypes, sendAmplitudeData } from '../../utils/amplitude';

const actionList = {

    //#region get regions
    getRegionsStarted: () => {
        return {
            type: types.GET_REGIONS_REQUEST
        }
    },
    getRegionsSuccess: (data) => {
        return {
            type: types.GET_REGIONS_SUCCESS,
            payload: data
        }
    },
    getRegionsFailed: () => {
        return {
            type: types.GET_REGIONS_FAILURE,
        }
    },
    //#endregion

    //#region getCities
    getCitiesStarted: () => {
        return {
            type: types.GET_CITIES_REQUEST
        }
    },
    getCitiesSuccess: (data) => {
        return {
            type: types.GET_CITIES_SUCCESS,
            payload: data
        }
    },
    getCitiesFailed: () => {
        return {
            type: types.GET_CITIES_FAILURE,
        }
    },
    //#endregion

    //#region Upload project image
    uploadImageStarted: () => {
        return {
            type: types.UPLOAD_IMAGE_REQUEST
        }
    },
    uploadImageSuccess: (data) => {
        return {
            type: types.UPLOAD_IMAGE_SUCCESS,
            payload: data
        }
    },
    uploadImageFailed: (errors) => {
        return {
            type: types.UPLOAD_IMAGE_FAILURE,
            payload: errors
        }
    },
    //#endregion    

    //#region get product types
    getProductTypesStarted: () => {
        return {
            type: types.GET_PRODUCT_TYPES_REQUEST
        }
    },
    getProductTypesSuccess: (data) => {
        return {
            type: types.GET_PRODUCT_TYPES_SUCCESS,
            payload: data
        }
    },
    getProductTypesFailed: (errors) => {
        return {
            type: types.GET_PRODUCT_TYPES_FAILURE
        }
    },
    //#endregion

    //#region get product types
    createProjectStarted: () => {
        return {
            type: types.CREATE_PROJECT_REQUEST
        }
    },
    createProjectSuccess: (data) => {
        return {
            type: types.CREATE_PROJECT_SUCCESS,
            payload: data
        }
    },
    createProjectFailed: (errors) => {
        return {
            type: types.CREATE_PROJECT_FAILURE
        }
    },
    //#endregion

    //#region getProjectDetails
    getProjectDetailsStarted: () => {
        return {
            type: types.GET_PROJECT_DETAILS_REQUEST
        }
    },
    getProjectDetailsSuccess: (data) => {
        return {
            type: types.GET_PROJECT_DETAILS_SUCCESS,
            payload: data
        }
    },
    getProjectDetailsFailed: (errors) => {
        return {
            type: types.GET_PROJECT_DETAILS_FAILURE
        }
    },
    //#endregion
}

export const getRegions = () => (dispatch) => {
    dispatch(actionList.getRegionsStarted());
    return axios.get(`api/region/getCountriesAndRegions`)
        .then((response) => {
            dispatch(actionList.getRegionsSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getRegionsFailed());
        })
}

export const getCities = (regionId) => (dispatch) => {
    dispatch(actionList.getCitiesStarted());
    return axios.get(`api/region/getCities?regionId=${regionId}`)
        .then((response) => {
            dispatch(actionList.getCitiesSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getCitiesFailed());
        })
}

export const uploadProjectImage = (file) => (dispatch) => {
    dispatch(actionList.uploadImageStarted());
    let f = new FormData();
    f.append("File", file)
    return axios.post(`api/project/uploadprojectimage`, f)
        .then((response) => {
            dispatch(actionList.uploadImageSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.uploadImageFailed(err.response.data));
        })
}

export const deleteProjectImage = (id) => (dispatch) => {
    dispatch({
        type: types.DELETE_IMAGE,
        payload: id
    });
}

export const getProductTypes = () => (dispatch) => {
    dispatch(actionList.getProductTypesStarted());
    return axios.get(`api/project/getproducttypes`)
        .then((response) => {
            dispatch(actionList.getProductTypesSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getProductTypesFailed());
        })
}

export const createProject = (data) => (dispatch) => {    
    dispatch(actionList.createProjectStarted());
    return axios.post(`api/project/create`, data)
        .then((response) => {
            dispatch(actionList.createProjectSuccess(response.data));
            if (data.id == null) {
                let productTypes = data.products.map((p) => p.productTypeId);
                sendAmplitudeData(amplitudeTypes.PROJECT_CREATE, { "productTypes": productTypes });
            }
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.createProjectFailed());
        })
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET
    });
}

export const getProjectDetails = (id) => (dispatch) => {
    dispatch(actionList.getProjectDetailsStarted());
    return axios.get(`api/project/getupdateprojectdetails/${id}`)
        .then((response) => {
            dispatch(actionList.getProjectDetailsSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(actionList.getProjectDetailsFailed());
        })
}

export const uploadProductImage = (file, productType, callback) => (dispatch) => {    
    let f = new FormData();
    f.append("File", file)
    f.append("ProductTypeId", productType)
    return axios.post(`api/project/uploadproductimage`, f)
        .then((response) => {
            if (callback) {
                callback(true, response.data);
            }
        }, err => { throw err; })
        .catch((err) => {
            if (callback) {
                callback(false);
            }
        })
}
