import Immutable from 'seamless-immutable';

import * as types from './types.js';
import { getBasket } from './actions.js';

const initialState = Immutable({
    loading: false,
    failed: false,
    basket: getBasket(),
    goodsInfos: [],
    elementsInfos: [],
    makeOrderSuccess: false,
    makeOrderElementsSuccess: false
});

export const basketReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.REFRESH_BASKET_WITHOUT_PAGE_UPDATE: {
            return state.merge({
                basket: action.payload
            })
        }

        case types.REFRESH_BASKET: {
            return state.merge({
                basket: action.payload,
                makeOrderSuccess: false,
                makeOrderElementsSuccess: false,
                loading: false
            });
        }

        case types.REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }

        case types.GET_GOODS_BY_IDS_SUCCESS: {
            return state.merge({
                loading: false,
                goodsInfos: action.payload
            });
        }

        case types.MAKE_ORDER_SUCCESS: {
            return state.merge({
                makeOrderSuccess: true
            });
        }

        //#region ELEMENTS
        case types.GET_ELEMENTS_BY_IDS_SUCCESS: {
            return state.merge({
                loading: false,
                elementsInfos: action.payload
            });
        }
        case types.MAKE_ELEMENTS_ORDER_SUCCESS: {
            return state.merge({
                makeOrderElementsSuccess: true
            });
        }    
        //#endregion

        default: {
            return state;
        }
    }
}