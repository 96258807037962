import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    get: {
        regions:[],
        loading: false,
        failed:false,
        success: false,
    },
});


export const regionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_REGIONS_REQUEST: { 
            return initialState.merge({
                get:{
                    ...initialState.get,
                }
            });
        }
        case types.GET_REGIONS_SUCCESS: {
            return state.merge({
                get:{
                    ...state.get,
                    success:true,
                    regions:action.payload.regions
                }
            });
        }
        case types.GET_REGIONS_FAILURE: {
            return state.merge({
                get:{
                    ...initialState.get,
                    failed:true
                }
            });
        }

        default: {
            return state;
        }
    }
}