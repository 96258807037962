import React from 'react';

import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
    DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem, Button, Input
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { Link } from 'react-router-dom';

import { setLanguage } from '../../../store/language/actions';

import { loadLogo } from '../../actions';

import { MdShoppingCart } from "react-icons/md";

import Helper from "../../../utils/helper";

import * as UserRoles from '../../../constants/specialty';

import { AiFillEdit } from "react-icons/ai";

import ReactTooltip from "react-tooltip";

export class CompanyNavMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            //i18n: props.i18n,
        };
    }

    componentDidMount() {
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    /*handleChange = (lang) => {
        this.changeLanguage(lang);
    }

    changeLanguage = lng => {
        this.state.i18n.changeLanguage(lng).then((t) => {
            this.props.setLanguage(lng).then((res) => {
                window.location.reload();
            });
        });;
    };*/

    loadLogo(selectorFiles: FileList) {
        for (let i = 0; i < selectorFiles.length; i++) {
            let file = selectorFiles[i];
            Helper.getBase64(file, (base64) => {
                this.props.loadLogo({ logo: base64, companyId: this.props.companySite.id });
            });
        }
    }

    render() {
        const { t, roles, isAuthenticated, companySite } = this.props;

        const { language } = this.props.i18n;

        let basketCount = this.props.basket.goods.length + this.props.basket.elements.length;

        let pathSplit = window.location.pathname.split('/');
        let pathNameFirst = pathSplit[1];
        let isEdit = pathNameFirst == "companyedit";
        let pathToView = '/company';
        pathSplit.map((item, index) => {
            if (index > 1) {
                pathToView += '/' + item;
            }
        });

        let code = language.substring(0, 2);
        if (language === "uk" || language === "uk-UA") {
            code = "ua";
        }
        if (language == "en") {
            code = "gb";
        }

        const logoImage = companySite != null && companySite.logo != null && companySite.logo != "" ? Helper.getSiteCompanyImgPath(companySite.logo) : "/img/logo.png";

        return (
            <div>
                {companySite != null &&
                    <Navbar style={{ backgroundColor: "#6D7487" }} className={"navbar-expand-sm navbar-toggleable-sm fixed-top " /*+ (!isMobile ? "header" : "header-mobile")*/} >
                        <Container className="container-full">
                            <NavLink tag={Link} to={`/${pathNameFirst}/${companySite.name}`}>
                                <div
                                    className="logo-img-с"
                                    style={{ backgroundImage: `url("${logoImage}")` }}
                                />
                            </NavLink>
                            {isEdit &&
                                <div style={{ position: 'relative' }}>
                                    <Input style={{ display: "none" }} type="file" id="logo_load" onChange={(e) => this.loadLogo(e.target.files)} />
                                    <label className="logo-handler" htmlFor="logo_load">
                                        <AiFillEdit className="edit-icon"
                                            data-tip data-for="loadLogo" />
                                    </label>
                                    <ReactTooltip id="loadLogo" place="top" effect="solid">
                                        {t('siteCompany.loadLogo')}
                                    </ReactTooltip>
                                </div>
                            }
                            <NavbarToggler onClick={this.toggle} className="mr-2" />
                            <Collapse style={{ justifyContent: "center" }} isOpen={this.state.isOpen} navbar>
                                <ul className={"navbar-nav"}>
                                    <NavItem>
                                        <NavLink tag={Link} className="menu-item" to={`/${pathNameFirst}/${companySite.name}`}>
                                            {t('siteCompany.about')}
                                        </NavLink>
                                    </NavItem>
                                    <>
                                        {companySite.isManufactoring &&
                                            <>
                                                <NavItem>
                                                    <NavLink tag={Link} className="menu-item" to={`/${pathNameFirst}/${companySite.name}/portfolio`}>
                                                        {t('siteCompany.portfolio')}
                                                    </NavLink>
                                                </NavItem>
                                                <NavLink tag={Link} className="menu-item" to={`/${pathNameFirst}/${companySite.name}/catalog/1`}>
                                                    {t('siteCompany.prices')}
                                                </NavLink>
                                            </>
                                        }
                                        {/*(companySite.isTrading || companySite.isManufactoring) ?
                                            <UncontrolledDropdown inNavbar>
                                                <DropdownToggle nav caret className="menu-item">
                                                    {t('siteCompany.3dconstructor')}
                                                </DropdownToggle>
                                                <DropdownMenu right className="dropdownmenu">
                                                    {companySite.isManufactoring &&
                                                        <DropdownItem className="menu-item" >
                                                            <NavLink tag={Link} className="menu-item" to={`/${pathNameFirst}/${companySite.name}/catalog/1`}>
                                                                {t('siteCompany.products')}
                                                            </NavLink>
                                                        </DropdownItem>
                                                    }
                                                    {companySite.isTrading &&
                                                        <DropdownItem className="menu-item" >
                                                            <NavLink tag={Link} className="menu-item" to={`/${pathNameFirst}/${companySite.name}/catalog/0`}>
                                                                {t('siteCompany.elements')}
                                                            </NavLink>
                                                        </DropdownItem>
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            : <></>
                                        */}
                                    </>
                                    <NavItem>
                                        <NavLink tag={Link} className="menu-item" to={`/${pathNameFirst}/${companySite.name}/contacts`} >
                                            {t('siteCompany.contacts')}
                                        </NavLink>
                                    </NavItem>
                                    {/*<UncontrolledDropdown inNavbar>
                                        <DropdownToggle nav caret className="menu-item">
                                            {language.substring(0, 2).toUpperCase()}
                                        </DropdownToggle>
                                        <DropdownMenu right className="dropdownmenu">
                                            <DropdownItem className="menu-item" onClick={() => this.handleChange("uk")}>
                                                UK
                                        </DropdownItem>
                                            <DropdownItem className="menu-item" onClick={() => this.handleChange("ru")}>
                                                RU
                                        </DropdownItem>
                                            <DropdownItem className="menu-item" onClick={() => this.handleChange("en")}>
                                                EN
                                        </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>*/}
                                    {/*companySite != null && companySite.isTrading &&
                                        <Link to="/basket">
                                            <div className="basket-div">
                                                <MdShoppingCart className="basket-icon" />
                                                {basketCount != 0 &&
                                                    <div className="basket-count">{basketCount}</div>
                                                }
                                            </div>
                                        </Link>
                                    */}
                                    {isEdit ?
                                        <NavLink tag={Link} className="menu-item" to={pathToView} >
                                            {t('siteCompany.toView')}
                                        </NavLink>
                                        :
                                        <div />
                                    }
                                </ul>
                            </Collapse>
                        </Container>
                    </Navbar>

                }
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.language.loading,
    basket: state.basket.basket,
    companySite: state.siteCompany.companySite
});

const mapDispatchToProps = {
    setLanguage: setLanguage,
    loadLogo: loadLogo
};


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CompanyNavMenu))