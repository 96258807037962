import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from '../actions';
import FullPageLoader from "../../components/loaders/FullPageLoader/FullPageLoader";
import { Row, Input, Button, FormFeedback, FormGroup, Label } from "reactstrap";
import "./ProposeSketch.css";
import { MdImage } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { isMobile } from "react-device-detect";

class ProposeSketch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            imageBase64: null,
            email: '',
            phone: '',
            errorimageBase64: false,
            erroremail: false,
            errorphone: false
        };
    }

    onImageSelect = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = () => {
            this.setState({
                imageBase64: reader.result,
                errorimageBase64: false
            });
        };
        reader.readAsDataURL(file);
    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            ['error' + e.target.name]: false
        });
    }

    onUserPhoneChange = (phone) => {
        if (phone == "3800") {
            phone = "380";
        }
        this.setState({
            phone: phone,
            errorphone: false
        });
    }

    onSendClick = () => {
        if (this.state.imageBase64 == null || this.state.phone == '') {
            this.setState({
                errorimageBase64: this.state.imageBase64 == null,
                errorphone: this.state.phone == ''
            });
            return;
        }

        this.setState({ loading: true });
        this.props.proposeSketchImage({
            imageBase64: this.state.imageBase64,
            email: this.state.phone
        }, isOk => {
            this.setState({ loading: false });
        });
    }

    render() {
        const { loading, phone, errorphone, errorimageBase64, imageBase64 } = this.state;
        const { t } = this.props;

        return (
            <div className={"psCard " + this.props.className} style={this.props.style} >
                {loading &&
                    <FullPageLoader />
                }
                <div className="align-center mt-2"><b>{t('autoBuildProduct.psTitle')}</b></div>
                <div className="align-center">
                    <Input id="proposeSketchImage" type="file" accept="image/*" onChange={this.onImageSelect} style={{ display: 'none' }} />
                    <Label htmlFor="proposeSketchImage" className={!isMobile ? "psImage" : "psImage-mobile"}>
                        {imageBase64 ?
                            <img src={imageBase64} />
                            :
                            <MdImage size="2em" className="psImageIcon" />
                        }
                    </Label>
                    {errorimageBase64 &&
                        <FormFeedback style={{ display: 'block' }}>{t('requiredField')}</FormFeedback>
                    }
                </div>
                <div>{t('autoBuildProduct.psPhoneNumber')}</div>
                <div>
                    <PhoneInput
                        inputClass="phone-input"
                        inputStyle={{ width: '100%' }}
                        country={'ua'}
                        preferredCountries={['ua', 'us', 'ca']}
                        name="phone"
                        value={phone}
                        onChange={this.onUserPhoneChange}
                    />
                    {errorphone &&
                        <FormFeedback style={{ display: 'block' }}>{t('requiredField')}</FormFeedback>
                    }
                </div>
                <div className="align-center mt-2">
                    <Button color="secondary" onClick={this.onSendClick}>{t('autoBuildProduct.sketchSend')}</Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    proposeSketchImage: actions.proposeSketchImage
}


export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProposeSketch));