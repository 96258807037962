import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';


import './style.sass';
import * as uniqueId from 'lodash/uniqueId';

const CheckBox = ({ className, label, name, value, onChange, style }) => {
    const { t } = useTranslation();
    const id = uniqueId(name);
    return (
        <div className={className} style={style}>
            <input checked={value} className="inp-cbx" id={id} name={name} onChange={onChange} type="checkbox" style={{ "display": "none" }} />
            <label className="cbx" htmlFor={id}>
                <span>
                    <svg width="18px" height="15px" viewBox="0 0 18 15">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                    </svg>
                </span>
                <span style={{ maxWidth: "90%" }}>{t(label)}</span>
            </label>
        </div>
    )
}

CheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default CheckBox;