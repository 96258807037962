import React from 'react';
import { withTranslation } from 'react-i18next';

class BannerColumn extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <h5><b>{t('banner.t1')}</b></h5>
                <ul>
                    <li>{t('banner.li1_1')}</li>
                    <li>{t('banner.li1_2')}</li>
                    <li>{t('banner.li1_3')}</li>
                </ul>
                <h5><b>{t('banner.t2')}</b></h5>
                <ul>
                    <li>{t('banner.li2_1')}</li>
                    <li>{t('banner.li2_2')}</li>
                    <li>{t('banner.li2_3')}</li>
                    <li>{t('banner.li2_4')}</li>
                    <li>{t('banner.li2_5')}</li>
                    <li>{t('banner.li2_6')}</li>
                </ul>
            </div>
        );
    }
}

export default withTranslation()(BannerColumn);