import * as types from './types';
import RegionsService from './regionsService';

const regionsActions={
    // Get regions
    getRegionsStarted: () => {
        return {
            type: types.GET_REGIONS_REQUEST
        }
    },
    getRegionsSuccess: (data) => {
        return {
            type: types.GET_REGIONS_SUCCESS,
            payload: data
        }
    },
    getRegionsFailed: () => {
        return {
            type: types.GET_REGIONS_FAILURE,
        }
    }
}


export const getRegions=()=>(dispatch)=>{
    dispatch(regionsActions.getRegionsStarted());
    return RegionsService.getRegions()
        .then((response) => {
            dispatch(regionsActions.getRegionsSuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(regionsActions.getRegionsFailed(err.response));
        })
}