import React, { Component } from "react";
import { FormGroup, Col, FormFeedback, Label, Input, Button, Alert } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import FullPageLoader from "../../components/loaders/FullPageLoader/FullPageLoader";
import { isEmpty } from "lodash";
import { MdClose } from "react-icons/md";

export class RegisterPopup extends Component {
    constructor(props) {
        super(props);

        let userPhone = localStorage.getItem("ownPhoneNumber");
        if (!userPhone)
            userPhone = '';

        this.state = {
            userPhone: userPhone,
            password: '',
            confirmCode: '',
            confirmationCodeSent: false,
            errors: {},
            loading: false,
            requestFailed: false,
            showPhoneLabel: true,
            topError: this.props.t('manufacturer.errorRegister')
        };
    }

    onUserInfoChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: {}
        });
    }

    onUserPhoneChange = (phone) => {
        if (phone == "3800") {
            phone = "380";
        }
        this.setState({
            userPhone: phone
        });
    }

    onUserPhoneFocus = () => {
        this.setState({
            showPhoneLabel: false
        });
    }
    onUserPhoneBlur = () => {
        this.setState({
            showPhoneLabel: true
        });
    }

    isValid() {
        let errors = {};
        if (this.state.userPhone == '') {
            errors.userPhone = this.props.t('basket.errors.phone');
        }
        if (!this.props.isAuthenticated) {
            if (this.state.password.length < 5) {
                errors.password = this.props.t('basket.errors.password');
            }
        }

        this.setState({
            errors: errors
        });

        return isEmpty(errors);
    }

    onApply = () => {
        if (this.isValid()) {
            localStorage.setItem("ownPhoneNumber", this.state.userPhone);
            if (!this.props.isAuthenticated) {
                this.setState({ loading: true, requestFailed: false, topError: this.props.t('manufacturer.errorRegister') });
                this.props.registerUser({
                    phone: this.state.userPhone,
                    password: this.state.password,
                    isExecutor: false,
                    registerFrom: this.props.registerFrom
                }, (isOk, data) => {
                    if (isOk) {
                        if (data.isConfirmed) {
                            this.setState({ loading: false });
                            this.sendContactRequest();
                        } else {
                            this.props.sendSmsVerification((isOk2, data) => {
                                if (isOk2) {
                                    this.setState({ loading: false, confirmationCodeSent: true });
                                } else {
                                    this.setState({ requestFailed: true, loading: false });
                                }
                            });
                        }
                    } else {
                        let topError = data.phone && data.phone == "PhoneExistentClient" ?
                            this.props.t('registerCommon.errors.PhoneExistentClient') : this.props.t('manufacturer.errorRegister');
                        this.setState({ requestFailed: true, loading: false, topError: topError });
                    }
                });
            } else {
                this.sendContactRequest();
            }
        }
    }

    onConfirmCode = () => {
        this.setState({ loading: true, errors: {} });
        this.props.verificateAccount(this.state.confirmCode, (isOk) => {
            if (isOk) {
                this.setState({ loading: false });
                this.sendContactRequest();
            } else {
                this.setState({
                    loading: false,
                    errors: {
                        confirmCode: this.props.t('basket.errors.confirmCode')
                    }
                });
            }
        });
    }

    sendContactRequest() {
        this.props.onSendContactRequest(this.state.userPhone);
    }

    render() {
        const { t, isAuthenticated, title } = this.props;
        const { requestFailed, loading, topError } = this.state;

        return (
            <div className="mp-popupBackground">
                <div className="mp-popupContainer">
                    {(loading) &&
                        <FullPageLoader />
                    }
                    {(requestFailed) &&
                        <Alert color="danger">
                            {topError}
                        </Alert>
                    }
                    <MdClose className="mp-popupClose" size="1.5em" onClick={this.props.onCloseClick} />
                    <h5 className="align-center">{title ? title : (isAuthenticated ? t('manufacturer.validateTitle2') : t('manufacturer.validateTitle1'))}</h5>
                    <FormGroup row className="mr-2">
                        <Label sm={3}>{t('basket.phone')}</Label>
                        <Col sm={9}>
                            <PhoneInput
                                inputClass="phone-input"
                                inputStyle={{ width: '100%' }}
                                country={'ua'}
                                preferredCountries={['ua', 'us', 'ca']}
                                name="userPhone"
                                value={this.state.userPhone}
                                onChange={this.onUserPhoneChange}
                                onFocus={this.onUserPhoneFocus}
                                onBlur={this.onUserPhoneBlur}
                            />
                            {this.state.errors.userPhone &&
                                <FormFeedback style={{ display: "block" }}>{this.state.errors.userPhone}</FormFeedback>
                            }
                            {this.state.showPhoneLabel && this.state.userPhone &&
                                <div><b>{t('basket.phoneValidLabel').replace('{0}', this.state.userPhone)}</b></div>
                            }
                        </Col>
                    </FormGroup>
                    {!this.props.isAuthenticated &&
                        <FormGroup row className="mt-2 mr-2">
                            <Label sm={3}>{t('basket.password')}</Label>
                            <Col sm={9}>
                                <Input type="password" name="password" value={this.state.password} onChange={this.onUserInfoChange} />
                                {this.state.errors.password &&
                                    <FormFeedback style={{ display: "block" }}>{this.state.errors.password}</FormFeedback>
                                }
                            </Col>
                        </FormGroup>
                    }
                    {this.state.confirmationCodeSent &&
                        <FormGroup row className="mt-2 mr-2">
                            <Label sm={3}>{t('basket.confirmCode')}</Label>
                            <Col sm={4}>
                                <Input type="number" name="confirmCode" value={this.state.confirmCode} onChange={this.onUserInfoChange} />
                                {this.state.errors.confirmCode &&
                                    <FormFeedback style={{ display: "block" }}>{this.state.errors.confirmCode}</FormFeedback>
                                }
                            </Col>
                            <Col sm={2}>
                                <Button
                                    className=""
                                    color="success"
                                    outline
                                    onClick={this.onConfirmCode}>
                                    {t('basket.confirmCodeButton')}
                                </Button>
                            </Col>
                        </FormGroup>
                    }
                    {!this.state.confirmationCodeSent &&
                        <div className="align-center">
                            <Button
                                className=""
                                color="success"
                                onClick={() => this.onApply()}>
                                {t('manufacturer.confirm')}
                            </Button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}