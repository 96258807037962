import React from 'react';
import NavMenu from '../NavMenu';
import { Container, Row, Col } from "reactstrap";
import './MainLayout.css'
import FooterLayout from '../footer/FooterLayout';

export default props => {
    return (
        <div>
            <NavMenu />
            <div style={{ width: "100%" }}>
                {props.children}
            </div>
            <FooterLayout />
        </div>
    );
}
