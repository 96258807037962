import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    failed: false,
    products: [],
    productDetails: null,
    lastSavedOrderId: null,
    isOwnProduct: false,
    productDetailsImages: []
});

export const myProjects3Reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        case types.SUCCESS: {
            return state.merge({
                loading: false
            });
        }
        case types.GET_MY_PROJECTS_SUCCESS: {
            return state.merge({
                loading: false,                
                products: action.payload
            });
        }
        case types.GET_PRODUCT_DETAILS_REQUEST: {
            return state.merge({
                loading: true,
                productDetails: null
            });
        }
        case types.GET_PRODUCT_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                productDetails: action.payload.product,
                isOwnProduct: action.payload.isOwnProduct
            });
        }

        case types.RELOAD_PRODUCT_ORDER_REQUEST: {
            return state.merge({
                //loading: true                
            });
        }
        case types.RELOAD_PRODUCT_ORDER_SUCCESS: {
            return state.merge({
                loading: false,
                productDetails: {
                    ...state.productDetails,
                    order: action.payload
                } 
            });
        }

        case types.SET_LAST_SAVED_ORDER_ID: {
            return state.merge({
                lastSavedOrderId: action.payload
            });
        }
        case types.SAVE_PRODUCT_IMAGE_SUCCESS: {
            return state.merge({
                loading: false
            });
        }

        case types.CHANGE_DETAILS_IMAGES_SUCCESS: {
            return state.merge({
                productDetailsImages: action.payload
            });
        }

        default: {
            return state;
        }
    }
}