import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';

const FullPageLoader = (props) => {
    return (
        <div className="fullPageLoader spinner-background">
            <div className="spinner-grow text-success" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            {props.text &&
                <div className="spinner-test" style={props.textStyle}>{props.text}</div>
            }
            {props.t && !props.text &&
                <div className="spinner-test">{props.t('loading')}</div>
            }
        </div>
    )
}

export default withTranslation()(FullPageLoader);

export { FullPageLoader as FullPageLoaderNoText };