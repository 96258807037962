// Auth token
export const getAuthToken=()=>{
    return getLocalStorageItem('authToken');
}

export const saveAuthToken=(token)=> {
    localStorage.setItem('authToken', token);
}


// Refresh token
export const getRefreshToken=()=> {
    return getLocalStorageItem('refreshToken');
}

export const saveRefreshToken=(token)=> {
    localStorage.setItem('refreshToken', token);
}


// Verif token
export const getVerificationToken=()=> {
    return getLocalStorageItem('verToken');
}
export const saveVerificationToken=(token)=> {
    localStorage.setItem('verToken', token);
}


//Remove token
export const removeVerificationToken=()=> {
    return removeStorageItem('verToken');
}

export const removeRefreshToken=()=> {
    return removeStorageItem('refreshToken');
}

export const removeAuthToken=()=> {
    return removeStorageItem('authToken');
}


// For all
const getLocalStorageItem=(name)=>{
    return localStorage.getItem(name);
}

const removeStorageItem=(name)=>{
    return localStorage.removeItem(name);
}

