import React, { Component } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AiFillEdit } from "react-icons/ai";
import './filtView.css';
import { IoIosCloseCircle, IoIosCamera, IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { isMobile } from "react-device-detect";
import { MdFilterList } from "react-icons/md";

export default class FiltrView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            selectedItem: this.props.startId ? this.props.startId : -1,
        }
    }

    toggle = () => {
        this.setState({ show: !this.state.show });
    }

    toggleDD = () => {
        if (this.state.selectedItem > 0 && this.state.show) {
            this.toggle();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startId != this.props.startId) {
            this.setState({ selectedItem: this.props.startId });
        }
    }

    onClick = (id) => {
        if (this.props.onChangeFiltr) {
            this.props.onChangeFiltr(id);
        }
        this.setState({ show: !this.state.show, selectedItem: id });
    }

    render() {
        const { t, items, showInHeader, title } = this.props;
        const { show, selectedItem } = this.state;
        let singleClassName = !isMobile ? "filt-single-container" : "filt-single-container-mobile";
        return (
            <>
                <div style={{ marginBottom: "0px" }}>
                    <div                        
                        className={!showInHeader ? (!isMobile ? "filt-toggle" : "filt-toggle-mobile") : "filt-toggle-header"}
                        onClick={this.toggle}>
                        {!showInHeader ?
                            <span style={{ cursor: "pointer", color: "white" }}>{!show ? <IoMdArrowDropdown /> : ""}{title ? title : t('catalogFiltr.title')}</span>
                            :
                            <span style={{ cursor: "pointer", color: "white" }}>
                                <MdFilterList size="2.5em" />
                            </span>
                        }
                    </div>
                    {show &&
                        <div className={!isMobile ? "filt-menu" : "filt-menu-mobile"} style={{}}>
                            <div className="filt-close">
                                <span className="filtr-title" onClick={this.toggle}><IoMdArrowDropup />{title ? title : t('catalogFiltr.title')}</span>
                            </div>
                            <div className="filtr-all-container">
                                {items &&
                                    items.map((item, itemIndex) => (
                                        <div
                                            className={item.id == selectedItem ? "selected " + singleClassName : singleClassName}
                                            key={itemIndex}
                                            onClick={() => this.onClick(item.id)}
                                        >
                                            <LazyLoadImage className="filt-item-image" src={item.image} />
                                            <span className="filt-item-name">{item.name}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    }
                </div>
            </>

        );
    }

}