import React, { Component } from "react";
import { FormGroup, Col, Row, FormFeedback, Label, Input, Button, Alert } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import FullPageLoader from "../../components/loaders/FullPageLoader/FullPageLoader";
import { isEmpty } from "lodash";
import { MdClose } from "react-icons/md";
import CheckBox from "../../components/fields/checkbox/CheckBox";

export class ContactManufacturerPopup extends Component {
    constructor(props) {
        super(props);

        let userPhone = localStorage.getItem("ownPhoneNumber");
        if (!userPhone)
            userPhone = '';

        this.state = {
            userPhone: userPhone,            
            showPhoneLabel: true,
            isCallAtTime: false,
            callTimeFrom: '',
            callTimeTo: '',
            errors: {},
            topError: this.props.t('manufacturer.errorRegister')
        };
    }

    onUserInfoChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: {}
        });
    }

    onUserPhoneChange = (phone) => {
        if (phone == "3800") {
            phone = "380";
        }
        this.setState({
            userPhone: phone
        });
    }

    onUserPhoneFocus = () => {
        this.setState({
            showPhoneLabel: false
        });
    }
    onUserPhoneBlur = () => {
        this.setState({
            showPhoneLabel: true
        });
    }

    onChangeCallAtTime = () => {
        this.setState({
            isCallAtTime: !this.state.isCallAtTime
        });
    }

    isValid() {
        let errors = {};
        if (this.state.userPhone == '') {
            errors.userPhone = this.props.t('basket.errors.phone');
        }

        this.setState({
            errors: errors
        });

        return isEmpty(errors);
    }

    onApply = () => {
        if (this.isValid()) {
            localStorage.setItem("ownPhoneNumber", this.state.userPhone);
            this.props.onSendContactRequest(this.state.userPhone, this.state.callTimeFrom, this.state.callTimeTo);
        }
    }

    render() {
        const { t, isAuthenticated, title } = this.props;
        const { requestFailed, loading, topError, isCallAtTime, callTimeFrom, callTimeTo } = this.state;

        return (
            <div className="mp-popupBackground">
                <div className="mp-popupContainer">
                    {(loading) &&
                        <FullPageLoader />
                    }
                    {(requestFailed) &&
                        <Alert color="danger">
                            {topError}
                        </Alert>
                    }
                    <MdClose className="mp-popupClose" size="1.5em" onClick={this.props.onCloseClick} />
                    <h5 className="align-center">{title ? title : (isAuthenticated ? t('manufacturer.validateTitle2') : t('manufacturer.validateTitle1'))}</h5>
                    <FormGroup row className="mr-2">
                        <Label sm={3}>{t('basket.phone')}</Label>
                        <Col sm={9}>
                            <PhoneInput
                                inputClass="phone-input"
                                inputStyle={{ width: '100%' }}
                                country={'ua'}
                                preferredCountries={['ua', 'us', 'ca']}
                                name="userPhone"
                                value={this.state.userPhone}
                                onChange={this.onUserPhoneChange}
                                onFocus={this.onUserPhoneFocus}
                                onBlur={this.onUserPhoneBlur}
                            />
                            {this.state.errors.userPhone &&
                                <FormFeedback style={{ display: "block" }}>{this.state.errors.userPhone}</FormFeedback>
                            }
                            {this.state.showPhoneLabel && this.state.userPhone &&
                                <div><b>{t('basket.phoneValidLabel').replace('{0}', this.state.userPhone)}</b></div>
                            }
                        </Col>
                    </FormGroup>
                    <Row className="mt-2">
                        <Col xs="auto">
                            <CheckBox label={t('manufacturer.callAtTime')} value={isCallAtTime} onChange={this.onChangeCallAtTime} />
                        </Col>
                    </Row>
                    {isCallAtTime &&
                        <Row>
                            <Col xs="auto">{t('manufacturer.from')}</Col>
                            <Col xs="auto">
                                <Input type="time" value={callTimeFrom} name="callTimeFrom" onChange={this.onUserInfoChange} />
                            </Col>
                            <Col xs="auto">{t('manufacturer.to')}</Col>
                            <Col xs="auto">
                                <Input type="time" value={callTimeTo} name="callTimeTo" onChange={this.onUserInfoChange} />
                            </Col>
                        </Row>
                    }
                    <div className="align-center mt-2">
                        <Button
                            className=""
                            color="success"
                            onClick={() => this.onApply()}>
                            {t('manufacturer.confirm')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}