import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    messages: [],
    user: null,
    users: [],
    autoMessage: null
});

export const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        //#region GET_MESSAGES
        case types.GET_MESSAGES_REQUEST: {
            return state.merge({
                loading: true,
                messages: [],
                user: null
            });
        }
        case types.GET_MESSAGES_SUCCESS: {
            let user = action.payload.userTo;
            let users = state.users.filter(u => u.id != user.id);
            users = [user, ...users];

            return state.merge({
                loading: false,
                success: true,
                messages: action.payload.messages,
                user: user,
                users: users
            });
        }
        case types.GET_MESSAGES_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_USERS
        case types.GET_USERS_REQUEST: {
            return state.merge({
                messages: [],
                loading: true
            });
        }
        case types.GET_USERS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                users: action.payload
            });
        }
        case types.GET_USERS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        case types.SET_AUTO_MESSAGE: {
            return state.merge({
                autoMessage: action.payload
            });
        }

        default: {
            return state;
        }
    }
}