export const GET_EXECUTER_DETAILS_REQUEST = 'project/GET_EXECUTER_DETAILS_REQUEST';
export const GET_EXECUTER_DETAILS_SUCCESS = 'project/GET_EXECUTER_DETAILS_SUCCESS';
export const GET_EXECUTER_DETAILS_FAILURE = 'project/GET_EXECUTER_DETAILS_FAILURE';

export const GET_PUBLICATIONS_REQUEST = 'project/GET_PUBLICATIONS_REQUEST';
export const GET_PUBLICATIONS_SUCCESS = 'project/GET_PUBLICATIONS_SUCCESS';
export const GET_PUBLICATIONS_FAILURE = 'project/GET_PUBLICATIONS_FAILURE';

export const SEND_INVITATION_REQUEST = 'project/SEND_INVITATION_REQUEST';
export const SEND_INVITATION_SUCCESS = 'project/SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_FAILURE = 'project/SEND_INVITATION_FAILURE';