import React from 'react';
import * as UserRoles from '../constants/specialty';
import { ProductTypes } from '../constants/productTypes';
import isEmpty from 'lodash/isEmpty';

export default class Helper {
    static AndroidMobileApp = 'https://play.google.com/store/apps/details?id=com.emaginaria.emaginariamobile';
    static EditorTimeParam = new Date().getTime();
    static ElementWholesaleLimit = 5000;

    static roleToLocalString = (role, t) => {
        switch (role) {
            case UserRoles.designElements:
                return t('projects.services.design');
            case UserRoles.productionElements:
                return t('projects.services.manufacture');
            case UserRoles.setupElements:
                return t('projects.services.install');
        }
        return '';
    }

    static getProducatSizeText = (product) => {
        if (product.dimention == null || isEmpty(product.dimention)) {
            return "";
        }

        switch (product.subTypeKey) {
            case ProductTypes.SubtypeBalcon_P:
                return product.dimention.depth + "x" + product.dimention.width;
            case ProductTypes.SubtypeBalcon_French:
                return product.dimention.depth + "x" + product.dimention.width;
        }
        if (product.dimention != null) {
            let str = '';
            if (product.dimention.depth != null && product.dimention.depth != 0) {
                str += product.dimention.depth;
            }
            if (product.dimention.width != null && product.dimention.width != 0) {
                if (str.length != 0) {
                    str += "x";
                }
                str += product.dimention.width;
            }
            if (product.dimention.height != null && product.dimention.height != 0) {
                if (str.length != 0) {
                    str += "x";
                }
                str += product.dimention.height;
            }
            return str;
        }
        return "";
    }

    static formatWithNewLine = (text) => {
        if (text == null)
            return '';
        let tarr = text.split('\n');
        return tarr.map((item, index) =>
            (
                <>
                    <span key={index}>{item}</span>
                    {(index != tarr.length - 1) && <br />}
                </>
            )
        )
    }

    static isShow3dOnDemand = () => {
        return window.navigator.deviceMemory == null || window.navigator.deviceMemory < 3;
    }

    static getEmbeddingLink = (key, lang, productType) => {
        return `${window.location.protocol}//${window.location.host}/embedding/${key}/${lang}/${productType ? productType : ''}`;
        return `${window.location.protocol}//${window.location.host}/embedding/key=${key}&lang=${lang}`;
        
    }

    static getMyCompanySiteLink = (name) => {
        return `${window.location.protocol}//${window.location.host}/company/${name}`;
    }
    
    static getMyCompanySiteLinkEdit = (name) => {
        return `${window.location.protocol}//${window.location.host}/companyedit/${name}`;
    }
    
    static editorPreviewLink = (productId) => {
        return `${window.location.protocol}//editor.${window.location.host}?productId=${productId}&nocontrols=true&time=${Helper.EditorTimeParam}`;
        //return `https://editor.user8945.realhost-free.net/?productId=${productId}&nocontrols=true&time=${Helper.EditorTimeParam}`;
    }

    static editorEditLink = (productId, isLightMode) => {
        return `${window.location.protocol}//editor.${window.location.host}?productId=${productId}&time=${Helper.EditorTimeParam}${isLightMode ? "&lightEditMode=true" : ""}`;
        //return `https://editor.user8945.realhost-free.net/?productId=${productId}${isLightMode ? "&lightEditMode=true" : ""}`;
    }

    static editorLinkAutoBuild = (autorotate) => {
        return `${window.location.protocol}//editor.${window.location.host}?autoBuildPreview=true&nocontrols=true${(autorotate ? '' : '&norotation=true')}&time=${Helper.EditorTimeParam}`;
        //return `https://editor.user8945.realhost-free.net/?autoBuildPreview=true&nocontrols=true${(autorotate ? '' : '&norotation=true')}`;
        //return 'https://editor.simplicad.com/?autoBuildPreview=true&nocontrols=true&norotation=true';
    }

    static editorLinkElementPreview = (elementId) => {
        return `${window.location.protocol}//editor.${window.location.host}?elementId=${elementId}&nocontrols=true&time=${Helper.EditorTimeParam}`;
        //return `https://editor.user8945.realhost-free.net/?elementId=${elementId}&nocontrols=true&time=${Helper.EditorTimeParam}`;
    }

    static mobileARView(productId) {
        return `simplicad://arview?productId=${productId}`;
    }

    static saveDemoProductId = (product) => {
        window.localStorage.setItem("demoProductId", product.id);
        window.localStorage.setItem("demoProductTypeId", product.productTypeId);
    }

    static getSiteCompanyImgPath = (img) => {
        return "api/Content/SiteCompany/" + img;
    }

    static getBase64 = (file, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(file);
    }

    static getDemoProductId = () => {
        return window.localStorage.getItem("demoProductId");
    }
    static getDemoProductTypeId = () => {
        return window.localStorage.getItem("demoProductTypeId");
    }

    static deleteDemoProductId = () => {
        window.localStorage.removeItem("demoProductId");
    }
}