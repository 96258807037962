import React from 'react';
import { Media, Row, Col, Button, Input } from 'reactstrap';
import { toastr } from 'react-redux-toastr'
import Helper from '../../utils/helper';

import { IoIosCloseCircle, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './ImageScroller.css';

export default class ImageScroller extends React.Component {

    constructor(props) {
        super(props);
        this.maxWidth = this.maxWidth.bind(this);
        this.state = {
            imageMaxWidth: props.selectFirst ? this.props.images[0].id : -1,
            show: this.props.show ? this.props.show : false
        };

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    maxWidth = (index) => {
        if (this.state.imageMaxWidth == index) {
            this.setState({ imageMaxWidth: -1, show: false });
            if (this.props.onHide)
                this.props.onHide();
        }
        else {
            this.setState({ imageMaxWidth: index, show: true });
        }
    }

    onBgClick = (e) => {
        this.setState({ imageMaxWidth: -1, show: false });
        if (this.props.onHide)
            this.props.onHide(e);
    }

    render() {
        const { images } = this.props;
        const { show, imageMaxWidth } = this.state;
        const length = images != null ? images.length : 0;
        return (
            <div className={"image-full-bg" + (!show ? " hide" : "")} onClick={this.onBgClick}>
                <div className="image-full-div">
                    {length > 0 &&
                        images.map((img, index) => {
                            return <div key={index} className={imageMaxWidth == img.id ? "" : "hide"}>
                                {index > 0 &&
                                    <div className="left-arrow-handler"
                                        onClick={(e) => { this.setState({ imageMaxWidth: images[index - 1].id }); e.stopPropagation(); }}>
                                        <IoIosArrowBack size="2em" className="arrow" />
                                    </div>
                                }
                                <img className="image-full" alt={this.props.altBuilder(img)}
                                    src={this.props.srcLinkBuilder(img)}
                                    onClick={(e) => { this.maxWidth(img.id); e.stopPropagation(); }} />
                                {index < length - 1 &&
                                    <div className="right-arrow-handler"
                                        onClick={(e) => { this.setState({ imageMaxWidth: images[index + 1].id }); e.stopPropagation(); }}>
                                        <IoIosArrowForward size="2em" className="arrow" />
                                    </div>
                                }
                            </div>
                        })
                    }
                    {length > 1 ?
                        <div className="image-portfolio-small-container">
                            {images.map((img2, index2) => {
                                if (img2.id != imageMaxWidth) {
                                    return <img
                                        key={"key-" + index2}
                                        className="image-portfolio-small"
                                        alt={this.props.altBuilder(img2)}
                                        src={this.props.srcLinkBuilder(img2)}
                                        onClick={(e) => { this.maxWidth(img2.id); e.stopPropagation(); }} />
                                }
                                else {
                                    return <img
                                        key={"key-" + index2}
                                        className="image-portfolio-small selected-image-portfolio-small"
                                        alt={this.props.altBuilder(img2)}
                                        src={this.props.srcLinkBuilder(img2)}
                                        onClick={(e) => { this.maxWidth(img2.id); e.stopPropagation(); }} />
                                }
                            })}
                        </div>
                        :
                        <div />
                    }
                </div>
            </div>
        )
    }
}