import Immutable from 'seamless-immutable';

import * as types from './types.js';
import { PublicationTypes } from '../../constants/publicationTypes';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    project: null,
    forceDimention: false,
    executers: [],
    isSearchDesigner: false,
    propositions: [],
    designDoneError: null
});

export const projectDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        //#region GET_DETAILS
        case types.GET_DETAILS_REQUEST: {
            return state.merge({
                loading: true,
                forceDimention: false
            });
        }
        case types.GET_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                project: action.payload
            });
        }
        case types.GET_DETAILS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region 
        case types.SET_PRODUCT_DIMENTION_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.SET_PRODUCT_DIMENTION_SUCCESS: {
            let productNew = action.payload;
            if (state.project != null) {
                let products = [...state.project.products];
                let index = products.findIndex(p => p.id == productNew.id);
                products[index] = productNew;
                return state.merge({
                    loading: false,
                    success: true,
                    project: {
                        ...state.project,
                        products: products
                    }
                });
            }
        }
        case types.SET_PRODUCT_DIMENTION_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region 
        case types.SET_DIMENTION_DONE_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.SET_DIMENTION_DONE_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                project: action.payload
            });
        }
        case types.SET_DIMENTION_DONE_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region 
        case types.CREATE_PUBLICATION_REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.CREATE_PUBLICATION_SUCCESS: {
            let project = { ...state.project };
            if (action.payload.searchFor == PublicationTypes.Design)
                project.designPublication = action.payload;
            return state.merge({
                loading: false,
                success: true,
                project: project
            });
        }
        case types.CREATE_PUBLICATION_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region 
        case types.FIND_EXECUTERS_REQUEST: {
            return state.merge({
                loading: true,
                executers: []
            });
        }
        case types.FIND_EXECUTERS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                executers: action.payload
            });
        }
        case types.CREATE_PUBLICATION_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region SET_DESIGN_VIEW_MODE
        case types.SET_DESIGN_VIEW_MODE: {
            return state.merge({                
                isSearchDesigner: action.payload
            });
        }
        //#endregion

        //#region GET_PROPOSITIONS
        case types.GET_PROPOSITIONS_REQUEST: {
            return state.merge({
                loading: true,
                propositions: []
            });
        }
        case types.GET_PROPOSITIONS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                propositions: action.payload
            });
        }
        case types.GET_PROPOSITIONS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region ACCEPT_PROPOSITION
        case types.ACCEPT_PROPOSITION_REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.ACCEPT_PROPOSITION_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
            });
        }
        case types.ACCEPT_PROPOSITION_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region SET_CONTRACT_DONE
        case types.SET_CONTRACT_DONE_REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.SET_CONTRACT_DONE_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
            });
        }
        case types.SET_CONTRACT_DONE_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region RETURN_BACK_CONTRACT
        case types.RETURN_BACK_CONTRACT_REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.RETURN_BACK_CONTRACT_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
            });
        }
        case types.RETURN_BACK_CONTRACT_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region SET_SELF_DESIGN
        case types.SET_SELF_DESIGN_REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.SET_SELF_DESIGN_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
            });
        }
        case types.SET_SELF_DESIGN_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region SET_DESIGN_DONE
        case types.SET_DESIGN_DONE_REQUEST: {
            return state.merge({
                loading: true,
                designDoneError: null
            });
        }
        case types.SET_DESIGN_DONE_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
            });
        }
        case types.SET_DESIGN_DONE_FAILURE: {
            return state.merge({
                loading: false,
                designDoneError: action.payload
            });
        }
        //#endregion

        //#region SET_PRODUCTION_DONE
        case types.SET_PRODUCTION_DONE_REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.SET_PRODUCTION_DONE_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
            });
        }
        case types.SET_PRODUCTION_DONE_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region SET_INSTALLATION_DONE
        case types.SET_INSTALLATION_DONE_REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.SET_INSTALLATION_DONE_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
            });
        }
        case types.SET_INSTALLATION_DONE_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        case types.SET_FORCE_DIMENTION: {
            return state.merge({
                forceDimention: action.value
            });
        }

        default: {
            return state;
        }
    }
}