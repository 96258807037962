export const REQUEST = 'autoBuildEdit/REQUEST';
export const FAILURE = 'autoBuildEdit/FAILURE';
export const SUCCESS = 'autoBuildEdit/SUCCESS';
export const REQUEST_TEMPLATE = 'autoBuildEdit/REQUEST_TEMPLATE';

export const GET_MYSKETCHES_SUCCESS = 'autoBuildEdit/GET_MYSKETCHES_SUCCESS';
export const GET_PRODUCT_EDITOR_STATE_SUCCESS = 'autoBuildEdit/GET_PRODUCT_EDITOR_STATE_SUCCESS';
export const SAVE_SKETCH_SUCCESS = 'autoBuildEdit/SAVE_SKETCH_SUCCESS';
export const GET_SKETCH_DATA_SUCCESS = 'autoBuildEdit/GET_SKETCH_DATA_SUCCESS';
export const DELETE_SKETCH_SUCCESS = 'autoBuildEdit/DELETE_SKETCH_SUCCESS';

export const COPY_SKETCH_SUCCESS = 'autoBuildEdit/COPY_SKETCH_SUCCESS';
export const TOGGLE_TEXTURE_VARIANT = "autoBuildEdit/TOGGLE_TEXTURE_VARIANT";

export const GET_SKETCH_METALS_SUCCESS = 'autoBuild/GET_SKETCH_METALS_SUCCESS';

export const GET_TEMPLATE_SUCCESS = 'autoBuild/GET_TEMPLATE_SUCCESS';

export const SAVE_END = 'autoBuild/SAVE_END';
export const GET_MYTEMPLATES_SUCCESS = 'autoBuildEdit/GET_MYTEMPLATES_SUCCESS';
export const SAVE_AUTOBUILD_TEMPLATE_SUCCESS = 'autoBuildEdit/SAVE_AUTOBUILD_TEMPLATE_SUCCESS';
export const GET_AUTOBUILD_TEMPLATE_DETAILS_SUCCESS = 'autoBuildEdit/GET_AUTOBUILD_TEMPLATE_DETAILS_SUCCESS';
export const DELETE_AUTOBUILD_TEMPLATE_SUCCESS = 'autoBuildEdit/DELETE_AUTOBUILD_TEMPLATE_SUCCESS';

export const GENERATE_HIDDEN_PRODUCT = "autoBuildEdit/GENERATE_HIDDEN_PRODUCT";
export const MOVE_PRODUCT_TO_NEW_PROJECT = "autoBuildEdit/MOVE_PRODUCT_TO_NEW_PROJECT";

export const GET_ELEMENTS_FOR_SKETCHES_SUCCESS = "autoBuildEdit/GET_ELEMENTS_FOR_SKETCHES_SUCCESS";