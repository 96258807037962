import axios from "axios";
import * as types from './types';

export const getManufacturers = (top,type) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    let path = `api/home/GetManufacturers`;
    if (top) {
        path += `?top=${top}`;
        if (type) {
            path += `&type=${type}`;
        }
    }
    else {
        if (type) {
            path += `?type=${type}`;
        }
    }
    return axios.get(path)
        .then((response) => {
            dispatch({
                type: types.GET_MANUFACTORERS_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getPriceManufacturers = (regionId, productId, paintId) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/home/GetPriceManufacturers?` + (regionId ? `regionId=${regionId}&` : '') + (productId ? `productId=${productId}&` : '') + (paintId ? `paintId=${paintId}&` : ``))
        .then((response) => {
            dispatch({
                type: types.GET_PRICE_MANUFACTORERS_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const sendContactRequest = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/home/SendContactRequest`, data)
        .then((response) => {
            dispatch({
                type: types.SEND_CONTACT_REQUEST_SUCCESS,
                payload: response.data
            });
            if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback) callback(false);
        })
}

export const becomeManufacturerRequest = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/home/BecomeManufacturerRequest`, data)
        .then((response) => {
            dispatch({
                type: types.BECOME_MANUFACTURER_REQUEST_SUCCESS,
                payload: response.data
            });
            if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback) callback(false);
        })
}