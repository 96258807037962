import * as uniqueId from "lodash/uniqueId";
import MathHelper from "../../../utils/MathHelper";
import { EditSketchConstants } from "../../../autoBuildEdit/EditSketchConstants";
import { FormulaHelper } from "../../../autoBuildEdit/FormulaHelper";
import * as isEmpty from "lodash/isEmpty";
import { toastr } from "react-redux-toastr";
import { pick } from "lodash";


export default class SectionHelper {
    static SECTION_STRAIGHT = "section_straight";
    static SECTION_STRAIGHT2 = "section_straight2";
    static SECTION_STAIRS = "section_stairs";
    static SECTION_STAIRS2 = "section_stairs2";
    static SECTION_LATTICE = "section_lattice";
    static SECTION_GATE = "section_gate";
    static SECTION_ANGLE = "angle";
    static SECTION_INDENT = "indent";
    static SECTION_CLIMB = "climb";
    static SECTION_CANOPY = "section_canopy";
    static SECTION_GO_START = "go_start";

    static TRANSFORM_ROTATION = "rotation";
    static TRANSFORM_OFFSET = "offset";

    static isInputValid(e, t) {
        if (e.target.name == 'length') {
            if (e.target.value > 20000) {
                toastr.warning('', t('editSketch.lengError'));
                return false;
            }
        }
        if (e.target.name == 'height') {
            if (e.target.value > 10000) {
                toastr.warning('', t('editSketch.heigError'));
                return false;
            }
        }
        return true;
    }

    static addNewVariant(sketch, metalList, name) {
        let return_obj = { id: 0, name: name, objects: [] };
        sketch.objects.map(item => {
            let newItemId = metalList.find(i => i.id == parseInt(item.id));
            let newItem = item.type == "metalrolling" && newItemId != null ? { ...item, id: newItemId.texturedMetalId } : { ...item };
            return_obj.objects.push(newItem);
        })

        return return_obj;
    }

    static mirrorObject(sections, sectionT, i) {
        if (sections[i].isMirror) {
            let sectionMirror = {
                ...sectionT,
                sketch: {
                    height: sectionT.sketch.height,
                    objects: []
                }
            };
            sectionT.sketch.objects.forEach((item, itemIndex) => {
                let newItem = {};
                if (!item.skipInMirror) {
                    newItem = item;
                    if (!item.isPillar || item.isPillarA) {
                        if (!item.donotMirror) {
                            if (typeof (newItem.rotation.y) == "object") {
                                newItem.rotation.y = newItem.rotation.y.map(yy => (yy + Math.PI));
                            } else {
                                newItem.rotation.y += Math.PI;
                            }                            
                        }
                        if (typeof (item.position.x) == "object") {
                            let x = [];
                            newItem.position.x.map((itemX) => {
                                x.push(sectionT.width - itemX);
                            });
                            newItem.position.x = x;
                        }
                        else {
                            newItem.position.x = sectionT.width - item.position.x;
                        }
                    }
                    sectionMirror.sketch.objects.push(newItem);
                }
            });
            sectionT = sectionMirror;
        }

        if (sections[i].isFaceRevert) {
            let sectionMirror = {
                ...sectionT,
                sketch: {
                    height: sectionT.sketch.height,
                    objects: []
                }
            };
            sectionT.sketch.objects.forEach((item, itemIndex) => {
                let newItem = {};
                //if (!item.skipInMirror) {
                    newItem = item;
                    //if (!item.donotMirror) {
                        if (typeof (newItem.rotation.y) == "object") {
                            newItem.rotation.y = newItem.rotation.y.map(yy => -yy);
                        } else {
                            newItem.rotation.y = -newItem.rotation.y;
                        }
                        if (typeof (newItem.rotation.x) == "object") {
                            newItem.rotation.x = newItem.rotation.x.map(x => -x);
                        } else {
                            newItem.rotation.x = -newItem.rotation.x;
                        }
                        if (typeof (item.position.z) == "object") {
                            newItem.position.z = newItem.position.z.map(z => -z);
                        } else {
                            newItem.position.z = -newItem.position.z;
                        }
                    //}                    
                    sectionMirror.sketch.objects.push(newItem);
                //}
            });
            sectionT = sectionMirror;
        }
        return sectionT;
    }

    static getNewSection(type, keyName, prevSection) {
        let section = {
            id: uniqueId('section-'),
            type: type
        };
        let length = 2000;
        let height = 1000;
        let depth = 1000;
        switch (keyName) {
            case "lattice":
                length = 1200;
                height = 1400;
                break;

            case "fence":
                height = 2000;
                length = 2500;
                break;

            case "lawn":
                length = 2000;
                break;

            case "gate":
                if (prevSection != null) {
                    prevSection.height != null ? height = prevSection.height : height = height;
                    prevSection.length != null ? length = prevSection.length : length = length;
                }
                else {
                    length = 1800;
                    height = 2000;
                }
                break;
        }

        switch (type) {
            case this.SECTION_STRAIGHT:
                section.length = length;
                section.pillar1 = true;
                section.pillar2 = true;
                section.pillarA = false;
                section.floatHeight = false;
                section.pillarACount = 0;
                break;

            case this.SECTION_STRAIGHT2:
                section.length = length;
                section.height = height;
                section.pillar1 = true;
                section.pillar2 = true;
                section.pillarA = false;
                section.floatHeight = true;
                section.pillarACount = 0;
                break;

            case this.SECTION_LATTICE:
                section.length = length;
                section.height = height;
                section.floatHeight = true;
                break;

            case this.SECTION_CANOPY:
                section.length = length;
                section.height = height;
                section.depth = depth;
                section.floatHeight = true;
                break;

            case this.SECTION_GATE:
                section.length = length;
                section.height = height;
                section.pillar1 = true;
                section.pillar2 = true;
                section.floatHeight = true;
                break;

            case this.SECTION_STAIRS:
                section.length = 1400;
                section.pillar1 = true;
                section.pillar2 = true;
                section.pillarA = false;
                section.floatHeight = false;
                section.pillarACount = 0;
                section.l1 = 50;
                section.l2 = 50;
                section.hStairs = 600;
                section.stairsCount = 4;
                break;
            case this.SECTION_STAIRS2:
                section.length = 900;
                section.pillar1 = true;
                section.pillar2 = true;
                section.pillarA = false;
                section.floatHeight = false;
                section.pillarACount = 0;
                section.l1 = 200;
                section.l2 = 200;
                section.hStairs = 600;
                section.stairsCount = 4;
                break;

            case this.SECTION_ANGLE:
                section.angle = 90;
                break;
            case this.SECTION_CLIMB:
                section.indent = 200;
                break;
            case this.SECTION_INDENT:
                section.indent = 200;
                break;
        }

        return section;
    }

    static buildTemplate(sections, sketch, variantId) {
        if (variantId != null && variantId != -1 && sketch.variants) {
            sketch = {
                ...sketch,
                objects: [...sketch.objects]
            };
            const variant = sketch.variants.find(v => v.id == variantId);
            if (variant != null) {
                let variantObjects = variant.objects;
                for (let i = 0; i < variantObjects.length; i++) {
                    let oIndex = sketch.objects.findIndex(o => o.key == variantObjects[i].key);
                    if (oIndex >= 0) {
                        sketch.objects[oIndex] = variantObjects[i];
                    }
                }

                sketch.sectionRotation = { ...sketch.sectionRotation };
                if (variant.rotateSectionCenter)
                    sketch.sectionRotation.center = variant.rotateSectionCenter;
                if (variant.handrailWidth)
                    sketch.sectionRotation.handrailWidth = variant.handrailWidth;
                if (variant.pillarWidth)
                    sketch.sectionRotation.pillarWidth = variant.pillarWidth;
                if (variant.pillarAWidth)
                    sketch.sectionRotation.pillarAWidth = variant.pillarAWidth;
            }
        }

        let template = {
            sections: []
        };
        let sectionRotation = {
            x: 0,
            y: 0,
            z: 0
        };
        let sectionOffset = {
            x: 0,
            y: 0,
            z: 0
        }
        let sectionTransform = [];
        let sectionRotationY = 0;
        let prevSection = null;
        let prevSectionYOffet = 0;
        for (let i = 0; i < sections.length; i++) {
            if (sections[i].pillarACount) {
                sections[i].pillarACount = parseInt(sections[i].pillarACount);
            }
            switch (sections[i].type) {
                case this.SECTION_STRAIGHT:
                case this.SECTION_LATTICE:
                case this.SECTION_STRAIGHT2:
                case this.SECTION_STAIRS:
                case this.SECTION_STAIRS2:
                case this.SECTION_GATE:
                case this.SECTION_CANOPY:
                    let hidePillar1 = false;
                    if (prevSection != null) {
                        hidePillar1 = prevSection.pillar2;
                        if (prevSection.type == this.SECTION_STAIRS || prevSection.type == this.SECTION_STAIRS2) {
                            hidePillar1 = false;
                        }
                        const indent = sectionTransform.find(t => t.type == SectionHelper.TRANSFORM_OFFSET && !t.isPillarOffset);
                        if (indent) {
                            hidePillar1 = false;
                        }
                    }
                    let sectionYOffet = prevSectionYOffet;
                    prevSectionYOffet = 0;
                    let sectionT = null;
                    if (sections[i].type == this.SECTION_STAIRS || sections[i].type == this.SECTION_STAIRS2) {
                        let hidePillar2 = false;
                        let nextSection = sections.filter((s, si) => si > i)
                            .find(s => s.type == this.SECTION_STRAIGHT || s.type == this.SECTION_STRAIGHT2 || s.type == this.SECTION_STAIRS || s.type == this.SECTION_STAIRS2 || s.type == this.SECTION_INDENT);
                        if (nextSection != null && nextSection.type == this.SECTION_STRAIGHT) {
                            hidePillar2 = true;
                        }
                        const contentBuilder = new SectionContentBuilder();
                        sectionT = contentBuilder.buildTemplateSectionStairs(sections[i], sketch, hidePillar1, hidePillar2, sectionRotationY);
                        prevSectionYOffet = contentBuilder.hStairs;
                    } else {
                        sectionT = new SectionContentBuilder().buildTemplateSection(sections[i], sketch, hidePillar1, sectionRotationY, (i == 0), (i == sections.length - 1));
                    }


                    if (sectionYOffet != 0) {
                        sectionTransform.push({
                            type: SectionHelper.TRANSFORM_OFFSET,
                            x: 0,
                            y: sectionYOffet,
                            z: 0
                        });
                    }
                    sectionTransform.push(this._getSectionOffset(sketch, sectionRotationY));
                    sectionT.transform = sectionTransform;


                    //sectionT.rotation = sectionRotation;
                    //sectionT.offset = this._getSectionOffset(sketch, sectionT.rotation.y);
                    //sectionT.offset.y += sectionYOffet;
                    //sectionT.offset.x += sectionOffset.x;
                    //sectionT.offset.y += sectionOffset.y;
                    //if (sectionOffset.resetToZero)
                    //    sectionT.offset.resetToZero = sectionOffset.resetToZero;
                    //sectionOffset = {
                    //    x: 0,
                    //    y: 0,
                    //    z: 0
                    //};
                    sectionT = SectionHelper.mirrorObject(sections, sectionT, i);
                    template.sections.push(sectionT);
                    prevSection = sections[i];
                    //sectionRotation = {
                    //    x: 0,
                    //    y: 0,
                    //    z: 0
                    //};
                    sectionTransform = [];
                    sectionRotationY = 0;
                    break;
                case this.SECTION_ANGLE:
                    const r = {
                        type: SectionHelper.TRANSFORM_ROTATION,
                        x: 0,
                        y: MathHelper.degreeToRad(parseInt(sections[i].angle)),
                        z: 0
                    }
                    sectionTransform.push(r);
                    sectionRotationY = r.y;
                    //sectionRotation.y += MathHelper.degreeToRad(parseInt(sections[i].angle));
                    break;
                case this.SECTION_CLIMB:
                    sectionTransform.push({
                        type: SectionHelper.TRANSFORM_OFFSET,
                        x: 0,
                        y: MathHelper.mmToMeters(parseInt(sections[i].indent)),
                        z: 0
                    });
                    //sectionOffset.y += MathHelper.mmToMeters(parseInt(sections[i].indent));
                    break;
                case this.SECTION_INDENT:
                    sectionTransform.push({
                        type: SectionHelper.TRANSFORM_OFFSET,
                        x: MathHelper.mmToMeters(parseInt(sections[i].indent)),
                        y: 0,
                        z: 0
                    });
                    //sectionOffset.x += MathHelper.mmToMeters(parseInt(sections[i].indent));
                    break;
                case this.SECTION_GO_START:
                    sectionTransform.push({
                        type: SectionHelper.TRANSFORM_OFFSET,
                        x: 0,
                        y: 0,
                        z: 0,
                        resetToZero: true
                    });
                    //sectionOffset.resetToZero = true;
                    break;
                default: //do we need it?
                    let hidePillar1_ = false;
                    if (prevSection != null) {
                        hidePillar1_ = prevSection.pillar2;
                    }
                    let sectionT_ = new SectionContentBuilder().buildTemplateSection(sections[i], sketch, hidePillar1_);
                    sectionT_.rotation = sectionRotation;
                    template.sections.push(sectionT_);
                    prevSection = sections[i];
                    sectionRotation = {
                        x: 0,
                        y: 0,
                        z: 0
                    };
                    break;
            }
        }
        return template;
    }

    static _getSectionOffset(sketch, angle) {
        if (sketch.sectionRotation != null) {
            let offset = { type: SectionHelper.TRANSFORM_OFFSET, x: 0, y: 0, z: 0 };
            if (sketch.sectionRotation.center == EditSketchConstants.ROTATE_SECTION_OUTER_ANGLE && sketch.sectionRotation.handrailWidth != 0) {
                const thHalf = MathHelper.mmToMeters(parseInt(sketch.sectionRotation.handrailWidth)) / 2;
                offset = {
                    type: SectionHelper.TRANSFORM_OFFSET,
                    x: -1 * Math.sign(angle) * (thHalf * Math.sin(angle)),
                    y: 0.0,
                    z: -1 * Math.sign(angle) * (thHalf * (1 - Math.cos(angle))),
                    isPillarOffset: true
                };
                offset.x -= this.getPillarOffset(sketch, angle);
            }
            if (sketch.sectionRotation.center == EditSketchConstants.ROTATE_SECTION_PILLAR && sketch.sectionRotation.pillarWidth != 0) {
                const thHalf = MathHelper.mmToMeters(parseInt(sketch.sectionRotation.pillarWidth)) / 2;
                offset = {
                    type: SectionHelper.TRANSFORM_OFFSET,
                    x: -1 * thHalf * (1 + Math.cos(angle)),
                    y: 0.0,
                    z: -1 * thHalf * Math.sin(angle),
                    isPillarOffset: true
                };
            }
            
            return offset;
        }
        return { type: SectionHelper.TRANSFORM_OFFSET, x: 0, y: 0, z: 0, isPillarOffset: true };
    }

    static getPillarOffset(sketch, angle) {
        if (sketch.sectionRotation.pillarWidth != 0) {
            const th = MathHelper.mmToMeters(parseInt(sketch.sectionRotation.pillarWidth));
            if (Math.abs(angle) == 0) {
                return th;
            }
            let offset = 0;
            if (0 < Math.abs(angle) && Math.abs(angle) < Math.PI / 2) {
                let x2 = th / Math.tan(Math.abs(angle));
                offset = ((x2 - th / 2) * th) / x2;
            }
            if (offset < 0) {
                let betta = (Math.PI - Math.abs(angle)) / 2;
                let x2 = th / Math.tan(betta);
                let x1 = th - x2;
                let y1 = x1 * Math.tan(Math.abs(angle));
                offset = x1 * (y1 - th / 2) / y1;
            }
            return offset;
        }
        return 0;
    }
}

class SectionContentBuilder {
    constructor() {

    }

    buildTemplateSection(sectionIn, sketch, hidePillar1, rotateAngle, isFirstSection, isLastSection) {
        this.sectionIn = sectionIn;
        this.sketch = sketch;
        this.hidePillar1 = hidePillar1;
        this.rotateAngle = rotateAngle;
        this.isFirstSection = isFirstSection;
        this.isLastSection = isLastSection;
        if (sketch.version >= 3) {
            return this._buildTemplateSectionV3();
        }
        let objectsOut = [];
        let sectionWidth = MathHelper.mmToMeters(parseInt(sectionIn.length));
        let sectionHeight = sectionIn.height != null ? MathHelper.mmToMeters(parseInt(sectionIn.height)) : MathHelper.mmToMeters(parseInt(sketch.height));
        //split section to inner sections by pillar A
        let innerSections = [];
        const objectPillarA = sketch.objects.find(o => o.tag == EditSketchConstants.PILLARA);
        if (sectionIn.pillarA && objectPillarA != null && sectionIn.pillarACount > 0) {
            let stepX = sectionWidth / (parseInt(sectionIn.pillarACount) + 1);
            for (let pillarIndex = 0; pillarIndex < sectionIn.pillarACount; pillarIndex++) {
                innerSections.push({
                    left: pillarIndex * stepX,
                    width: stepX,
                });
            }
            innerSections.push({
                left: sectionIn.pillarACount * stepX,
                width: stepX,
            });
        } else {
            innerSections.push({ left: 0, width: sectionWidth });
        }

        for (let i = 0; i < sketch.objects.length; i++) {
            let objectIn = sketch.objects[i];

            if (objectIn.tag == EditSketchConstants.PILLAR1 && (!sectionIn.pillar1 || hidePillar1)) {
                continue;
            }
            if (objectIn.tag == EditSketchConstants.PILLAR2 && !sectionIn.pillar2) {
                continue;
            }

            if (objectIn.processType == EditSketchConstants.PROCESS_TYPE_SINGLE && objectIn.visibleInterval != null) {
                if (sectionWidth < MathHelper.mmToMeters(parseInt(objectIn.visibleInterval.min))
                    || sectionWidth > MathHelper.mmToMeters(parseInt(objectIn.visibleInterval.max))) {
                    continue;
                }
            }

            let objectOut = {
                processType: objectIn.processType,
                id: parseInt(objectIn.id),
                type: objectIn.type,
                position: {
                    x: 0,
                    y: 0,
                    z: MathHelper.mmToMeters(parseInt(objectIn.position.z))
                },
                rotation: {
                    x: MathHelper.degreeToRad(parseInt(objectIn.rotation.x)),
                    y: MathHelper.degreeToRad(parseInt(objectIn.rotation.y)),
                    z: MathHelper.degreeToRad(parseInt(objectIn.rotation.z))
                }
            };
            
            if (objectIn.length != null) {
                if (typeof (objectIn.length) == "object") {
                    if (objectIn.length.l == FormulaHelper.FILL_WIDTH) {
                        objectOut.length = sectionWidth * parseFloat(objectIn.length.k);
                    }
                    else if (objectIn.length.l == FormulaHelper.FILL_HEIGHT) {
                        objectOut.length = sectionHeight * parseFloat(objectIn.length.k);
                    }
                } else {
                    objectOut.length = MathHelper.mmToMeters(parseInt(objectIn.length));
                }
            }
            if (objectIn.useSegment) {
                objectOut.segmentHeight = MathHelper.mmToMeters(objectIn.segmentHeight);
                objectOut.segmentDirection = {
                    x: objectIn.segmentDirection.x,
                    y: objectIn.segmentDirection.y,
                    z: objectIn.segmentDirection.z,
                };
            }
            if (objectIn.useSpline) {
                if (objectIn.useStartPoints) {
                    objectOut.points = [                        
                        ...objectIn.points.map(p => ({
                            x: MathHelper.mmToMeters(p.x),
                            y: MathHelper.mmToMeters(p.y),
                            z: MathHelper.mmToMeters(p.z)
                        }))
                    ];
                } else {
                    objectOut.points = [
                        { x: 0, y: -(objectOut.length / 2), z: 0 },
                        ...objectIn.points.map(p => ({
                            x: MathHelper.mmToMeters(p.x),
                            y: MathHelper.mmToMeters(p.y),
                            z: MathHelper.mmToMeters(p.z)
                        })),
                        { x: 0, y: (objectOut.length / 2), z: 0 }
                    ];
                }
            }

            if (objectIn.tag == EditSketchConstants.PILLARA) {
                if (sectionIn.pillarA) {
                    let stepX = sectionWidth / (parseInt(sectionIn.pillarACount) + 1);
                    for (let pillarIndex = 0; pillarIndex < sectionIn.pillarACount; pillarIndex++) {
                        let objectOutCopy = JSON.parse(JSON.stringify(objectOut));
                        objectOutCopy.position.x = (pillarIndex + 1) * stepX;
                        typeof (objectIn.position.y) == "object" ?
                            objectOutCopy.position.y =
                            sectionHeight * parseFloat(objectIn.position.y.k) + MathHelper.mmToMeters(parseInt(objectIn.position.y.offset))
                            :
                            objectOutCopy.position.y = MathHelper.mmToMeters(parseInt(objectIn.position.y));
                        objectsOut.push(objectOutCopy);
                    }
                }
                continue;
            }

            if (objectIn.processType == EditSketchConstants.PROCESS_TYPE_SINGLE) {
                objectOut.position.x =
                    sectionWidth * parseFloat(objectIn.position.x.k) + MathHelper.mmToMeters(parseInt(objectIn.position.x.offset));
                typeof (objectIn.position.y) == "object" ?
                    objectOut.position.y =
                    sectionHeight * parseFloat(objectIn.position.y.k) + MathHelper.mmToMeters(parseInt(objectIn.position.y.offset))
                    :
                    objectOut.position.y = MathHelper.mmToMeters(parseInt(objectIn.position.y));
            } else if (objectIn.processType == EditSketchConstants.PROCESS_TYPE_COPY) {
                //use innerSections from pillarA
                for (let isi = 0; isi < innerSections.length; isi++) {
                    const innerSection = innerSections[isi];
                    if (objectIn.visibleInterval != null) {
                        if (innerSection.width < MathHelper.mmToMeters(parseInt(objectIn.visibleInterval.min))
                            || innerSection.width > MathHelper.mmToMeters(parseInt(objectIn.visibleInterval.max))) {
                            continue;
                        }
                    }
                    let isHorizontal = objectIn.copy.isHorizontal != null ? objectIn.copy.isHorizontal : true;
                    let stepX = MathHelper.mmToMeters(parseInt(objectIn.copy.stepX));
                    let stepY = objectIn.copy.stepY != null ? MathHelper.mmToMeters(parseInt(objectIn.copy.stepY)) : 0;
                    let stepZ = objectIn.copy.stepZ != null ? MathHelper.mmToMeters(parseInt(objectIn.copy.stepZ)) : 0;
                    let xOffset = MathHelper.mmToMeters(parseInt(objectIn.position.x.offset)) + innerSection.left;
                    let yOffset = typeof (objectIn.position.y) == "object" ?
                        MathHelper.mmToMeters(parseInt(objectIn.position.y.offset))
                        :
                        MathHelper.mmToMeters(parseInt(objectIn.position.y));
                    let zOffset = MathHelper.mmToMeters(parseInt(objectIn.position.z));
                    let firstIndent = MathHelper.mmToMeters(parseInt(objectIn.copy.left));
                    let secondIndent = MathHelper.mmToMeters(parseInt(objectIn.copy.right));
                    let multiplicity = objectIn.copy.multiplicity != null ? parseInt(objectIn.copy.multiplicity) : 1;
                    let shift = objectIn.copy.shift != null ? parseInt(objectIn.copy.shift) : 0;
                    let copyLenght;
                    let count;
                    if (isHorizontal) {
                        copyLenght = innerSection.width - firstIndent - secondIndent;
                        count = copyLenght / stepX;
                    }
                    else {
                        copyLenght = sectionHeight - firstIndent - secondIndent;
                        count = copyLenght / stepY;
                    }
                    if (count % 1.0 <= parseFloat(objectIn.copy.k)) {
                        count = Math.floor(count);
                    } else {
                        count = Math.ceil(count);
                    }
                    isHorizontal ? stepX = copyLenght / count : stepY = copyLenght / count;
                    if (objectIn.copy.offsetHalfStep) {
                        count -= 1;
                        firstIndent += isHorizontal ? stepX / 2 : stepY / 2;
                    } else if (objectIn.copy.hideFirstLast) {
                        count -= 2;
                        firstIndent += isHorizontal ? stepX : stepY;
                    }
                    if (isHorizontal) {
                        xOffset += firstIndent;
                    }
                    else {
                        yOffset += secondIndent;
                    }
                    objectOut.position = {
                        x: [],
                        y: [],
                        z: []
                    };
                    if (shift == 0) {
                        objectOut.position.x.push(xOffset);
                        objectOut.position.y.push(yOffset);
                        objectOut.position.z.push(zOffset);
                    }
                    for (let i = 0; i < count; i++) {
                        xOffset += stepX;
                        yOffset += stepY;
                        zOffset += stepZ;
                        if (multiplicity == 1 || (i + 2 - shift) % multiplicity == 1) {
                            objectOut.position.x.push(xOffset);
                            objectOut.position.y.push(yOffset);
                            objectOut.position.z.push(zOffset);
                        }
                    }
                    let objectOutCopy = JSON.parse(JSON.stringify(objectOut));
                    objectsOut.push(objectOutCopy);
                    /*
                    //OLD
                    let copywidth = innerSection.width - left - right;
                    let count = copywidth / stepX;
                    if (count % 1.0 <= parseFloat(objectIn.copy.k)) {
                        count = Math.floor(count);
                    } else {
                        count = Math.ceil(count);
                    }
                    stepX = copywidth / count;
                    if (objectIn.copy.offsetHalfStep) {
                        count -= 1;
                        left += stepX / 2;
                    } else if (objectIn.copy.hideFirstLast) {
                        count -= 2;
                        left += stepX;
                    }
                    let objectOutCopy = JSON.parse(JSON.stringify(objectOut));
                    objectOutCopy.copy = {
                        left: innerSection.left + left + xOffset,
                        stepX: stepX,
                        count: parseInt(count) + 1,
                    };
                    objectsOut.push(objectOutCopy);
                    */
                }
                continue;
            }
            objectsOut.push(objectOut);
        }

        return {
            width: sectionWidth,
            sketch: {
                height: sectionHeight,
                objects: objectsOut
            }
        };
    }

    _buildTemplateSectionV3() {
        let objectsOut = [];

        this.sectionWidthExtra = 0;
        if ((this.sectionIn.pillar1 == false || this.sectionIn.pillar2 == false) && this.sketch.sectionRotation.pillarWidth != 0) {
            const pillarWidth = MathHelper.mmToMeters(parseInt(this.sketch.sectionRotation.pillarWidth));
            const objPillar1 = this.sketch.objects.find(o => o.tag == EditSketchConstants.PILLAR1);
            const objPillar2 = this.sketch.objects.find(o => o.tag == EditSketchConstants.PILLAR2);
            if (this.isFirstSection && this.sectionIn.pillar1 == false && objPillar1) {
                this.sectionWidthExtra += pillarWidth;
            }
            if (this.isLastSection && this.sectionIn.pillar2 == false && objPillar2) {
                this.sectionWidthExtra += pillarWidth;
            }
        }

        this.sectionWidth = MathHelper.mmToMeters(parseInt(this.sectionIn.length)) + this.sectionWidthExtra;        
        this.sectionHeight = this.sectionIn.height != null ? MathHelper.mmToMeters(parseInt(this.sectionIn.height)) : MathHelper.mmToMeters(parseInt(this.sketch.height));
        this.sectionDepth = this.sectionIn.depth != null ? MathHelper.mmToMeters(parseInt(this.sectionIn.depth)) : 0;
        this.userValues = {
            [FormulaHelper.FILL_WIDTH]: this.sectionWidth,
            [FormulaHelper.FILL_HEIGHT]: this.sectionHeight,
            [FormulaHelper.FILL_DEPTH]: this.sectionDepth,
            [FormulaHelper.STEP]: 0,
            ...this._getExtraVars()
        };

        //split section to inner sections by pillar A
        this.innerSections = this._getInnerSections();

        const globalSteps = this._getGlobalStep();
        for (let i = 0; i < globalSteps.length; i++) {
            if (globalSteps[i]) {
                if (i == 0) {
                    this.userValues[FormulaHelper.STEP] = globalSteps[i];
                } else {
                    this.userValues[FormulaHelper.STEP + (i)] = globalSteps[i];
                }
            }
        }

        this._attachConstStepRemain();

        let complexCopy = [];

        let objects = [...this.sketch.objects];
        for (let i = 0; i < objects.length; i++) {
            let objectIn = objects[i];

            if (objectIn.tag == EditSketchConstants.PILLAR1 && (!this.sectionIn.pillar1 || this.hidePillar1)) {
                continue;
            }
            if (objectIn.tag == EditSketchConstants.PILLAR2 && !this.sectionIn.pillar2) {
                continue;
            }

            if (objectIn.processType == EditSketchConstants.PROCESS_TYPE_SINGLE && !objectIn.copyToInnerSection) {
                if (this._visibleCheck(objectIn, null, !this.sectionIn.floatHeight, 0)) {
                    continue;
                }
            }

            if (objectIn.type == EditSketchConstants.OBJECT_TYPE_GROUP) {
                if (objectIn.processType == EditSketchConstants.PROCESS_TYPE_COPY && objectIn.copy.isComplex) {
                    complexCopy.push(objectIn);
                    continue;
                }
                let arr = this._processGroup(objectIn);
                objectsOut.push(...arr);
                continue;
            }

            let objectOut = this._getObjectOutFromObjectIn(objectIn);

            if (objectIn.tag == EditSketchConstants.PILLARA) {
                this._processPillarA(objectOut, objectsOut);
                continue;
            }
            if (objectIn.pillarAAlternative) {
                if (this.innerSections.length > 1) {
                    this._processPillarA(objectOut, objectsOut, true);
                }
                continue;
            }

            if (objectIn.copyToInnerSection) {
                let arr = this._processCopyToInnerSection(objectIn, objectOut);
                arr.forEach(item => {
                    if (this._visibleCheck(objectIn, this.innerSections[0], !this.sectionIn.floatHeight, 0)) {
                    } else {
                        objectsOut.push(item);
                    }
                });
                continue;
            }

            if (objectIn.processType == EditSketchConstants.PROCESS_TYPE_COPY) {
                if (objectIn.copy.shift2 != null) {
                    objectIn.copy.shift2.map((item) => {
                        let objectInNewShift = {
                            ...objectIn,
                            copy: {
                                ...objectIn.copy,
                                shift: item,
                                shift2:null,
                            }
                        };
                        objects.push(objectInNewShift);
                    })
                }
                if (objectIn.copy.isComplex) {
                    complexCopy.push(objectIn);
                    continue;
                }
                let copyArr = this._processCopyItem(objectIn, objectOut);
                objectsOut.push(...copyArr);
                continue;
            }

            if (objectIn.isHandRails && this.hidePillar1 && this.sketch.sectionRotation.center == EditSketchConstants.ROTATE_SECTION_OUTER_ANGLE) {
                let xOffset = SectionHelper.getPillarOffset(this.sketch, this.rotateAngle);
                objectOut.position.x += xOffset / 2;
                objectOut.length -= xOffset;
            }
            if (objectIn.isHandRails && this.sectionWidthExtra != 0 && this.sketch.sectionRotation.center == EditSketchConstants.ROTATE_SECTION_OUTER_ANGLE) {
                objectOut.length -= this.sectionWidthExtra;
                if (this.sectionIn.pillar1 == false && this.sectionIn.pillar2 == true) {
                    objectOut.position.x += this.sectionWidthExtra / 2;
                }
                if (this.sectionIn.pillar1 == true && this.sectionIn.pillar2 == false) {
                    objectOut.position.x -= this.sectionWidthExtra / 2;
                }
            }

            objectsOut.push(objectOut);
        }

        if (complexCopy.length > 0) {
            let copyArr = this._processComplexCopyItem2(complexCopy);
            objectsOut.push(...copyArr);
        }

        //if (this.sectionIn.curveHeight && parseInt(this.sectionIn.curveHeight) != 0) {
        //    this._makeSectionCurve(objectsOut);
        //}

        return {
            width: this.sectionWidth,
            sketch: {
                height: this.sectionHeight,
                objects: objectsOut
            }
        };
    }

    _visibleCheckCopy(stepx, stepY, stepZ, objectIn) {
        let flag = false;
        let array_flag = false;
        if (objectIn.visibleIntervalCopy != null) {
            objectIn.visibleIntervalCopy.map((item) => {
                if (stepx < MathHelper.mmToMeters(parseFloat(item.min))
                    || stepx >= MathHelper.mmToMeters(parseFloat(item.max))) {
                    flag = true;
                }
                else {
                    array_flag = true;
                }
            });
        }
        if (objectIn.visibleIntervalYCopy != null) {
            objectIn.visibleIntervalYCopy.map((item) => {
                if (stepY < MathHelper.mmToMeters(parseFloat(item.min))
                    || stepY >= MathHelper.mmToMeters(parseFloat(item.max))) {
                    flag = true;
                }
                else {
                    array_flag = true;
                }
            });
        }
        if (objectIn.visibleIntervalZCopy != null) {
            objectIn.visibleIntervalZCopy.map((item) => {
                if (stepZ < MathHelper.mmToMeters(parseFloat(item.min))
                    || stepZ >= MathHelper.mmToMeters(parseFloat(item.max))) {
                    flag = true;
                }
                else {
                    array_flag = true;
                }
            });
        }
        if (array_flag) {
            return false;
        }
        return flag;
    }

    _visibleCheck(objectIn, innerSection, skipY, alfa) {
        let flagSkip = false;
        let array_flag = false;
        if (objectIn.visibleInterval != null) {
            if (innerSection != null) {
                if (Array.isArray(objectIn.visibleInterval)) {
                    const passIntervals = objectIn.visibleInterval.filter((item) => {
                        if (innerSection.width < MathHelper.mmToMeters(parseInt(item.min))
                            || innerSection.width > MathHelper.mmToMeters(parseInt(item.max))) {
                            return false;
                        } else
                            return true;
                    });
                    if (passIntervals.length == 0) {
                        flagSkip = true;
                    }
                }
                else if (innerSection.width < MathHelper.mmToMeters(parseInt(objectIn.visibleInterval.min))
                    || innerSection.width > MathHelper.mmToMeters(parseInt(objectIn.visibleInterval.max))) {
                    flagSkip = true;
                }
            } else {
                if (Array.isArray(objectIn.visibleInterval)) {
                    const passIntervals = objectIn.visibleInterval.filter((item) => {
                        if (this.sectionWidth < MathHelper.mmToMeters(parseInt(item.min))
                            || this.sectionWidth > MathHelper.mmToMeters(parseInt(item.max))) {
                            return false;
                        } else
                            return true;
                    });
                    if (passIntervals.length == 0) {
                        flagSkip = true;
                    }
                }
                else if (this.sectionWidth < MathHelper.mmToMeters(parseInt(objectIn.visibleInterval.min))
                    || this.sectionWidth > MathHelper.mmToMeters(parseInt(objectIn.visibleInterval.max))) {
                    flagSkip = true;
                }
            }
        }
        if (!skipY && objectIn.visibleIntervalY != null) {
            if (Array.isArray(objectIn.visibleIntervalY)) {
                const passIntervals = objectIn.visibleIntervalY.filter((item) => {
                    if (this.sectionHeight < MathHelper.mmToMeters(parseInt(item.min))
                        || this.sectionHeight > MathHelper.mmToMeters(parseInt(item.max))) {
                        return false;
                    } else {
                        return true;
                    }
                });
                if (passIntervals.length == 0) {
                    flagSkip = true;
                }
            }
            else if (this.sectionHeight < MathHelper.mmToMeters(parseInt(objectIn.visibleIntervalY.min))
                || this.sectionHeight > MathHelper.mmToMeters(parseInt(objectIn.visibleIntervalY.max))) {
                flagSkip = true;
            }
        }
        if (objectIn.visibleIntervalZ != null) {
            const passIntervals = objectIn.visibleIntervalZ.filter((item) => {
                if (this.sectionDepth < MathHelper.mmToMeters(parseInt(item.min))
                    || this.sectionDepth > MathHelper.mmToMeters(parseInt(item.max))) {
                    return false;
                } else {
                    return true;
                }
            });
            if (passIntervals.length == 0) {
                flagSkip = true;
            }
        }
        if (objectIn.visibleIntervalA != null && alfa != null) {
            let deegre = MathHelper.radToDegree(alfa);
            const passIntervals = objectIn.visibleIntervalA.filter((item) => {
                if (deegre < parseInt(item.min)
                    || deegre > parseInt(item.max)) {
                    return false;
                } else {
                    return true;
                }
            });
            if (passIntervals.length == 0) {
                flagSkip = true;
            }
        }
        return flagSkip;
    }

    _getObjectOutFromObjectIn(objectIn) {
        let userValuesInnerSection = { ...this.userValues };
        if (objectIn.copyToInnerSection) {
            userValuesInnerSection[FormulaHelper.FILL_WIDTH] = this.innerSections[0].width;
        }
        let objectOut = {
            key: objectIn.key,
            isPillar: objectIn.tag == EditSketchConstants.PILLAR1 || objectIn.tag == EditSketchConstants.PILLAR2 ? true : false,
            skipCalculation: objectIn.noCalculate ? true : false,
            useCoeff: objectIn.useCoeff ? true : false,
            skipInMirror: objectIn.skipInMirror ? true : false,
            donotMirror: (objectIn.donotMirror || objectIn.autofit) ? true : false,
            processType: objectIn.processType,
            id: parseInt(objectIn.id),
            type: objectIn.type,
            position: {
                x: FormulaHelper.calcValue(objectIn.position.x, this.userValues),
                y: FormulaHelper.calcValue(objectIn.position.y, this.userValues),
                z: FormulaHelper.calcValue(objectIn.position.z, this.userValues),
                y2: objectIn.position.y2 != null ? FormulaHelper.calcValue(objectIn.position.y2, this.userValues) : null,
            },
            rotation: {
                x: typeof (objectIn.rotation.x) == "object" ? FormulaHelper.calcValue(objectIn.rotation.x, userValuesInnerSection) : MathHelper.degreeToRad(parseFloat(objectIn.rotation.x)),
                y: typeof (objectIn.rotation.y) == "object" ? FormulaHelper.calcValue(objectIn.rotation.y, userValuesInnerSection) : MathHelper.degreeToRad(parseFloat(objectIn.rotation.y)),
                z: typeof (objectIn.rotation.z) == "object" ? FormulaHelper.calcValue(objectIn.rotation.z, userValuesInnerSection) : MathHelper.degreeToRad(parseFloat(objectIn.rotation.z))
            },
            extraData: {
                calculateAsStraight: objectIn.calculateAsStraight == true
            }
        };
        if (objectIn.rotation2 != null) {
            objectOut.rotation2 = {
                x: FormulaHelper.calcValue(objectIn.rotation2.x, this.userValues),
                y: FormulaHelper.calcValue(objectIn.rotation2.y, this.userValues),
                z: FormulaHelper.calcValue(objectIn.rotation2.z, this.userValues)
            }
        }
        if (objectIn.length != null && objectIn.type == EditSketchConstants.OBJECT_TYPE_METALL) {
            objectOut.length = FormulaHelper.calcValue(objectIn.length, this.userValues);
        }

        //apply segment/spline
        if (objectIn.useSegment) {
            objectOut.segmentHeight = MathHelper.mmToMeters(objectIn.segmentHeight);
            objectOut.segmentDirection = {
                x: objectIn.segmentDirection.x,
                y: objectIn.segmentDirection.y,
                z: objectIn.segmentDirection.z,
            };
        }
        if (objectIn.useSpline) {
            if (objectIn.useStartPoints) {
                objectOut.points = [                    
                    ...objectIn.points.map(p => ({
                        x: MathHelper.mmToMeters(p.x),
                        y: MathHelper.mmToMeters(p.y),
                        z: MathHelper.mmToMeters(p.z)
                    }))                    
                ];
            } else {
                objectOut.points = [
                    { x: 0, y: -(objectOut.length / 2), z: 0 },
                    ...objectIn.points.map(p => ({
                        x: MathHelper.mmToMeters(p.x),
                        y: MathHelper.mmToMeters(p.y),
                        z: MathHelper.mmToMeters(p.z)
                    })),
                    { x: 0, y: (objectOut.length / 2), z: 0 }
                ];
            }
        }

        if (objectIn.autosize != null) {
            const end1 = MathHelper.mmToMeters(parseInt(objectIn.autosize.end1));
            const end2 = MathHelper.mmToMeters(parseInt(objectIn.autosize.end2));
            if (end1 != 0 || end2 != 0) {
                objectOut.autosize = {};
                if (end1 != 0)
                    objectOut.autosize.end1 = end1;
                if (end2 != 0)
                    objectOut.autosize.end2 = end2;
            }
        }

        if (objectIn.ends) {
            objectOut.ends = {};
            if (objectIn.ends.first) {
                if (objectIn.ends.first.type == EditSketchConstants.END_TYPE_CUT) {
                    objectOut.ends.first = {
                        cutLength: MathHelper.mmToMeters(parseInt(objectIn.ends.first.cutLength)),
                        axis: objectIn.ends.first.axis
                    };
                }
                if (objectIn.ends.first.type == EditSketchConstants.END_TYPE_ABC) {
                    objectOut.ends.first = {
                        a: MathHelper.mmToMeters(parseInt(objectIn.ends.first.a)),
                        b: MathHelper.mmToMeters(parseInt(objectIn.ends.first.b)),
                        c: MathHelper.mmToMeters(parseInt(objectIn.ends.first.c))
                    };
                }
            }

            if (objectIn.ends.last) {
                if (objectIn.ends.last.type == EditSketchConstants.END_TYPE_CUT) {
                    objectOut.ends.last = {
                        cutLength: MathHelper.mmToMeters(parseInt(objectIn.ends.last.cutLength)),
                        axis: objectIn.ends.last.axis
                    };
                }
                if (objectIn.ends.last.type == EditSketchConstants.END_TYPE_ABC) {
                    objectOut.ends.last = {
                        a: MathHelper.mmToMeters(parseInt(objectIn.ends.last.a)),
                        b: MathHelper.mmToMeters(parseInt(objectIn.ends.last.b)),
                        c: MathHelper.mmToMeters(parseInt(objectIn.ends.last.c))
                    };
                }
            }            
        }
        if (objectIn.isBorder || objectIn.autofit) {
            if (objectIn.autofitGroup) {
                objectOut.autofitGroup = parseInt(objectIn.autofitGroup);
            }
            else {
                objectOut.autofitGroup = 0;
            }
            if (objectIn.isBorder) {
                objectOut.isBorder = true;
            }
            if (objectIn.autofit) {
                objectOut.autofit = { type: objectIn.autofit.type, offset: MathHelper.mmToMeters(parseInt(objectIn.autofit.offset)) };
                objectOut.position.z += MathHelper.mmToMeters(parseInt(objectIn.autofit.offset));
            }
        }
        /*if (objectIn.torsion) {
            
        }*/
        if (objectIn.torsions) {
            let firstTorsion = { ...objectIn.torsions[0] };
            const end = FormulaHelper.calcValue(firstTorsion.end, this.userValues);
            const start = FormulaHelper.calcValue(firstTorsion.start, this.userValues);
            //objectOut.torsion = {
            //    angle: MathHelper.radToDegree(FormulaHelper.calcValue(firstTorsion.angle, this.userValues, { end: end, start: start })),
            //    end: end,
            //    start: start
            //};
            objectOut.torsions = objectIn.torsions.map((torsion) => {
                const end = FormulaHelper.calcValue(torsion.end, this.userValues);
                const start = FormulaHelper.calcValue(torsion.start, this.userValues);
                return {
                    angle: parseFloat(torsion.angle), //FormulaHelper.calcValue(torsion.angle, this.userValues, { end: end, start: start }),
                    end: end,
                    start: start
                };
            });
        }
        return objectOut;
    }

    _getGlobalStep() {
        const globalStepObjects = this.sketch.objects.filter(o => o.globalStep);
        let steps = [];

        for (let innerSectionIndex = 0; innerSectionIndex < this.innerSections.length; innerSectionIndex++) {
            const innerSection = this.innerSections[innerSectionIndex];
            if (!innerSection.steps)
                innerSection.steps = [];

            let userValues = { ...this.userValues };
            userValues[FormulaHelper.FILL_WIDTH] = innerSection.width;

            for (let i = 0; i < globalStepObjects.length; i++) {
                const globalStepObject = globalStepObjects[i]; //this.sketch.objects.find(o => o.globalStep && (o.globalStepNumber == null || o.globalStepNumber <= 1));

                if (globalStepObject != null) {
                    let isHorizontal = globalStepObject.copy.isHorizontal == true;

                    let step = 0;
                    let copyLenght = 0;
                    let count = 0;
                    //TODO: adding version support
                    if (isHorizontal) {
                        step = typeof (globalStepObject.copy.stepX) != 'object' ? MathHelper.mmToMeters(parseInt(globalStepObject.copy.stepX)) : FormulaHelper.calcValue(globalStepObject.copy.stepX, this.userValues);
                    } else {
                        step = typeof (globalStepObject.copy.stepY) != 'object' ? MathHelper.mmToMeters(parseInt(globalStepObject.copy.stepY)) : FormulaHelper.calcValue(globalStepObject.copy.stepY, this.userValues);
                    }

                    let firstIndent = FormulaHelper.calcValue(globalStepObject.copy.left, userValues);
                    let secondIndent = FormulaHelper.calcValue(globalStepObject.copy.right, userValues);

                    if (isHorizontal) {
                        copyLenght = innerSection.width - firstIndent - secondIndent;
                    } else {
                        copyLenght = this.sectionHeight - firstIndent - secondIndent;
                    }
                    count = copyLenght / step;
                    if (!globalStepObject.copy.constStep) {
                        if (count % 1.0 <= parseFloat(globalStepObject.copy.k)) {
                            count = Math.floor(count);
                        } else {
                            count = Math.ceil(count);
                        }
                        if (count == 0) {
                            count = 1;
                        }
                        if (globalStepObject.copy.isEven && (count + 1) % 2 != 0) {
                            count += 1;
                        } else if (globalStepObject.copy.isOdd && (count + 1) % 2 != 1) {
                            count += 1;
                        }
                        step = copyLenght / count;
                    }
                    let sindex = globalStepObject.globalStepNumber != null ? parseInt(globalStepObject.globalStepNumber) : 0;
                    if (sindex == 1) {
                        sindex = 0;
                    }
                    innerSection.steps[sindex] = step;
                    if (innerSectionIndex == 0) {
                        steps[sindex] = step;
                    }
                }
            }
        }

        if (steps.length == 0) {
            steps[0] = 0;
        }

        return steps;
    }

    _attachConstStepRemain() {
        const constStepObjects = this.sketch.objects.filter(o => o.copy && o.copy.constStep && o.copy.constStepNumber);

        let userValues = { ...this.userValues };
        userValues[FormulaHelper.FILL_WIDTH] = this.innerSections[0].width;

        for (let i = 0; i < constStepObjects.length; i++) {
            const constStepObject = constStepObjects[i];

            let isHorizontal = constStepObject.copy.isHorizontal == true;
            let step = 0;
            let copyLenght = 0;            
            if (isHorizontal) {
                step = typeof (constStepObject.copy.stepX) != 'object' ? MathHelper.mmToMeters(parseInt(constStepObject.copy.stepX)) : FormulaHelper.calcValue(constStepObject.copy.stepX, this.userValues);
            } else {
                step = typeof (constStepObject.copy.stepY) != 'object' ? MathHelper.mmToMeters(parseInt(constStepObject.copy.stepY)) : FormulaHelper.calcValue(constStepObject.copy.stepY, this.userValues);
            }

            let firstIndent = FormulaHelper.calcValue(constStepObject.copy.left, userValues);
            let secondIndent = FormulaHelper.calcValue(constStepObject.copy.right, userValues);

            if (isHorizontal) {
                copyLenght = this.innerSections[0].width - firstIndent - secondIndent;
            } else {
                copyLenght = this.sectionHeight - firstIndent - secondIndent;
            }

            const remain = ((copyLenght / step) % 1.0) * step;

            this.userValues[FormulaHelper.CONST_STEP_REMAIN + constStepObject.copy.constStepNumber] = remain;
        }
    }

    _getExtraVars() {
        if (this.sketch.extraVars == null)
            return {};

        let extraVarsObj = {};
        this.sketch.extraVars.forEach(item => {
            extraVarsObj[item.name] = MathHelper.mmToMeters(parseInt(item.val));
        });
        return extraVarsObj;
    }

    _getInnerSections() {
        let sectionsA = [];
        let innerSections = [];

        const pillarAWidth = (this.sketch.sectionRotation && this.sketch.sectionRotation.pillarAWidth) ? MathHelper.mmToMeters(parseInt(this.sketch.sectionRotation.pillarAWidth)) : 0;
        if (this.sectionIn.pillarA && this.sectionIn.pillarACount > 0) {
            const pillarACount = parseInt(this.sectionIn.pillarACount);
            
            let stepX = this.sectionWidth / (pillarACount + 1);
            if (pillarAWidth != 0) {
                stepX = (this.sectionWidth - pillarAWidth) / (pillarACount + 1);
            }
            for (let pillarIndex = 0; pillarIndex < pillarACount + 1; pillarIndex++) {
                sectionsA.push({
                    left: pillarIndex * stepX,
                    width: stepX + pillarAWidth,
                    hasPillarA: pillarIndex != pillarACount
                });
            }           
        } else {
            sectionsA.push({ left: 0, width: this.sectionWidth });
        }


        if (this.sketch.innerSectionWidthForSplitting && this.sectionIn.type != SectionHelper.SECTION_CANOPY) {
            const innerSectionWidthForSplitting = MathHelper.mmToMeters(parseInt(this.sketch.innerSectionWidthForSplitting));
            for (let si = 0; si < sectionsA.length; si++) {
                const sectionWidth = sectionsA[si].width;
                const pillarCount = Math.floor(sectionWidth / innerSectionWidthForSplitting);
                if (pillarCount == 0) {
                    innerSections.push(sectionsA[si]);
                } else {
                    let stepX = sectionWidth / (pillarCount + 1);
                    if (pillarAWidth != 0) {
                        stepX = (sectionWidth - pillarAWidth) / (pillarCount + 1);
                    }
                    for (let pillarIndex = 0; pillarIndex < pillarCount + 1; pillarIndex++) {
                        innerSections.push({
                            left: sectionsA[si].left + pillarIndex * stepX,
                            width: stepX + pillarAWidth,
                            hasPillarA: pillarIndex == pillarCount
                        });
                    }    
                }
            }
        } else {
            innerSections = [...sectionsA];
        }

        this.innerSectionsA = sectionsA;

        return innerSections;
    }

    _processPillarA(objectOut, objectsOut, isAlternativePillarA) {
        let arr = [];
        if (this.sectionIn.pillarA || isAlternativePillarA) {
            const pillarAWidth = (this.sketch.sectionRotation && this.sketch.sectionRotation.pillarAWidth) ? MathHelper.mmToMeters(parseInt(this.sketch.sectionRotation.pillarAWidth)) : 0;
            for (let i = 0; i < this.innerSections.length - 1; i++) {
                if ((!isAlternativePillarA && this.innerSections[i].hasPillarA) ||
                    (isAlternativePillarA && !this.innerSections[i].hasPillarA)) {
                    let objectOutCopy = JSON.parse(JSON.stringify(objectOut));
                    objectOutCopy.position.x = this.innerSections[i].left + this.innerSections[i].width - pillarAWidth / 2;
                    objectOutCopy.isPillarA = true;
                    objectOutCopy.isPillar = !isAlternativePillarA;
                    objectsOut.push(objectOutCopy);
                    arr.push(objectOutCopy);
                }
            }
        }
        return arr;
    }

    _processCopyToInnerSection(objectIn, objectOut) {
        let objectsOut = [];
        const pillarAWidth = (this.sketch.sectionRotation && this.sketch.sectionRotation.pillarAWidth) ? MathHelper.mmToMeters(parseInt(this.sketch.sectionRotation.pillarAWidth)) : 0;
        const pillarWidth = (this.sketch.sectionRotation && this.sketch.sectionRotation.pillarWidth) ? MathHelper.mmToMeters(parseInt(this.sketch.sectionRotation.pillarWidth)) : 0;

        let innerSections = this.innerSections;

        if (objectIn.notInnersectionIfNoPillarA) {
            innerSections = this.innerSectionsA;
        }

        for (let isi = 0; isi < innerSections.length; isi++) {
            const innerSection = {
                ...innerSections[isi]
            };
            if (innerSections.length > 1 && objectIn.rotation.z != Math.PI / 2 &&
                pillarAWidth != 0 && pillarWidth != 0 && pillarAWidth != pillarWidth) {
                const deltaPillar = pillarWidth - pillarAWidth;                
                innerSection.width += (isi == 0 || isi == innerSections.length - 1) ? deltaPillar : deltaPillar * 2;
                if (isi != 0) {                    
                    innerSection.left -= deltaPillar;
                }
            }

            if (this._visibleCheck(objectIn, innerSection, null, this.angleAlpha ? this.angleAlpha : 0)) {
                return [];
            }
            let innerSectionWidth = innerSection.width;
            let userValueForInnerSection = {
                ...this.userValues,
                [FormulaHelper.FILL_WIDTH]: innerSectionWidth
            }
            let x = FormulaHelper.calcValue(objectIn.position.x, userValueForInnerSection);
            let y = FormulaHelper.calcValue(objectIn.position.y, userValueForInnerSection);
            let z = FormulaHelper.calcValue(objectIn.position.z, userValueForInnerSection);
            let objectOutCopy = JSON.parse(JSON.stringify(objectOut));
            objectOutCopy.position = {
                x: innerSection.left + x,
                y: y,
                z: z,
                y2: objectOut.position.y2
            };
            //if (isi > 0) {
            //    objectOutCopy.position.x -= pillarAWidth / 2;
            //}
            if (objectIn.length != null && objectIn.type == EditSketchConstants.OBJECT_TYPE_METALL) {
                objectOutCopy.length = FormulaHelper.calcValue(objectIn.length, userValueForInnerSection);
            }
            //mirror subsection
            if (this.sectionIn.isThroughOneMirror && isi % 2 == 1) {
                if (!objectIn.donotMirror) {
                    objectOutCopy.rotation.y += Math.PI;
                }
                objectOutCopy.position.x = innerSection.left + (innerSection.width - x);
            }
            if (this.sectionIn.isThroughOneMirror && isi % 2 == 1 && objectIn.skipInMirror) {
                objectOutCopy = null;
            }

            if (objectOutCopy) {
                objectsOut.push(objectOutCopy);
            }
        }
        return objectsOut;
    }

    _processComplexCopyItem2(objectsIn) {
        let outArr = [];
        let mainObjects = objectsIn.filter(o => !o.copy.minorComplex);
        let minorObject = objectsIn.find(o => o.copy.minorComplex);
        let centerObject = mainObjects.find(o => o.copy.centerObject);
        let minusShiftObjects = mainObjects.filter(o => !o.copy.centerObject && o.copy.shift < 0);

        if (centerObject == null || minorObject == null) {
            return outArr;
        }

        const setObjectToStepArray = (item, stepArr, currentStepIndex) => {
            const stepLength = parseInt(item.copy.complexLength);
            if (stepLength <= 1 || currentStepIndex + parseInt((stepLength - 1) / 2) < stepArr.length) {
                stepArr[currentStepIndex].objectId.push(item.key);
                stepArr[currentStepIndex].isSet = true;

                //use item width
                if (stepLength > 1) {
                    const oneWayCount = parseInt((stepLength - 1) / 2);
                    for (let li = 1; li <= oneWayCount; li++) {
                        if (stepArr[currentStepIndex - li] != null)
                            stepArr[currentStepIndex - li].isSet = true;
                        if (stepArr[currentStepIndex + li] != null)
                            stepArr[currentStepIndex + li].isSet = true;
                    }
                }
            }
        };


        let minorObjectOut = this._getObjectOutFromObjectIn(minorObject);
        let copyObjectsArr = this._processCopyItem(minorObject, minorObjectOut);
        for (let sectionIndex = 0; sectionIndex < copyObjectsArr.length; sectionIndex++) {
            let stepArr = copyObjectsArr[sectionIndex].position.x.map(x => ({
                objectId: [],
                isSet: false
            }));

            //main objects
            for (let objIndex = 0; objIndex < mainObjects.length; objIndex++) {
                const item = mainObjects[objIndex];
                const shift = parseInt(item.copy.shift);
                const multiplicity = parseInt(item.copy.multiplicity);

                let currentStepIndex = shift;
                while (currentStepIndex < stepArr.length) {
                    if (currentStepIndex < 0) {
                        currentStepIndex += multiplicity;
                        continue;
                    }

                    setObjectToStepArray(item, stepArr, currentStepIndex);

                    currentStepIndex += multiplicity;
                }
            }


            //make center
            let shiftStepsToRight = 0;
            let leftOffsetCurrent = stepArr.findIndex(step => step.objectId.find(i => i == centerObject.key) != null);
            let lastIndex = -1;
            for (let si = stepArr.length - 1; si >= 0; si--) {
                if (stepArr[si].objectId.find(i => i == centerObject.key) != null) {
                    lastIndex = si;
                    break;
                }
            }
            if (leftOffsetCurrent != -1 && lastIndex != -1) {
                let rightOffset = (stepArr.length - 1) - lastIndex;
                let leftOffsetNew = Math.ceil((leftOffsetCurrent + rightOffset) / 2);
                if (leftOffsetNew < leftOffsetCurrent) {
                    let shiftCount = leftOffsetCurrent - leftOffsetNew;
                    shiftStepsToRight = -shiftCount;
                    for (let i = 0; i < shiftCount; i++) {
                        stepArr.shift();
                        stepArr.push({
                            objectId: [],
                            isSet: false
                        });
                    }
                } else if (leftOffsetNew > leftOffsetCurrent) {
                    let shiftCount = leftOffsetNew - leftOffsetCurrent;
                    shiftStepsToRight = shiftCount;
                    for (let i = 0; i < shiftCount; i++) {
                        stepArr.pop();
                        stepArr.unshift({
                            objectId: [],
                            isSet: false
                        });
                    }
                }
            }


            //apply minusShiftObjects
            if (shiftStepsToRight > 0 && minusShiftObjects.length > 0) {
                for (let objIndex = 0; objIndex < minusShiftObjects.length; objIndex++) {
                    let objShitf = minusShiftObjects[objIndex].copy.shift;
                    if (shiftStepsToRight >= Math.abs(objShitf)) {
                        setObjectToStepArray(minusShiftObjects[objIndex], stepArr, shiftStepsToRight - Math.abs(objShitf));
                    }
                }
            }


            //fill with minor object
            for (let si = 0; si < stepArr.length; si++) {
                if (!stepArr[si].isSet) {
                    stepArr[si].objectId.push(minorObject.key);
                    stepArr[si].isSet = true;
                }
            }
            console.log(stepArr);


            //make outObjects
            for (let si = 0; si < stepArr.length; si++) {
                if (stepArr[si].objectId != null) {
                    stepArr[si].objectId.forEach(item => {
                        let o = objectsIn.find(oo => oo.key == item);
                        if (o.type != EditSketchConstants.OBJECT_TYPE_GROUP) {
                            let objectOut = this._getObjectOutFromObjectIn(o);
                            objectOut.position = {
                                x: copyObjectsArr[sectionIndex].position.x[si] + FormulaHelper.calcValue(o.position.x, this.userValues),
                                y: objectOut.position.y,
                                z: objectOut.position.z,
                                y2: objectOut.position.y2
                            };
                            objectOut.processType = EditSketchConstants.PROCESS_TYPE_SINGLE;
                            outArr.push(objectOut);
                        } else {
                            o = { ...o, processType: EditSketchConstants.PROCESS_TYPE_SINGLE };
                            let gItemArr = this._processGroup(o);
                            gItemArr.forEach(objectOut => {
                                objectOut.position = {
                                    x: objectOut.position.x + copyObjectsArr[sectionIndex].position.x[si],
                                    y: objectOut.position.y,
                                    z: objectOut.position.z,
                                    y2: objectOut.position.y2
                                };
                                objectOut.processType = EditSketchConstants.PROCESS_TYPE_SINGLE;
                                outArr.push(objectOut);
                            });
                        }
                    });
                }
            }
        }

        return outArr;
    }

    _processCopyItem(objectIn, objectOut, groupCenter) {
        let userValues = { ...this.userValues };
        const pillarAWidth = (this.sketch.sectionRotation && this.sketch.sectionRotation.pillarAWidth) ? MathHelper.mmToMeters(parseInt(this.sketch.sectionRotation.pillarAWidth)) : 0;
        const pillarWidth = (this.sketch.sectionRotation && this.sketch.sectionRotation.pillarWidth) ? MathHelper.mmToMeters(parseInt(this.sketch.sectionRotation.pillarWidth)) : 0;        
        //use innerSections from pillarA
        let outArr = [];        
        for (let isi = 0; isi < this.innerSections.length; isi++) {
            const innerSection = {
                ...this.innerSections[isi]
            };
            if (this.innerSections.length > 1 && objectIn.rotation.z != Math.PI / 2 &&
                pillarAWidth != 0 && pillarWidth != 0 && pillarAWidth != pillarWidth) {
                const deltaPillar = pillarWidth - pillarAWidth;
                innerSection.width += (isi == 0 || isi == this.innerSections.length - 1) ? deltaPillar : deltaPillar * 2;
                if (isi != 0) {
                    innerSection.left -= deltaPillar;
                }
            }

            if (this._visibleCheck(objectIn, innerSection, !this.sectionIn.floatHeight, this.angleAlpha == null ? 0 : null)) {
                continue;
            }
            userValues[FormulaHelper.FILL_WIDTH] = innerSection.width;
            if (innerSection.steps) {
                for (let stepIndex = 0; stepIndex < innerSection.steps.length; stepIndex++) {
                    if (innerSection.steps[stepIndex]) {
                        if (stepIndex == 0) {
                            userValues[FormulaHelper.STEP] = innerSection.steps[stepIndex];
                        } else {
                            userValues[FormulaHelper.STEP + (stepIndex)] = innerSection.steps[stepIndex];
                        }
                    }
                }
            }

            //let isHorizontal = objectIn.copy.isHorizontal != null ? objectIn.copy.isHorizontal : true;
            const copyDirection = objectIn.copy.isDepth ? 'z' : (objectIn.copy.isHorizontal == null || objectIn.copy.isHorizontal == true ? 'x' : 'y' );
            let stepX, stepY, stepZ;
            //TODO: add version support
            if (typeof (objectIn.copy.stepX) != 'object') {
                stepX = MathHelper.mmToMeters(parseInt(objectIn.copy.stepX));
                stepY = objectIn.copy.stepY != null ? MathHelper.mmToMeters(parseInt(objectIn.copy.stepY)) : 0;
                stepZ = objectIn.copy.stepZ != null ? MathHelper.mmToMeters(parseInt(objectIn.copy.stepZ)) : 0;
            }
            else {
                stepX = FormulaHelper.calcValue(objectIn.copy.stepX, userValues);
                stepY = FormulaHelper.calcValue(objectIn.copy.stepY, userValues);
                stepZ = FormulaHelper.calcValue(objectIn.copy.stepZ, userValues);

            }
            if (stepX == 0 && stepY == 0 && stepZ == 0) {
                continue;
            }
            let curveX, curveY, curveZ;
            let stepForCurve, offsetCurve;
            let xOffset = objectOut.position.x + innerSection.left;
            let yOffset = objectOut.position.y;
            let zOffset = objectOut.position.z;
            let firstIndent = FormulaHelper.calcValue(objectIn.copy.left, userValues);
            let secondIndent = FormulaHelper.calcValue(objectIn.copy.right, userValues);
            if (groupCenter != null && objectIn.copy.useGroupCenterForLeftRight) {
                if (copyDirection == 'x') {
                    firstIndent = groupCenter.x - firstIndent;
                    secondIndent = innerSection.width - groupCenter.x - secondIndent;
                } else if (copyDirection == 'y') {
                    firstIndent = groupCenter.y - firstIndent;
                    secondIndent = this.sectionHeight - groupCenter.y - secondIndent;                    
                } else if (copyDirection == 'z') {
                    firstIndent = groupCenter.z - firstIndent;
                    secondIndent = this.sectionDepth - groupCenter.z - secondIndent;                    
                }
            }
            
            let multiplicity = objectIn.copy.multiplicity != null ? parseInt(objectIn.copy.multiplicity) : 1;
            let shift = objectIn.copy.shift != null ? parseInt(objectIn.copy.shift) : 0;
            let copyLenght;
            let count;
            if (copyDirection == 'x') {
                copyLenght = innerSection.width - firstIndent - secondIndent;
                count = copyLenght / stepX;
            } else if (copyDirection == 'y') {
                copyLenght = this.sectionHeight - firstIndent - secondIndent;
                count = copyLenght / stepY;
            } else if (copyDirection == 'z') {
                copyLenght = this.sectionDepth - firstIndent - secondIndent;
                count = copyLenght / stepZ;
            }
            //if (isHorizontal) {
            //    copyLenght = innerSection.width - firstIndent - secondIndent;
            //    count = copyLenght / stepX;
            //}
            //else {
            //    copyLenght = this.sectionHeight - firstIndent - secondIndent;
            //    count = copyLenght / stepY;
            //}
            if (!objectIn.copy.constStep) {
                if (count % 1.0 <= parseFloat(objectIn.copy.k)) {
                    count = Math.floor(count);
                } else {
                    count = Math.ceil(count);
                }
                if (objectIn.copy.isEven && (count + 1) % 2 != 0) { //count+1 - number of items
                    count += 1;
                } else if (objectIn.copy.isOdd && (count + 1) % 2 != 1) {
                    count += 1;
                }
                if (copyDirection == 'x') {
                    stepX = copyLenght / count;
                } else if (copyDirection == 'y') {
                    stepY = copyLenght / count;
                } else if (copyDirection == 'z') {
                    stepZ = copyLenght / count;
                }
                //isHorizontal ? stepX = copyLenght / count : stepY = copyLenght / count;
            }
            else {
                count = Math.floor(count);

                if (objectIn.copy.isEven && (count + 1) % 2 != 0) { //count+1 - number of items
                    count -= 1;
                } else if (objectIn.copy.isOdd && (count + 1) % 2 != 1) {
                    count -= 1;
                }

                if (objectIn.copy.rightCorner == "center") {
                    if (copyDirection == 'x') {
                        let indent = copyLenght - count * stepX;
                        xOffset += indent / 2;
                    } else if (copyDirection == 'y') {
                        let indent = copyLenght - count * stepY;
                        yOffset += indent / 2;
                    } else if (copyDirection == 'z') {
                        let indent = copyLenght - count * stepZ;
                        zOffset += indent / 2;
                    }
                    //let indent = isHorizontal ? copyLenght - count * stepX : copyLenght - count * stepY;
                    //isHorizontal ? xOffset += indent / 2 : yOffset += indent / 2;
                }
                else if (objectIn.copy.rightCorner) {
                    if (copyDirection == 'x') {
                        let indent = copyLenght - count * stepX;
                        xOffset += indent;
                    } else if (copyDirection == 'y') {
                        let indent = copyLenght - count * stepY;
                        yOffset += indent;
                    } else if (copyDirection == 'z') {
                        let indent = copyLenght - count * stepZ;
                        zOffset += indent;
                    }
                    //let indent = isHorizontal ? copyLenght - count * stepX : copyLenght - count * stepY;
                    //isHorizontal ? xOffset += indent : yOffset += indent;
                }
            }
            if (copyDirection == 'x') {
                xOffset += firstIndent;
                offsetCurve = xOffset;
                stepForCurve = stepX;
            } else if (copyDirection == 'y') {
                yOffset += secondIndent;
                offsetCurve = yOffset;
                stepForCurve = stepY;
            } else if (copyDirection == 'z') {
                zOffset += firstIndent;
                offsetCurve = zOffset;
                stepForCurve = stepZ;
            }
            //if (isHorizontal) {
            //    xOffset += firstIndent;
            //    offsetCurve = xOffset;
            //    stepForCurve = stepX;
            //}
            //else {
            //    yOffset += secondIndent;
            //    offsetCurve = yOffset;
            //    stepForCurve = stepY;
            //}
            if (this._visibleCheckCopy(stepX, stepY, stepZ, objectIn)) {
                continue;
            }
            curveX = FormulaHelper.getCurvePosition(objectIn.copy.stepX, userValues, count, stepForCurve);
            curveY = FormulaHelper.getCurvePosition(objectIn.copy.stepY, userValues, count, stepForCurve);
            curveZ = FormulaHelper.getCurvePosition(objectIn.copy.stepZ, userValues, count, stepForCurve);
            let objectOutCopy = JSON.parse(JSON.stringify(objectOut));
            objectOutCopy.position = {
                x: [],
                y: [],
                z: [],
                y2: objectOut.position.y2
            };
            let curveShiftX = 0;
            let curveShiftY = 0;
            let curveShiftZ = 0;
            if (curveX.length > 0) {
                curveShiftX = curveX[0].v;
                zOffset += curveY[0].offset;
                stepZ = curveX[0].xStepNew;
            }
            if (curveY.length > 0) {
                curveShiftY = curveY[0].v;
                xOffset += curveY[0].offset;
                if (copyDirection == 'z') {
                    stepZ = curveY[0].xStepNew;
                } else {
                    stepX = curveY[0].xStepNew;
                }
            }
            if (curveZ.length > 0) {
                curveShiftZ = curveZ[0].v;
            }
            if (shift == 0 && !objectIn.copy.hideFirstLast) {
                objectOutCopy.position.x.push(xOffset + curveShiftX);
                objectOutCopy.position.y.push(yOffset + curveShiftY);
                objectOutCopy.position.z.push(zOffset + curveShiftZ);
            }
            for (let i = 0; i < count; i++) {
                if (curveX.length >= i + 1) {
                    curveShiftX = curveX[i + 1].v;
                }
                if (curveY.length >= i + 1) {
                    curveShiftY = curveY[i + 1].v;
                }
                if (curveZ.length >= i + 1) {
                    curveShiftZ = curveZ[i + 1].v;
                }
                xOffset += stepX;
                yOffset += stepY;
                zOffset += stepZ;
                if (objectIn.copy.hideFirstLast) {
                    if (i == count - 1) {
                        continue;
                    }
                }
                if (multiplicity == 1 || (i + 2 - shift) % multiplicity == 1) {
                    objectOutCopy.position.x.push(xOffset + curveShiftX);
                    objectOutCopy.position.y.push(yOffset + curveShiftY);
                    objectOutCopy.position.z.push(zOffset + curveShiftZ);
                }
            }
            if (typeof(objectIn.copy.stepX) == "object" && (objectIn.copy.stepY.perpendicular || objectIn.copy.stepX.perpendicular)) {
                let temp_x = [];
                let temp_y = [];
                let temp_z = [];
                for (let i = 0; i <= count; i++) {
                    temp_x.push(objectOutCopy.rotation.x);
                    temp_y.push(objectOutCopy.rotation.y);
                    temp_z.push(objectOutCopy.rotation.z);
                }
                let coef = 1;
                if (objectIn.copy.stepY.v == "-") {
                    coef = -1;
                }
                if (objectIn.copy.stepY.perpendicular) {
                    curveY.forEach((item, itemIndex) => {
                        if (copyDirection == 'z') {
                            temp_x[itemIndex] += -item.a * coef;
                        } else {
                            temp_z[itemIndex] += item.a * coef;
                        }
                    });
                }
                if (objectIn.copy.stepX.perpendicular) {
                    curveX.forEach((item, itemIndex) => {
                        temp_y[itemIndex] += item.a * coef;
                    });
                }
                objectOutCopy.rotation.x = temp_x;
                objectOutCopy.rotation.y = temp_y;
                objectOutCopy.rotation.z = temp_z;
            }

            //mirror subsection
            if (this.sectionIn.isThroughOneMirror && isi % 2 == 1) {
                if (!objectIn.donotMirror) {
                    if (typeof (objectOutCopy.rotation.y) == "number") {
                        objectOutCopy.rotation.y += Math.PI;
                    } else {
                        objectOutCopy.rotation.y = objectOutCopy.rotation.y.map(yy => yy + Math.PI);
                    }
                }
                objectOutCopy.position.x = objectOutCopy.position.x.map(xx => innerSection.left + (innerSection.left + innerSection.width - xx));
            }
            if (this.sectionIn.isThroughOneMirror && isi % 2 == 1 && objectIn.skipInMirror) {
                objectOutCopy = null;
            }

            if (objectOutCopy) {
                outArr.push(objectOutCopy);
            }
        }
        return outArr;
    }

    _processGroup(groupObjectIn, useStairsCorrection) {
        let objectsOut = [];
        let userValues = this.userValues;

        //build centers of each group
        let centerPositions = [];
        let rotationObject = {x:[], y:[], z:[]};
        if (groupObjectIn.processType == EditSketchConstants.PROCESS_TYPE_SINGLE) {
            if (groupObjectIn.copyToInnerSection) {
                let groupObjectOut = this._getObjectOutFromObjectIn(groupObjectIn);
                let arr = this._processCopyToInnerSection(groupObjectIn, groupObjectOut);
                if (useStairsCorrection) {
                    arr.forEach(item => this._correctStairsPositionY(item));
                }
                for (let i = 0; i < arr.length; i++) {
                    centerPositions.push(arr[i].position);
                }
                userValues = {
                    ...this.userValues,
                    [FormulaHelper.FILL_WIDTH]: this.innerSections[0].width
                };
            } else {
                let pos = {
                    x: FormulaHelper.calcValue(groupObjectIn.position.x, userValues),
                    y: FormulaHelper.calcValue(groupObjectIn.position.y, userValues),
                    z: FormulaHelper.calcValue(groupObjectIn.position.z, userValues)
                };
                if (useStairsCorrection) {
                    pos.y = this._getCorrectionStairsY(pos.x, pos.y);
                }
                centerPositions.push(pos);
            }
        } else {
            let groupObjectOut = this._getObjectOutFromObjectIn(groupObjectIn);
            let copyArr = this._processCopyItem(groupObjectIn, groupObjectOut);
            for (let i = 0; i < copyArr.length; i++) {
                const position = copyArr[i].position;
                for (let pi = 0; pi < position.x.length; pi++) {
                    let pos = {
                        x: position.x[pi],
                        y: position.y[pi],
                        z: position.z[pi]
                    };
                    if (useStairsCorrection) {
                        pos.y = this._getCorrectionStairsY(pos.x, pos.y);
                    }
                    centerPositions.push(pos);
                }
                if (Array.isArray(copyArr[i].rotation.x)) {
                    rotationObject.x = [...rotationObject.x, ...copyArr[i].rotation.x];
                    rotationObject.y = [...rotationObject.y, ...copyArr[i].rotation.y];
                    rotationObject.z = [...rotationObject.z, ...copyArr[i].rotation.z];
                }
            }
        }

        const gRotationx = (typeof (groupObjectIn.rotation.x) == "object" ? FormulaHelper.calcValue(groupObjectIn.rotation.x, this.userValues) : MathHelper.degreeToRad(parseFloat(groupObjectIn.rotation.x)));
        const gRotationy = (typeof (groupObjectIn.rotation.y) == "object" ? FormulaHelper.calcValue(groupObjectIn.rotation.y, this.userValues) : MathHelper.degreeToRad(parseFloat(groupObjectIn.rotation.y)));
        const gRotationz = (typeof (groupObjectIn.rotation.z) == "object" ? FormulaHelper.calcValue(groupObjectIn.rotation.z, this.userValues) : MathHelper.degreeToRad(parseFloat(groupObjectIn.rotation.z)));

        let widthOrig = MathHelper.mmToMeters(parseFloat(groupObjectIn.sizeOriginal.x));
        let heightOrig = MathHelper.mmToMeters(parseFloat(groupObjectIn.sizeOriginal.y));
        let widthTarget = FormulaHelper.calcValue(groupObjectIn.sizeTarget.x, userValues);
        let heightTarget = FormulaHelper.calcValue(groupObjectIn.sizeTarget.y, userValues);
        let scaleX = widthTarget / widthOrig;
        let scaleY = heightTarget / heightOrig;

        if (!groupObjectIn.isEditorGroup) {
            //process items in each group
            for (let gi = 0; gi < centerPositions.length; gi++) {
                const gPosx = centerPositions[gi].x;
                const gPosy = centerPositions[gi].y;
                const gPosz = centerPositions[gi].z;
                let useAsElementIndex = SectionContentBuilder.getUseAsElementIndex(groupObjectIn.useAsElement);
                for (let i = 0; i < groupObjectIn.objects.length; i++) {
                    let objectIn = groupObjectIn.objects[i];
                    let objectOut = this._getObjectOutFromObjectIn(objectIn, userValues);
                    if (groupObjectIn.useAsElement && !objectIn.calculateAsNotGroupElement) {
                        objectOut.calcAsElementOf = groupObjectIn.useAsElement + "&" + useAsElementIndex;
                    }
                    if (objectOut.type == EditSketchConstants.OBJECT_TYPE_METALL && (scaleX != 1 || scaleY != 1)) {
                        this._scaleMetalSpline(objectOut, scaleX, scaleY);
                    }
                    if (objectOut.type == EditSketchConstants.OBJECT_TYPE_ELEMENT) {
                        objectOut.position.x *= scaleX;
                        objectOut.position.y *= scaleY;
                    }
                    objectOut.position.x += gPosx;
                    objectOut.position.y += gPosy;

                    objectOut.position.z += gPosz;
                    objectOut.rotation.x += gRotationx;
                    objectOut.rotation.y += gRotationy;
                    objectOut.rotation.z += gRotationz;
                    //rotation of group
                    if (gRotationz != 0) {
                        let pNew = this._rotatePoint(objectOut.position, { x: gPosx, y: gPosy }, gRotationz);
                        objectOut.position.x = pNew.x;
                        objectOut.position.y = pNew.y;
                    }
                    if (gRotationy != 0) {
                        let pNew = this._rotatePoint(
                            { x: objectOut.position.x, y: -objectOut.position.z },
                            { x: gPosx, y: gPosz },
                            gRotationy);
                        objectOut.position.x = pNew.x;
                        objectOut.position.z = -pNew.y;
                    }
                    if (gRotationx != 0) {
                        let pNew = this._rotatePoint(
                            { x: -objectOut.position.z, y: objectOut.position.y },
                            { x: gPosz, y: gPosy },
                            gRotationx);
                        objectOut.position.z = -pNew.x;
                        objectOut.position.y = pNew.y;
                    }
                    objectsOut.push(objectOut);
                }
            }
        } else {
            const groupObjectOut = this._getObjectOutFromObjectIn(groupObjectIn);
            if (rotationObject.x.length) {
                groupObjectOut.rotation = rotationObject;
            }
            groupObjectOut.cloneOf = groupObjectOut.key;           
            if (!useStairsCorrection) {
                groupObjectOut.children = [];
                this._buildGroupInnerObjects(groupObjectIn, groupObjectOut, userValues, scaleX, scaleY, useStairsCorrection);

                groupObjectOut.processType = EditSketchConstants.PROCESS_TYPE_COPY;
                groupObjectOut.position = { x: [], y: [], z: [] };
                for (let gi = 0; gi < centerPositions.length; gi++) {
                    groupObjectOut.position.x.push(centerPositions[gi].x);
                    groupObjectOut.position.y.push(centerPositions[gi].y);
                    groupObjectOut.position.z.push(centerPositions[gi].z);
                }
                objectsOut.push(groupObjectOut);
            } else { // for stairs split opbjects with inner children
                for (let gi = 0; gi < centerPositions.length; gi++) {
                    let groupObjectOutCopy = JSON.parse(JSON.stringify(groupObjectOut));
                    groupObjectOutCopy.position = {
                        x: centerPositions[gi].x,
                        y: centerPositions[gi].y,
                        z: centerPositions[gi].z,
                    };
                    groupObjectOutCopy.processType = EditSketchConstants.PROCESS_TYPE_SINGLE;
                    groupObjectOutCopy.children = [];
                    this._buildGroupInnerObjects(groupObjectIn, groupObjectOutCopy, userValues, scaleX, scaleY, useStairsCorrection);
                    objectsOut.push(groupObjectOutCopy);
                }
            }

            
        }
        return objectsOut;
    }

    _buildGroupInnerObjects(groupObjectIn, groupObjectOut, userValues, scaleX, scaleY, useStairsCorrection) {
        let useAsElementIndex = SectionContentBuilder.getUseAsElementIndex(groupObjectIn.useAsElement);
        for (let i = 0; i < groupObjectIn.objects.length; i++) {
            let objectIn = groupObjectIn.objects[i];
            let oOut = this._getObjectOutFromObjectIn(objectIn, userValues);
            let copyArr = [];
            if (objectIn.processType == EditSketchConstants.PROCESS_TYPE_SINGLE) {
                if (!objectIn.copyToInnerSection) {
                    if (this._visibleCheck(objectIn, null, !this.sectionIn.floatHeight, 0)) {
                        continue;
                    }
                }
                if (useStairsCorrection) {
                    const oOutTmp = JSON.parse(JSON.stringify(oOut));
                    oOutTmp.position.x += groupObjectOut.position.x;
                    this._correctStairsPositionY(oOutTmp,
                        objectIn.isPerpendicular,
                        oOutTmp.position.y2,
                        objectIn.ladderAngle);
                    oOut.rotation = oOutTmp.rotation;
                }
                copyArr = [oOut];
            } else {
                const copyDirection = objectIn.copy.isDepth ? 'z' : (objectIn.copy.isHorizontal == null || objectIn.copy.isHorizontal == true ? 'x' : 'y');
                const copyArrTmp = this._processCopyItem(objectIn, oOut, groupObjectOut.position);
                copyArr = this._splitCopyArr(copyArrTmp);
                for (let pi = 0; pi < copyArr.length; pi++) {
                    if (copyDirection == 'x') {
                        copyArr[pi].position.x -= groupObjectOut.position.x;
                    } else if (copyDirection == 'y') {
                        copyArr[pi].position.y -= groupObjectOut.position.y;
                    } else if (copyDirection == 'z') {
                        copyArr[pi].position.z -= groupObjectOut.position.z;
                    }
                }
            }
            copyArr.forEach((objectOut) => {
                if (groupObjectIn.useAsElement && !objectIn.calculateAsNotGroupElement) {
                    objectOut.calcAsElementOf = groupObjectIn.useAsElement + "&" + useAsElementIndex;
                }
                if (objectOut.type == EditSketchConstants.OBJECT_TYPE_METALL && (scaleX != 1 || scaleY != 1)) {
                    this._scaleMetalSpline(objectOut, scaleX, scaleY);
                }
                if (objectOut.type == EditSketchConstants.OBJECT_TYPE_ELEMENT) {
                    objectOut.position.x *= scaleX;
                    objectOut.position.y *= scaleY;
                }
                groupObjectOut.children.push(objectOut);
            });
        }
    }

    _scaleMetalSpline(objectOut, scaleX, scaleY) {
        if (objectOut.points == null) {
            objectOut.points =
                [{ x: 0, y: -(objectOut.length / 2), z: 0 },
                { x: 0, y: (objectOut.length / 2), z: 0 }];
        }
        const angleRad = objectOut.rotation.z;
        let pointsNew = [];
        for (let i = 0; i < objectOut.points.length; i++) {
            const pOrig = objectOut.points[i];
            let rx = (pOrig.x * Math.cos(angleRad) - pOrig.y * Math.sin(angleRad)) * scaleX;
            let ry = (pOrig.x * Math.sin(angleRad) + pOrig.y * Math.cos(angleRad)) * scaleY;
            pointsNew.push({ x: rx, y: ry, z: pOrig.z });
        }
        let lastPoint = pointsNew[pointsNew.length - 1];
        let angleNewRad = 0;
        if (Math.sign(lastPoint.y) < 0) {
            angleNewRad = -1 * (Math.PI - Math.atan(lastPoint.x / lastPoint.y));
        } else {
            angleNewRad = Math.atan(lastPoint.x / lastPoint.y);
        }
        for (let i = 0; i < pointsNew.length; i++) {
            let p = pointsNew[i];
            let xn = p.x * Math.cos(angleNewRad) - p.y * Math.sin(angleNewRad);
            let yn = p.x * Math.sin(angleNewRad) + p.y * Math.cos(angleNewRad);
            p.x = xn;
            p.y = yn;
        }
        objectOut.points = pointsNew;
        objectOut.rotation.z = -angleNewRad;
        objectOut.length = -objectOut.points[0].y * 2;
        objectOut.position.x = objectOut.position.x * scaleX;
        objectOut.position.y = objectOut.position.y * scaleY;
    }

    _rotatePoint(point, center, angleRad) {
        var rotatedX = Math.cos(angleRad) * (point.x - center.x) - Math.sin(angleRad) * (point.y - center.y) + center.x;
        var rotatedY = Math.sin(angleRad) * (point.x - center.x) + Math.cos(angleRad) * (point.y - center.y) + center.y;
        return { x: rotatedX, y: rotatedY };
    }

    _makeSectionCurve(objectsOut) {
        const segmentHeight = MathHelper.mmToMeters(parseInt(this.sectionIn.curveHeight));

        for (let objectIndex = 0; objectIndex < objectsOut.length; objectIndex++) {
            const object = objectsOut[objectIndex];

            if (object.type == EditSketchConstants.OBJECT_TYPE_METALL
                && object.processType == EditSketchConstants.PROCESS_TYPE_SINGLE
                && object.rotation.z == Math.PI/2) {
                //TODO: correct with length
                object.segmentHeight = Math.abs(segmentHeight) * (object.length / this.sectionWidth);
                object.segmentDirection = { x: Math.sign(segmentHeight), y: 0, z: 0 };
            }


        }
    }

    //#region stairs

    buildTemplateSectionStairs(sectionIn, sketch, hidePillar1, hidePillar2) {
        this.sectionIn = sectionIn;
        this.sketch = sketch;
        this.hidePillar1 = hidePillar1;

        let objectsOut = [];
        this.l1 = MathHelper.mmToMeters(parseInt(this.sectionIn.l1));
        this.l2 = MathHelper.mmToMeters(parseInt(this.sectionIn.l2));
        this.l = MathHelper.mmToMeters(parseInt(this.sectionIn.length));
        this.hStairs = MathHelper.mmToMeters(parseInt(this.sectionIn.hStairs));
        if (this.hStairs < 0) {
            this.hStairs = Math.abs(this.hStairs);
            this.stairsReverse = true;
        }
        if (this.sectionIn.type == SectionHelper.SECTION_STAIRS2) {
            this.stairsCount = parseInt(this.sectionIn.stairsCount) - 1;
            this.hStairSingle = this.hStairs / (this.stairsCount + 1);
            this.widthStairSingle = this.l / this.stairsCount;
            this.l1 -= this.widthStairSingle / 2;
            this.l2 -= this.widthStairSingle / 2;
            this.l += this.widthStairSingle;
        }
        this.sectionWidth = this.l1 + this.l + this.l2;
        this.sectionHeight = this.sectionIn.height != null ? MathHelper.mmToMeters(parseInt(this.sectionIn.height)) : MathHelper.mmToMeters(parseInt(this.sketch.height));
        this.angleAlpha = Math.atan(this.hStairs / this.l);

        this.userValues = {
            [FormulaHelper.FILL_WIDTH]: this.sectionWidth,
            [FormulaHelper.FILL_HEIGHT]: this.sectionHeight,
            [FormulaHelper.STEP]: 0,
            [FormulaHelper.STAIR_HEIGHT]: this.hStairSingle,
            ...this._getExtraVars()
        };

        this.heightRail = FormulaHelper.calcValue(this.sketch.heightRail, this.userValues);
        this.heightRailPerpendicular = this.heightRail * Math.cos(this.angleAlpha);

        if (this.sectionIn.type == SectionHelper.SECTION_STAIRS2) {
            this._buildStairs(objectsOut);
        }



        //split section to inner sections by pillar A
        this.innerSections = this._getInnerSections();
        if (this.sectionIn.type == SectionHelper.SECTION_STAIRS2) {
            this._correctInnerSectionsForStairs();
        }

        const globalSteps = this._getGlobalStep();
        for (let i = 0; i < globalSteps.length; i++) {
            if (globalSteps[i]) {
                if (i == 0) {
                    this.userValues[FormulaHelper.STEP] = globalSteps[i];
                } else {
                    this.userValues[FormulaHelper.STEP + (i)] = globalSteps[i];
                }
            }
        }

        this._attachConstStepRemain();

        let complexCopy = [];

        let objects = [...this.sketch.objects];
        for (let i = 0; i < objects.length; i++) {
            let objectIn = objects[i];

            if (objectIn.tag == EditSketchConstants.PILLAR1 && (!this.sectionIn.pillar1 || this.hidePillar1)) {
                continue;
            }
            if (objectIn.tag == EditSketchConstants.PILLAR2 && (!this.sectionIn.pillar2 || hidePillar2)) {
                continue;
            }

            //if (objectIn.copyToInnerSection && objectIn.notInnersectionIfNoPillarA
            //    && (!this.sectionIn.pillarA || this.sectionIn.pillarACount <= 0)) {
            //    objectIn.copyToInnerSection = false;
            //}

            if (objectIn.processType == EditSketchConstants.PROCESS_TYPE_SINGLE && !objectIn.copyToInnerSection) {
                if (this._visibleCheck(objectIn, null, !this.sectionIn.floatHeight, this.angleAlpha)) {
                    continue;
                }
            }

            if (objectIn.type == EditSketchConstants.OBJECT_TYPE_GROUP) {
                let arr = this._processGroup(objectIn, true);
                objectsOut.push(...arr);
                continue;
            }

            let objectOut = this._getObjectOutFromObjectIn(objectIn);

            //special horizontal metall
            if (objectIn.isStairsHorizontal) {
                if (objectIn.copyToInnerSection) {
                    if (this._visibleCheck(objectIn, this.innerSections[0], null, this.angleAlpha ? this.angleAlpha : 0)) {
                        continue;
                    }
                }
                this._processHorizontalSegments(objectOut, objectsOut, objectIn);
                continue;
            }
            if (objectIn.type == EditSketchConstants.OBJECT_TYPE_METALL && objectOut.rotation.z == Math.PI / 2) {
                const pillarWidth = (this.sketch.sectionRotation && this.sketch.sectionRotation.pillarWidth) ? MathHelper.mmToMeters(parseInt(this.sketch.sectionRotation.pillarWidth)) : 0;
                const sectionWidth = this.sectionWidth - pillarWidth * 2;
                if (sectionWidth * 0.90 <= objectOut.length && objectOut.length <= this.sectionWidth * 1.10) {
                    if (objectIn.copyToInnerSection) {
                        if (this._visibleCheck(objectIn, this.innerSections[0], null, this.angleAlpha ? this.angleAlpha : 0)) {
                            continue;
                        }
                    }
                    this._processHorizontalSegments(objectOut, objectsOut, objectIn);
                    continue;
                }
            }

            if (objectIn.tag == EditSketchConstants.PILLARA) {
                let pillarAArr = this._processPillarA(objectOut, objectsOut);
                pillarAArr.forEach(item => this._correctStairsPositionY(item));
                continue;
            }
            if (objectIn.pillarAAlternative) {
                if (this.innerSections.length > 1
                    && (!this.sectionIn.pillarA || this.sectionIn.pillarACount <= 0)) {                    
                    let pillarAArr = this._processPillarA(objectOut, objectsOut, true);
                    pillarAArr.forEach(item => this._correctStairsPositionY(item));
                }
                continue;
            }

            if (objectIn.processType == EditSketchConstants.PROCESS_TYPE_SINGLE) {
                this._correctStairsPositionY(objectOut,
                    objectIn.isPerpendicular && objectIn.processType == EditSketchConstants.PROCESS_TYPE_SINGLE && !objectIn.copyToInnerSection,
                    objectOut.position.y2,
                    objectIn.ladderAngle);
            }

            if (this.l1 < 0 && objectIn.tag == EditSketchConstants.PILLAR1) { //correct to floor
                let dy = objectOut.position.y - objectOut.length / 2;
                objectOut.length += dy;
                objectOut.position.y -= dy / 2;
            }


            if (objectIn.copyToInnerSection) {
                let arr = this._processCopyToInnerSection(objectIn, objectOut);
                arr.forEach(item => this._correctStairsPositionY(item, objectIn.isPerpendicular, null, false));
                objectsOut.push(...arr);
                continue;
            }

            if (objectIn.processType == EditSketchConstants.PROCESS_TYPE_COPY) {
                if (objectIn.copy.shift2 != null) {
                    objectIn.copy.shift2.map((item) => {
                        let objectInNewShift = {
                            ...objectIn,
                            copy: {
                                ...objectIn.copy,
                                shift: item,
                                shift2: null,
                            }
                        };
                        objects.push(objectInNewShift);
                    })
                }
                if (objectIn.copy.isComplex) {
                    complexCopy.push(objectIn);
                    continue;
                }
                if (objectIn.isPerpendicular == true) {
                    let copyArr = this._processCopyItemStairsSegment(objectIn, objectOut);
                    objectsOut.push(...copyArr);
                } else {
                    let copyArr = this._processCopyItem(objectIn, objectOut);
                    let filtredCopy = [];
                    copyArr.forEach((item) => filtredCopy.push(this._visibleCheckAlfaForSimpleCopy(objectIn, item)));
                    copyArr = filtredCopy;
                    if (objectIn.type == EditSketchConstants.OBJECT_TYPE_METALL) {
                        copyArr = this._splitCopyArr(copyArr);
                    }
                    copyArr.forEach(item => this._correctStairsPositionY(item, null, item.position.y2, objectIn.ladderAngle));
                    objectsOut.push(...copyArr);
                }
                continue;
            }

            objectsOut.push(objectOut);
        }

        if (complexCopy.length > 0) {
            let copyArr = this._processComplexCopyItem2(complexCopy);
            copyArr.forEach(item => this._correctStairsPositionY(item, null, item.position.y2, item.ladderAngle));
            objectsOut.push(...copyArr);
        }

        return {
            width: this.sectionWidth,
            sketch: {
                height: this.sectionHeight,
                objects: objectsOut
            }
        };
    }

    _splitCopyArr(copyArr) {
        let arr = [];
        copyArr.forEach(item => {
            for (let i = 0; i < item.position.x.length; i++) {
                let copyItem = JSON.parse(JSON.stringify(item));
                copyItem.position = {
                    x: item.position.x[i],
                    y: item.position.y[i],
                    z: item.position.z[i],
                    y2: item.position.y2,
                };
                if (Array.isArray(item.rotation.x)) {
                    copyItem.rotation = {
                        x: item.rotation.x[i],
                        y: item.rotation.y[i],
                        z: item.rotation.z[i],
                    };
                }
                copyItem.processType = EditSketchConstants.PROCESS_TYPE_SINGLE;
                arr.push(copyItem);
            }
        });
        return arr;
    }

    _visibleCheckAlfaForSimpleCopy(objectIn, objectOut) {
        let objectOutNew = {
            ...objectOut, position: {
                x: [...objectOut.position.x],
                y: [...objectOut.position.y],
                z: [...objectOut.position.z],
                y2: objectOut.position.y2
            }
        };        
        if (objectIn.visibleIntervalA != null) {            
            let xArr = [];
            let yArr = [];
            let zArr = [];
            objectOutNew.position.x.forEach((item, index) => {
                let deegre = 0;
                if (this.l1 < item && item < this.l1 + this.l) {
                    deegre = MathHelper.radToDegree(this.angleAlpha);
                }
                let passIntervals = objectIn.visibleIntervalA.filter((intv) => {
                    if (parseInt(intv.min) <= deegre && deegre <= parseInt(intv.max)) {
                        return true;
                    }
                    return false;
                });
                if (passIntervals.length > 0) {
                    xArr.push(objectOutNew.position.x[index]);
                    yArr.push(objectOutNew.position.y[index]);
                    zArr.push(objectOutNew.position.z[index]);
                }
            });

            objectOutNew.position.x = xArr;
            objectOutNew.position.y = yArr;
            objectOutNew.position.z = zArr;
        }
        return objectOutNew;
    }

    _correctStairsPositionY(objectOut, isPerpendicular, yPos2, isLadderAngle) {
        let stairsCoeff = (this.heightRail / this.sectionHeight);
        if (typeof (objectOut.position.x) == "number") {
            if (objectOut.type == EditSketchConstants.OBJECT_TYPE_METALL && objectOut.points == null) {
                const xPos = objectOut.position.x;
                if (this.l1 <= xPos && xPos < this.l1 + this.l && isLadderAngle) {
                    objectOut.rotation.z += this.angleAlpha;
                }
                let xSDelta = (this.sectionHeight - this.heightRail) / Math.tan(this.angleAlpha);
                let lenTop1 = this.l1 + xSDelta;
                let lenTop2 = this.l1 + this.l + xSDelta;
                if (xPos <= this.l1) {
                } else if (this.l1 < xPos && xPos < lenTop1) {
                    let dy = (xPos - this.l1) * Math.tan(this.angleAlpha);
                    dy *= objectOut.length / this.sectionHeight;
                    objectOut.length -= dy;
                    objectOut.position.y += dy / 2;
                } else if (lenTop1 <= xPos && xPos <= this.l1 + this.l) {
                    objectOut.position.y = this._getCorrectionStairsY(objectOut.position.x, objectOut.position.y, null, null, yPos2);
                    objectOut.length *= stairsCoeff;

                } else if (this.l1 + this.l < xPos && xPos < lenTop2) {
                    let dy = (lenTop2 - xPos) * Math.tan(this.angleAlpha);
                    dy *= objectOut.length / this.sectionHeight;
                    objectOut.length -= dy;
                    objectOut.position.y += !this.stairsReverse ? (this.hStairs - dy / 2) : -(this.hStairs - dy / 2);
                } else if (xPos >= lenTop2) {
                    objectOut.position.y += !this.stairsReverse ? this.hStairs : -this.hStairs;
                }
            } else {
                let outParams = {};
                objectOut.position.y = this._getCorrectionStairsY(objectOut.position.x, objectOut.position.y, false, outParams, yPos2);
                if (isPerpendicular && outParams.isStairsSegment) {
                    objectOut.rotation.z += this.angleAlpha;
                }
            }

        } else {
            let rotationZ = [];
            let rotationX = [];
            let rotationY = [];
            let rotationType = false;
            if (typeof (objectOut.rotation.z) == "object") {
                rotationType = true;
            }
            for (let i = 0; i < objectOut.position.x.length; i++) {
                if (isLadderAngle) {
                    if (rotationType) {
                        if (this.l1 <= objectOut.position.x[i] && objectOut.position.x[i] < this.l1 + this.l && isLadderAngle) {
                            objectOut.rotation.z[i] += this.angleAlpha;
                        }
                    } else {
                        rotationX.push(objectOut.rotation.x);
                        rotationY.push(objectOut.rotation.y);
                        if (this.l1 <= objectOut.position.x[i] && objectOut.position.x[i] < this.l1 + this.l && isLadderAngle) {
                            rotationZ.push(objectOut.rotation.z + this.angleAlpha);
                        }
                        else {
                            rotationZ.push(objectOut.rotation.z);
                        }
                    }
                }
                objectOut.position.y[i] = this._getCorrectionStairsY(objectOut.position.x[i], objectOut.position.y[i], null, null, yPos2);
            }
            if (!rotationType && isLadderAngle) {
                objectOut.rotation = { x: rotationX, y: rotationY, z: rotationZ };
            }
        }
    }

    _getCorrectionStairsY(xPos, yPos, isPerpendicular, outParams, yPos2) {
        let pYStairs = yPos2 != null ? yPos2 : (this.heightRail / this.sectionHeight) * yPos;
        let yDelta = yPos - pYStairs;
        let length1 = this.l1 + yDelta / Math.tan(this.angleAlpha);

        if (isPerpendicular) {
            let hhStairs = (xPos - this.l1) * Math.tan(this.angleAlpha);
            return yPos + hhStairs;
        }

        if (xPos <= length1) {
            if (outParams) outParams.isStairsSegment = false;
            return yPos;
        } else if (xPos >= (length1 + this.l)) {
            if (outParams) outParams.isStairsSegment = false;
            return !this.stairsReverse ? yPos + this.hStairs : yPos - this.hStairs;
        } else {
            if (outParams) outParams.isStairsSegment = true;
            let hhStairs = (xPos - this.l1) * Math.tan(this.angleAlpha);            
            return !this.stairsReverse ? pYStairs + hhStairs : pYStairs - hhStairs;
        }
    }

    _processHorizontalSegments(objectOut, objectsOut, objectIn) {
        let y = objectOut.position.y;
        let y2 = objectOut.position.y2 != null ? objectOut.position.y2 : objectOut.position.y;
        let pYStairs = objectOut.position.y2 != null ? objectOut.position.y2 : (this.heightRail / this.sectionHeight) * y2;
        let yDelta = y - pYStairs;
        let sideHalfB = objectIn.length.b ? MathHelper.mmToMeters(parseInt(objectIn.length.b)) / 2 : 0;

        let o1 = JSON.parse(JSON.stringify(objectOut));
        o1.length = this.l1 + (yDelta / Math.tan(this.angleAlpha)) * (!this.stairsReverse ? 1 : -1);
        o1.position.x = o1.length / 2;        

        let o3 = JSON.parse(JSON.stringify(objectOut));
        o3.length = this.l2 - (yDelta / Math.tan(this.angleAlpha)) * (!this.stairsReverse ? 1 : -1);
        o3.position.x = this.sectionWidth - o3.length / 2;
        o3.position.y += this.hStairs * (!this.stairsReverse ? 1 : -1);        

        let o2 = JSON.parse(JSON.stringify(objectOut));
        let l = this.l;
        if (o3.length < 0) {
            l += o3.length;
        }
        o2.length = l / Math.cos(this.angleAlpha);
        o2.position.x = o1.length + l / 2;
        o2.position.y = pYStairs + (this.hStairs / 2) * (!this.stairsReverse ? 1 : -1) + yDelta;
        if (o3.length < 0) {
            o2.position.y -= (this.l - l) / 2 * Math.tan(this.angleAlpha);
        }
        if (o1.length < 0) {
            o2.length -= Math.abs(o1.length) / Math.cos(this.angleAlpha);
            o2.position.x += Math.abs(o1.length) / 2;
            o2.position.y += (Math.abs(o1.length) * Math.tan(this.angleAlpha)) / 2;
        }
        o2.rotation.z += this.angleAlpha * (!this.stairsReverse ? 1 : -1);

        if (sideHalfB != 0) {
            o1.length += sideHalfB;
            o1.position.x -= sideHalfB / 2;
            o3.length += sideHalfB;
            o3.position.x += sideHalfB / 2;

            let dl1Cos = 0; let dl3Cos = 0;
            if (o1.length < 0) {
                dl1Cos = - o1.length / Math.cos(this.angleAlpha);
            }
            if (o3.length < 0) {
                dl3Cos = - o3.length / Math.cos(this.angleAlpha);
            }
            if (dl1Cos != 0 || dl3Cos != 0) {
                o2.length -= (dl1Cos + dl3Cos);
                o2.position.x += (-o1.length / 2) - (-o3.length / 2);
                o2.position.y += (-o1.length * Math.tan(this.angleAlpha)) / 2 - (-o3.length * Math.tan(this.angleAlpha)) / 2
            }
        }

        if (o1.length >= 0.001) {
            objectsOut.push(o1);
        }
        if (o3.length >= 0.001) {
            objectsOut.push(o3);
        }
                
        if (!objectIn.copyToInnerSection || this.innerSections.length <= 1) {
            objectsOut.push(o2);
        } else {            
            const pillarAWidth = (this.sketch.sectionRotation && this.sketch.sectionRotation.pillarAWidth) ? MathHelper.mmToMeters(parseInt(this.sketch.sectionRotation.pillarAWidth)) : 0;
            let lenHo2f = o2.length * Math.cos(this.angleAlpha);
            let x1o2f = o2.position.x - lenHo2f / 2;
            let x2o2f = o2.position.x + lenHo2f / 2;
            let lastYo2f = o1.position.y;
            for (let si = 0; si < this.innerSections.length; si++) {
                let o2copy = JSON.parse(JSON.stringify(o2));
                objectsOut.push(o2copy);

                let lenH = this.innerSections[si].width;
                let xo2 = this.innerSections[si].left + lenH / 2;
                if (si == 0) {
                    lenH -= x1o2f;
                    xo2 = x1o2f + lenH / 2;
                }
                if (si == this.innerSections.length - 1) {
                    lenH = x2o2f - this.innerSections[si].left;
                    xo2 = this.innerSections[si].left + lenH / 2;
                }
                let len = lenH / Math.cos(this.angleAlpha);
                let heighto2 = lenH * Math.tan(this.angleAlpha);
                o2copy.length = len;
                o2copy.position.x = xo2;
                o2copy.position.y = lastYo2f + (heighto2 / 2) * (!this.stairsReverse ? 1 : -1);
                lastYo2f += (heighto2 - pillarAWidth * Math.tan(this.angleAlpha)) * (!this.stairsReverse ? 1 : -1);
            }
        }
    }

    _processCopyItemStairsSegment(objectIn, objectOut) {
        let outArr = [];

        let userValues = { ...this.userValues, [FormulaHelper.FILL_HEIGHT]: this.heightRailPerpendicular };
        let posYViaPerpendicular = FormulaHelper.calcValue(objectIn.position.y2 ? objectIn.position.y2 : objectIn.position.y, userValues) / Math.cos(this.angleAlpha);
        let pYStairs = (this.heightRail / this.sectionHeight) * objectOut.position.y;
        let yDelta = objectOut.position.y - pYStairs;
        const lengthSegment1 = this.l1 + yDelta / Math.tan(this.angleAlpha);
        const lengthSegment3 = this.l2 - yDelta / Math.tan(this.angleAlpha);
        const xSegment2 = lengthSegment1;
        const xSegment3 = this.sectionWidth - lengthSegment3;
                
        const corner = objectIn.copy.rightCorner == "center" ? "center" :
            (objectIn.copy.rightCorner ? "right" : "left");

        let innerSections = this.innerSections;

        for (let isi = 0; isi < innerSections.length; isi++) {
            const innerSection = innerSections[isi];
            if (this._visibleCheck(objectIn, innerSection, !this.sectionIn.floatHeight, innerSection.rotationZ)) {
                continue;
            }

            if (innerSection.steps) {
                for (let stepIndex = 0; stepIndex < innerSection.steps.length; stepIndex++) {
                    if (innerSection.steps[stepIndex]) {
                        if (stepIndex == 0) {
                            userValues[FormulaHelper.STEP] = innerSection.steps[stepIndex];
                        } else {
                            userValues[FormulaHelper.STEP + (stepIndex)] = innerSection.steps[stepIndex];
                        }
                    }
                }
            }

            let isHorizontal = true;
            let stepX = FormulaHelper.calcValue(objectIn.copy.stepX, this.userValues);
            let stepXOblique = stepX;
            let stepY = 0;
            if (stepX == 0) {
                continue;
            }
            let xOffset = objectOut.position.x + innerSection.left;
            let yOffset = objectOut.position.y;
            let zOffset = objectOut.position.z;
            let firstIndent = FormulaHelper.calcValue(objectIn.copy.left, this.userValues);
            let secondIndent = FormulaHelper.calcValue(objectIn.copy.right, this.userValues);
            let multiplicity = objectIn.copy.multiplicity != null ? parseInt(objectIn.copy.multiplicity) : 1;
            let shift = objectIn.copy.shift != null ? parseInt(objectIn.copy.shift) : 0;
            let copyLenght;
            let count;

            let sectionWidthFull = 0;
            let obliqueWidth = innerSection.width;
            if (innerSection.left < xSegment2) {
                sectionWidthFull += lengthSegment1;
                obliqueWidth -= lengthSegment1;
            }
            if (innerSection.left + innerSection.width > xSegment3) {
                sectionWidthFull += lengthSegment3;
                obliqueWidth -= lengthSegment3;
            }
            let obliqueWidthFull = obliqueWidth / Math.cos(this.angleAlpha);
            sectionWidthFull += obliqueWidthFull;            

            copyLenght = sectionWidthFull - firstIndent - secondIndent;
            count = copyLenght / stepX;

            if (!objectIn.copy.constStep) {
                if (count % 1.0 <= parseFloat(objectIn.copy.k)) {
                    count = Math.floor(count);
                } else {
                    count = Math.ceil(count);
                }
                if (objectIn.copy.isEven && (count + 1) % 2 != 0) { //count+1 - number of items
                    count += 1;
                } else if (objectIn.copy.isOdd && (count + 1) % 2 != 1) {
                    count += 1;
                }
                isHorizontal ? stepX = copyLenght / count : stepY = copyLenght / count;
            }
            else {
                count = Math.floor(count);
                if (corner == "center") {
                    let indent = isHorizontal ? copyLenght - count * stepX : copyLenght - count * stepY;
                    isHorizontal ? xOffset += indent / 2 : yOffset += indent / 2;
                }
                else if (corner == "right") {
                    let indent = isHorizontal ? copyLenght - count * stepX : copyLenght - count * stepY;
                    isHorizontal ? xOffset += indent : yOffset += indent;
                }
            }
            stepXOblique = stepX * Math.cos(this.angleAlpha);

            xOffset += firstIndent;

            let objectOutCopy = JSON.parse(JSON.stringify(objectOut));
            objectOutCopy.position = {
                x: [],
                y: [],
                z: [],
                y2: objectOut.position.y2
            };

            objectOutCopy.rotation = {
                x: [],
                y: [],
                z: []
            }

            if (shift == 0 && !objectIn.copy.hideFirstLast) {
                let isOnOblique = ((xOffset) >= xSegment2 && (xOffset) <= xSegment3);
                yOffset = isOnOblique ? posYViaPerpendicular : objectOut.position.y;
                objectOutCopy.position.x.push(xOffset);
                objectOutCopy.position.y.push(this._getCorrectionStairsY(xOffset, yOffset, false));
                objectOutCopy.position.z.push(zOffset);
                objectOutCopy.rotation.x.push(objectOut.rotation.x);
                objectOutCopy.rotation.y.push(objectOut.rotation.y);
                objectOutCopy.rotation.z.push(objectOut.rotation.z + isOnOblique ? this.angleAlpha * (!this.stairsReverse ? 1 : -1) : 0);
            }
            for (let i = 0; i < count; i++) {
                let isOnOblique = ((xOffset + stepX) >= xSegment2 && (xOffset + stepX) <= xSegment3);
                xOffset += isOnOblique ? stepXOblique : stepX;
                yOffset = isOnOblique ? posYViaPerpendicular : objectOut.position.y;
                if (objectIn.copy.hideFirstLast) {
                    if (i == count - 1) {
                        continue;
                    }
                }
                if (multiplicity == 1 || (i + 2 - shift) % multiplicity == 1) {
                    objectOutCopy.position.x.push(xOffset);
                    objectOutCopy.position.y.push(this._getCorrectionStairsY(xOffset, yOffset, false));
                    objectOutCopy.position.z.push(zOffset);
                    objectOutCopy.rotation.x.push(objectOut.rotation.x);
                    objectOutCopy.rotation.y.push(objectOut.rotation.y);
                    objectOutCopy.rotation.z.push(objectOut.rotation.z + isOnOblique ? this.angleAlpha * (!this.stairsReverse ? 1 : -1) : 0);
                }
            }
            outArr.push(objectOutCopy);
        }
        return outArr;
    }

    _buildStairs(objectsOut) {
        const metallId = window.location.host == 'simplicad.com' ? 42 : 18;
        let horStair = {
            id: metallId,
            position: { x: [], y: [], z: [] },
            rotation: { x: 0, y: 0, z: MathHelper.degreeToRad(90) },
            length: this.widthStairSingle,
            processType: EditSketchConstants.PROCESS_TYPE_COPY,
            skipCalculation: true,
            type: EditSketchConstants.OBJECT_TYPE_METALL
        };

        let vertStair = {
            id: metallId,
            position: { x: [], y: [], z: [] },
            rotation: { x: 0, y: 0, z: 0 },
            length: this.hStairSingle,
            processType: EditSketchConstants.PROCESS_TYPE_COPY,
            skipCalculation: true,
            type: EditSketchConstants.OBJECT_TYPE_METALL
        };

        for (let i = 0; i < this.stairsCount; i++) {
            horStair.position.x.push(this.l1 + (i + 1) * this.widthStairSingle);
            horStair.position.y.push(((i + 1) * this.hStairSingle) * (!this.stairsReverse ? 1.0 : -1.0));
            horStair.position.z.push(0);

            vertStair.position.x.push(this.l1 + (i + 1) * this.widthStairSingle - this.widthStairSingle / 2);
            vertStair.position.y.push(((i + 1) * this.hStairSingle - this.hStairSingle / 2) * (!this.stairsReverse ? 1.0 : -1.0));
            vertStair.position.z.push(0);
        }
        vertStair.position.x.push(this.l1 + (this.stairsCount + 1) * this.widthStairSingle - this.widthStairSingle / 2);
        vertStair.position.y.push(((this.stairsCount + 1) * this.hStairSingle - this.hStairSingle / 2) * (!this.stairsReverse ? 1.0 : -1.0));
        vertStair.position.z.push(0);

        objectsOut.push(horStair);
        objectsOut.push(vertStair);

        objectsOut.push({
            id: metallId,
            position: {
                x: (this.l1 + this.widthStairSingle / 2) / 2,
                y: 0,
                z: 0
            },
            rotation: { x: 0, y: 0, z: MathHelper.degreeToRad(90) },
            length: this.l1 + this.widthStairSingle / 2,
            processType: EditSketchConstants.PROCESS_TYPE_SINGLE,
            skipCalculation: true,
            type: EditSketchConstants.OBJECT_TYPE_METALL
        });

        objectsOut.push({
            id: metallId,
            position: {
                x: this.sectionWidth - (this.l2 + this.widthStairSingle / 2) / 2,
                y: !this.stairsReverse ? this.hStairs : -this.hStairs,
                z: 0
            },
            rotation: { x: 0, y: 0, z: MathHelper.degreeToRad(90) },
            length: this.l2 + this.widthStairSingle / 2,
            processType: EditSketchConstants.PROCESS_TYPE_SINGLE,
            skipCalculation: true,
            type: EditSketchConstants.OBJECT_TYPE_METALL
        });
    }

    _correctInnerSectionsForStairs() {
        if (this.innerSections.length < 2)
            return;
        for (let i = 0; i < this.innerSections.length; i++) {
            let currentInnerSection = this.innerSections[i];
            let right = currentInnerSection.left + currentInnerSection.width;
            if (this.l1 < right && right < this.l1 + this.l) {
                let rightNew = Math.round((right - this.l1) / this.widthStairSingle) * this.widthStairSingle + this.l1;
                let dx = rightNew - right;
                currentInnerSection.width += dx;
                if (i + 1 < this.innerSections.length) {
                    this.innerSections[i + 1].left += dx;
                    this.innerSections[i + 1].width -= dx;
                }
            }
        }
    }
    //#endregion


    static useAsElementMap = {};
    static getUseAsElementIndex(element) {
        if (this.useAsElementMap[element] == null) {
            this.useAsElementMap[element] = -1;
        }
        this.useAsElementMap[element]++;
        return this.useAsElementMap[element];
    }
}