import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";

const UserRouteSeller = ({ isAuthenticated, isSeller , layout:Layout,isConfirmed , component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated && isSeller && isConfirmed ? 
      <Layout>
        <Component {...props} />
      </Layout>
      : 
      !isConfirmed ?  <Redirect to="/confirmaccount" /> :
      <Redirect to="/" />
    }
  />
);
UserRouteSeller.propTypes = {
  component: PropTypes.any.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};
function mapStateToProps(state) {
  return {
    isAuthenticated: state.login.isAuthenticated,
    isConfirmed: state.login.isConfirmed,
    isSeller: state.login.user.roles.includes("Seller")
  };
}

export default connect(mapStateToProps)(UserRouteSeller);
