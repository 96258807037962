import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    get: {
        data:{
            name: '',
            description: '',
            youtubeLink: ''
        },
        loading: false,
        success: false,
        failed: false,
    },
    edit: {
        loading: false,
        success: false,
        failed: false,
    },
    emailConfirm: {
        loading: false,
        success: false,
        failed: false,
    }
});

export const myInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_MYINFO_REQUEST: { 
            return initialState.merge({
                get:{
                    ...initialState.get,
                    loading:true
                }
            });
        }
        case types.GET_MYINFO_SUCCESS: {
            return state.merge({
                get:{
                    ...state.get,
                    loading:false,
                    success:true,
                    data:action.payload
                }
            });
        }
        case types.GET_MYINFO_FAILURE: {
            return state.merge({
                get:{
                    ...initialState.get,
                    loading:false,
                    failed:true
                }
            });
        }


        case types.SAVE_MYINFO_REQUEST: { 
            return initialState.merge({
                ...state,
                edit:{
                    ...initialState.edit,
                    loading:true
                }
            });
        }
        case types.SAVE_MYINFO_SUCCESS: {
            return state.merge({
                ...state,
                edit:{
                    ...state.edit,
                    loading:false,
                    success:true,
                    data:action.payload
                }
            });
        }
        case types.SAVE_MYINFO_FAILURE: {
            return state.merge({
                ...state,
                edit:{
                    ...state.edit,
                    loading:false,
                    failed:true,
                    errors:action.errors
                }
            });
        }

        case types.EDIT_MYINFO:{
            return state.merge({
                ...state,
                get:{
                    ...state.get,
                    data:{
                        ...state.get.data,
                        [action.payload.name]:action.payload.value
                    }

                }
            });
        }

        //#region SEND_EMAILCONFIRMATION
        case types.SEND_EMAILCONFIRMATION_REQUEST: {
            return state.merge({
                ...state,
                emailConfirm: {
                    ...state.emailConfirm,
                    loading: true
                }
            });
        }
        case types.SEND_EMAILCONFIRMATION_SUCCESS: {
            return state.merge({
                ...state,
                emailConfirm: {
                    ...state.emailConfirm,
                    loading: false,
                    success: true
                }
            });
        }
        case types.SEND_EMAILCONFIRMATION_FAILURE: {
            return state.merge({
                ...state,
                emailConfirm: {
                    ...state.emailConfirm,
                    loading: false,
                    failed: true
                }
            });
        }
        //#endregion

        default: {
            return state;
        }
    }
}