import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Media, Row, Col, Button, UncontrolledPopover, PopoverBody, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { MdFavoriteBorder, MdFavorite, MdShare } from "react-icons/md";
import { toastr } from 'react-redux-toastr'
import ProjectsExecuterHelper from '../../projectsExecuter/helper';
import LabelWithMoreText from '../../components/labelWithMoreText/LabelWithMoreText';
import Helper from '../../utils/helper';
import FavotireHelper from '../../utils/favoriteHelper';
import SharePopUp from '../catalog/SharePopUp';
import ImageScroller from '../../components/imageScroller/ImageScroller';
import { isMobile } from 'react-device-detect';

export default class ProductCard extends React.Component {
    constructor(props) {
        super(props);
        let isFavoriteChange = false;
        let isFavorite = false;
        if (!this.props.isAuthenticated) {
            const productId = parseInt(this.props.item.id);
            isFavoriteChange = true;
            const productIds = FavotireHelper.getFavoriteProducts();
            if (productIds.indexOf(productId) != -1)
                isFavorite = true;
        }

        this.state = {
            selectedImageIndex: 0,
            isRedirectToRegister: false,
            isFavoriteChange: isFavoriteChange,
            isFavorite: isFavorite,
            showAfterDemoPopup: false,
            showPopUp: false,
            showImagesPopup: false,
            logoImg: null,
            showTopManufacturers: true
        };
    }

    onFavoriteClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!this.isFavorite()) {
            if (this.props.isAuthenticated) {
                this.props.addFavorites({
                    productIds: [this.props.item.id]
                });
            } else {
                FavotireHelper.addFavoriteProduct(this.props.item.id);
                this.props.loadFavoriteLocal();
            }
            this.setState({
                isFavoriteChange: true,
                isFavorite: true
            });
        } else {
            if (this.props.isAuthenticated) {
                this.props.removeFavorites({
                    productIds: [this.props.item.id]
                });
            } else {
                FavotireHelper.removeFavoriteProduct(this.props.item.id);
                this.props.loadFavoriteLocal();
            }
            this.setState({
                isFavoriteChange: true,
                isFavorite: false
            });
        }
    }

    onConstructorOpenClickHandler = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.isAuthenticated == true) {
            this.props.onConstructorOpenClick(id);
        }
        else {
            this.setState({
                showAfterDemoPopup: true
            });
        }
    }

    onCreateProjectWithSketch = (e, sketchId) => {
        e.preventDefault();
        e.stopPropagation();


    }

    cancelAfterDemo = () => {
        this.hideAfterDemoPopup();
    }

    hideAfterDemoPopup = () => {
        this.setState({
            showAfterDemoPopup: false
        });
    }

    isFavorite = () => {
        return (this.state.isFavoriteChange || this.props.item == null) ? this.state.isFavorite : this.props.item.isFavorite;
    }
    showpopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showPopUp: !this.state.showPopUp });
    }
    hidepopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showPopUp: false });
    }

    onImageClick = (e) => {
        e.stopPropagation();
        this.setState({
            showImagesPopup: true
        });
    }

    hideImagesPopup = (e) => {
        if(e) e.stopPropagation();
        this.setState({
            showImagesPopup: false
        });
    }

    onMouseEnterLogo = () => {
        const item = this.props.item;
        if (item.productImages.length > 0) {
            this.setState({                
                logoImg: item.productImages[0].image
            });
            let imgIndex = 0;
            this.logoInterval = setInterval(() => {
                imgIndex++;
                if (imgIndex >= item.productImages.length)
                    imgIndex = 0;
                this.setState({
                    logoImg: item.productImages[imgIndex].image
                });
            }, 2000);
        }
    }

    onMouseLeaveLogo = () => {
        if (this.logoInterval) {
            clearInterval(this.logoInterval);
        }
        this.setState({
            logoImg: null
        });
    }

    onMouseEnterSelf = () => {
        this.setState({ showTopManufacturers: false });
    }

    onMouseLeaveSelf = () => {
        this.setState({ showTopManufacturers: true });
    }

    getPrice(m) {
        const installPrice = m.installPriceFactor ?
            Math.max((m.price.productionPrice + m.price.paintingPrice) * this.props.dimention * (m.installPriceFactor.percent / 100), m.installPriceFactor.minPrice)
            : m.price.installationPrice;
        const price = ((m.price.productionPrice + m.price.paintingPrice + m.price.concretingPrice)
            * this.props.dimention + installPrice + m.price.shippingPrice).toFixed(0);
        return price;
    }

    render() {
        const { item, t, isSelected, productType, dimention, isHomeCard, isSmallMode } = this.props;
        const { logoImg, showTopManufacturers } = this.state;
        let link = `${window.location.protocol}//${window.location.host}/product/${item.id}/1&0`;
        //link += "/home/productDetails/" + item.id;

        const manufacturers = item.priceManufacturers ?
            (showTopManufacturers ? item.priceManufacturers.slice(0, 2) : item.priceManufacturers.slice(0, 5))
            : [];

        return (
            <div className={"catalog-item-v3" + (isSelected ? " catalog-item-selected" : "")} onMouseEnter={this.onMouseEnterSelf} onMouseLeave={this.onMouseLeaveSelf}>                
                <div className={!isSmallMode ? "catalog-img" : "catalog-img catalog-img-small"}>
                    <img src={logoImg ? logoImg : item.firstSpecificationImage} alt={item.imageAlt}
                        onMouseEnter={this.onMouseEnterLogo} onMouseLeave={this.onMouseLeaveLogo} />
                </div>
                {item.executorProductImagesModerationCount > 0 &&
                    <div className="photo-executor-handler">{item.executorProductImagesModerationCount + t('catalogPage.photo')}</div>
                }
                {!isMobile &&
                    <>
                        <div>
                            <div className="catalog-item-name">{item.name} {(item.isNoContent ? "Д" : "") + item.id}</div>
                        </div>
                        {/*dimention && productType &&
                            <div className="align-center">{t('catalogPage.turnkey')} {dimention}{productType.generalUnits}</div>
                        */}
                        {manufacturers.map(m => (
                            <div key={m.executerId}>
                                {m.price &&
                                    <>
                                        {this.getPrice(m)}{t('currencyUah')} &nbsp;
                                    </>
                                }
                                {m.name}
                            </div>
                        ))}
                        {item.productionPricePerUnit != 0 && productType &&
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td>{t('manufacturer.production')}</td>
                                        <td className="align-right">{item.productionPricePerUnit.toFixed(0)}{t('currencyUah')}/{productType.generalUnits}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('manufacturer.painting')}</td>
                                        <td className="align-right">{item.paintPricePerUnit.toFixed(0)}{t('currencyUah')}/{productType.generalUnits}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('manufacturer.installation')}</td>
                                        <td className="align-right">{item.installPricePerUnit.toFixed(0)}{t('currencyUah')}/{productType.generalUnits}</td>
                                    </tr>
                                    {item.concretingPricePerUnit != 0 &&
                                        <tr>
                                            <td>{t('manufacturer.concreting')}</td>
                                            <td className="align-right">{item.concretingPricePerUnit.toFixed(0)}{t('currencyUah')}/{productType.generalUnits}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        }
                        {!isHomeCard && item.productSize &&
                            <div className="catalogSizeText">
                                {t('catalogPage.productSize')} {item.productSize.width * 1000}{t('mm')}*{item.productSize.height * 1000}{t('mm')}
                            </div>
                        }

                        {item.isNoContent &&
                            <div>{t('catalogPage.bottomPricev3')}</div>
                        }
                        {!isHomeCard &&
                            <div className="catalog-bottom-v2">
                                {isMobile ?
                                    <Button color="success" size="sm" >{t('catalogPage.bottomButtonv3')}</Button>
                                    :
                                    <div></div>
                                }
                                <div className="catalog-fav-v2">
                                    {item.productImages.length > 0 &&
                                        <img src="/img/icon-photos.png" style={{ height: "1.5em" }} onClick={this.onImageClick} />
                                    }
                                    {this.state.showPopUp ?
                                        <div style={{ position: "relative" }} onClick={this.hidepopup}>
                                            <SharePopUp
                                                className="popUp"
                                                itemName={item.name}
                                                link={link}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                    <MdShare size="1.5em" onClick={this.showpopup} style={{ verticalAlign: "text-bottom" }} />
                                    {this.isFavorite() ?
                                        <MdFavorite size="1.5em" color="#ff8707" style={{ verticalAlign: "text-bottom" }} onClick={this.onFavoriteClick} />
                                        :
                                        <MdFavoriteBorder size="1.5em" color="#ff8707" style={{ verticalAlign: "text-bottom" }} onClick={this.onFavoriteClick} />
                                    }
                                </div>
                            </div>
                        }
                    </>
                }
                {isMobile &&
                    <>
                        {item.productionPricePerUnit != 0 && productType &&
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td>{t('manufacturer.production')}</td>
                                        <td className="align-right">{item.productionPricePerUnit.toFixed(0)}{t('currencyUah')}/{productType.generalUnits}</td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                    </>
                }
                {this.state.showAfterDemoPopup &&
                    <Modal isOpen={true} toggle={this.hideAfterDemoPopup}>
                        <ModalBody className="pl-5 pr-5">
                            <div className="align-center">{t('productInfo.tryDemoText')}</div>
                        </ModalBody>
                        <ModalFooter>
                            <Link to="/login">
                                <Button color="success">{t('navbar.login')}</Button>
                            </Link>
                            <Link to="/register">
                                <Button color="success">{t('homeProject.adpRegister')}</Button>
                            </Link>
                            <Button color="secondary" onClick={this.cancelAfterDemo}>{t('homeProject.adpCancel')}</Button>
                        </ModalFooter>
                    </Modal>
                }
                {this.state.showImagesPopup &&
                    <ImageScroller
                        images={item.productImages}
                        selectFirst={true}
                        show={true}
                        srcLinkBuilder={(img) => img.image}
                        altBuilder={img => ""}
                        onHide={(e) => this.hideImagesPopup(e)}
                    />
                }
            </div>
        );
    }
}
