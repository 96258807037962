import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    projects: [],
    projects2: [],
    projectsWithoutMeasurer: [],
    projectsWithoutMeasurerRequested: false,
    callMeasurerSucess: false,
    priceFactors: null
});

export const myProjectsReducer = (state = initialState, action) => {
    switch (action.type) {
        //#region get mty projects
        case types.GET_MYPROJECTS_REQUEST: {
            return state.merge({
                loading: true,
                projects: [],
                projects2: []
            });
        }
        case types.GET_MYPROJECTS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                projects: action.payload
            });
        }
        case types.GET_MYPROJECTSV2_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                projects2: action.payload
            });
        }
        case types.GET_MYPROJECTS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_PROJECTS_WITHOUT_MEASURER
        case types.GET_PROJECTS_WITHOUT_MEASURER_REQUEST: {  
            return state;
        }
        case types.GET_PROJECTS_WITHOUT_MEASURER_SUCCESS: {
            return state.merge({
                projectsWithoutMeasurer: action.payload,
                projectsWithoutMeasurerRequested: true
            });
        }
        case types.GET_PROJECTS_WITHOUT_MEASURER_FAILURE: {
            return state;
        }
        //#endregion

        //#region CALL_MEASURER_REQUEST
        case types.CALL_MEASURER_REQUEST: {
            return state.merge({
                loading: true,
                callMeasurerSucess: false
            });
        }
        case types.CALL_MEASURER_SUCCESS: {
            return state.merge({
                loading: false,
                callMeasurerSucess: true
            });
        }
        case types.CALL_MEASURER_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region DELETE_PROJECT_REQUEST
        case types.DELETE_PROJECT_REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.DELETE_PROJECT_SUCCESS: {
            return state.merge({
                loading: false,
                success: true
            });
        }
        case types.DELETE_PROJECT_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region CREATE_PRODUCT
        case types.CREATE_PRODUCT_REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.CREATE_PRODUCT_SUCCESS: {
            return state.merge({
                loading: false,
                success: true
            });
        }
        case types.CREATE_PRODUCT_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion


        case types.REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }

        //#region CALCULATE_PRICE
        case types.CALCULATE_PRICE_SUCCESS: {
            let projects2 = [...state.projects2];
            const projectIndex = projects2.findIndex(p => p.id == action.payload.id);
            projects2[projectIndex] = action.payload;
            return state.merge({
                loading: false,
                success: true,
                projects2: projects2
            });
        }
        //#endregion

        case types.DELETE_PRODUCT_SUCCESS:
        case types.DELETE_PRODUCT_GROUP_SUCCESS: {
            return state.merge({
                loading: false,
                success: true
            });
        }

        case types.GET_SPECIFICATION_SUCCESS: {
            return state.merge({
                loading: false,
                success: true
            });
        }

        case types.UPDATE_PROJECT_NAME_SUCCESS: {
            let projects2 = [...state.projects2];
            let projIndex = projects2.findIndex(p => p.id == action.payload.projectId);
            projects2[projIndex] = {
                ...projects2[projIndex],
                name: action.payload.name
            };
            return state.merge({
                loading: false,
                success: true,
                projects2: projects2
            });
        }

        case types.CLOSE_PROJECT_SUCCESS: {
            let projects2 = [...state.projects2];
            let projIndex = projects2.findIndex(p => p.id == action.payload);
            projects2[projIndex] = {
                ...projects2[projIndex],
                isClosed: true
            };
            return state.merge({
                loading: false,
                success: true,
                projects2: projects2
            });
        }

        case types.GET_MY_PRICE_FACTOR: {
            return state.merge({
                loading: false,
                success: true,
                priceFactors: action.payload
            });
        }

        default: {
            return state;
        }
    }
}