export const REQUEST = 'siteCompany/REQUEST';
export const FAILURE = 'siteCompany/FAILURE';
export const SUCCESS = 'siteCompany/SUCCESS';

export const LOAD_DETAILS = 'siteCompany/LOAD_DETAILS';

export const SUCCESS_LOGO_LOAD = 'siteCompany/SUCCESS_LOGO_LOAD';

export const SUCCESS_BGI_LOAD = 'siteCompany/SUCCESS_BGI_LOAD';

export const SUCCESS_PRODUCT_LOAD = 'siteCompany/SUCCESS_PRODUCT_LOAD';

export const GET_MY_COMPANY_SITE = 'siteCompany/GET_MY_COMPANY_SITE';

export const GET_TRANSACTION_DATA = 'siteCompany/GET_TRANSACTION_DATA';

export const UPDATE_SITE = 'siteCompany/UPDATE_SITE';
export const UPDATE_SITE_FAILURE = 'siteCompany/UPDATE_SITE_FAILURE';

export const LOAD_SITE = 'siteCompany/LOAD_SITE';
export const LOAD_SITE_FAILURE = 'siteCompany/LOAD_SITE_FAILURE';

export const CREATE_SITE = 'siteCompany/CREATE_SITE';
export const CREATE_SITE_FAILURE = 'siteCompany/CREATE_SITE_FAILURE';

export const REQUEST_PRODUCTS = 'siteCompany/REQUEST_PRODUCTS';