import React, { Component } from "react";
import { MdChat, MdSend, MdClose } from "react-icons/md";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
import * as actions from '../actions';
import './LiveSupport.css';
import MessageItem from "../../chat/components/MessageItem";
import { isMobile } from "react-device-detect";
import * as uniqueId from "lodash/uniqueId";


class LiveSupport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isChatVisible: false,
            textToSend: '',
            localMessages: []
        };

        this.sessionId = null;
    }

    componentDidMount() {
        this.props.getIsAdminOnline();

        this.questionTimeout = setTimeout(() => {
            this.setState({
                //isChatVisible: true,
                localMessages: [{
                    id: -2,
                    isAuthorizedUser: false,
                    isSupportRead: true,
                    isUserSender: false,
                    text: this.props.t('feedback.timeoutQuestion'),
                    time: new Date().toISOString(),
                    userSessionId: ""
                }]
            });
        }, 60 * 1000);
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    startTimer = () => {
        this.timerInterval = setInterval(() => {
            this.props.getLiveSupportMessages(true, this.getSessionId());
        }, 2000);
    }

    stopTimer() {
        if (this.timerInterval != null) {
            clearInterval(this.timerInterval);
            this.timerInterval = null;
        }
    }

    keydownHandler = (e) => {
        if (e.keyCode === 13)
            this.onSendMessage();
    }

    componentDidUpdate() {
        if (this.state.isChatVisible) {
            this.scrollToBottom();
        }
    }

    onChatToggle = () => {
        if (!this.state.isChatVisible) {
            this.props.getLiveSupportMessages(false, this.getSessionId());
            this.startTimer();
        } else {
            this.stopTimer();
        }
        clearTimeout(this.questionTimeout);
        this.setState({
            isChatVisible: !this.state.isChatVisible
        });
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    handleMessageTextChange = (e) => {
        this.setState({
            textToSend: e.target.value
        });
    }

    getSessionId = () => {
        if (this.sessionId != null)
            return this.sessionId;

        this.sessionId = window.localStorage.getItem("liveSupportSession");
        if (this.sessionId == null) {
            this.sessionId = uuidv4();
            window.localStorage.setItem("liveSupportSession", this.sessionId);
        }
        return this.sessionId;
    }

    onSendMessage = () => {
        if (this.state.textToSend != '') {
            this.props.sendLiveSupportMessage({
                sessionId: this.getSessionId(),
                text: this.state.textToSend
            });
            this.setState({
                textToSend: ''
            });
            if (!this.props.isAdminOnline) {
                var t = new Date();
                t.setSeconds(t.getSeconds() + 3);

                this.props.attachAdminLiveSupportMessage({
                    id: "local-" + uniqueId(),
                    userSessionId: this.getSessionId(),
                    text: this.props.t('feedback.adminOfflineMessage'),
                    time: t,
                    isUserSender: false,
                    isTempAdmin: true
                });
            }
        }
    }

    render() {
        const { isChatVisible, textToSend } = this.state;
        const { isAdminOnline, t } = this.props;
        const messages = [...this.state.localMessages, ...this.props.messages];
        let containerStyle = "ls-icon-container";
        let svgStyle = "ls-icon-chat";
        if (isAdminOnline) {
            containerStyle += " animation-container";
            svgStyle += " animation-svg";
        }
        return (
            <div className="live-support">
                {isChatVisible &&
                    <div className={!isMobile ? "ls-chat-container" : "ls-chat-container-mobile"}>
                        <div className="ls-top-layout">
                            {isAdminOnline ?
                                <div className="ls-network-indicator">
                                    <div className="ls-online"></div>
                                    <span>Online</span>
                                </div>
                                :
                                <div className="ls-network-indicator">
                                    <div className="ls-offline"></div>
                                    <span>Offline</span>
                                </div>
                            }
                            <MdClose size="2em" color="black" className="ls-top-close" onClick={this.onChatToggle} />
                        </div>
                        <div className="ls-message-layout">
                            {
                                messages.map((message) => (
                                    <MessageItem
                                        key={message.id}
                                        t={t}
                                        text={message.text}
                                        userName={message.isUserSender ? t('chat.me') : t('feedback.supportName')}
                                        date={message.time}
                                        isMy={message.isUserSender}
                                    />
                                ))
                            }
                            <div style={{ float: "left", clear: "both" }}
                                ref={(el) => { this.messagesEnd = el; }}>
                            </div>
                        </div>
                        <div className="ls-bottom-layout">
                            <InputGroup style={{ height: '100%' }}>
                                <Input
                                    value={textToSend}
                                    type="textarea"
                                    onChange={this.handleMessageTextChange}
                                    onKeyDown={this.keydownHandler}
                                    style={{ height: '100%' }}
                                    placeholder={t('feedback.messagePlaceholder')}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button color="primary" onClick={this.onSendMessage}><MdSend /></Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                    </div>
                }
                <div className={containerStyle} onClick={this.onChatToggle}>
                    <MdChat size="1.5em" color="white" className={svgStyle} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    messages: state.feedback.liveSupportMessages,
    isAdminOnline: state.feedback.liveSupportIsAdminOnline
})

const mapDispatchToProps = {
    sendLiveSupportMessage: actions.sendLiveSupportMessage,
    getLiveSupportMessages: actions.getLiveSupportMessages,
    attachAdminLiveSupportMessage: actions.attachAdminLiveSupportMessage,
    getIsAdminOnline: actions.getIsAdminOnline
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(LiveSupport));