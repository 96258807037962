import axios from "axios";

export function getCountryByIp(sucessCallback) {
    //http://ip-api.com/json
    //http://api.ipstack.com/check?access_key=a6fcc12f70f8d3387152f9a0dcbc0791
    //https://freegeoip.app/json/
    let instance = axios.create();
    delete instance.defaults.headers.common['Authorization'];
    instance.get(`https://freegeoip.app/json/`)
        .then((response) => {
            console.log(response.data);
            if (sucessCallback) {
                sucessCallback(response.data.country_code.toLowerCase(), response.data.city);
            }
        }, err => { throw err; })
        .catch((err) => {
            console.log(err);
        });
}

export function getInnerRegionIdByIp(sucessCallback) {
    let instance = axios.create();
    delete instance.defaults.headers.common['Authorization'];
    instance.get(`https://freegeoip.app/json/`).then((response) => {
            const region_code = response.data.region_code;
            let regionCodeInner = -1;
            switch (region_code) {
                case "09":
                    regionCodeInner = 1;
                    break;
                case "63":
                    regionCodeInner = 2;
                    break;
                case "61":
                    regionCodeInner = 3;
                    break;
                case "59":
                    regionCodeInner = 4;
                    break;
                case "56":
                    regionCodeInner = 5;
                    break;
                case "53":
                    regionCodeInner = 6;
                    break;
                case "51":
                    regionCodeInner = 7;
                    break;
                case "48":
                    regionCodeInner = 8;
                    break;
                case "46":
                    regionCodeInner = 9;
                    break;
                case "74":
                    regionCodeInner = 10;
                    break;
                case "35":
                    regionCodeInner = 11;
                    break;
                case "30":
                case "32":
                    regionCodeInner = 12;
                    break;
                case "26":
                    regionCodeInner = 13;
                    break;
                case "23":
                    regionCodeInner = 14;
                    break;
                case "21":
                    regionCodeInner = 15;
                    break;
                case "18":
                    regionCodeInner = 16;
                    break;
                case "14":
                    regionCodeInner = 17;
                    break;
                case "12":
                    regionCodeInner = 18;
                    break;
                case "07":
                    regionCodeInner = 19;
                    break;
                case "05":
                    regionCodeInner = 20;
                    break;
                case "71":
                    regionCodeInner = 21;
                    break;
                case "77":
                    regionCodeInner = 22;
                    break;
                case "65":
                    regionCodeInner = 23;
                    break;
                case "68":
                    regionCodeInner = 24;
                    break;
            }

            if (sucessCallback) {
                sucessCallback(regionCodeInner);
            }
        }, err => { throw err; })
        .catch((err) => {
            console.log(err);
        });
}