import Immutable from 'seamless-immutable';
import isEmpty from 'lodash/isEmpty';

import * as types from './types.js';

const initialState = Immutable({
    post: {
        loading: false,
        success: false,
        failed: false,
        errors: {}
    },
    social: {
        loading: false,
        success: false,
        failed: false,
    },
    isAuthenticated: false,
    isConfirmed:false,
    user: {
      id: '',
      name: '',
      //image:'',
      roles: []
    }
});

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN_POST_REQUEST: { 
            return initialState.merge({
                post:{
                    ...initialState.post,
                    loading:true
                }
            });
        }
        case types.LOGIN_SET_CURRENT_USER: {
            return state.merge({
                isAuthenticated: !isEmpty(action.user),
                isConfirmed: (action.user.isConfirmed==="True"),
                user: isEmpty(action.user) ? initialState.user : action.user
            });
        }
        case types.LOGIN_POST_SUCCESS: {
            return state.merge({
                post:{
                    ...initialState.post,
                    loading:false,
                    success:true
                }
            });
        }
        case types.LOGIN_POST_FAILED: {
            return state.merge({
                post:{
                    ...initialState.post,
                    loading:false,
                    failed:true,
                    errors:action.errors
                }
            });
        }
        case types.LOGIN_SOCIAL_REQUEST: {
            return state.merge({
                social: {                    
                    failed: false,
                    success: false,
                    loading: true,
                }
            });
        }
        case types.LOGIN_SOCIAL_SUCCESS: {
            return state.merge({
                social: {
                    ...initialState.social,
                    loading: false,
                    success: true,
                }
            });
        }
        case types.LOGIN_SOCIAL_FAILED: {
            return state.merge({
                social: {
                    ...initialState.social,
                    loading: false,
                    failed: true
                }
            });
        }
        default: {
            return state;
        }
    }
}