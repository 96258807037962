import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    executer: null,
    publications: []
});

export const executerReducer = (state = initialState, action) => {
    switch (action.type) {
        //#region GET_EXECUTER_DETAILS
        case types.GET_EXECUTER_DETAILS_REQUEST: {
            return state.merge({
                loading: true,
                executer: null
            });
        }
        case types.GET_EXECUTER_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                executer: action.payload
            });
        }
        case types.GET_EXECUTER_DETAILS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region GET_PUBLICATIONS
        case types.GET_PUBLICATIONS_REQUEST: {
            return state.merge({
                loading: true,
                publication: []
            });
        }
        case types.GET_PUBLICATIONS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                publications: action.payload
            });
        }
        case types.GET_PUBLICATIONS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        //#region SEND_INVITATION
        case types.SEND_INVITATION_REQUEST: {
            return state.merge({
                loading: true
            });
        }
        case types.SEND_INVITATION_SUCCESS: {
            return state.merge({
                loading: false,
                success: true
            });
        }
        case types.SEND_INVITATION_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion

        default: {
            return state;
        }
    }
}