import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    failed: false,
    sellers: [],
    categories: [],
    elements: []
});

export const elementMatchReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST: {
            return state.merge({
                loading: true,
            });
        }
        case types.SUCCESS: {
            return state.merge({
                loading: false,
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        case types.GET_SELLERS_SUCCESS: {
            return state.merge({
                loading: false,
                sellers: action.payload
            });
        }
        case types.GET_CATEGORIES_SUCCESS: {
            return state.merge({
                loading: false,
                categories: action.payload
            });
        }
        case types.GET_ELEMENTS_SUCCESS: {
            return state.merge({
                loading: false,
                elements: action.payload
            });
        }
        case types.SAVE_ELEMENT_MATCH_SUCCESS: {
            return state.merge({
                loading: false,
            });
        }

        default: {
            return state;
        }
    }
}