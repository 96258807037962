export const REQUEST = 'myproject3/REQUEST';
export const FAILURE = 'myproject3/FAILURE';
export const SUCCESS = 'myproject3/SUCCESS';

export const GET_MY_PROJECTS_SUCCESS = 'myproject3/GET_MY_PROJECTS_SUCCESS';

export const GET_PRODUCT_DETAILS_REQUEST = 'myproject3/GET_PRODUCT_DETAILS_REQUEST';
export const GET_PRODUCT_DETAILS_SUCCESS = 'myproject3/GET_PRODUCT_DETAILS_SUCCESS';

export const RELOAD_PRODUCT_ORDER_REQUEST = 'myproject3/RELOAD_PRODUCT_ORDER_REQUEST';
export const RELOAD_PRODUCT_ORDER_SUCCESS = 'myproject3/RELOAD_PRODUCT_ORDER_SUCCESS';

export const SET_LAST_SAVED_ORDER_ID = 'myproject3/SET_LAST_SAVED_ORDER_ID';
export const SAVE_PRODUCT_IMAGE_SUCCESS = 'myproject3/SAVE_PRODUCT_IMAGE_SUCCESS';

export const CHANGE_PRODUCT_NAME_SUCCESS = 'myproject3/CHANGE_PRODUCT_NAME_SUCCESS';
export const CHANGE_DETAILS_IMAGES_SUCCESS = 'myproject3/CHANGE_DETAILS_IMAGES_SUCCESS';