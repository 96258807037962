import React from 'react';
import NavMenu from '../NavMenu';
import { Container, Row, Col } from "reactstrap";
import BannerColumn from '../../aboutProject/BannerColumn';
import FooterLayout from '../footer/FooterLayout';
import './MainLayout.css'
import { isMobile } from 'react-device-detect';


export default props => (
    <div className="ml-wrapper">
        <NavMenu />
        <Container className={!isMobile ? "container-full ml-container" : " ml-container"}>
            {props.children}
        </Container>        
        <FooterLayout />
    </div>
);
