import Immutable from 'seamless-immutable';

import * as types from './types.js';


const initialState = Immutable({
    success: false,
    failed: false,
    loading: false,
    flag:false
})

export const refreshReducer = (state = initialState, action) => {
    switch(action.type){
        case types.REFRESH_STARTED: {
            return initialState.merge({ loading: true });
        }
        case types.REFRESH_SUCCESS: {
            return state.merge({ success: true, loading: false });
        }
        case types.REFRESH_FAILED: {
            return state.merge({ failed: true, loading: false });
        }
        default: {
            return state
        }
    };
}