export const REQUEST = 'calculator/REQUEST';
export const FAILURE = 'calculator/FAILURE';
export const SUCCESS = 'calculator/SUCCESS';

export const GET_METALS_SUCCESS = 'calculator/GET_METALS_SUCCESS';
export const GET_PAINTS_SUCCESS = 'calculator/GET_PAINTS_SUCCESS';
export const SAVE_ORDER_SUCCESS = 'calculator/SAVE_ORDER_SUCCESS';
export const GET_ORDERS_SUCCESS = 'calculator/GET_ORDERS_SUCCESS';
export const GET_ORDER_DETAILS_SUCCESS = 'calculator/GET_ORDER_DETAILS_SUCCESS';
export const MAKE_CONTRACT_SUCCESS = 'calculator/MAKE_CONTRACT_SUCCESS';
export const GET_EXECUTOR_INFO_SUCCESS = 'calculator/GET_EXECUTOR_INFO_SUCCESS';
export const GET_ELEMENTS_FOR_ORDERS_SUCCESS = 'calculator/GET_ELEMENTS_FOR_ORDERS_SUCCESS';
