import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    failed: false,
    sketches: [],
    sketchDetails: null,
    autoBuildTemplates: []
});

export const autoBuildReducer = (state = initialState, action) => {
    switch (action.type) {        
        case types.REQUEST: {
            return state.merge({
                loading: true,
                failed: false,
            });
        }
        case types.FAILURE: {
            return state.merge({
                loading: false,
                failed: true
            });
        }

        case types.GET_SKETCHES_SUCCESS: {
            return state.merge({
                loading: false,
                sketches: action.payload
            });
        }

        case types.GET_SKETCH_DETAILS_SUCCESS: {
            return state.merge({
                loading: false,
                sketchDetails: action.payload
            });
        }

        case types.GET_TEMPLATES_SUCCESS: {
            return state.merge({
                //loading: false,
                autoBuildTemplates: action.payload
            });
        }

        default: {
            return state;
        }
    }
}