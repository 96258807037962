import axios from "axios";
import * as types from './types';

export const getInstructionType = (data) => (dispatch) => {
    dispatch({ type: types.GET_INSTUCTION_TYPE_REQUEST });
    return axios.get(`api/instruction/getInstructionType`)
        .then((response) => {
            dispatch({
                type: types.GET_INSTUCTION_TYPE_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.GET_INSTUCTION_TYPE_FAILURE });
        })
}

export const getAllInstruction = (data) => (dispatch) => {
    dispatch({ type: types.GET_ALL_INSTRUCTIONS_REQUEST });
    return axios.get(`api/instruction/getAllInstruction?isInstruction=true`)
        .then((response) => {
            dispatch({
                type: types.GET_ALL_INSTRUCTIONS_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.GET_ALL_INSTRUCTIONS_FAILURE });
        })
}

export const getAllBlog = (data) => (dispatch) => {
    dispatch({ type: types.GET_ALL_BLOG_REQUEST });
    return axios.get(`api/instruction/getAllInstruction?isInstruction=false`)
        .then((response) => {
            //console.log(response.data);
            dispatch({
                type: types.GET_ALL_BLOG_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.GET_ALL_BLOG_FAILURE });
        })
}

export const getLatestBlog = (data) => (dispatch) => {
    dispatch({ type: types.GET_LAST_BLOG_REQUEST });
    return axios.get(`api/instruction/getLastBlogs`)
        .then((response) => {
            dispatch({
                type: types.GET_LAST_BLOG_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.GET_LAST_BLOG_FAILURE });
        })
}

export const getInstructionDetails = (Id) => (dispatch) => {
    dispatch({ type: types.GET_SINGLE_INSTRUCTION_REQUEST });
    return axios.get(`api/instruction/instruction?id=${Id}`)
        .then((response) => {
            dispatch({
                type: types.GET_SINGLE_INSTRUCTION_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.GET_SINGLE_INSTRUCTION_FAILURE });
        })
}