export const GET_DETAILS_REQUEST = 'projectdetails/GET_DETAILS_REQUEST';
export const GET_DETAILS_SUCCESS = 'projectdetails/GET_DETAILS_SUCCESS';
export const GET_DETAILS_FAILURE = 'projectdetails/GET_DETAILS_FAILURE';

export const SET_PRODUCT_DIMENTION_REQUEST = 'projectdetails/SET_PRODUCT_DIMENTION_REQUEST';
export const SET_PRODUCT_DIMENTION_SUCCESS = 'projectdetails/SET_PRODUCT_DIMENTION_SUCCESS';
export const SET_PRODUCT_DIMENTION_FAILURE = 'projectdetails/SET_PRODUCT_DIMENTION_FAILURE';

export const SET_DIMENTION_DONE_REQUEST = 'projectdetails/SET_DIMENTION_DONE_REQUEST';
export const SET_DIMENTION_DONE_SUCCESS = 'projectdetails/SET_DIMENTION_DONE_SUCCESS';
export const SET_DIMENTION_DONE_FAILURE = 'projectdetails/SET_DIMENTION_DONE_FAILURE';

export const CREATE_PUBLICATION_REQUEST = 'projectdetails/CREATE_PUBLICATION_REQUEST';
export const CREATE_PUBLICATION_SUCCESS = 'projectdetails/CREATE_PUBLICATION_SUCCESS';
export const CREATE_PUBLICATION_FAILURE = 'projectdetails/CREATE_PUBLICATION_FAILURE';

export const FIND_EXECUTERS_REQUEST = 'projectdetails/FIND_EXECUTERS_REQUEST';
export const FIND_EXECUTERS_SUCCESS = 'projectdetails/FIND_EXECUTERS_SUCCESS';
export const FIND_EXECUTERS_FAILURE = 'projectdetails/FIND_EXECUTERS_FAILURE';

export const SET_DESIGN_VIEW_MODE = 'projectdetails/SET_DESIGN_VIEW_MODE';

export const GET_PROPOSITIONS_REQUEST = 'projectdetails/GET_PROPOSITIONS_REQUEST';
export const GET_PROPOSITIONS_SUCCESS = 'projectdetails/GET_PROPOSITIONS_SUCCESS';
export const GET_PROPOSITIONS_FAILURE = 'projectdetails/GET_PROPOSITIONS_FAILURE';

export const ACCEPT_PROPOSITION_REQUEST = 'projectdetails/ACCEPT_PROPOSITION_REQUEST';
export const ACCEPT_PROPOSITION_SUCCESS = 'projectdetails/ACCEPT_PROPOSITION_SUCCESS';
export const ACCEPT_PROPOSITION_FAILURE = 'projectdetails/ACCEPT_PROPOSITION_FAILURE';

export const SET_CONTRACT_DONE_REQUEST = 'projectdetails/SET_CONTRACT_DONE_REQUEST';
export const SET_CONTRACT_DONE_SUCCESS = 'projectdetails/SET_CONTRACT_DONE_SUCCESS';
export const SET_CONTRACT_DONE_FAILURE = 'projectdetails/SET_CONTRACT_DONE_FAILURE';

export const RETURN_BACK_CONTRACT_REQUEST = 'projectdetails/RETURN_BACK_CONTRACT_REQUEST';
export const RETURN_BACK_CONTRACT_SUCCESS = 'projectdetails/RETURN_BACK_CONTRACT_SUCCESS';
export const RETURN_BACK_CONTRACT_FAILURE = 'projectdetails/RETURN_BACK_CONTRACT_FAILURE';

export const SET_SELF_DESIGN_REQUEST = 'projectdetails/SET_SELF_DESIGN_REQUEST';
export const SET_SELF_DESIGN_SUCCESS = 'projectdetails/SET_SELF_DESIGN_SUCCESS';
export const SET_SELF_DESIGN_FAILURE = 'projectdetails/SET_SELF_DESIGN_FAILURE';

export const SET_DESIGN_DONE_REQUEST = 'projectdetails/SET_DESIGN_DONE_REQUEST';
export const SET_DESIGN_DONE_SUCCESS = 'projectdetails/SET_DESIGN_DONE_SUCCESS';
export const SET_DESIGN_DONE_FAILURE = 'projectdetails/SET_DESIGN_DONE_FAILURE';

export const SET_PRODUCTION_DONE_REQUEST = 'projectdetails/SET_PRODUCTION_DONE_REQUEST';
export const SET_PRODUCTION_DONE_SUCCESS = 'projectdetails/SET_PRODUCTION_DONE_SUCCESS';
export const SET_PRODUCTION_DONE_FAILURE = 'projectdetails/SET_PRODUCTION_DONE_FAILURE';

export const SET_INSTALLATION_DONE_REQUEST = 'projectdetails/SET_INSTALLATION_DONE_REQUEST';
export const SET_INSTALLATION_DONE_SUCCESS = 'projectdetails/SET_INSTALLATION_DONE_SUCCESS';
export const SET_INSTALLATION_DONE_FAILURE = 'projectdetails/SET_INSTALLATION_DONE_FAILURE';

export const SET_FORCE_DIMENTION = 'projectdetails/SET_FORCE_DIMENTION';