import MySpecialtyService from './mySpecialtyService';
import { toastr } from 'react-redux-toastr'
import axios from "axios";
import * as types from './types';
import jwt from 'jsonwebtoken';
import { saveAuthToken } from '../../utils/lsMethods';
import setAuthorizationToken from '../../utils/setAuthorizationToken';
import { loginActions, rolesIdentity } from '../../login/actions';

const mySpecialtyActions = {
    // For select info
    getMySpecialtyStarted: () => {
        return {
            type: types.GET_MYSPECIALTY_REQUEST
        }
    },
    getMySpecialtySuccess: (data) => {
        return {
            type: types.GET_MYSPECIALTY_SUCCESS,
            payload: data
        }
    },
    getMySpecialtyFailed: () => {
        return {
            type: types.GET_MYSPECIALTY_FAILURE,
        }
    },

    // For edit my info
    saveMySpecialtyStarted: () => {
        return {
            type: types.SAVE_MYSPECIALTY_REQUEST,
        }
    },
    saveMySpecialtySuccess: () => {
        return {
            type: types.SAVE_MYSPECIALTY_SUCCESS,
        }
    },
    saveMySpecialtyFailed: (response) => {
        return {
            type: types.SAVE_MYSPECIALTY_FAILURE,
            errors: response.data
        }
    },


    editmySpecialty: (data) => {
        return {
            type: types.EDIT_MYSPECIALTY,
            payload:data
        }
    },
}

export const getMySpecialty = () => (dispatch) => {
    dispatch(mySpecialtyActions.getMySpecialtyStarted());
    return MySpecialtyService.getMySpecialty()
        .then((response) => {
            dispatch(mySpecialtyActions.getMySpecialtySuccess(response.data));
        }, err => { throw err; })
        .catch((err) => {
            dispatch(mySpecialtyActions.getMySpecialtyFailed());
        })
}

export const saveMySpecialty = (data) => (dispatch) => {
    dispatch(mySpecialtyActions.saveMySpecialtyStarted());
    return MySpecialtyService.saveMySpecialty(data)
        .then((response) => {
            dispatch(mySpecialtyActions.saveMySpecialtySuccess());
            const authToken=response.data.authToken;
            const decode=jwt.decode(authToken);
            const isConfirmed=decode.phone;
            const roles=decode[rolesIdentity];           
            var user = {
                roles,
                isConfirmed,
                name: decode.ownname,
                isExecutor: decode.isExecutor == "True"
            };
            if (!Array.isArray(user.roles)) {
                user.roles = Array.of(user.roles);
            }
            // Set auth in localstorage
            saveAuthToken(authToken);
        
            setAuthorizationToken(authToken);
            dispatch(loginActions.setCurrentUser(user));

            toastr.success('Успіх', 'Інформація збережена');
        }, err => { throw err; })
        .catch((err) => {
            dispatch(mySpecialtyActions.saveMySpecialtyFailed(err.response));
            toastr.error('Помилка', err.response);
        })
}

export const changeInfo=(name,value)=>(dispatch)=>{
    dispatch(mySpecialtyActions.editmySpecialty({name,value}));
}

export const makeExecutor = (callback) => (dispatch) => {
    return axios.post(`api/profile/makeExecutor`, {})
        .then((response) => {
            const authToken = response.data.authToken;
            const decode = jwt.decode(authToken);
            const isConfirmed = decode.phone;
            const roles = decode[rolesIdentity];
            var user = {
                roles,
                isConfirmed,
                name: decode.ownname,
                isExecutor: decode.isExecutor == "True"
            };
            if (!Array.isArray(user.roles)) {
                user.roles = Array.of(user.roles);
            }
            // Set auth in localstorage
            saveAuthToken(authToken);

            setAuthorizationToken(authToken);
            dispatch(loginActions.setCurrentUser(user));

            if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            if (callback) callback(false);
        })
}