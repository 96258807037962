import React, { Component } from "react";
import * as actions from '../actions';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import FullPageLoader from "../../components/loaders/FullPageLoader/FullPageLoader";
import './ManufacturerPriceList.css';
import { Button } from "reactstrap";
import { RegisterPopup } from "./RegisterPopup";
import { registerUser } from "../../register/registerSimple/actions";
import { sendSms, verificateAccount } from "../../verify/actions";
import Helper from "../../utils/helper";
import { toastr } from "react-redux-toastr";
import { getInnerRegionIdByIp } from "../../utils/languageDetector";
import { getRegions } from "../../projects/newproject/actions";
import ImageScroller from "../../components/imageScroller/ImageScroller";
import { uniqueId } from "lodash";
import { sendAmplitudeData, amplitudeTypes } from "../../utils/amplitude";
import { isMobile } from "react-device-detect";
import InlineLoader from "../../components/loaders/InlineLoader/InlineLoader";
import { ContactManufacturerPopup } from "./ContactManufacturerPopup";

class ManufacturerPriceList extends Component {
    constructor(props) {
        super(props);

        const selectedRegionId = localStorage.getItem("selectedRegionId");

        this.state = {
            contactSelectedManufacturer: null,
            isBecomeManufacturerClick: false,
            selectedRegionId: selectedRegionId ? parseInt(selectedRegionId) : -1,
            isRegionListShow: false,
            previewImages: null,
            logoItemId: null,
            logoImg: null,
            phone: false,
            showPhonesIds: []
        };
    }

    componentDidMount() {
        //this.props.getPriceManufacturers(null, this.props.productId);
        if (this.props.regions == null || this.props.regions.length == 0) {
            this.props.getRegions();
        } else {
            this.buildRegions();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.productId !== prevProps.productId) {
            this.props.getPriceManufacturers(this.state.selectedRegionId != -1 ? this.state.selectedRegionId : null, this.props.productId, this.props.outerPaintId);
        }
        if (this.props.regions !== prevProps.regions) {
            this.buildRegions();
        }
        if (this.props.regionId !== prevProps.regionId) {
            this.setState({
                selectedRegionId: this.props.regionId
            });
            this.props.getPriceManufacturers(this.props.regionId != -1 ? this.props.regionId : null, this.props.productId, this.props.outerPaintId);
        }
        if (this.props.outerPaintId !== prevProps.outerPaintId) {
            this.props.getPriceManufacturers(this.state.selectedRegionId != -1 ? this.state.selectedRegionId : null, this.props.productId, this.props.outerPaintId);
        }
    }

    buildRegions() {
        if (!localStorage.getItem("selectedRegionId")) {
            getInnerRegionIdByIp((regionId) => {
                this.setState({ selectedRegionId: regionId });
                this.props.getPriceManufacturers(regionId != -1 ? regionId : null, this.props.productId, this.props.outerPaintId);
                localStorage.setItem("selectedRegionId", regionId);
            });
        } else {
            this.props.getPriceManufacturers(this.state.selectedRegionId != -1 ? this.state.selectedRegionId : null, this.props.productId, this.props.outerPaintId);
        }
    }

    onRegionLabelClick = () => {
        this.setState({ isRegionListShow: true });
    }

    onRegionItemClick = (regionId) => {
        this.setState({
            selectedRegionId: regionId,
            isRegionListShow: false
        });
        this.props.getPriceManufacturers(regionId != -1 ? regionId : null, this.props.productId, this.props.outerPaintId);
        localStorage.setItem("selectedRegionId", regionId);
        if (this.props.onRegionSelected) {
            this.props.onRegionSelected(regionId);
        }
    }

    onPortfolioClick = (userItem) => {
        if (userItem.portfolio && userItem.portfolio.length != 0) {
            this.setState({
                previewImages: userItem.portfolio.map(img => ({ id: uniqueId(), image: img }))
            });
        }
    }

    hidePorfolio = () => {
        this.setState({
            previewImages: null
        });
    }

    onManufacturerSelect = (id, e) => {
        e.stopPropagation();
        sendAmplitudeData(amplitudeTypes.CONTACT_MANUFACTURER, null);
        this.setState({
            contactSelectedManufacturer: id
        });
    }

    onRegisterPopupHide = (userPhone, callTimeFrom, callTimeTo) => {
        if (this.state.contactSelectedManufacturer) {
            const manufactorId = this.state.contactSelectedManufacturer;
            this.setState({ contactSelectedManufacturer: null });
            const data = {
                userPhone: userPhone,
                priceManufacturer: this.props.priceManufacturers.find(m => m.executerId == manufactorId),
                sketchLink: window.location.href,
                userProductLink: this.props.productId ? Helper.editorPreviewLink(this.props.productId) : null,
                callTimeFrom: callTimeFrom,
                callTimeTo: callTimeTo
            };
            this.props.sendContactRequest(data, (isOk) => {
                if (isOk) {
                    toastr.success('', this.props.t('manufacturer.contactRequestSent'));
                }
            });
        } else if (this.state.isBecomeManufacturerClick) {
            this.setState({ isBecomeManufacturerClick: false });
            this.props.becomeManufacturerRequest({ userPhone: userPhone }, (isOk) => {
                if (isOk) {
                    toastr.success('', this.props.t('manufacturer.becomeManufacturerSent'));
                }
            });
        }
    }

    onCloseRegisterPopupClick = () => {
        this.setState({
            contactSelectedManufacturer: null,
            isBecomeManufacturerClick: false
        });
    }

    onBecomeManufacturerClick = () => {
        sendAmplitudeData(amplitudeTypes.BECOME_MANUFACTURER, null);
        this.setState({ isBecomeManufacturerClick: true });
    }

    onMouseEnterLogo = (item) => {
        if (item.portfolio && item.portfolio.length > 0) {
            this.setState({
                logoItemId: item.executerId,
                logoImg: item.portfolio[0]
            });
            let imgIndex = 0;
            this.logoInterval = setInterval(() => {
                imgIndex++;
                if (imgIndex >= item.portfolio.length)
                    imgIndex = 0;
                this.setState({
                    logoImg: item.portfolio[imgIndex]
                });
            }, 2000);
        }
    }

    onMouseLeaveLogo = (item) => {
        if (this.logoInterval) {
            clearInterval(this.logoInterval);
        }
        this.setState({
            logoItemId: null,
            logoImg: null
        });
    }

    getIntallPrice(item) {
        if (this.props.priceCoeff && item.installPriceFactor) {
            return Math.max((item.price.productionPrice + item.price.paintingPrice) * this.props.priceCoeff * (item.installPriceFactor.percent / 100), item.installPriceFactor.minPrice);
        }
        let priceCoeff = this.props.priceCoeff ? this.props.priceCoeff : 1;
        return (item.price.installationPrice * priceCoeff);
    }

    onGetPhoneClick = (e, item) => {
        e.stopPropagation();
        //item.executerId
        sendAmplitudeData(amplitudeTypes.GET_MANUFACTURER_PHONE, { phone: item.phoneNumber });
        this.setState({
            showPhonesIds: [...this.state.showPhonesIds, item.executerId]
        });
    }

    onWebsiteClick = (e) => {
        e.stopPropagation();
    }

    render() {
        const { loading, failed, t, priceManufacturers, isActiveExecutor, hideRegion } = this.props;
        const { contactSelectedManufacturer, isBecomeManufacturerClick, selectedRegionId, isRegionListShow, previewImages,
            logoImg, logoItemId, showPhonesIds } = this.state;
        let priceCoeff = this.props.priceCoeff ? this.props.priceCoeff : 1;
        
        const regions = [{ id: -1, name: t('manufacturer.regionAll') }, ...this.props.regions];

        let regionLabel = t('manufacturer.regionAll');
        if (selectedRegionId != -1) {
            const region = regions.find(r => r.id == selectedRegionId);
            if (region) {
                regionLabel = region.name;
            }
        }

        return (
            <div>
                {loading &&
                    <InlineLoader />
                }                
                <div>
                    <span className="mr-2">{t('manufacturer.pmanufacturers')}</span>
                    {!hideRegion &&
                        <span className="mp-region-label" onClick={this.onRegionLabelClick}>{regionLabel}</span>
                    }
                </div>
                {isRegionListShow &&
                    <div className="mp-region-list">
                        {regions && regions.map(regionItem => (
                            <div key={regionItem.id} className="mp-region-item" onClick={() => this.onRegionItemClick(regionItem.id)}>
                                {regionItem.name}
                            </div>
                        ))}
                    </div>
                }
                <div className="mp-itemContainer">
                    {priceManufacturers && priceManufacturers.map(item => (
                        <div className={"mp-item" + ((item.portfolio && item.portfolio.length != 0) ? " mp-item-clickable" : "" )}
                            key={item.executerId}
                            onClick={() => this.onPortfolioClick(item)}
                        >
                            <div className={!isMobile ? "mp-image-item" : "mp-image-item-mobile"} >
                                <img
                                    src={(logoImg && item.executerId == logoItemId) ? logoImg : (item.logo ? item.logo : "/img/welder.png")}
                                    onMouseEnter={() => this.onMouseEnterLogo(item)}
                                    onMouseLeave={() => this.onMouseLeaveLogo(item)}
                                />
                            </div>                            
                            <div className="align-center">{item.name}</div>
                            {item.address &&
                                <div className="mp-addess-item">{item.address}</div>
                            }
                            {item.price &&
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td>{t('manufacturer.production')}</td>
                                            <td className="align-right">{(item.price.productionPrice * priceCoeff).toFixed(0)}{t('currencyUah')}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('manufacturer.painting')}</td>
                                            <td className="align-right">{(item.price.paintingPrice * priceCoeff).toFixed(0)}{t('currencyUah')}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('manufacturer.delivery')}</td>
                                            <td className="align-right">{(item.price.shippingPrice).toFixed(0)}{t('currencyUah')}</td>
                                        </tr>
                                        {item.price.installationPrice != 0 &&
                                            <tr>
                                                <td>{t('manufacturer.installation')}</td>
                                                <td className="align-right">{this.getIntallPrice(item).toFixed(0)}{t('currencyUah')}</td>
                                            </tr>
                                        }
                                        {item.price.concretingPrice != 0 &&
                                            <tr>
                                                <td>{t('manufacturer.concreting')}</td>
                                                <td className="align-right">{(item.price.concretingPrice * priceCoeff).toFixed(0)}{t('currencyUah')}</td>
                                            </tr>
                                        }
                                        <tr style={{ borderTop: '1px solid black' }}>
                                            <td><b>{t('manufacturer.totalPrice')}</b></td>
                                            <td className="align-right">
                                                <b>
                                                    {((item.price.productionPrice + item.price.paintingPrice + item.price.concretingPrice) * priceCoeff
                                                        + (this.getIntallPrice(item)) + item.price.shippingPrice).toFixed(0)}
                                                    {t('currencyUah')}
                                                </b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                            {item.isShowPhoneInPriceCatalog &&
                                <>
                                {!showPhonesIds.includes(item.executerId) ?
                                    <Button color="success" size="sm" style={{ width: '100%', marginBottom: "5px" }}
                                        onClick={(e) => this.onGetPhoneClick(e, item)}>
                                        {t('manufacturer.getPhone')}
                                    </Button>
                                    :
                                    <>
                                        <div>{item.phoneNumber}</div>
                                        {item.innerWebsiteName &&
                                            <div><a href={`/company/${item.innerWebsiteName}`} target="_blank" onClick={this.onWebsiteClick}>{t('manufacturer.website')}</a></div>
                                        }
                                    </>
                                }
                                </>
                            }
                            <Button color="success" size="sm" style={{ width: '100%' }}
                                onClick={(e) => this.onManufacturerSelect(item.executerId, e)}>
                                {t('manufacturer.contact')}
                            </Button>
                        </div>
                    ))}
                    {!isActiveExecutor &&
                        <div className="align-center">
                            <Button color="success" outline onClick={this.onBecomeManufacturerClick}>{t('manufacturer.becomeManufacturer')}</Button>
                        </div>
                    }
                </div>                
                {contactSelectedManufacturer &&
                    <ContactManufacturerPopup
                        t={t}
                        isAuthenticated={this.props.isAuthenticated}
                        onSendContactRequest={this.onRegisterPopupHide}
                        onCloseClick={this.onCloseRegisterPopupClick}
                    />
                }
                {isBecomeManufacturerClick &&
                    <RegisterPopup
                        t={t}
                        isAuthenticated={this.props.isAuthenticated}
                        registerUser={this.props.registerUser}
                        sendSmsVerification={this.props.sendSmsVerification}
                        verificateAccount={this.props.verificateAccount}
                        onSendContactRequest={this.onRegisterPopupHide}
                        onCloseClick={this.onCloseRegisterPopupClick}
                        registerFrom={isBecomeManufacturerClick ? "Become manufacturer" : "Contact manufacturer"}
                    />
                }
                {previewImages &&
                    <ImageScroller
                        images={previewImages}
                        selectFirst={true}
                        show={true}
                        srcLinkBuilder={(img) => img.image}
                        altBuilder={img => ""}
                        onHide={() => this.hidePorfolio()}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.manufacturer,
    isAuthenticated: state.login.isAuthenticated,
    isExecutor: state.login.user.isExecutor,
    regions: state.createProject.regions,
    countries: state.createProject.countries,
})

const mapDispatchToProps = {
    getPriceManufacturers: actions.getPriceManufacturers,
    sendContactRequest: actions.sendContactRequest,
    becomeManufacturerRequest: actions.becomeManufacturerRequest,
    registerUser: registerUser,
    sendSmsVerification: sendSms,
    verificateAccount: verificateAccount,
    getRegions: getRegions
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ManufacturerPriceList));