import React from 'react';
import './InfoView.css';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

class InfoView extends React.Component {    
    render() {
        const { showIcon, title, line1, line2, linkText, linkTo, className } = this.props;

        return (
            <div className={"info-container " + className} style={this.props.style}>
                {showIcon ?
                    <Row>
                        {!isMobile &&
                            <Col xs="auto">
                                <img src="img/warning-icon.png" />
                            </Col>
                        }
                        <Col>
                            {isMobile &&
                                <img src="img/warning-icon.png" />
                            }
                            {!!title &&
                                <div style={{ color: "#12ae6b", fontSize: "24px" }}><b>{title}</b></div>
                            }
                            <div style={{ color: "black", fontSize: "18px" }}><b>{line1}</b></div>
                            <div style={{ color: "black", fontSize: "16px" }}>
                                {line2}&nbsp;
                                {linkTo && linkText &&
                                    <Link to={linkTo}>{linkText}</Link>
                                }
                            </div>
                        </Col>
                    </Row>
                    :
                    <>
                        {!!title &&
                            <div style={{ color: "#12ae6b", fontSize: "24px" }}><b>{title}</b></div>
                        }
                        <div style={{ color: "black", fontSize: "18px" }}><b>{line1}</b></div>
                        <div style={{ color: "black", fontSize: "16px" }}>
                            {line2}&nbsp;
                            {linkTo && linkText &&
                                <Link to={linkTo}>{linkText}</Link>
                            }
                        </div>
                    </>
                }
            </div>
        );
    }
}

InfoView.propTypes = {
    showIcon: PropTypes.bool,
    title: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string
}

export default InfoView;