import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";

const UserRouteExecutor = ({ isAuthenticated, isExecutor , layout:Layout, isConfirmed , component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated && isExecutor && isConfirmed ? 
      <Layout>
        <Component {...props} />
      </Layout>
      : 
      !isConfirmed ?  <Redirect to="/confirmaccount" /> :
      <Redirect to="/" />
    }
  />
);
UserRouteExecutor.propTypes = {
  component: PropTypes.any.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};
function mapStateToProps(state) {
  return {
    isAuthenticated: state.login.isAuthenticated,
    isConfirmed: state.login.isConfirmed,
    isExecutor: state.login.user.isExecutor
  };
}

export default connect(mapStateToProps)(UserRouteExecutor);
