import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { titleChanger } from "./utils/TitleHelper"

class AppTitleHelper extends Component {
    componentDidMount() {
        this.changeTitle(this.props.history.location);
        this.unlisten = this.props.history.listen((location, action) => {
            this.changeTitle(location);
        });
    }

    changeTitle(location) {
        const path = location.pathname;//.replace('/', '');
        let ptitle = this.props.t('seo.title.' + path);
        let pdesc = this.props.t('seo.desc.' + path);
        let pkeywords = this.props.t('seo.keywords.' + path);
        if (ptitle == 'seo.title.' + path) {
            ptitle = this.props.t('mainTitle');
        }
        if (pdesc == 'seo.desc.' + path) {
            pdesc = this.props.t('mainDescription');
        }
        if (pkeywords == 'seo.keywords.' + path) {
            pkeywords = null;
        }

        titleChanger(ptitle, pdesc, pkeywords);
        //console.log(location);
        //console.log(this.props.t('seo.title.' + location.pathname));
        //console.log(this.props.t('seo.desc.' + location.pathname));
    }

    componentWillUnmount() {
        if (this.unlisten)
            this.unlisten();
    }

    render() {
        return <span style={{ display: 'none' }}></span>;
    }
}

export default withTranslation()(AppTitleHelper);