import React, { Component } from "react";
import { getInnerRegionIdByIp } from "../../utils/languageDetector";

export default class RegionSelect extends Component {
    static getRegionId(onRegionFetched) {
        let selectedRegionId = localStorage.getItem("selectedRegionId");
        if (selectedRegionId) {
            onRegionFetched(selectedRegionId);
            return;
        }

        getInnerRegionIdByIp((regionId) => {            
            localStorage.setItem("selectedRegionId", regionId);
            onRegionFetched(regionId);
        });
    }

    constructor(props) {
        super(props);

        const selectedRegionId = localStorage.getItem("selectedRegionId");
        this.state = {
            selectedRegionId: selectedRegionId ? parseInt(selectedRegionId) : -1,
            isRegionListShow: false,
        };
    }

    componentDidMount() {
        if (this.props.regions == null || this.props.regions.length == 0) {
            this.props.getRegions();
        } else {
            this.buildRegions();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.regions !== prevProps.regions) {
            this.buildRegions();
        }
        if (this.props.regionId !== prevProps.regionId) {
            this.setState({
                selectedRegionId: this.props.regionId
            });
        }
    }

    buildRegions() {
        if (!localStorage.getItem("selectedRegionId")) {
            getInnerRegionIdByIp((regionId) => {
                this.setState({ selectedRegionId: regionId });
                this.props.onRegionSelected(regionId);
                localStorage.setItem("selectedRegionId", regionId);
            });
        } else {
            this.props.onRegionSelected(this.state.selectedRegionId);
        }
    }

    onRegionLabelClick = () => {
        this.setState({ isRegionListShow: true });
    }

    onRegionItemClick = (regionId) => {
        this.setState({
            selectedRegionId: regionId,
            isRegionListShow: false
        });
        this.props.onRegionSelected(regionId);
        localStorage.setItem("selectedRegionId", regionId);
    }

    render() {
        const { selectedRegionId, isRegionListShow } = this.state;
        const { t } = this.props;
        const regions = [{ id: -1, name: t('manufacturer.regionAll') }, ...this.props.regions];

        let regionLabel = t('manufacturer.regionAll');
        if (selectedRegionId != -1 && regions) {
            const region = regions.find(r => r.id == selectedRegionId);
            if (region) {
                regionLabel = region.name;
            }
        }

        return (
            <div className={this.props.className ? this.props.className : ""} style={this.props.style}>
                <div>
                    <span className="mr-2">{this.props.topLabel ? this.props.topLabel : t('manufacturer.pmanufacturers')}</span>
                    <span className="mp-region-label" onClick={this.onRegionLabelClick}>{regionLabel}</span>
                </div>
                {
                    isRegionListShow &&
                    <div className="mp-region-list">
                        {regions && regions.map(regionItem => (
                            <div key={regionItem.id} className="mp-region-item" onClick={() => this.onRegionItemClick(regionItem.id)}>
                                {regionItem.name}
                            </div>
                        ))}
                    </div>
                }
            </div>
        );
    }
}