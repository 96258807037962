import React from 'react';
import Linkify from 'react-linkify';
import Helper from '../../utils/helper';
import '../ChatPage.css';

export default class MessageItem extends React.Component {
    render() {
        const { t, text, userName, date, isMy } = this.props;
        const color = isMy ? "secondary" : "primary";

        let dateStr = null;
        if (typeof (date) == "string") {
            dateStr = date.indexOf("Z") > 0 ? date : date + "Z";
            dateStr = new Date(dateStr).toLocaleString();
        } else {
            dateStr = date.toLocaleString();
        }
        
        return (
            <div className={isMy ? 'my-message-div' : ''}>
                <span className={isMy ? 'my-message' : 'notmy-message'}>
                    <b>{isMy ? t('chat.me') : userName}</b>
                    <br />
                    <span className="multiline"><Linkify>{text}</Linkify></span>
                    <br />
                    <span className="date-text">{dateStr}</span>
                </span>
            </div>
        )
    }}