import React from 'react';
import CompanyNavMenu from './CompanyNavMenu';
import { Container, Row, Col } from "reactstrap";

import './CompanyLayout.css'
import { isMobile } from 'react-device-detect';

export default props => {
    return (
        <div>
            <CompanyNavMenu />
            <Container className={!isMobile ? "ml-container full-width-container" : " ml-container"}>
                {props.children}
            </Container>
        </div>
    );
}