import { push } from 'connected-react-router';
import LoginService from './loginService';
import jwt from 'jsonwebtoken';
import * as types from './types';
import axios from "axios";
import setAuthorizationToken from '../utils/setAuthorizationToken';
import { sendAmplitudeData, setAmplitudeUserId, amplitudeTypes } from '../utils/amplitude';


import { removeAuthToken, removeRefreshToken, saveAuthToken, saveRefreshToken, saveVerificationToken, removeVerificationToken, getRefreshToken } from '../utils/lsMethods';

export const rolesIdentity = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
export const nameIdentity = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";

export const loginActions = {
    loginStarted: () => {
        return { 
            type: types.LOGIN_POST_REQUEST 
        }
    },
    loginSuccess: () => {
        return {
            type: types.LOGIN_POST_SUCCESS
        }
    },
    loginFailed: (response) => {
        return {
            type: types.LOGIN_POST_FAILED,
            errors: response.data
        }
    },
    setCurrentUser: (user) => {
        return {
            type: types.LOGIN_SET_CURRENT_USER,
            user
        }
    },
    logoutStarted: () => {
        return {
            type: types.LOGOUT_POST_REQUEST
        }
    },
}


export const loginPost = (model) => {
    return (dispatch) => {
        dispatch(loginActions.loginStarted());
        LoginService.login(model)
            .then((response) => {
                dispatch(loginActions.loginSuccess());
                loginByJWT(response.data, dispatch);

                const tokenData = jwt.decode(response.data.authToken);
                setAmplitudeUserId(tokenData[nameIdentity]);
                sendAmplitudeData(amplitudeTypes.OPEN_WEBSITE, { 'registered': true });                
            }, err => { throw err; })
            .catch(err => {
                dispatch(loginActions.loginFailed(err.response));
            });
    }
}

export const loginSocial = (model) => (dispatch) => {
    dispatch({ type: types.LOGIN_SOCIAL_REQUEST });
    axios.post('api/account/loginSocial', model)
        .then((response) => {            
            loginByJWT(response.data, dispatch);

            const tokenData = jwt.decode(response.data.authToken);
            setAmplitudeUserId(tokenData[nameIdentity]);
            sendAmplitudeData(amplitudeTypes.OPEN_WEBSITE, { 'registered': true });            

            dispatch({ type: types.LOGIN_SOCIAL_SUCCESS });
        }, err => { throw err; })
        .catch(err => {
            dispatch({ type: types.LOGIN_SOCIAL_FAILED });
        });
}


export function logout() {
    return dispatch => {
        logoutByJWT(dispatch);
        sendAmplitudeData(amplitudeTypes.OPEN_WEBSITE, { 'registered': false });
    };
}

// Login with localstorage

export const loginByJWT = (tokens, dispatch) => {
    const { authToken, refreshToken } = tokens;
    const decode=jwt.decode(authToken);
    const isConfirmed=decode.phone;
    const roles = decode[rolesIdentity];
    var user = {
        roles,
        isConfirmed,
        name: decode.ownname,
        isExecutor: decode.isExecutor == "True"
    };
    if (!Array.isArray(user.roles)) {
        user.roles = Array.of(user.roles);
    }
    // Set auth and refresh token in localstorage
    if(!!refreshToken)
    {
        saveAuthToken(authToken);
        saveRefreshToken(refreshToken)
    }
    else{
        saveVerificationToken(authToken);
    }



    setAuthorizationToken(authToken);
    dispatch(loginActions.setCurrentUser(user));
}

export const logoutByJWT = (dispatch) => {
    dispatch(loginActions.logoutStarted());
    LoginService.logout({ refreshToken: getRefreshToken() })
        .then((response) => {
            // Remove tokens
            removeAuthToken();
            removeRefreshToken();
            removeVerificationToken();


            //Set null to header axios
            setAuthorizationToken(false);
            dispatch(loginActions.setCurrentUser({}));
            dispatch(push("/"));
        }, err => { throw err; })
        .catch(err => {  
            // Remove tokens
            removeAuthToken();
            removeRefreshToken();
            removeVerificationToken();

            //Set null to header axios
            setAuthorizationToken(false);
            dispatch(loginActions.setCurrentUser({}));
            dispatch(push("/"));
        });
}

export const passwordRecoveryRequest = (data, callback) => {
    axios.post(`api/account/passwordrecoveryrequest`, data)
        .then((response) => {
            if (callback) callback(true);
        }, err => { throw err; })
        .catch(err => {
            if (callback) callback(false);
        });
}

export const passwordRecoveryFinish = (data, callback) => {
    axios.post(`api/account/passwordrecoveryfinish`, data)
        .then((response) => {
            if (callback) callback(true);
        }, err => { throw err; })
        .catch(err => {
            if (callback) callback(false);
        });
}
