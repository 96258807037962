import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    AllInstructions: [],
    SingleInstruction: {},
    LastBlogs: [],
    InstructionType: []
});

export const instructionsReducer = (state = initialState, action) => {

    switch (action.type) {
        //#region GET_ALL_INSTRUCTIONS
        case types.GET_ALL_INSTRUCTIONS_REQUEST: {
            return state.merge({
                loading: true
            });
        }

        case types.GET_ALL_INSTRUCTIONS_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                AllInstructions: action.payload,
            });
        }
        case types.GET_ALL_INSTRUCTIONS_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion
        //#region GET_ALL_BLOG
        case types.GET_ALL_BLOG_REQUEST: {
            return state.merge({
                loading: true
            });
        }

        case types.GET_ALL_BLOG_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                AllInstructions: action.payload,
            });
        }
        case types.GET_ALL_BLOG_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion
        //#region GET_SINGLE_INSTRUCTION
        case types.GET_SINGLE_INSTRUCTION_REQUEST: {
            return state.merge({
                loading: true
            });
        }

        case types.GET_SINGLE_INSTRUCTION_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                SingleInstruction: action.payload,
            });
        }
        case types.GET_SINGLE_INSTRUCTION_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion
        //#region GET_LAST_BLOG
        case types.GET_LAST_BLOG_REQUEST: {
            return state.merge({
                loading: true
            });
        }

        case types.GET_LAST_BLOG_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                LastBlogs: action.payload,
            });
        }
        case types.GET_LAST_BLOG_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion
        //#region GET_INSTUCTION_TYPE
        case types.GET_INSTUCTION_TYPE_REQUEST: {
            return state.merge({
                loading: true
            });
        }

        case types.GET_INSTUCTION_TYPE_SUCCESS: {
            return state.merge({
                loading: false,
                success: true,
                InstructionType: action.payload,
            });
        }
        case types.GET_INSTUCTION_TYPE_FAILURE: {
            return state.merge({
                loading: false,
                failed: true,
            });
        }
        //#endregion
        default: {
            return state;
        }
    }
}