import RefreshService from './refreshService';
import * as loginActions from '../login/actions';

import * as types from './types.js';

export const refreshStarted = () => ({ type: types.REFRESH_STARTED });
export const refreshSuccess = () => ({ type: types.REFRESH_SUCCESS });
export const refreshFailed = () => ({ type: types.REFRESH_FAILED });

export const refreshToken = (dispatch) => {
    dispatch(refreshStarted())
    return RefreshService.RefreshToken()
        .then((response) => {
            dispatch(refreshSuccess());
            loginActions.loginByJWT(response.data, dispatch);
            return Promise.resolve(response);
        }, err=> { throw err; })
        .catch((err) => {
            dispatch(refreshFailed());
            loginActions.logoutByJWT(dispatch);
            return Promise.reject(err);
        })
}