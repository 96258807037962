export const GET_MESSAGES_REQUEST = 'feedback/GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'feedback/GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAILURE = 'feedback/GET_MESSAGES_FAILURE';

export const GET_MYFEEDBACKS_REQUEST = 'feedback/GET_MYFEEDBACKS_REQUEST';
export const GET_MYFEEDBACKS_SUCCESS = 'feedback/GET_MYFEEDBACKS_SUCCESS';
export const GET_MYFEEDBACKS_FAILURE = 'feedback/GET_MYFEEDBACKS_FAILURE';

export const SEND_MESSAGE_REQUEST = 'feedback/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'feedback/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'feedback/SEND_MESSAGE_FAILURE';


export const GET_LIVE_SUPPORT_MESSAGES_REQUEST = 'feedback/GET_LIVE_SUPPORT_MESSAGES_REQUEST';
export const GET_LIVE_SUPPORT_MESSAGES_SUCCESS = 'feedback/GET_LIVE_SUPPORT_MESSAGES_SUCCESS';
export const GET_LIVE_SUPPORT_MESSAGES_FAILURE = 'feedback/GET_LIVE_SUPPORT_MESSAGES_FAILURE';

export const SEND_LIVE_SUPPORT_MESSAGE_REQUEST = 'feedback/SEND_LIVE_SUPPORT_MESSAGE_REQUEST';
export const SEND_LIVE_SUPPORT_MESSAGE_SUCCESS = 'feedback/SEND_LIVE_SUPPORT_MESSAGE_SUCCESS';
export const SEND_LIVE_SUPPORT_MESSAGE_FAILURE = 'feedback/SEND_LIVE_SUPPORT_MESSAGE_FAILURE';

export const ATTACH_ADMIN_LIVE_SUPPORT_MESSAGE = 'feedback/ATTACH_ADMIN_LIVE_SUPPORT_MESSAGE';

export const GET_IS_ADMIN_ONLINE = "feedback/GET_IS_ADMIN_ONLINE";
