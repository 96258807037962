import React from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
    DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem, Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { FiUser } from "react-icons/fi";
import { MdChat, MdShoppingCart, MdFavorite } from "react-icons/md";
import axios from "axios";
import './NavMenu.css';
import FlagIcon from './flags/FlagIcon';

import { setLanguage } from '../store/language/actions';
import { logout } from '../login/actions';
import { getRegions } from '../regions/actions';
import { getProjectsWithoutMeasurer } from '../projects/myprojects/actions';
import * as UserRoles from '../constants/specialty';

import FeedbackPopup from '../feedback/FeedbackPopup';

import { getCountryByIp } from '../utils/languageDetector';
import CallMeasurerPopup from '../projects/callMeasurerPopup/CallMeasurerPopup';
import MyProfileSideBar from './sidebar/MyProfileSideBar';
import { isMobile } from 'react-device-detect';
import HistoryStorage from '../utils/HistoryStorage';

export class NavMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            i18n: props.i18n,
            isFeedbackOpen: false,
            isCallMeasurerOpen: false
        };
    }

    componentDidMount() {
        if (localStorage.getItem("isLangFirstTime") != "1") {
            getCountryByIp(countryCode => {
                localStorage.setItem("isLangFirstTime", "1");
                switch (countryCode) {
                    case 'ua':
                        this.changeLanguage('ua');
                        break;
                    case 'ru':
                        this.changeLanguage('ru');
                        break;
                    default:
                        this.changeLanguage('en');
                        break;
                }
            });
        }
        //if (!this.props.projectsWithoutMeasurerRequested && this.props.isAuthenticated) {
        //    this.props.getProjectsWithoutMeasurer();
        //}
    }


    onShowFeedbackClick = () => {
        this.setState({
            isFeedbackOpen: true
        });
    }

    onHideFeedbackClick = () => {
        this.setState({
            isFeedbackOpen: false
        });
    }

    onCallMeasurerClick = () => {
        this.setState({
            isCallMeasurerOpen: true
        });
    }

    onHideCallMeasurerClick = () => {
        this.setState({
            isCallMeasurerOpen: false
        });
    }



    changeLanguage = lng => {
        this.state.i18n.changeLanguage(lng).then((t) => {
            this.props.setLanguage(lng).then((res) => {
                window.location.reload();
            });
        });;
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleChange = (lang) => {
        this.changeLanguage(lang);
    }

    logOut = () => {
        this.props.logout();
    }

    onOpenConstructor = () => {
        HistoryStorage.isRedirectToConstructor = true;
    }

    render() {
        const { t, roles, hasProjectsWithoutMeasurer, isAuthenticated } = this.props;
        const { language } = this.props.i18n;
        let userName = this.props.userName;

        if (userName != null && userName.length > 10) {
            userName = userName.substring(0, 8) + "...";
        }

        let code = language.substring(0, 2);
        if (language === "uk" || language === "uk-UA") {
            code = "ua";
        }
        if (language == "en") {
            code = "gb";
        }

        let isHomePage = (window.location.pathname == "/");
        let isCustomer = roles.includes(UserRoles.customer);
        const isSketchEditor = roles.includes("SketchBuilder");
        const isGoodsSeller = roles.includes("GoodsSeller");

        let basketCount = this.props.basket.goods.length + this.props.basket.elements.length;

        return (
            <header className={!isMobile ? "header" : "header-mobile"}>
                <Navbar className={"fixed-top " + (!isMobile ? "header navbar-expand-sm navbar-toggleable-sm" : "header-mobile")} >
                    <Container className="container-full">
                        <img className="logo-img" src="/img/logo.png" />
                        <NavbarBrand style={{ minWidth: '120px' }} className="title-text menu-item" tag={Link} to="/">SimpliCAD</NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className={!isMobile ? " flex-sm-row-reverse" : ""} isOpen={this.state.isOpen} navbar>
                            <ul className={"navbar-nav flex-grow" + (!isMobile ? "" : " navbar-scrollable")}>
                                <UncontrolledDropdown inNavbar>
                                    <DropdownToggle nav caret className="menu-item">
                                        {t('sidebar.tCatalog')}
                                    </DropdownToggle>
                                    <DropdownMenu right className="dropdownmenu">
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/instruction/design-kovanih-izdeliy">{t('navbar.ourProjects')}</Link>
                                        </DropdownItem>
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/kovanie-izdeliya-ceni">{t('sidebar.products')}</Link>
                                        </DropdownItem>
                                        {/*
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/kovanie-izdeliya-ceni-v2">{t('sidebar.products')}(V2)</Link>
                                        </DropdownItem>                                        
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/kovanie-izdeliya-ceni-v3">{t('sidebar.products')}(V3)</Link>
                                        </DropdownItem>
                                        */}
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/kovanie-izdeliya-ceni-v4">{t('sidebar.productsv4')}</Link>
                                        </DropdownItem>

                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/kovanie-elementi-kovki">{t('sidebar.elements_catalog')}</Link>
                                        </DropdownItem>
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/goods">{t('sidebar.goods')}</Link>
                                        </DropdownItem>
                                        {/*
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/manufacturers">{t('navbar.manufacturers')}</Link>
                                        </DropdownItem>
                                        */}
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <UncontrolledDropdown inNavbar>
                                    <DropdownToggle nav caret className="menu-item">
                                        {t('navbar.info')}
                                    </DropdownToggle>
                                    <DropdownMenu right className="dropdownmenu">
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/solution">{t('solutions')}</Link>
                                        </DropdownItem>
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/instructions/1">{t('sidebar.instructions')}</Link>
                                        </DropdownItem>
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/delivery">{t('navbar.delivery')}</Link>
                                        </DropdownItem>
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/instructions/0">{t('sidebar.articles')}</Link>
                                        </DropdownItem>
                                        <DropdownItem className="menu-item">
                                            <Link className="menu-item" to="/aboutProject">{t('home.title')}</Link>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                {(!this.props.isAuthenticated || !this.props.isConfirmed) &&
                                    <NavItem>
                                        <NavLink tag={Link} className="menu-item" to="/login">{t('navbar.login')}</NavLink>
                                    </NavItem>
                                }
                                {(!this.props.isAuthenticated || !this.props.isConfirmed) &&
                                    <NavItem>
                                        <NavLink tag={Link} className="menu-item" to="/register">{t('navbar.register')}</NavLink>
                                    </NavItem>
                                }

                                {(this.props.isAuthenticated || this.props.isConfirmed) &&
                                    <>
                                        <UncontrolledDropdown inNavbar>
                                            <DropdownToggle nav caret className="menu-item">
                                                <FiUser size="1.5rem" />{userName}
                                            </DropdownToggle>
                                            <DropdownMenu right className="dropdownmenu">                                                
                                                {/*
                                                <DropdownItem className="menu-item">
                                                        <Link className="menu-item" to="/myorders">{t('navbar.myorders')}</Link>
                                                </DropdownItem>
                                                */}
                                                {isSketchEditor &&
                                                    <DropdownItem className="menu-item">
                                                        <Link className="menu-item" to="/projectn/myprojects">{t('sidebar.myProjects2')}</Link>
                                                    </DropdownItem>
                                                }
                                                <DropdownItem className="menu-item">
                                                    <Link className="menu-item" to="/myprojectsv3">{t('navbar.myProjectsv3')}</Link>
                                                </DropdownItem>
                                                {isGoodsSeller &&
                                                    <DropdownItem className="menu-item">
                                                        <Link className="menu-item" to="/myprofile/mygoods">{t('sidebar.myGoods')}</Link>
                                                    </DropdownItem>
                                                }
                                                {isSketchEditor &&
                                                    <>
                                                        <DropdownItem className="menu-item">
                                                            <Link className="menu-item" to="/autoBuildEdit/sketches">{t('sidebar.sketchAutoBuild')}</Link>
                                                        </DropdownItem>
                                                        <DropdownItem className="menu-item">
                                                            <Link className="menu-item" to="/autoBuildEdit/templates">{t('sidebar.templatesAutoBuild')}</Link>
                                                        </DropdownItem>
                                                    </>
                                                }
                                                <DropdownItem className="menu-item">
                                                    <Link className="menu-item" to="/myprofile">
                                                        {t('navbar.myprofile')}
                                                    </Link>
                                                </DropdownItem>
                                                <DropdownItem className="menu-item" onClick={() => this.logOut()}>
                                                    {t('navbar.logout')}
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </>
                                }

                                <UncontrolledDropdown inNavbar>
                                    <DropdownToggle nav caret className="menu-item">
                                        {language.substring(0, 2).toUpperCase()}
                                    </DropdownToggle>
                                    <DropdownMenu right className="dropdownmenu">
                                        <DropdownItem className="menu-item" onClick={() => this.handleChange("uk")}>
                                            UK
                                        </DropdownItem>
                                        <DropdownItem className="menu-item" onClick={() => this.handleChange("ru")}>
                                            RU
                                        </DropdownItem>
                                        <DropdownItem className="menu-item" onClick={() => this.handleChange("en")}>
                                            EN
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <Link to="/favorite/products">
                                    <div className="basket-div">
                                        <MdFavorite className="favorite-icon" />
                                    </div>
                                </Link>

                                <Link to="/basket">
                                    <div className="basket-div">
                                        <MdShoppingCart className="basket-icon" />
                                        {basketCount != 0 &&
                                            <div className="basket-count">{basketCount}</div>
                                        }
                                    </div>
                                </Link>

                                {!isHomePage &&
                                    <Link to="/createProduct/0"><Button color="success" onClick={this.onOpenConstructor}>{t('homeProject.createProject')}</Button></Link>
                                }
                                {isMobile && this.props.isExecutor &&
                                    <Link to="/calculator" className="calcButton" onClick={() => { localStorage.removeItem("lastCreateOrderId"); }}>
                                        <Button color="success">{t('navbar.calculator')}</Button>
                                    </Link>
                                }
                                {isMobile && window.location.pathname.includes("myprofile") &&
                                    <div className="mt-4">
                                        <MyProfileSideBar />
                                    </div>
                                }
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>

                {this.state.isFeedbackOpen &&
                    <FeedbackPopup
                        t={t}
                        onHideFeedbackClick={this.onHideFeedbackClick}
                        isAuthenticated={this.props.isAuthenticated}
                    />
                }

                {this.state.isCallMeasurerOpen &&
                    <CallMeasurerPopup
                        onHideClick={this.onHideCallMeasurerClick}
                    />
                }

            </header>

        );
    }
}


const mapStateToProps = (state) => ({
    loading: state.language.loading,
    isAuthenticated: state.login.isAuthenticated,
    isConfirmed: state.login.isConfirmed,
    isExecutor: state.login.user.isExecutor,
    roles: state.login.user.roles,
    userName: state.login.user.name,
    hasProjectsWithoutMeasurer: state.myProjects.projectsWithoutMeasurer.length != 0,
    projectsWithoutMeasurerRequested: state.myProjects.projectsWithoutMeasurerRequested,
    basket: state.basket.basket
});

const mapDispatchToProps = {
    setLanguage: setLanguage,
    logout: logout,
    getProjectsWithoutMeasurer: getProjectsWithoutMeasurer
};


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NavMenu))