import React, { Component } from "react";
import { getInfoVideo } from "../../home/actions";
import { MdPlayCircleOutline } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";
import './VideoInfoView.css';
import { isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class VideoInfoView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            videoCode: null,
            showFullscreenVideo: false
        };
    }

    componentDidMount() {
        this.props.getInfoVideo(this.props.videoKey, (code) => {
            this.setState({
                videoCode: code
            });
        });
    }

    hideFullscreenVideo = () => {
        this.setState({ showFullscreenVideo: false });
    }

    onVideoPlayClick = () => {
        this.setState({ showFullscreenVideo: true });
    }

    render() {
        return (
            <div>
                {this.state.videoCode &&
                    <div className={!isMobile ? "proj-video-small self-center mb-4" : "proj-video-small-img-mobile self-center mb-4"}
                        onClick={this.onVideoPlayClick}>
                        <img
                            className="proj-video-small-img"
                            src={`https://img.youtube.com/vi/${this.state.videoCode}/hqdefault.jpg`}
                        />
                        <MdPlayCircleOutline className="proj-video-small-icon" />
                    </div>
                }
                {this.state.showFullscreenVideo &&
                    <div className="proj-fullscreen">
                        <IoIosCloseCircle className="proj-fullscreen-close" size="1.8em" color="white" onClick={this.hideFullscreenVideo} />
                        <iframe
                            className="proj-fullscreen-video"
                            type="text/html"
                            src={`https://www.youtube.com/embed/${this.state.videoCode}?autoplay=1`}
                            frameBorder="0"
                            allow='autoplay'
                        />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    getInfoVideo: getInfoVideo
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(VideoInfoView));