export default class FavotireHelper {
    static addFavoriteProduct = (productId) => {
        let data = this.loadFavoriteData();
        if (data.productIds == null)
            data.productIds = [];
        if (data.productIds.indexOf(productId) == -1) {
            data.productIds.push(productId);
            this.saveFavoriteData(data);
        }
    }

    static removeFavoriteProduct = (productId) => {
        let data = this.loadFavoriteData();
        if (data.productIds != null) {
            data.productIds = data.productIds.filter((pid) => pid != productId);
            this.saveFavoriteData(data);
        }
    }

    static getFavoriteProducts = () => {
        let data = this.loadFavoriteData();
        if (data.productIds == null)
            data.productIds = [];
        return data.productIds;
    }

    static clearFavorite = () => {        
        this.saveFavoriteData({ productIds: []});
    }

    static loadFavoriteData = () => {
        let favStr = window.localStorage.getItem("favorites");
        if (favStr == null) {
            return {
                productIds: []
            };
        }

        return JSON.parse(favStr);
    }

    static saveFavoriteData = (data) => {
        window.localStorage.setItem("favorites", JSON.stringify(data));
    }
}