export const GET_MYELEMENTS_REQUEST = 'myelements/GET_MYELEMENTS_REQUEST';
export const GET_MYELEMENTS_SUCCESS = 'myelements/GET_MYELEMENTS_SUCCESS';
export const GET_MYELEMENTS_FAILURE = 'myelements/GET_MYELEMENTS_FAILURE';


export const GET_MYELEMENT_REQUEST = 'myelements/GET_MYELEMENT_REQUEST';
export const GET_MYELEMENT_SUCCESS = 'myelements/GET_MYELEMENT_SUCCESS';
export const GET_MYELEMENT_FAILURE = 'myelements/GET_MYELEMENT_FAILURE';


export const GET_CATEGORIES_REQUEST = 'myelements/GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'myelements/GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'myelements/GET_CATEGORIES_FAILURE';

export const GET_ELEMENTFORM_REQUEST = 'myelements/GET_ELEMENTFORM_REQUEST';
export const GET_ELEMENTFORM_SUCCESS = 'myelements/GET_ELEMENTFORM_SUCCESS';
export const GET_ELEMENTFORM_FAILURE = 'myelements/GET_ELEMENTFORM_FAILURE';

export const GET_ELEMENTTEXTURES_REQUEST = 'myelements/GET_ELEMENTTEXTURES_REQUEST';
export const GET_ELEMENTTEXTURES_SUCCESS = 'myelements/GET_ELEMENTTEXTURES_SUCCESS';
export const GET_ELEMENTTEXTURES_FAILURE = 'myelements/GET_ELEMENTTEXTURES_FAILURE';

export const UPDATE_ELEMENT_REQUEST = 'myelements/UPDATE_ELEMENT_REQUEST';
export const UPDATE_ELEMENT_SUCCESS = 'myelements/UPDATE_ELEMENT_SUCCESS';
export const UPDATE_ELEMENT_FAILURE = 'myelements/UPDATE_ELEMENT_FAILURE';

export const UPLOAD_THREEDMODEL_REQUEST = 'myelements/UPLOAD_THREEDMODEL_REQUEST';
export const UPLOAD_THREEDMODEL_SUCCESS = 'myelements/UPLOAD_THREEDMODEL_SUCCESS';
export const UPLOAD_THREEDMODEL_FAILURE = 'myelements/UPLOAD_THREEDMODEL_FAILURE';

export const UPLOAD_ELEMENTFORM_REQUEST = 'myelements/UPLOAD_ELEMENTFORM_REQUEST';
export const UPLOAD_ELEMENTFORM_SUCCESS = 'myelements/UPLOAD_ELEMENTFORM_SUCCESS';
export const UPLOAD_ELEMENTFORM_FAILURE = 'myelements/UPLOAD_ELEMENTFORM_FAILURE';

export const UPLOAD_ELEMENTTEXTURE_REQUEST = 'myelements/UPLOAD_ELEMENTTEXTURE_REQUEST';
export const UPLOAD_ELEMENTTEXTURE_SUCCESS = 'myelements/UPLOAD_ELEMENTTEXTURE_SUCCESS';
export const UPLOAD_ELEMENTTEXTURE_FAILURE = 'myelements/UPLOAD_ELEMENTTEXTURE_FAILURE';

export const DELETE_ELEMENT_REQUEST = 'myelements/DELETE_ELEMENT_REQUEST';
export const DELETE_ELEMENT_SUCCESS = 'myelements/DELETE_ELEMENT_SUCCESS';
export const DELETE_ELEMENT_FAILURE = 'myelements/DELETE_ELEMENT_FAILURE';

export const UPLOAD_BULKELEMENTS_REQUEST = 'myelements/UPLOAD_BULKELEMENTS_REQUEST';
export const UPLOAD_BULKELEMENTS_SUCCESS = 'myelements/UPLOAD_BULKELEMENTS_SUCCESS';
export const UPLOAD_BULKELEMENTS_FAILURE = 'myelements/UPLOAD_BULKELEMENTS_FAILURE';

export const CHANGE_AVAILABILITY_REQUEST = 'myelements/CHANGE_AVAILABILITY_REQUEST';
export const CHANGE_AVAILABILITY_SUCCESS = 'myelements/CHANGE_AVAILABILITY_SUCCESS';
export const CHANGE_AVAILABILITY_FAILURE = 'myelements/CHANGE_AVAILABILITY_FAILURE';

export const REQUEST = 'myelements/REQUEST';
export const GET_LOADED_IMAGE_SUCCESS = 'myelements/GET_LOADED_IMAGE_SUCCESS';
export const DELETE_IMAGE_SUCCESS = 'myelements/DELETE_IMAGE_SUCCESS';
export const FAILURE = 'myelements/FAILURE';
export const CHANGE_WRAPPER_SUCCESS = 'myelements/CHANGE_WRAPPER_SUCCESS';

export const GET_CODE_GROUPS = 'myelements/GET_CODE_GROUPS';
export const SAVE_CODE_GROUP = 'myelements/SAVE_CODE_GROUP';
export const SAVE_CODE = 'myelements/SAVE_CODE';