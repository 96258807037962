import Immutable from 'seamless-immutable';

import * as types from './types.js';
import { data } from 'jquery';

const initialState = Immutable({
    get: {
        data:{
            items:[]
        },
        loading: false,
        success: false,
        failed: false,
        bulkUploadErrors: null,
        codeGroups: []
    },
    element:{
        get:{
            loading: false,
            success: false,
            failed: false,
            categories: [],
            forms: [],
            textures: [],
        },
        edit: {
            loading: false,
            success: false,
            failed: false,
            data:{
                category:undefined,
                id:undefined,
                description:'',
                name: '',
                customElementFormId: null,
                customElementTextureId: null,
            },
        },
    }
    
});

export const myElementsReducer = (state = initialState, action) => {
    switch (action.type) {
        //#region Get Elements
        case types.GET_MYELEMENTS_REQUEST: { 
            return initialState.merge({
                get:{
                    ...initialState.get,
                    loading:true
                }
            });
        }
        case types.GET_MYELEMENTS_SUCCESS: {
            return state.merge({
                get:{
                    ...state.get,
                    loading:false,
                    success:true,
                    data:{
                        items:action.payload
                    }
                }
            });
        }
        case types.GET_MYELEMENTS_FAILURE: {
            return state.merge({
                get:{
                    ...state.get,
                    loading:false,
                    failed:true
                }
            });
        }
        //#endregion


        //#region Get one element
        case types.GET_MYELEMENT_REQUEST: {
            return state.merge({
                element: {
                    ...state.element,
                    edit:{
                        ...state.element.edit,
                        loading: true,
                    },
                }
            });
        }
        case types.GET_MYELEMENT_SUCCESS: {
            return state.merge({
                element:{
                    ...state.element,
                    get:{
                        ...state.element.get,
                        success:true,
                    },
                    edit:{
                        ...state.element.edit,
                        loading: false,
                        data:action.payload
                    },
                }
            });
        }
        case types.GET_MYELEMENT_FAILURE: {
            return state.merge({
                element:{
                    ...state.element,
                    edit:{
                        ...state.element.edit,
                        loading: false,
                        failed:true,
                    },
                }
            });
        }
        //#endregion
        

        //#region Get Categories
        case types.GET_CATEGORIES_REQUEST: { 
            return initialState.merge({
                element:{
                    ...initialState.element,
                    get:{
                        ...state.element.get,
                        loading: true,

                    },
                }
            });
        }
        case types.GET_CATEGORIES_SUCCESS: {
            return state.merge({
                element:{
                    ...state.element,
                    get:{
                        ...state.element.get,
                        loading: false,
                        success:true,
                        categories:action.payload.items
                    },
                }
            });
        }
        case types.GET_CATEGORIES_FAILURE: {
            return state.merge({
                element:{
                    ...state.element,
                    get:{
                        ...state.element.get,
                        loading: false,
                        failed:true,
                    },
                }
            });
        }
        //#endregion

        //#region Get Forms
        case types.GET_ELEMENTFORM_REQUEST: {
            return state.merge({
                element: {
                    ...state.element,
                    get: {
                        ...state.element.get,
                        loading: true,
                        forms: initialState.element.get.forms
                    },
                }
            });
        }
        case types.GET_ELEMENTFORM_SUCCESS: {
            return state.merge({
                element: {
                    ...state.element,
                    get: {
                        ...state.element.get,
                        loading: false,
                        success: true,
                        forms: action.payload.items
                    },
                }
            });
        }
        case types.GET_ELEMENTFORM_FAILURE: {
            return state.merge({
                element: {
                    ...state.element,
                    get: {
                        ...state.element.get,
                        loading: false,
                        failed: true,
                    },
                }
            });
        }
        //#endregion

        //#region Get textures
        case types.GET_ELEMENTTEXTURES_REQUEST: {
            return state.merge({
                element: {
                    ...state.element,
                    get: {
                        ...state.element.get,
                        loading: true,
                        textures: initialState.element.get.textures
                    },
                }
            });
        }
        case types.GET_ELEMENTTEXTURES_SUCCESS: {
            return state.merge({
                element: {
                    ...state.element,
                    get: {
                        ...state.element.get,
                        loading: false,
                        success: true,
                        textures: action.payload.items
                    },
                }
            });
        }
        case types.GET_ELEMENTTEXTURES_FAILURE: {
            return state.merge({
                element: {
                    ...state.element,
                    get: {
                        ...state.element.get,
                        loading: false,
                        failed: true,
                    },
                }
            });
        }
        //#endregion


        //#region Upload threedmodel
         case types.UPLOAD_THREEDMODEL_REQUEST: { 
            return initialState.merge({
                element:{
                    ...state.element,
                    edit:{
                        ...state.element.edit,
                        loading: true,

                    },
                }
            });
        }
        case types.UPLOAD_THREEDMODEL_SUCCESS: {
            return state.merge({
                element:{
                    ...state.element,
                    edit:{
                        ...state.element.edit,
                        loading: false,
                        data:{
                            ...state.element.edit.data,
                            threedmodel:action.payload
                        }
                    },
                }
            });
        }
        case types.UPLOAD_THREEDMODEL_FAILURE: {
            return state.merge({
                element:{
                    ...state.element,
                    edit:{
                        ...state.element.edit,
                        loading: false,
                        failed:true,
                    },
                }
            });
        }
        //#endregion

        //#region Upload element form
        case types.UPLOAD_ELEMENTFORM_REQUEST: {
            return state.merge({
                element: {
                    ...state.element,
                    edit: {
                        ...state.element.edit,
                        loading: true,
                        data: {
                            ...state.element.edit.data,
                            customElementFormId: null
                        }
                    },
                }
            });
        }
        case types.UPLOAD_ELEMENTFORM_SUCCESS: {
            return state.merge({
                element: {
                    ...state.element,
                    edit: {
                        ...state.element.edit,
                        loading: false,
                        data: {
                            ...state.element.edit.data,
                            customElementFormId: action.payload
                        }
                    },
                }
            });
        }
        case types.UPLOAD_ELEMENTFORM_FAILURE: {
            return state.merge({
                element: {
                    ...state.element,
                    edit: {
                        ...state.element.edit,
                        loading: false,
                        failed: true,
                    },
                }
            });
        }
        //#endregion

        //#region Upload element texture
        case types.UPLOAD_ELEMENTTEXTURE_REQUEST: {
            return state.merge({
                element: {
                    ...state.element,
                    edit: {
                        ...state.element.edit,
                        loading: true,
                        data: {
                            ...state.element.edit.data,
                            customElementTextureId: null
                        }
                    },
                }
            });
        }
        case types.UPLOAD_ELEMENTTEXTURE_SUCCESS: {
            return state.merge({
                element: {
                    ...state.element,
                    edit: {
                        ...state.element.edit,
                        loading: false,
                        data: {
                            ...state.element.edit.data,
                            customElementTextureId: action.payload
                        }
                    },
                }
            });
        }
        case types.UPLOAD_ELEMENTTEXTURE_FAILURE: {
            return state.merge({
                element: {
                    ...state.element,
                    edit: {
                        ...state.element.edit,
                        loading: false,
                        failed: true,
                    },
                }
            });
        }
        //#endregion


        //#region Update element
        case types.UPDATE_ELEMENT_REQUEST: { 
            return initialState.merge({
                element:{
                    ...state.element,
                    edit:{
                        ...state.element.edit,
                        loading: true,

                    },
                }
            });
        }
        case types.UPDATE_ELEMENT_SUCCESS: {
            return state.merge({
                element:{
                    ...state.element,
                    edit:{
                        ...state.element.edit,
                        loading: false,
                        success:true
                    },
                }
            });
        }
        case types.UPDATE_ELEMENT_FAILURE: {
            return state.merge({
                element:{
                    ...state.element,
                    edit:{
                        ...state.element.edit,
                        loading: false,
                        errors:action.payload,
                        failed:true,
                    },
                }
            });
        }
        case types.REQUEST: {
            return state.merge({
                element: {
                    ...state.element,
                    edit: {
                        ...state.element.edit,
                        loading: true,

                    },
                }
            });
        }
        case types.FAILURE: {
            return state.merge({
                element: {
                    ...state.element,
                    edit: {
                        ...state.element.edit,
                        loading: false,
                        failed: true,
                    },
                }
            });
        }
        case types.GET_LOADED_IMAGE_SUCCESS: {
            let image = action.payload;
            return state.merge({
                element: {
                    ...state.element,
                    edit: {
                        ...state.element.edit,
                        loading: false,
                    },
                }
            });
        }
        case types.DELETE_IMAGE_SUCCESS:
        case types.CHANGE_WRAPPER_SUCCESS: {
            return state.merge({
                element: {
                    ...state.element,
                    edit: {
                        ...state.element.edit,
                        loading: false,
                    },
                }
            });
        }
        //#endregion

        //#region Delete element
        case types.DELETE_ELEMENT_REQUEST: { 
            return initialState.merge({
                    get:{
                        ...state.get,
                        loading: true,
                    },
            });
        }
        case types.DELETE_ELEMENT_SUCCESS: {
            return state.merge({
                    get:{
                        ...state.get,
                        loading: false,
                        success:true,
                        data:{
                            items:[...state.get.data.items].filter(item=>item.id!=action.payload)
                        }
                    },
            });
        }
        case types.DELETE_ELEMENT_FAILURE: {
            return state.merge({
                    get:{
                        ...state.get,
                        loading: false,
                        failed:true,
                    },
            });
        }
        //#endregion

        //#region UPLOAD_BULKELEMENTS
        case types.UPLOAD_BULKELEMENTS_REQUEST: {
            return initialState.merge({
                get: {
                    ...state.get,
                    loading: true,
                    bulkUploadErrors: null
                },
            });
        }
        case types.UPLOAD_BULKELEMENTS_SUCCESS: {
            return state.merge({
                get: {
                    ...state.get,
                    loading: false,
                    success: true,                    
                },
            });
        }
        case types.UPLOAD_BULKELEMENTS_FAILURE: {
            return state.merge({
                get: {
                    ...state.get,
                    loading: false,
                    failed: true,
                    bulkUploadErrors: action.payload.errors
                },
            });
        }
        //#endregion

        //#region CHANGE_AVAILABILITY
        case types.CHANGE_AVAILABILITY_REQUEST: {
            return initialState.merge({
                get: {
                    ...state.get,
                    loading: true
                },
            });
        }
        case types.CHANGE_AVAILABILITY_SUCCESS: {
            return state.merge({
                get: {
                    ...state.get,
                    loading: false
                },
            });
        }
        case types.CHANGE_AVAILABILITY_FAILURE: {
            return state.merge({
                get: {
                    ...state.get,
                    loading: false
                },
            });
        }
        //#endregion

        case types.GET_CODE_GROUPS: {
            return state.merge({
                get: {
                    ...state.get,
                    loading: false,
                    success: true,
                    codeGroups: action.payload
                }
            });
        }

        case types.SAVE_CODE_GROUP: {
            return state.merge({
                get: {
                    ...state.get,
                    loading: false,
                    success: true,
                    codeGroups: [...state.get.codeGroups, action.payload]
                }
            });
        }

        case types.SAVE_CODE: {            
            return state.merge({
                get: {
                    ...state.get,
                    loading: false,
                    success: true,
                    data: {
                        ...state.get.data,
                        items: action.payload.items
                    }
                }
            });
        }
        
        default: {
            return state;
        }
    }
}