export const GET_FAVORITES_PRODUCTS_REQUEST = 'home/GET_FAVORITES_PRODUCTS_REQUEST';
export const GET_FAVORITES_PRODUCTS_SUCCESS = 'home/GET_FAVORITES_PRODUCTS_SUCCESS';
export const GET_FAVORITES_PRODUCTS_FAILURE = 'home/GET_FAVORITES_PRODUCTS_FAILURE';

export const ADD_FAVORITES_REQUEST = 'home/ADD_FAVORITES_REQUEST';
export const ADD_FAVORITES_SUCCESS = 'home/ADD_FAVORITES_SUCCESS';
export const ADD_FAVORITES_FAILURE = 'home/ADD_FAVORITES_FAILURE';

export const REMOVE_FAVORITES_REQUEST = 'home/REMOVE_FAVORITES_REQUEST';
export const REMOVE_FAVORITES_SUCCESS = 'home/REMOVE_FAVORITES_SUCCESS';
export const REMOVE_FAVORITES_FAILURE = 'home/REMOVE_FAVORITES_FAILURE';

export const GET_FAVORITES_REQUEST = 'home/GET_FAVORITES_REQUEST';
export const GET_FAVORITES_SUCCESS = 'home/GET_FAVORITES_SUCCESS';
export const GET_FAVORITES_FAILURE = 'home/GET_FAVORITES_FAILURE';

export const LOAD_FAVORITES_LOCAL = 'home/LOAD_FAVORITES_LOCAL';