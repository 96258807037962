import axios from "axios";

export default class MySpecialtyService {
    static getMySpecialty() {
        return axios.get(`api/profile/getServices`)
    };
    static getRegions(){
        return axios.get(`api/region/get`)
    }
    static saveMySpecialty(model){
        return axios.post(`api/profile/savemyservices`,model)
    }
}