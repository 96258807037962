import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    get:{
        loading: false,
        success: false,
        failed: false,
        errors:{},
        expiredCode: undefined,
        phone: ''
    },
    confirm: {
        loading: false,
        success: false,
        failed: false,
        errors:{},
        confirmationCode:'', 
    },
});

export const verifyAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SEND_SMS_REQUEST: { 
            return initialState.merge({
                get:{
                    ...initialState.get,
                    loading:true
                }
            });
        }
        case types.SEND_SMS_SUCCESS: {
            return state.merge({
                get:{
                    ...state.get,
                    loading:false,
                    success:true,
                    expiredCode: action.payload.expired,
                    phone: action.payload.phone
                }
            });
        }
        case types.SEND_SMS_FAILURE: {
            return state.merge({
                get:{
                    ...initialState.get,
                    loading:false,
                    failed:true,
                    errors:action.payload,
                    expiredCode: action.payload.expired,
                    phone: action.payload.phone
                }
            });
        }


        case types.VERIFY_ACCOUNT_REQUEST: { 
            return state.merge({
                confirm:{
                    ...initialState.confirm,
                    loading:true
                }
            });
        }
        case types.VERIFY_ACCOUNT_SUCCESS: {
            return state.merge({
                confirm:{
                    ...state.confirm,
                    loading:false,
                    success:true,
                },
            });
        }
        case types.VERIFY_ACCOUNT_FAILURE: {
            return state.merge({
                confirm:{
                    ...state.confirm,
                    loading:false,
                    failed:true,
                    errors:action.payload
                }
            });
        }

        case types.SET_TIMER_REQUEST:{
            return state.merge({
                get:{
                    ...state.get,
                    expiredCode:action.payload
                }
            });
        }

        case types.REQUEST: {
            return state.merge({
                get: {
                    ...initialState.get,
                    loading: true
                }
            });
        }
        case types.SUCCESS: {
            return state.merge({
                get: {
                    ...initialState.get,
                    loading: false
                }
            });
        }
        case types.FAILURE: {
            return state.merge({
                get: {
                    ...initialState.get,
                    loading: false,
                    failed: true,
                }
            });
        }

        default: {
            return state;
        }
    }
}