import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";
import { getVerificationToken } from "../../utils/lsMethods";
import jwt from 'jsonwebtoken';


const UnConfirmAccountRoute = ({ isConfirmed, layout: Layout, component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isConfirmed ? (
                <Layout>
                    <Component {...props} />
                </Layout>
            ) : (
                    <Redirect to="/login" />
                )
        }
    />
);
UnConfirmAccountRoute.propTypes = {
    component: PropTypes.any.isRequired,
    isConfirmed: PropTypes.bool.isRequired
};
function mapStateToProps(state) {
    var token = jwt.decode(getVerificationToken());
    const refreshThreshold = (Math.round(Date.now() / 1000));
    var isConfirmed=false;
    if(token && refreshThreshold < token.exp){
        isConfirmed=true
    }
    return {
        isConfirmed:isConfirmed
    };
}

export default connect(mapStateToProps)(UnConfirmAccountRoute);
