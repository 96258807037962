import Immutable from 'seamless-immutable';
import * as types from './types.js';

const initialState = Immutable({
    loading: false,
    success: false,
    failed: false,
    sessions: []
});

export const editorSessionsReducer = (state = initialState, action) => {
    switch(action.type){
        case(types.GET_MYEDITORSESSIONS_REQUEST): {
            return state.merge({
                loading: true
            });
        }
        case(types.GET_MYEDITORSESSIONS_SUCCESS): {
            return state.merge({
                loading: false,
                sessions: action.payload
            });
        }
        case(types.GET_MYEDITORSESSIONS_FAILURE): {
            return state.merge({
                loading: false,
                failed: true
            });
        }
        default: {
            return state;
        }
    }
};