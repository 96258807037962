import axios from "axios";
import * as types from './types';

export const getElementsForSketches = () => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/AutoBuild/GetElementsForAllSketches`)
        .then((response) => {
            dispatch({
                type: types.GET_ELEMENTS_FOR_SKETCHES_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getMySketches = () => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/AutoBuild/GetMySketches`)
        .then((response) => {
            dispatch({
                type: types.GET_MYSKETCHES_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const toggleTextureVariant = (id) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/AutoBuild/ToggleSketchTextureVariant?id=${id}`)
        .then((response) => {
            dispatch({
                type: types.TOGGLE_TEXTURE_VARIANT,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getEditorState = (productId, callback) => (dispatch) => {
    if (!callback) {
        dispatch({ type: types.REQUEST });
    }
    return axios.get(`api/AutoBuild/GetProductEditorState?productId=${productId}`)
        .then((response) => {
            if (callback) {
                callback(response.data);
            } else {
                dispatch({
                    type: types.GET_PRODUCT_EDITOR_STATE_SUCCESS,
                    payload: response.data
                });
            }
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getElementContent = (elementId, useContentRecursive, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/AutoBuild/GetElementContent?elementId=${elementId}&useContentRecursive=${useContentRecursive}`)
        .then((response) => {
            dispatch({
                type: types.SUCCESS,
                payload: response.data
            });
            callback(response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const saveSketch = (data) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/AutoBuild/SaveSketch`, data)
        .then((response) => {
            dispatch({
                type: types.SAVE_SKETCH_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const copySketch = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/AutoBuild/CopySketch`, JSON.stringify(data), {
        headers: {
            'content-type': 'application/json',
        },
    })
        .then((response) => {
            dispatch({
                type: types.COPY_SKETCH_SUCCESS
            });
            callback(true, response.data);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            callback(false);
        })
}

export const getSketchData = (sketchId) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/AutoBuild/GetSketchDetails?sketchId=${sketchId}`)
        .then((response) => {
            dispatch({
                type: types.GET_SKETCH_DATA_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getTemplates = () => (dispatch) => {
    dispatch({ type: types.REQUEST_TEMPLATE });
    return axios.get('api/AutoBuild/GetAllEditorTemplates')
        .then((response) => {
            dispatch({
                type: types.GET_TEMPLATE_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const getMetals = () => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get('api/AutoBuild/GetSketchMetals')
        .then((response) => {
            dispatch({
                type: types.GET_SKETCH_METALS_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const deleteSketch = (sketchId, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/AutoBuild/DeleteSketch?sketchId=${sketchId}`)
        .then((response) => {
            dispatch({
                type: types.DELETE_SKETCH_SUCCESS,
                payload: response.data
            });
            if (callback)
                callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback)
                callback(false);
        })
}

export const saveEnd = () => (dispatch) => {
    dispatch({ type: types.SAVE_END });
}

export const getMyAutoBuildTemplates = () => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/AutoBuild/GetMyTemplates`)
        .then((response) => {
            dispatch({
                type: types.GET_MYTEMPLATES_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const saveAutoBuildTemplate = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/AutoBuild/SaveTemplate`, data)
        .then((response) => {
            dispatch({
                type: types.SAVE_AUTOBUILD_TEMPLATE_SUCCESS
            });
            if (callback) callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback) callback(false);
        })
}

export const getAutoBuildTemplateDetails = (id) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/AutoBuild/GetTemplateDetails?id=${id}`)
        .then((response) => {
            dispatch({
                type: types.GET_AUTOBUILD_TEMPLATE_DETAILS_SUCCESS,
                payload: response.data
            });
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
        })
}

export const deleteAutoBuildTemplate = (id, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/AutoBuild/DeleteTemplate?id=${id}`)
        .then((response) => {
            dispatch({
                type: types.DELETE_AUTOBUILD_TEMPLATE_SUCCESS
            });
            if (callback)
                callback(true);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback)
                callback(false);
        })
}

export const generateHiddenProduct = (data, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.post(`api/AutoBuild/GenerateHiddenProduct`, data)
        .then((response) => {
            dispatch({
                type: types.GENERATE_HIDDEN_PRODUCT
            });
            if (callback)
                callback(true, response.data.productId);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback)
                callback(false);
        })
}

export const moveProductToNewProject = (productId, callback) => (dispatch) => {
    dispatch({ type: types.REQUEST });
    return axios.get(`api/AutoBuild/MoveProductToNewProject?productId=${productId}`)
        .then((response) => {
            dispatch({
                type: types.MOVE_PRODUCT_TO_NEW_PROJECT
            });
            if (callback)
                callback(true, response.data.projectId);
        }, err => { throw err; })
        .catch((err) => {
            dispatch({ type: types.FAILURE });
            if (callback)
                callback(false);
        })
}