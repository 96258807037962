import Immutable from 'seamless-immutable';

import * as types from './types.js';

const initialState = Immutable({
    get: {
        data:{
            items:[]
        },
        loading: false,
        success: false,
        failed: false,
        errors:{}
    },
    edit: {
        loading: false,
        success: false,
        failed: false,
        data:{
            images:[]
        },
        errors:{}
    },
});


export const myPortfolioReducer = (state = initialState, action) => {
    switch (action.type) {
        //#region Get Portfolio
        case types.GET_MYPORTFOLIO_REQUEST: { 
            return initialState.merge({
                get:{
                    ...initialState.get,
                    loading:true
                }
            });
        }
        case types.GET_MYPORTFOLIO_SUCCESS: {
            return state.merge({
                get:{
                    ...state.get,
                    loading:false,
                    success:true,
                    data:action.payload
                }
            });
        }
        case types.GET_MYPORTFOLIO_FAILURE: {
            return state.merge({
                get:{
                    ...initialState.get,
                    loading:false,
                    failed:true
                }
            });
        }
        //#endregion

        //#region Get PortfolioItem
        case types.GET_MYPORTFOLIOITEM_REQUEST: { 
            return initialState.merge({
                edit:{
                    ...initialState.get,
                    loading:true
                }
            });
        }
        case types.GET_MYPORTFOLIOITEM_SUCCESS: {
            return state.merge({
                edit:{
                    ...state.get,
                    loading:false,
                    data:action.payload
                }
            });
        }
        case types.GET_MYPORTFOLIOITEM_FAILURE: {
            return state.merge({
                edit:{
                    ...initialState.get,
                    loading:false,
                    failed:true
                }
            });
        }
        //#endregion

        //#region Upload image
        case types.UPLOAD_PORTFOLIOIMAGE_REQUEST: { 
            return initialState.merge({
                edit:{
                    ...state.edit,
                    loading:true
                }
            });
        }
        case types.UPLOAD_PORTFOLIOIMAGE_SUCCESS: {
            return state.merge({
                edit:{
                    ...state.edit,
                    loading:false,
                    data:{
                        ...state.edit.data,
                        images:[...state.edit.data.images,action.payload]
                    }
                }
            });
        }
        case types.UPLOAD_PORTFOLIOIMAGE_FAILURE: {
            return state.merge({
                edit:{
                    ...state.edit,
                    loading:false,
                    failed:true
                }
            });
        }
        //#endregion

        //#region Delete photo
        case types.DELETE_PORTFOLIOIMAGE_REQUEST: { 
            return initialState.merge({
                edit:{
                    ...state.edit,
                    loading:true
                }
            });
        }
        case types.DELETE_PORTFOLIOIMAGE_SUCCESS: {
            return state.merge({
                edit:{
                    ...state.edit,
                    loading:false,
                    data:{
                        ...state.edit.data,
                        images:state.edit.data.images.filter(item => item.id !== parseInt(action.payload))
                    }
                }
            });
        }
        case types.DELETE_PORTFOLIOIMAGE_FAILURE: {
            return state.merge({
                edit:{
                    ...state.edit,
                    loading:false,
                    failed:true
                }
            });
        }
        //#endregion

        //#region Update portfolio item 
        case types.UPDATE_PORTFOLIOITEM_REQUEST: { 
            return initialState.merge({
                edit:{
                    ...state.edit,
                    loading:true
                }
            });
        }
        case types.UPDATE_PORTFOLIOITEM_SUCCESS: {
            return state.merge({
                edit:{
                    ...state.edit,
                    loading:false,
                    success:true,
                    data:{}
                }
            });
        }
        case types.UPDATE_PORTFOLIOITEM_FAILURE: {
            return state.merge({
                edit:{
                    ...state.edit,
                    loading:false,
                    failed:true
                }
            });
        }

        //#endregion


        //#region Upload model

        case types.UPLOAD_PORTFOLIOMODEL_REQUEST: { 
            return initialState.merge({
                edit:{
                    ...state.edit,
                    loading:true
                }
            });
        }
        case types.UPLOAD_PORTFOLIOMODEL_SUCCESS: {
            return state.merge({
                edit:{
                    ...state.edit,
                    loading:false,
                    data:{
                        ...state.edit.data,
                        threedmodel:action.payload
                    }
                }
            });
        }
        case types.UPLOAD_PORTFOLIOMODEL_FAILURE: {
            return state.merge({
                edit:{
                    ...state.edit,
                    loading:false,
                    failed:true
                }
            });
        }
        //#endregion

        //#region Delete portfolio

        case types.DELETE_PORTFOLIO_REQUEST: { 
            return initialState.merge({
                get:{
                    ...state.get,
                    loading:true
                }
            });
        }
        case types.DELETE_PORTFOLIO_SUCCESS: {
            return state.merge({
                get:{
                    ...state.get,
                    loading:false,
                    success:true,
                    data:{
                        items:state.get.data.items.filter(item=>item.id!=action.payload)
                    }
                }
            });
        }
        case types.DELETE_PORTFOLIO_FAILURE: {
            return state.merge({
                get:{
                    ...state.get,
                    loading:false,
                    failed:true
                }
            });
        }
        //#endregion

        //#region Edit info
        case types.EDIT_MYPORTFOLIO:{
            return state.merge({
                ...state,
                get:{
                    ...state.get,
                    data:{
                        ...state.get.data,
                        [action.payload.name]:action.payload.value
                    }

                }
            });
        }
        //#endregion
        default: {
            return state;
        }
    }
}